import React from 'react';
import { useLoginHook } from '../../Hooks/useLoginHook';

function Packages() {
    let CONFIG = useLoginHook();
    return (
        <iframe src={`https://packages.cleartrip.com?product=perks&origin=${CONFIG.email?.split('@')[1]}`} className='w-100' style={{height:'100vh', border: 'none'}} title='Cleartrip Packages'/>
    );
}

export default Packages;