import React from 'react';
import NavBar from '../../../Component/NavBar'
import SectionHeader from '../../../Component/Containers/Section-Header'
import BreadCrumb from '../../../Component/Breadcrumb'
import Seats from './Seats'
import Travellers from './Travellers'
import Payment from '../../Payment'
import {Switch, Route, useLocation, useHistory} from 'react-router-dom'
import {useLoginHook} from "../../../Hooks/useLoginHook";
 
function Index(props) {

    let route = useLocation().pathname
    let HISTORY = useHistory()
    let CONFIG = useLoginHook();
	
	if(!CONFIG.authenticated){
        HISTORY.push('/login')
    }

    return (
        <>
        <SectionHeader color={'var(--material-blue)'}>
            <NavBar/>
            {
                !route.includes('buses/booking/success')&&
                <div className="p-1 mt-4 pb-4 d-none d-md-block">
                    <BreadCrumb steps={[{label:'Seats', active:route.includes('buses/booking')}, {label:'Travellers', active:route.includes('buses/booking/travellers')||route.includes('buses/booking/payment')}, {label:'Payment', active:route.includes('buses/booking/payment')}]} />
                    <div className='d-flex align-items-center'>
                        <h4 onClick={()=>HISTORY.goBack()} className='back-btn me-2'>
                            <i className="fas fa-arrow-left"></i>
                        </h4>
                        <h1 className='fw-bold'>
                            {
                                route.includes('buses/booking/travellers')?'Add Travellers':
                                route.includes('buses/booking/payment')?'Booking Payment':
                                route.includes('buses/booking')?'Choose Seats':<></>
                            }
                        </h1>
                    </div>
                </div>
            }
        </SectionHeader>

        <div style={{minHeight:400}}>
            <Switch>
                <Route path="/buses/booking/payment" component={Payment} exact={true}/>
                <Route path="/buses/booking/travellers" component={Travellers} exact={true}/>
                <Route path="/buses/booking" component={Seats} exact={true}/>
            </Switch>
        </div>
        </>
    );
}

export default Index;