import React, {useEffect, useState} from 'react'
import SearchCity from "../../../../Component/Form/SearchCity";
import DateInput from "../../../../Component/Form/DateInput";
import {useHistory} from "react-router-dom";
import useHistoryState from "../../../../Hooks/useHistoryState";
import Modal from '../../../../Component/Containers/Modal'
import {showToast} from "../../../../Component/Toast";
import {useCheckScreenHook} from '../../../../Hooks/useCheckScreenHook'

export default function BusSearch({from,to,date,setDate}) {
	
	let HISTORY = useHistory()
    const [getModal, setModal] = useState(false);
	const [Collapse, setCollapse] = useState(true);

    const ScreenSize = useCheckScreenHook();
	
    useEffect(() => {
        if(ScreenSize==='sm'){
            setCollapse(true)
            setModal(true)
        }else{
            setCollapse(undefined)
            setModal(undefined)
        }
    }, [ScreenSize]);

	const [BusFrom, setBusFrom] = useHistoryState("search_from",{city:from});
	const [BusTo, setBusTo] = useHistoryState("search_to",{city:to});

	const swapPoints = () => {
		let temp = BusFrom;
		setBusFrom(BusTo)
		setBusTo(temp)
	}

	const SearchBus = () => {
		if(!BusFrom){
			showToast({
				type:'error',
				message:"Select origin"
			})
			return;
		}
		if(!BusTo){
			showToast({
				type:'error',
				message:"Select  destination"
			})
			return;
		}
		if(!date){
			showToast({
				type:'error',
				message:"Select travel date"
			})
			return;
		}
		HISTORY.push(`/buses/search?from=${BusFrom.city}&to=${BusTo.city}&date=${date}`)
	}
	return (
		<div>
			<div className='d-flex align-items-center justify-content-between pt-3 pb-3'>
				<h1 className="heading-1" style={{fontWeight: 'bold'}}>
					<i className="fas fa-bus ms-3 me-3"></i>
					{from} - {to}
				</h1>
				<div className='mb-3'>
					<div onClick={()=>setCollapse(!Collapse)} className='d-flex align-items-center rounded-pill p-1 ps-3 pe-2 text-white bg-dark text-small cursor-pointer' style={{border:'1px solid #000'}}>
						Edit <h6 className='mb-1 ms-2'><svg style={{transform: !Collapse?'rotate(-180deg)':''}} xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-chevron-down"><path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" /></svg></h6>
					</div>
				</div>
			</div>
			{
				Collapse?<></>:getModal?
				<Modal onClose={()=>{setCollapse(true)}} onSuccess={()=>{SearchBus();setCollapse(true)}} title='Search Bus' button={'Search'}>
					<div className="row mt-3">
						<div className="col-md-8 col-xl-6">
							<div className="d-flex justify-content-between align-items-center h-100 p-1">
								<div style={{flex:1}}>
									<SearchCity api='/search/city' onSelect={setBusFrom} selected={BusFrom} label='From' placeholder='Search City' value=''/>
								</div>
								<div onClick={swapPoints} className="col-2 text-center"
									 style={{transform: 'rotate(90deg)', fontSize: '1rem'}}>
									<div className='swap-btn'>
										<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
											 fill="currentColor" viewBox="0 0 16 16" className="bi bi-arrow-down-up">
											<path fillRule="evenodd"
												  d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5zm-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5z"/>
										</svg>
									</div>
								</div>
								<div style={{flex:1}}>
									<SearchCity  api='/search/city' onSelect={setBusTo} selected={BusTo} label='To' placeholder='Search City' value=''/>
								</div>
							</div>
						</div>
						<div className="col-md-4 col-xl-3">
							<DateInput selectedDate={date} onSelect={setDate} border="#222" type={undefined}/>
						</div>
					</div>
				</Modal>:
				<div className="row mt-3 pb-4">
					<div className="col-md-8 col-xl-6">
						<div className="d-flex justify-content-between align-items-center h-100 p-1">
							<div style={{flex:1}}>
								<SearchCity background="#FACFCF" api='/search/city' onSelect={setBusFrom} selected={BusFrom} label='From' placeholder='Search City' value=''/>
							</div>
							<div onClick={swapPoints} className="col-2 text-center"
									style={{transform: 'rotate(90deg)', fontSize: '1rem'}}>
								<div className='swap-btn'>
									<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
											fill="currentColor" viewBox="0 0 16 16" className="bi bi-arrow-down-up">
										<path fillRule="evenodd"
												d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5zm-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5z"/>
									</svg>
								</div>
							</div>
							<div style={{flex:1}}>
								<SearchCity  background="#FACFCF" api='/search/city' onSelect={setBusTo} selected={BusTo} label='To' placeholder='Search City' value=''/>
							</div>
						</div>
					</div>
					<div className="col-md-4 col-xl-3">
						<DateInput background="#FACFCF" selectedDate={date} onSelect={setDate} border="#222" type={undefined}/>
					</div>
					<div className="col-md-4 col-xl-3 align-self-center">
						<button onClick={() => SearchBus()} className="btn btn-dark btn-lg ps-4 w-100"
								type="button">Search
							<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor"
									viewBox="0 0 16 16" className="bi bi-arrow-right ms-2 mb-1">
								<path fillRule="evenodd"
										d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
							</svg>
						</button>
					</div>
				</div>
			}
		</div>
	)
}
