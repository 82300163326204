import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import BookingCalc from '../../FareCalc'
import Loader from '../../../../Component/Loader'
import { showToast } from '../../../../Component/Toast';
import moment from 'moment';
import PreviewRoom from '../../ViewHotel/Components/RoomCard/PreviewCard'
import { post } from '../../../../Model/Config/Axios';
import SelectGuests from './SelectGuests'

function Index() {

    let HISTORY = useHistory()
    let {state} = useLocation();
    let TRAVELLERS = state.TRAVELLERS
    let ROOMS = state.ROOMS
    let SESSION = state.SESSION
    let ID = state.ID
    // let APP_ID = state.APP_ID
    let DATES = state.DATES
    let HOTEL = state.HOTEL

    const [SelectedGuests, setSelectedGuests] = useState({});
    console.log(SelectedGuests);
    const [Processing, setProcessing] = useState();
    let stayDates = moment(DATES.endDate).diff(moment(DATES.startDate), 'days')

    if(!(ROOMS||SESSION||ID||DATES||HOTEL)){
        showToast({
            type: 'error',
            message:'Invalid Session'
        })
        HISTORY.push('/hotels')
    }

    let adult = 0, child = 0, rooms = 0, total = 0;

    TRAVELLERS.forEach(item => {
        adult += item.adult
        child += item.childAges.length
        rooms++
    });
    total = ROOMS.totalFare

    const getOrderId = () => {
        if(Object.keys(SelectedGuests).length<TRAVELLERS.length){
            showToast({
                message:'Select a Guest for every room'
            })
            return
        }
        setProcessing(true)

        let details = {
            travellers: Object.keys(SelectedGuests).map(guest=>{
                return SelectedGuests[guest]
            })
        }

        post(`/hotel/hold`, {sessionId: SESSION, hotelId: ID, roomId: ROOMS.id, details}, (e,r)=>{
            if(r&&r.errors&&r.errors.length){
                setProcessing(false)
                showToast({
                    message: r.errors[0].message
                })
            }else if(r){
                HISTORY.push({
                    pathname: `/hotels/booking/payment`,
                    state: {OrderId: r.id, travellers: {adult, child, rooms, total}, rooms: ROOMS, type:'Hotel', hotel: HOTEL, guests: SelectedGuests}
                })
            }else if(e){
                setProcessing(false)
                showToast({
                    message:'Something went wrong, Try booking again'
                })
            }
            setProcessing(false)
        })
    }

    return (
        <>
        <div className="container mb-5">
            <div className="row">
                <div className="col-md-8 col-xl-9 p-3 pt-4 pt-md-5 pb-3">
                    <div className="d-flex">
                        <div style={{background: '#000', color: '#fff', height: 25, width: 25, borderRadius: 30, marginRight: 15, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 12, fontWeight: 'bold'}}><span>1</span></div>
                        <h5 style={{fontWeight: 'bold'}}>Review you Booking</h5>
                    </div>

                    {
                        ROOMS&&HOTEL?
                        <div>
                            <div className="d-flex mt-3 align-items-center flex-wrap">
                                <button className="btn btn-sm rounded-pill p-2 ps-3 pe-3 me-3 mt-3" s="button" style={{background: '#ceebf2', fontWeight: 'bold', fontSize: 16}}>{stayDates} Nights</button>
                                <h6 className='fw-bold mb-0 me-4 mt-3'>{moment(DATES.startDate).format('DD MMM YY')} to {moment(DATES.endDate).format('DD MMM YY')}</h6>
                            </div>

                            <div className="border-box mt-4 shadow-sm rounded-3">
                                <div className='row'>
                                    <div className='col-12 col-md-2'>
                                        <img src={HOTEL.details.images[0]} loading='lazy' className='me-3 rounded-4 w-100' alt='Room' style={{height: 100, objectFit:'cover'}}/>
                                    </div>
                                    <div className='col-12 col-md-10'>
                                        <h6 className="fw-bold">{HOTEL.details.name}</h6>
                                        <div>
                                            {
                                                Array(HOTEL.details.rating).fill(0).map((i,index)=>{
                                                    return <span key={index} style={{color:'#fd7e14'}}>★</span>
                                                })
                                            }
                                        </div>
                                        <div className='text-small mt-2'><i className="fas fa-map-pin me-2"/>{HOTEL.details.address.address}, {HOTEL.details.address.city}</div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className='mt-4'>
                                <h6 className='fw-bold'>Your Rooms</h6>
                                <PreviewRoom stay={stayDates} session={SESSION} hotelId={ID} roomId={ROOMS.id} room={ROOMS} travellers={{adult, child}}/>
                            </div>
                        </div>
                        :<div className="border-box mt-4 shadow-sm rounded-3 overflow-hidden text-center"><Loader/></div>
                        }

                        <SelectGuests ROOMS={ROOMS} TRAVELLERS={TRAVELLERS} selected={SelectedGuests} setSelected={setSelectedGuests}/>
                        
                    </div>
                    <div className="col-md-4 col-xl-3 p-0 pe-3 ps-3">
                        <BookingCalc button={
                            Processing?
                            <button className='btn btn-lg btn-dark rounded-pill ps-4 mt-4' style={{border:'2px solid #000'}}>
                                <div className="spinner m-auto"><div className="bounce1" /><div className="bounce2" /><div className="bounce3" /></div>
                            </button>
                            :<button onClick={getOrderId} className='btn btn-lg btn-dark mt-4 rounded-pill' style={{border:'2px solid #000'}}>Continue<i className="fas fa-arrow-right ms-3"/></button>
                        } adult={adult} child={child} rooms={rooms} price={total} coins={0} fee={0} />
                    </div>
                </div>
            </div>
            </>
    );
}

export default Index;