import React, {useState} from 'react';
import './ImageViewer.css'

function ImageViewer({images, title}) {
    const [Show, setShow] = useState(false);
    const [Selected, setSelected] = useState(0);

    return (
        <>
        <div className='image-thumbnail'>
            <img src={images[0]} className='rounded-4 w-100 cursor-pointer' alt='Hotel' style={{height:350, objectFit:'cover'}}/>
            <div onClick={()=>setShow(true)} className='thumb rounded-4' style={{fontSize:'2rem'}}>View +{images.length-3} Photos</div>
        </div>
        <div className='d-flex align-items-center w-100 mt-3'>
            {
                images.length>1&&
                <div className='image-thumbnail flex-fill me-3'>
                    <img src={images[1]} className='rounded-4 w-100 cursor-pointer' alt='Hotel' style={{height:160, objectFit:'cover'}}/>
                    <div onClick={()=>setShow(true)} className='thumb rounded-4' style={{fontSize:'1.5rem'}}>View +{images.length-3} Photos</div>
                </div>
            }
            {
                images.length>2&&
                <div className='image-thumbnail flex-fill'>
                    <img src={images[2]} className='rounded-4 w-100 cursor-pointer' alt='Hotel' style={{height:160, objectFit:'cover'}}/>
                    <div onClick={()=>setShow(true)} className='thumb show rounded-4' style={{fontSize:'1.5rem'}}>View +{images.length-3} Photos</div>
                </div>
            }
        </div>
        {
            Show?
            <div className='FadeUp-Effect image-viewer d-flex flex-column p-5'>
                <div className='d-flex justify-content-between w-100 text-white'>
                    <h4 className='text-truncate'>{title}</h4>
                    <div onClick={()=>setShow(false)} className='p-1 cursor-pointer' style={{fontSize:'1.5rem'}}><i className='fas fa-times'/></div>
                </div>
                <div className='mt-4 flex-fill'>
                    <div className='row h-100'>
                        <div className='col-12 col-md-9 full-img pe-md-5'>
                            <img onClick={()=>window.open(images[Selected], '_blank')} src={images[Selected]} className='rounded-4 w-100 h-100 cursor-pointer' alt='Hotel' style={{objectFit:'cover'}}/>
                        </div>
                        <div className='col-12 col-md-3 h-100'>
                            <div className='carousel-img pe-3 pb-3' style={{overflowY:'scroll'}}>
                                {
                                    images.map((img,index)=>{
                                        return (
                                            <img onClick={()=>setSelected(index)} key={index} src={img} className='rounded-4 cursor-pointer me-4 me-md-0 mb-0 mb-md-4' alt='Hotel' style={{objectFit:'cover', height:200}}/>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            :<></>
        }
        </>
    );
}

export default ImageViewer;