import React from 'react';
import CheckIcon from '../../Assets/Images/check.png'
import WarningIcon from '../../Assets/Images/info.webp'
import Back from '../../Assets/Images/circle.webp'
import { useHistory } from 'react-router-dom';
import ViewTicket from '../Bookings/View'

function Processing({State, transId}) {

    let HISTORY = useHistory()

    if(State==='ONGOING'){
        return (
            <div className='d-flex flex-center h-100 text-center'>
                <div className='p-4 bg-white'>
                    <h2>Processing your Booking</h2>
                    <div className='text-small'>Please wait while we confirm your booking</div>
                    <div className='payment-ongoing m-auto mt-4'/>
                </div>
            </div>
        );
    }

    if(State==='PAYMENT'){
        return (
            <div className='d-flex flex-center h-100 text-center'>
                <div className='p-4 bg-white'>
                    <h2>Processing Payment</h2>
                    <div className='text-small'>Please wait while we receive your payment</div>
                    <div className='payment-ongoing m-auto mt-4'/>
                </div>
            </div>
        )
    }

    if(State==='COMPLETED' || State==='SUCCESS' || State==='CAPTURED'){
        return (
            <div className='d-flex flex-center p-4 h-100 text-center h-100'>
                <div className='w-100'>
                    <div className='mb-3 p-3' style={{background: `url(${Back})`, backgroundPosition: 'center', backgroundSize:'contain', backgroundRepeat: 'no-repeat'}}>
                        <img src={CheckIcon} className='mt-1' alt='Icon' style={{maxWidth:70}}/>
                    </div>
                    <h2>Booking Done</h2>
                    <div className='text-small'>Your booking was completed and we are awaiting for the confirmation.<br/> We will also email you the tickets on your registered email.</div>
                    <div className='text-start mt-5 bg-light border rounded-4 p-3'>
                        <ViewTicket id={transId} type={''}/>
                    </div>
                </div>
            </div>
        );
    }

    if(State==='HOLD_FAIL'){
        return (
            <div className='d-flex flex-center h-100 text-center m-auto h-100'>
                <div>
                    <div className='mb-3 p-3' style={{background: `url(${Back})`, backgroundPosition: 'center', backgroundSize:'contain', backgroundRepeat: 'no-repeat'}}>
                        <img src={WarningIcon} className='mt-1' alt='Icon' style={{maxWidth:70}}/>
                    </div>
                    <h2>Failed to hold</h2>
                    <div className='text-small'>We faced some problems in fetching information about this booking, <br/>You can back and try booking again.</div>
                    <button onClick={()=>HISTORY.push('/')} className='btn btn-dark mt-4'>Go to Home <i className='fas fa-arrow-right ms-1'/></button>
                </div>
            </div>
        )
    }

    if(State==='PAYMENT_FAIL'){
        return (
            <div className='d-flex flex-center h-100 text-center m-auto h-100'>
                <div>
                    <div className='mb-3 p-3' style={{background: `url(${Back})`, backgroundPosition: 'center', backgroundSize:'contain', backgroundRepeat: 'no-repeat'}}>
                        <img src={WarningIcon} className='mt-1' alt='Icon' style={{maxWidth:70}}/>
                    </div>
                    <h2>Payment Failed</h2>
                    <div className='text-small'>We faced a problem in completing your payment, <br/>If money was not debited you can try booking again.</div>
                    <button onClick={()=>HISTORY.push('/bookings')} className='btn btn-dark mt-4'>Go to Bookings <i className='fas fa-arrow-right ms-1'/></button>
                </div>
            </div>
        )
    }

    if(State==='BOOKING_FAILED'){
        return (
            <div className='d-flex flex-center h-100 text-center m-auto h-100'>
                <div>
                    <div className='mb-3 p-3' style={{background: `url(${Back})`, backgroundPosition: 'center', backgroundSize:'contain', backgroundRepeat: 'no-repeat'}}>
                        <img src={WarningIcon} className='mt-1' alt='Icon' style={{maxWidth:70}}/>
                    </div>
                    <h2>Booking Failed</h2>
                    <div className='text-small'>We faced a problem in booking this ticket, <br/>Go to bookings to check updated status for your booking.</div>
                    <button onClick={()=>HISTORY.push('/bookings')} className='btn btn-dark mt-4'>Go to Bookings <i className='fas fa-arrow-right ms-1'/></button>
                </div>
            </div>
        )
    }

    return (
        <div className='d-flex flex-center h-100 text-center m-auto h-100'>
            <div>
                <div className='mb-3 p-3' style={{background: `url(${Back})`, backgroundPosition: 'center', backgroundSize:'contain', backgroundRepeat: 'no-repeat'}}>
                    <img src={WarningIcon} className='mt-1' alt='Icon' style={{maxWidth:70}}/>
                </div>
                <h2>Failed</h2>
                <div className='text-small'>We were unable to completed this booking, <br/>If money was not debited, Please go back and try booking again</div>
                <button onClick={()=>HISTORY.push('/bookings')} className='btn btn-dark mt-4'>Go to Bookings <i className='fas fa-arrow-right ms-1'/></button>
            </div>
        </div>
    );
}

export default Processing;