import React, {useEffect, useId, useRef, useState} from 'react'
import Props from 'prop-types'
import './Input.css'
import axios from 'axios'
import { showToast } from '../../Toast'

/**
 *@author [Aakash Bhadana](https://github.com/aakashbhadana)
 *
 * Default File Input with All classes
 */

 const FileInput = React.forwardRef(({label, type, className, updateFiles, mono, status}, ref) => {

    const ID = useId();

    let FILE_REF = useRef(undefined);
    const [FileName, setFileName] = useState(undefined);

    const [Uploading, setUploading] = useState(false);

    useEffect(() => {
        if(status){
            status(Uploading)
        }
    }, [Uploading, status]);

    const uploadFile = async (file) => {
		if (file) {
			const formData = new FormData();
			formData.append('file', file);
            setUploading(true);

			await axios({
				method: 'post',
                url: 'https://file.eka.io/file',
				data: formData,
				headers: {
					clientId: '422dc3e2-a960-4941-9a7a-af64732157b5',
					clientSecret: "9cd4ce3e-6ea6-45d7-bf92-06c9c7a69873"
				}
			}).then(res => {
                if(updateFiles){
                    updateFiles(res.data.url)
                }
                setUploading(false);
                if(mono){
                    setFileName(file.name)
                }
			})

		}
	};

    const selectFile = () => {
        let file;
        if(type==='multiple'){
            file = FILE_REF.current.files;
            Object.keys(file).forEach(item => {
                if(file[item].name){
                    uploadFile(file[item])
                }else{
                    showToast({
                        type:'error',
                        message:'Invalid file, only images and documets are allowed'
                    })
                }
            });
        }else{
            file = FILE_REF.current.files[0];
            if(checkExtension(file.name)){
                uploadFile(file)
            }else{
                showToast({
                    type:'error',
                    message:'Invalid file, only images and documets are allowed'
                })
            }
        }
    }

    const checkExtension = (name) => {
        let Extensions = ['.jpg','.jpeg','.png','.bmp','.pdf','.xls', '.xlsx', '.sheet','.doc', '.docx', '.page', '.ppt', '.pptx', '.csv', '.eml'];
        let FLAG = false;

        Extensions.forEach(ext => {
            if(name.endsWith(ext) && FLAG===false){
                FLAG = true;
            }
        });

        return FLAG
    }

    return (
        <>
        <div className={`${className}`}>
            {label&&
            <div className='text-start mb-1'>
				<label className='text-input-label text-truncate'>{label}</label>
			</div>
            }
            <input type='file' onChange={selectFile} id={ID} ref={(r)=>{FILE_REF.current=r;if(ref){ref.current=r}}} style={{display:'none'}} multiple={type==='multiple'}/>
            {
                FileName?
                <div className='text-small p-1 rounded-3 bg-light'>{FileName} <i onClick={()=>setFileName(undefined)} className='fa fa-times-circle cursor-pointer me-1'/></div>:
                <label htmlFor={ID} className='file-input'>
                    {
                        Uploading?
                        <div className='mini-loader'></div>
                        :<i className="fas fa-paperclip"/>
                    }
                </label>
            }
        </div>
        </>
    );
})

FileInput.propTypes = {
	/**
	 * Use external css classes
	 */
	className:Props.string,
    /**
	 * By default multiple file selection is allowed, pass type 'single' to make single file select.
	 */
	type: Props.string,
    /**
	 * Array of previous files, required if using multiple files select
	 */
	files: Props.array,
    /**
	 * Function to update previous files array
	 */
	updateFiles: Props.func,
}

export default FileInput;
