import React, {useEffect, useState} from 'react';
import ChatBox from '../../Component/ChatBox/ChatBox'
import {showToast} from '../../Component/Toast'
import {get, post} from '../../Model/Config/Axios'
import Loader from '../../Component/Loader'

function Chat({onClose, id}) {
    
    const [Details, setDetails] = useState(undefined);

    useEffect(() => {
        fetchTicket(id)
        let interval = setInterval(() => {
            fetchTicket(id)
        }, 5000);

        return ()=>{
            clearInterval(interval)
        }
    }, [id]);

    const fetchTicket = (ID) => {
        get(`support/${ID}`, (e,r)=>{
            if(r){
                setDetails(JSON.parse(r))
            }else if(e){
                showToast({
                    type:'error',
                    message: 'Problem in opening Mail'
                })
            }
        })
    }

    const sendMessage = (type, content, ID) => {
        if(!content || !type || !ID){
            return
        }

        post(`support/${ID}`, {content, type}, (e,r)=>{
            if(r){
                fetchTicket(ID)
            }else if(e){
                showToast({
                    type:'error',
                    message: 'Problem in sending message'
                })
            }
        })
    }

    if(!Details){
        return <div className='w-100 h-100 d-flex flex-center'><Loader/></div>
    }

    return (
        <>
        <ChatBox id={id} onClose={onClose} title={Details&&Details.title} category={Details&&Details.category} comments={Details?Details.comments:[]} onSend={sendMessage}/>
        </>
    );
}

export default Chat;