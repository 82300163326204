import React, {useId} from 'react'
import Props from 'prop-types'
import moment from 'moment';
import './Input.css'

const DateInput = React.forwardRef(({label, onChange, disabled, defaultValue, className}, ref, type) => {

	const ID = useId();

	const changeEvent = (e) => {
		let val = e.target.value;
		if(onChange){
			val = moment(moment(val,'YYYY-MM-DD')).valueOf()
			onChange(val)
		}
	}

	return (
		<div className={`${className}`}>
			<div className='form-text text-start'>
				{label&&<label htmlFor={ID} className={`text-input-label mb-1`}>{label}</label>}
			</div>
			<input className='form-control' type={type?type:'date'} style={{textTransform:'uppercase'}} id={ID} onChange={changeEvent} ref={ref} disabled={disabled} defaultValue={defaultValue?moment(defaultValue).format('YYYY-MM-DD'):''}/>
		</div>
	)
})

DateInput.defaultProps = {
}

DateInput.propTypes = {
	/**
	 * Use external css classes
	 */
	className:Props.string,
	/**
	 * Function to handle onChange event
	 */
	onChange:Props.func,
	/**
	 * Default value of input
	 */
	defaultValue: Props.number,
	/**
	 * Make input disabled
	 */
	disabled: Props.bool,
	/**
	 * Label for input
	 */
	label: Props.string,
}

export default DateInput;
