import React from 'react';

function Index({icon, title, description, onClick= ()=>{} }) {
    return (
        <>
        <div onClick={onClick} className="col-md-4 mt-3">
            <div className="white-cards shadow-sm">
                <h4 className="fw-bold">{icon&&icon}{title}</h4>
                <p className="m-0">{description}</p>
            </div>
        </div>
        </>
    );
}

export default Index;
