import React from "react";
import NavBar from "../../Component/NavBar";
import SectionHeader from "../../Component/Containers/Section-Header";
import { useLoginHook } from "../../Hooks/useLoginHook";
import BasicInfo from './BasicInfo'
import Travellers from './Travellers'
import User from './User'

function Index() {
  let CONFIG = useLoginHook();

  if (!CONFIG.authenticated) {
    CONFIG.Login();
  }

  return (
    <>
        <SectionHeader color={"var(--material-blue)"}>
            <NavBar />
            <div className="p-1 mt-4 pb-4 ps-2">
                <h5>Settings</h5>
                <h1 className="display-5" style={{fontWeight: 'bold'}}>Your Profile</h1>
            </div>
        </SectionHeader>

        <div className="container mb-5">
            <div className="row">
                <div className="col-md-4 col-xl-3 col-xxl-4">
                    <User/>
                </div>
                <div className="col-md-8 col-xl-9 col-xxl-8 overflow-scroll overflow-x-hidden">
                    <BasicInfo/>
                    <Travellers/>
                </div>
            </div>
        </div>
    </>
  );
}

export default Index;
