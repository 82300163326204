import React, {useEffect, useState} from 'react'
import packageJson from '../../../package.json';
import './AppUpdate.css'
// import Icon from './disco.webp'

const semverGreaterThan = (versionA, versionB) => {
    const versionsA = versionA.split(/\./g);
    const versionsB = versionB.split(/\./g);
    while (versionsA.length || versionsB.length) {
      const a = Number(versionsA.shift());
      const b = Number(versionsB.shift());
      // eslint-disable-next-line no-continue
      if (a === b) continue;
      // eslint-disable-next-line no-restricted-globals
      return a > b || isNaN(b);
    }
    return false;
  };

function CahceBuster() {

  const [Update, setUpdate] = useState(false);

  useEffect(() => {
    if (caches) {
      caches.keys().then(function(names) {
        for (let name of names) caches.delete(name);
      });
      console.log('Cache Cleared!!')
    }
    // fetch('./meta.json').then((response) => response.json()).then((meta) => {
    //   console.log('Version Checked',meta.version, packageJson.version)
      
    //   setUpdate(semverGreaterThan(meta.version, packageJson.version))

    //   if(semverGreaterThan(meta.version, packageJson.version)){
    //     if (caches) {
    //       caches.keys().then(function(names) {
    //         for (let name of names) caches.delete(name);
    //       });
    //       console.log('Cache Cleared!!')
    //     }
    //   }
    //   });
  }, []);

  const clearCache = (params) => {
    if (caches) {
      caches.keys().then(function(names) {
        for (let name of names) caches.delete(name);
      });
      console.log('Cache Cleared!!')
      window.location.reload();
    }
  }

  if(!Update){
    return <></>
  }

  return (
    <div className='FadeUp-Effect update-wrapper'>
        <div className='notification'>
              {/* <img src={Icon} style={{width:100}} alt='Update'/> */}
              <h5>Update Available</h5>
              <p>We have a new update available, Do you want clear cache and update now?</p>
              <button onClick={clearCache}>Update now</button>
              <span onClick={()=>setUpdate(false)} className='cancel-btn'>Cancel</span>
        </div>
    </div>
  );

}

export default CahceBuster;