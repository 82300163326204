import React from 'react';

function Travellers({details,copy, onReload}) {

    if(!details?.ticket?.travellers.length)
    return <></>

    return (
        <>
        <div className='mt-3'>
            <label className='text-muted text-small'>Travellers</label><br/>
            {
                details?.ticket?.travellers.map((person,index)=>{
                    return (
                        <div className='overflow-hidden bg-light border rounded-3 mt-2 p-3'>
                        <div key={index} className='row'>
                            <div className='col-12 col-md-4'>
                                <span className='text-small fw-bold'>{person.fName} {person.lName} ({person.type})</span>
                            </div>
                            {person.pnrs&&Object.keys(person.pnrs).length?
                                <div className='col-12 col-md-8 text-small'>
                                    <span className='text-small fw-bold'>PNR</span>
                                    {
                                        Object.keys(person.pnrs).map((key, index)=>{
                                            return (
                                                <div className='d-flex w-100 mt-1'>
                                                    <div className='w-25'>{key.split('-')[0]} - {key.split('-')[1]}</div>
                                                    <div className='w-75 ps-3'>
                                                        <i className="me-2 fas fa-ticket-alt"></i> {person.pnrs[key]}
                                                        <i onClick={()=>copy(person.pnrs[key])} className="ms-2 cursor-pointer far fa-copy"></i>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>:
                                <div className='p-2'>
                                    <div className="border-box text-tiny border rounded-4" style={{background: '#FEF3CC', border: 'none'}}>
                                        This is a group fare, PNRs are updated 24 hrs before departure, updated booking will be sent on your email.
                                    </div>
                                </div>
                            }
                        </div>
                        </div>
                    )
                })
            }
        </div>
        </>
    );
}

export default Travellers;