import React from 'react';
import moment from 'moment';

function Details({seats, details, points, showTotal=true}) {

    let total = 0;
    const {pick, drop} = points
    seats.forEach(seat => {
        total+=seat.price
    });

    return (
        <>
        <div className="bg-white rounded-4 shadow-sm mt-2 p-4" >
            <h6 className='fw-bold'>Boarding and Drop Point</h6>

            <div className='d-flex mt-4'>
                <div className='position-relative ms-1 me-3 mt-2 mb-2' style={{width:2, borderLeft: '1px dashed #000'}}>
                    <div style={{width:5, height:5, borderRadius:5, background:'#000', position:'absolute', top:0, left:-3}}/>
                    <div style={{width:5, height:5, borderRadius:5, background:'#000', position:'absolute', bottom:0, left:-3}}/>
                </div>
                <div className='w-100'>
                    <div className='d-flex align-items-center'>
                        {
                            pick?
                            <div className='text-nowrap bg-light p-1 rounded-3 border me-3 text-small'>
                                {moment(pick.time).format('HH:mm A')}
                            </div>
                            :<></>
                        }
                        <div className='text-nowrap text-small text-truncate overflow-hidden'>{pick?pick.name:<span className='text-small'>Board</span>}</div>
                    </div>
                    <div className='d-flex align-items-center mt-4'>
                        {
                            drop?
                            <div className='text-nowrap bg-light p-1 rounded-3 border me-3 text-small'>
                                {moment(drop.time).format('HH:mm A')}
                            </div>
                            :<></>
                        }
                        <div className='text-nowrap text-small text-truncate overflow-hidden'>{drop?drop.name:<span className='text-small'>Drop</span>}</div>
                    </div>
                </div>
            </div>

            <h6 className='fw-bold mt-4'>Seats</h6>
            <div>
                <div className='d-flex flex-wrap align-items-center'>
                    {seats.length?
                        seats.map(seat=>{
                            return <div className='border p-1 ps-2 pe-2 rounded-3 me-2 mt-2 text-small'>{seat.code}</div>
                        })
                    :'--'}
                </div>
            </div>

            {showTotal&&<div className='mt-4 d-flex justify-content-between align-items-end w-100 border-top pt-3'>
                <h4 className='fw-bold'>Total </h4>
                <h4 className='fw-bold'>INR {total}</h4>
            </div>}
        </div>
        </>
    );
}

export default Details;