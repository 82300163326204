import React from 'react';
import './index.css'
import moment from 'moment';
import { priceBreakup } from '../../../HotelUtils';

function Index({selected, travellers, onCancel, onBook, dates}) {

    const stayNights = moment(dates.endDate).diff(moment(dates.startDate), 'days');

    let Total = 0, adult = 0, child = 0, Rooms = 0;

    travellers.forEach(item => {
        adult += item.adult
        child += item.childAges.length
        Rooms++
    });
    Total = selected.totalFare

    if(selected){
        return (
            <>
            <div className='FadeUp-Effect info-pane shadow-lg d-flex justify-content-center align-items-center'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 col-md-8 p-4 d-flex flex-column justify-content-center'>
                            <div>
                                <h5 className='fw-bold mt-3'>
                                    <i className="fas fa-bed me-1"/> {Rooms} Room x {adult} Adult {child?<> + {child} Child</>:<></>} x {stayNights} Night
                                </h5>
                                <div className='d-flex align-items-center flex-wrap mt-3' style={{margin:'-0.5rem'}}>
                                    {
                                         <div className='rounded-3 p-1 ps-2 pe-2 text-small m-1' style={{border: '1px solid #000'}}>
                                         {selected.name.split('Non-refundable')[0]}
                                     </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-md-4 p-4 pt-0 pt-md-4 d-flex flex-column justify-content-center'>
                            <div>
                                <span className='text-small text-muted ms-2 d-none d-md-block'>Total Amount</span>
                                <div className='d-flex align-items-center'>
                                    <h3 className='m-0 ms-2'>₹ {Math.ceil(Total)}</h3>
                                    <div className='text-small ms-2'>(Includes ₹ {priceBreakup(Total).tax} tax)</div>
                                </div>
                            </div>
                            <div className='d-flex flex-wrap align-items-center'>
                                <button onClick={onBook} className='btn btn-dark btn-lg rounded-pill ps-4 pe-4 me-3 mt-2 text-truncate'>Book<i className="fas fa-arrow-right ms-2"></i></button>
                                <button onClick={()=>onCancel()} className='btn btn-outline-dark btn-lg rounded-pill ps-4 pe-4 mt-2 text-truncate'>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
        );
    }else{
        return <></>
    }
}

export default Index;