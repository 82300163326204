import React from 'react';
import Dice from './dice.png'
import Dice_White from './dice_white.png'
import { useHistory } from 'react-router-dom';

function Index({white}) {

    let HISTORY = useHistory()

    return (
        <div onClick={()=>HISTORY.push('/')} className='mt-2 mb-2'>
            <img alt='Dice Logo' className="me-2" src={white?Dice_White:Dice} style={{maxWidth: 110}}/>
        </div>
    );
}

export default Index;