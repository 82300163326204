import React from 'react';
import RoomCard from './RoomCard/RoomCard'
import Loader from '../../../../Component/Loader'
import moment from 'moment';

function RoomsRendrer({rooms, travellers, selected, onSelect, dates}) {

    const stayNights = moment(dates.endDate).diff(moment(dates.startDate), 'days');

    //Clubbing Rooms according to different meal options
    let CLUBBED = {};
    if(rooms&&rooms.length){
        rooms.forEach(room => {
            let {name, description, items, totalFare, id, inPolicy, amountError, errors} = room
            if(name){
                name = name.split('-')[0];
                //LowerCase to match strings
                description = description.toLowerCase()
                
                //Check if Room Names are same and prices are same

                if(CLUBBED[name]){
                    let options = [...CLUBBED[name].options]
                    let option = {
                        halfBoard: (description.includes('half board') || description.includes('lunch/dinner')) && !description.includes('room only'),
                        fullBoard: (description.includes('full board') || (description.includes('breakfast')&&description.includes('lunch')&&description.includes('dinner')&& !description.includes('lunch/dinner'))) && !description.includes('room only'),
                        breakfast: description.includes('breakfast') && !description.includes('lunch') && !description.includes('dinner') && !description.includes('room only'),
                        roomOnly: description.includes('room only'),
                        refundable: !description.includes('Non-refundable'),
                        items,
                        price: totalFare,
                        id,
                        inPolicy,
                        amountError,
                        errors
                    }
                    options.push(option)
                    CLUBBED[name] = {room: CLUBBED[name].room, options}
                }else{
                    CLUBBED[name] = {room, options: [{
                        halfBoard: (description.includes('half board') || description.includes('lunch/dinner')) && !description.includes('room only'),
                        fullBoard: (description.includes('full board') || (description.includes('breakfast')&&description.includes('lunch')&&description.includes('dinner'&& !description.includes('lunch/dinner')))) && !description.includes('room only'),
                        breakfast: description.includes('breakfast') && !description.includes('lunch') && !description.includes('dinner') && !description.includes('room only'),
                        refundable: !description.includes('Non-refundable'),
                        roomOnly: description.includes('room only'),
                        items,
                        price: totalFare,
                        id,
                        inPolicy,
                        amountError,
                        errors
                    }]}
                }
            }
        });
    }

    if(!rooms){
        return <div className='w-100 h-100 d-flex flex-center'><Loader/></div>
    }
    
    if(!rooms.length){
        return (
            <div className='d-flex flex-center rounded-md p-4 bg-light'>
                <h3><i className='fas fa-bed mr2'/>This Hotel is sold Out</h3>
            </div>
        )
    }

    return (
        Object.keys(CLUBBED).map((roomKey, index)=>{
            return (
                <RoomCard travellers={travellers} stay={stayNights} selected={selected} onSelect={onSelect} key={index} roomDetails={CLUBBED[roomKey]}/>
            )
        })
    );
}

export default RoomsRendrer;