import moment from 'moment';
import React from 'react';

function Bus({BUS_DETAILS, POINTS, TRAVELLERS}) {

    return ( 
        <>
            <h5 style={{fontWeight: 'bold'}}>Booking Details</h5>
            <div className='row'>
                <div className='col-md-6 mt-3'>
                    <div className="p-3 border bg-light rounded-3 h-100">
                        <h6>{BUS_DETAILS.provider} - {BUS_DETAILS.label}</h6>
                        
                        <h6 className='mt-3'><b>Seats</b> {Object.keys(TRAVELLERS).map(seat=>`[${seat}] `)}</h6>
                        <h6 className="fw-bold mt-3">Passengers ({Object.keys(TRAVELLERS).length})</h6>
                        <p className="m-0 text-small">
                        {
                            Object.values(TRAVELLERS).map((person,i)=>{
                                return <div key={i}><b>{person.name&&person.name}</b> ({person.email&&person.email}, {person.mobile&&person.mobile})<br/></div>
                            })
                        }
                        </p>
                    </div>
                </div>
                <div className='col-md-6 mt-3'>
                    <div className="p-3 border bg-light rounded-3 h-100">
                        <h6 className="fw-bold">Board</h6>
                        <div className="m-0 text-small">
                            <div className="mt-2 text-small mb-3">
                                <div>Time - {moment(POINTS.pick.time).format('hh:MM A DD MMM YY')}</div>
                                <div>Place - {POINTS.pick.name}</div>
                            </div>
                        </div>
                        <h6 className="fw-bold">Drop</h6>
                        <div className="m-0 text-small">
                        <div className="mt-2 text-small mb-3">
                                <div>Time - {moment(POINTS.drop.time).format('hh:MM A DD MMM YY')}</div>
                                <div>Place - {POINTS.drop.name}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
     );
}

export default Bus;
