import React from 'react';
import { useHistory } from 'react-router-dom';
import Cleartrip from './cleartrip.png'
import Cleartrip_white from './cleartrip_white.png'

function Index({white}) {

    let HISTORY = useHistory()

    return (
        <div onClick={()=>HISTORY.push('/')} className='mt-2 mb-2'>
            <img alt='Cleartrip Logo' className="me-2" src={white?Cleartrip_white:Cleartrip} style={{maxWidth: 135}}/>
        </div>
    );
}

export default Index;