import React, {useState, useEffect} from 'react';
import CheckboxFilter from './Components/CheckboxFilter'
import Modal from '../Containers/Modal'
import {useCheckScreenHook} from '../../Hooks/useCheckScreenHook'

function Index({count, showFilters,maxValue=350000, closeFilters, filters, onApply}) {

    const ScreenSize = useCheckScreenHook()

    let TIMES = [{name:'Early Morning', value:6},{name:'Morning', value:11},{name:'Afternoon', value:15},{name:'Evening', value:19},{name:'Night', value:24}]
    let TYPE = [{name:'Sleeper', value:'SLEEPER'},{name:'Seater', value:'SEATER'}, {name: 'Sleeper & Seater', value: 'SEATER_SLEEPER'}]

    const [TimeFilter, setTimeFilter] = useState([]);
    const [TypeFilter, setTypeFilter] = useState([]);

    const [getModal, setModal] = useState(showFilters);

    useEffect(() => {
        if(ScreenSize==='sm'){
            setModal(true)
        }else{
            setModal(undefined)
        }
    }, [ScreenSize]);

    useEffect(() => {
        if(onApply){
            onApply({...filters, time: TimeFilter, type: TypeFilter})
        }
        // eslint-disable-next-line
    }, [TimeFilter, TypeFilter]);


    if(getModal)
    return (
            showFilters?
            <Modal onClose={()=>closeFilters(undefined)} onSuccess={()=>{closeFilters(undefined)}} title='Filter' button={'Apply'}>
                <div className='w-100'>
                    <CheckboxFilter heading='Departure Time' options={TIMES} onChange={setTimeFilter} selected={TimeFilter}/>
                    <CheckboxFilter heading='Bus Type' options={TYPE} onChange={setTypeFilter} selected={TypeFilter}/>
                </div>
            </Modal>
            :<></>
    )
    else
    return (
        <div>
            <CheckboxFilter heading='Departure Time' options={TIMES} onChange={setTimeFilter} selected={TimeFilter}/>
            <CheckboxFilter heading='Bus Type' options={TYPE} onChange={setTypeFilter} selected={TypeFilter}/>
        </div>
    )
}

export default Index;
