import React from 'react';
import moment from 'moment';

function Index({travellers=[], onDelete, onEdit}) {
    
    return (
        travellers.length?
        travellers.map((traveller, index)=>{
            return (
                <div key={index} className="FadeUp-Effect m-0 mt-3 row bg-light border p-3 rounded-3">
                    <div className='col-md-4 align-self-center'><h6 className="m-0 me-3 mt-2"><i className="fas fa-user me-2"></i> {traveller.fName} {traveller.lName}</h6></div>
                    <div className='col-md-5 align-self-center'>
                        <p className="m-0 me-3 mt-2" style={{ fontSize: "0.8rem" }}>
                            {traveller.email?`Email: ${traveller.email}, `:''}
                            {traveller.mobile?`Mobile: ${traveller.mobile}, `:''}
                            {traveller.dob?`DOB: ${moment(traveller.dob).format('DD MMM YYYY')}, `:''}
                            {traveller.gender?`Gender: ${traveller.gender}`:''}
                        </p>
                    </div>
                    <div className='col-md-3 d-flex align-self-center justify-content-end'>
                        {/* <button onClick={()=>{onEdit(traveller)}} className='btn btn-small btn-secondary mt-2 me-2'>Edit</button> */}
                        <button onClick={()=>{onDelete(traveller)}} className='btn btn-small btn-dark mt-2'>Delete</button>
                    </div>
                </div>
            )
        }):
            <div className="mt-3 d-flex flex-wrap align-items-center border p-2 rounded-3">
                <p className="m-auto" style={{ fontSize: "0.8rem" }}>
                No Travellers added
                </p>
            </div>
    );
}

export default Index