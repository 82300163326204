import React from 'react';
import Travellers from './Travellers'
import Itenary from './Itenary'

function Flight({details, type, copyValue}) {
    return (
        <>
         {
            details?.ticket?.segments.length?
            <div>
                <label className='text-muted text-small mt-4'>Itenary</label>
                <Itenary type={type} segments={details.ticket.segments}/>
            </div>
            :<></>
        }
        <Travellers details={details} copy={copyValue}/>
        </>
    );
}

export default Flight;