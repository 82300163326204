import { useHistory } from "react-router-dom";
import {useState} from "react";

function useHistoryState(key, initialValue){
	const history = useHistory();
	const [rawState, rawSetState] = useState(() => {
		const value = history.location.state?.[key];
		return value ?? initialValue;
	});
	function setState(value) {
		history.replace({
			...history.location,
			state: {
				...history.location.state,
				[key]: value
			}
		});
		rawSetState(value);
	}
	return [rawState, setState];
}

export default useHistoryState;
