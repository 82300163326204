import React from 'react';
import NavBar from '../../../Component/NavBar'
import SectionHeader from '../../../Component/Containers/Section-Header'
import BreadCrumb from '../../../Component/Breadcrumb'
import Review from './Review'
import Payment from '../../Payment'
import {Switch, Route, useLocation, useHistory} from 'react-router-dom'
import {useLoginHook} from "../../../Hooks/useLoginHook";
 
function Index(props) {

    let route = useLocation().pathname
    let HISTORY = useHistory()

    let CONFIG = useLoginHook();
	
	if(!CONFIG.authenticated){
        HISTORY.push('/login')
    }

    return (
        <>
        <SectionHeader color={'var(--material-blue)'}>
            <NavBar/>
            {
                !route.includes('hotels/booking/success')&&
                <div className="p-1 mt-4 pb-4 d-none d-md-block">
                    <BreadCrumb steps={[{label:'Review', active:route.includes('hotels/booking')}, {label:'Guests', active:route.includes('hotels/booking/guests')||route.includes('hotels/booking/payment')}, {label:'Payment', active:route.includes('hotels/booking/payment')}]} />
                    <h1 style={{fontWeight: 'bold'}}>
                        Complete Booking
                    </h1>
                </div>
            }
        </SectionHeader>

        <Switch>
            <Route path="/hotels/booking/payment" component={Payment} exact={true}/>
            <Route path="/hotels/booking" component={Review} exact={true}/>
        </Switch>
        </>
    );
}

export default Index;