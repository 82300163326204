import React, {useRef} from 'react';
import TicketIcon from './ticket.webp'
import {post} from '../../Model/Config/Axios'
import {showToast} from '../../Component/Toast'
import TextInput from '../../Component/Form/Inputs/TextInput'
import SelectInput from '../../Component/Form/Inputs/SelectInput'

function AddTicket({onReload, onClose}) {

    let TITLE = useRef(), ISSUE  = useRef()

    // const CATEGORIES = ['Technical','Unable to submit Invoice','Inbox','Not receiving approvals', 'Approval not working']

    const createTicket = () => {

        let title = TITLE.current.value
        let category = ISSUE.current.value
        if(!title){
            showToast({
                type:'error',
                message:'Enter a title for Ticket'
            })
            return
        }
        
        if(!category){
            showToast({
                type:'error',
                message:'Select an issue for Ticket'
            })
            return
        }

        post(`/support`, {title, category: category}, (e,r)=>{
            if(r){
                TITLE.current.value = ''
                ISSUE.current.value = 'NONE'
                onReload()
            }else if(e){
                showToast({
                    type:'error',
                    message:'Problem in opening Mail'
                })
            }
        })
    }

    return (
        <>
        <div className='FadeUp-Effect h-100 d-flex flex-column flex-center position-relative new-ticket'>
            <div className='w-100 text-center'  style={{maxWidth:'400px'}}>
                <img src={TicketIcon} style={{width:'50px'}} alt='Ticket Icon'/>
                <h4 className='fw-bold mb-4'> Create New Ticket</h4>
                <TextInput ref={TITLE} type='text' placeholder='Enter Ticket Title' className='m-auto'/>
                <SelectInput className='mt-2' ref={ISSUE} placeholder='Select a Issue' options={[{label:'Not able to Book', value: 'BOOKING'},{label:'Issue with existing booking', value:'TICKET'}]}/>
                <div className='w-100 text-center'>
                </div>
                <button onClick={createTicket} className='btn btn-dark rounded-pill mt-4'>
                    Create<i className="ms-2 fas fa-arrow-circle-right"></i>
                </button>
            </div>
        </div>
        </>
    );
}

export default AddTicket;