import React from 'react';
import NavBar from '../../../../../Component/NavBar'
import SectionHeader from '../../../../../Component/Containers/Section-Header'
import BreadCrumb from '../../../../../Component/Breadcrumb'
import Review from './Review'
import Travellers from './Travellers'
import Payment from '../../../../Payment'
import {Switch, Route, useLocation, useHistory} from 'react-router-dom'
import {useLoginHook} from "../../../../../Hooks/useLoginHook";
 
function Index(props) {

    let route = useLocation().pathname
    let HISTORY = useHistory()

    let CONFIG = useLoginHook();
	
	if(!CONFIG.authenticated){
        HISTORY.push('/login')
    }

    return (
        <>
        <SectionHeader color={'var(--material-blue)'}>
            <NavBar/>
            {
                !route.includes('flights/main/booking/success')&&
                <div className="p-1 mt-4 pb-4 d-none d-md-block">
                    <BreadCrumb steps={[{label:'Review', active:route.includes('flights/main/booking')}, {label:'Travellers', active:route.includes('flights/main/booking/travellers')||route.includes('flights/main/booking/payment')}, {label:'Payment', active:route.includes('flights/main/booking/payment')}]} />
                    <div className='d-flex align-items-center'>
                        <h4 onClick={()=>HISTORY.goBack()} className='back-btn me-2'>
                            <i className="fas fa-arrow-left"></i>
                        </h4>
                        <h1 className='fw-bold'>
                            {
                                route.includes('flights/main/booking/travellers')?'Add Travellers':
                                route.includes('flights/main/booking/payment')?'Booking Payment':
                                route.includes('flights/main/booking')?'Review Booking':<></>
                            }
                        </h1>
                    </div>
                </div>
            }
        </SectionHeader>

        <div style={{minHeight:400}}>
            <Switch>
                <Route path="/flights/main/booking/payment" component={Payment} exact={true}/>
                <Route path="/flights/main/booking/travellers" component={Travellers} exact={true}/>
                <Route path="/flights/main/booking" component={Review} exact={true}/>
            </Switch>
        </div>
        </>
    );
}

export default Index;