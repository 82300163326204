import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import './index.css'
import Trending from './Components/Trending'
import SearchDeparture from '../../../../../Component/Form/SearchDeparture'
import SearchDestination from '../../../../../Component/Form/SearchDestination'
import SelectPassengers from '../../../../../Component/Form/SelectPassengers'
import DateInput from '../../../../../Component/Form/DateInput'
import {useLoginHook} from "../../../../../Hooks/useLoginHook";
import {get} from '../../../../../Model/Config/Axios'
import { showToast } from '../../../../../Component/Toast';
import RoundTripToggle from './Components/RoundTripToggle'

import moment from 'moment';

function Index() {

    let HISTORY = useHistory()
    let CONFIG = useLoginHook();

    useEffect(()=>{
        fetchTrending()
    },[])

    const [RoundTrip, setRoundTrip] = useState(false);
    const [FlightFrom, setFlightFrom] = useState(undefined);
    const [FlightTo, setFlightTo] = useState(undefined);
    const [Passengers, setPassengers] = useState({adult: 1, child: 0, infant: 0});
    const [TravelDates, setTravelDates] = useState({startDate: moment().valueOf(), endDate: moment().add(3,'days').valueOf(), key: 'selection'});

    const [TrendingFlights, setTrendingFlights] = useState([]);

    const swapPoints = (params) => {
        let temp = FlightFrom;
        setFlightFrom(FlightTo)
        setFlightTo(temp)
    }

    const fetchTrending = (params) => {
        get(`flight/domestic/trending`, (e,r)=>{
            if(r){
                setTrendingFlights(r.trending)
            }else if(e){
                showToast({
                    message:'Problem in loading Trending'
                })
            }
        })
    }

    const useTrending = (flight) => {
        setFlightFrom(flight.origin)
        setFlightTo(flight.destination)
    }

    const SearchFlights = (params) => {
        if(!FlightFrom){
            showToast({
                message:'Select Origin Airport'
            })
            return
        }
        if(!FlightTo){
            showToast({
                message:'Select Destination Airport'
            })
            return
        }

        if(RoundTrip){
            HISTORY.push(`/flights/main/round/search?from=${FlightFrom.code}&fcity=${FlightFrom.city}&to=${FlightTo.code}&tcity=${FlightTo.city}&departDate=${TravelDates.startDate}&returnDate=${TravelDates.endDate}&adult=${Passengers.adult}&child=${Passengers.child}&infant=${Passengers.infant}`)
        }else{
            HISTORY.push(`/flights/main/search?from=${FlightFrom.code}&fcity=${FlightFrom.city}&to=${FlightTo.code}&tcity=${FlightTo.city}&date=${TravelDates.startDate}&adult=${Passengers.adult}&child=${Passengers.child}&infant=${Passengers.infant}`)
        }
    }

    const updateDate = (departDate, returnDate) => {
        if(departDate&&returnDate){
            setTravelDates({
                startDate: departDate,
                endDate: returnDate,
                key: 'selection'
            })
        }else{
            setTravelDates({
                startDate: departDate,
                endDate: moment(departDate).add(1,'days').valueOf(),
                key: 'selection'
            })
        }
    }

    return (
        <>
            <div className="p-1 search mt-4">
                <h5>Hello {CONFIG.name}</h5>
                <h1 className="display-5" style={{fontWeight: 'bold'}}>Find the perfect flight.</h1>
                <div className="bg-white shadow-sm mt-4 rounded-3 p-4">
                    <RoundTripToggle toggle={RoundTrip} onChange={setRoundTrip}/>
                    <div className="row mt-3">
                        <div className="col-md-4 col-xl-5">
                            <div className="row d-flex justify-content-between align-items-center p-3">
                                <SearchDeparture node='results' api='/search/airport' onSelect={setFlightFrom} selected={FlightFrom} label='From' placeholder='From' value=''/>
                                <div onClick={swapPoints} className="col-2 text-center"
                                     style={{transform: 'rotate(90deg)', fontSize: '1.6rem'}}>
                                    <div className='swap-btn'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-arrow-down-up"><path fillRule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5zm-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5z"/></svg>
                                    </div>
                                </div>
                                <SearchDestination node='results' noOrigin={true} api='/search/airport' onSelect={setFlightTo} selected={FlightTo} label='To' placeholder='To' value=''/>
                            </div>
                        </div>
                        <div className="col-md-4 col-xl-3">
                            {
                                RoundTrip
                                ?<DateInput min={moment()._d} selectedDate={TravelDates} label='Travel Dates' placeholder={['Depart','Return']} onSelect={updateDate} border="var(--material-grey)" type='range'/>
                                :<DateInput onSelect={updateDate} border="var(--material-grey)" type={undefined}/>
                            }
                        </div>
                        <div className="col-md-4 col-xl-2">
                            <SelectPassengers noFooter={true} border="var(--material-grey)" passengers={Passengers} onSelect={setPassengers}/>
                        </div>
                        <div className="col-md-4 col-xl-2 align-self-center">
                            <button onClick={() => SearchFlights()} className="btn btn-dark btn-lg ps-4 w-100" type="button">Search
                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor"viewBox="0 0 16 16" className="bi bi-arrow-right ms-2 mb-1"><path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/></svg>
                            </button>
                        </div>
                    </div>
                </div>
                <Trending onSelect={useTrending} style={{marginBottom: '-220px'}} title={'Trending'} items={TrendingFlights}/>
            </div>

        </>
    );
}

export default Index;
