import React from 'react';
import { showToast } from '../../Toast';
import './index.css'

function Index({passengers, onChange, max=10, label, style,description}) {

    let count = 0;
    if(label==='Adult'){
        count = passengers.Adult
    }else if(label==='Child'){
        count = passengers.Child
    }else if(label==='Infant'){
        count = passengers.Infant
    }

    const changeCount = (op) => {
        let total = passengers.Adult+passengers.Child+passengers.Infant;
        let newCount = count + op;

        if(label==='Adult'){
            if((passengers.Child||passengers.Infant)&&newCount===0){
                showToast({
                    type:'error',
                    message:'Atleast 1 adult is required with Child / Infant'
                })
                return
            }
        }else if(label==='Child'){
            if(passengers.Adult<1){
                showToast({
                    type:'error',
                    message:'Atleast 1 adult is required with a Child'
                })
                return
            }
        }else if(label==='Infant'){
            if(passengers.Adult<1){
                showToast({
                    type:'error',
                    message:'Atleast 1 adult is required with an Infant'
                })
                return
            }
        }
        
        if(newCount<0){
            newCount = 0
        }
        if(max && (total+op)>max){
            showToast({
                type:'error',
                message:'Maximum 10 Passengers are allowed'
            })
            return
        }else{
            onChange(newCount)
        }
    }

    return (
        <>
        <div className='d-flex justify-content-between align-items-center' style={{...style}}>
            <div onClick={()=>changeCount(-1)} className='d-flex align-items-center justify-content-center rounded-pill inc-dec-btn'>-</div>
            <div className='text-center ps-2 pe-2 text-truncate text-small fw-bold'>{count} {label?label:''}</div>
            <div onClick={()=>changeCount(1)} className='d-flex align-items-center justify-content-center rounded-pill inc-dec-btn'>+</div>
        </div>
        {
            description?
            <div className='text-muted text-small'>{description}</div>
            :<></>
        }
        </>
    );
}

export default Index;
