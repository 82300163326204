import React, {useState, useEffect} from 'react';
import CheckboxFilter from './Components/CheckboxFilter'
import RangeFilter from './Components/RangeFilter'
import Modal from '../Containers/Modal'
import {useCheckScreenHook} from '../../Hooks/useCheckScreenHook'
import SearchHotels from './Components/SearchHotels'
import { useHistory } from 'react-router-dom';

function Index({session, dates, cityId, showFilters, closeFilters, hotels=[], minValue=0, maxValue=300000, filters, onApply}) {

    const HISTORY = useHistory()

    let QUICK = [{name:'Available Only', value:0}]
    let STARS = [{name:'★★★ 3 Star', value:3},{name:'★★★★ 4 Star', value:4},{name:'★★★★★ 5 Star', value:5}]
    // let AMENITIES = [{name:'Wifi', value:'WIFI'},{name:'Parking', value:'PARKING'},{name:'Restraunt', value:'RESTRAUNT'},{name:'Air Conditioning', value:'AC'},{name:'Room Service', value:'SERVICE'}]
    // let PROPERTY = [{name:'Hotel', value:'HOTEL'},{name:'Resort', value:'RESORT'},{name:'Guest House', value:'GUEST'}]

    const [QuickFilter, setQuickFilter] = useState([0]);
    const [SearchFilter, setSearchFilter] = useState();
    const [LocationFilter, setLocationFilter] = useState([]);
    const [ChainFilter, setChainFilter] = useState([]);
    const [StarFilter, setStarFilter] = useState([]);
    const [PriceFilter, setPriceFilter] = useState(maxValue);
     // eslint-disable-next-line
    const [AmenityFilter, setAmenityFilter] = useState([]);
     // eslint-disable-next-line
    const [PropertyFilter, setPropertyFilter] = useState([]);

    const [getModal, setModal] = useState(showFilters);
    const ScreenSize = useCheckScreenHook();

    let LOCATIONS = {}, CHAINS = {}, TopLocations = [], TopChains = []
    if(hotels&&hotels.length){
        hotels.forEach(hotel => {
            if(hotel.locality){
                //Gathering distinct localities
                if(!Object.keys(LOCATIONS).includes(hotel.locality)){
                    LOCATIONS[hotel.locality] = 1
                }else{
                    LOCATIONS[hotel.locality] += 1
                }
            }
            if(hotel.hotelChain){
                //Gathering distict hotel chains
                if(!Object.keys(CHAINS).includes(hotel.hotelChain)){
                    CHAINS[hotel.hotelChain] = 1
                }else{
                    CHAINS[hotel.hotelChain] += 1
                }
            }
            //Sorting hotels to get the min and max price
            if(hotel.pricePerNight>0){
                if(hotel.pricePerNight>maxValue||maxValue===300000){
                    maxValue = hotel.pricePerNight
                }
                if(hotel.pricePerNight<minValue||minValue===0){
                    minValue = hotel.pricePerNight
                }
            }
        })
        //Sort Cluster according to most hotel dense areas
        for ( let locality in LOCATIONS){
            TopLocations.push([locality, LOCATIONS[locality]])
        }
        //Sort Cluster according to most prominent chains
        for ( let chain in CHAINS){
            TopChains.push([chain, CHAINS[chain]])
        }
        TopLocations.sort((a,b)=>b[1]-a[1])
        TopChains.sort((a,b)=>b[1]-a[1])
        //Restriction results to 10
        TopLocations = TopLocations.slice(0,10)
        TopChains = TopChains.slice(0,10)
    }

    useEffect(() => {
        setPriceFilter(maxValue)
    }, [maxValue]);

    useEffect(() => {
        if(ScreenSize==='sm'){
            setModal(true)
        }else{
            setModal(undefined)
        }
    }, [ScreenSize]);


    useEffect(() => {
        if(onApply){
            onApply({stars: StarFilter, search: SearchFilter, price: PriceFilter, amenity: AmenityFilter, property: PropertyFilter, quick: QuickFilter, location: LocationFilter, chain: ChainFilter})
        } // eslint-disable-next-line
    }, [LocationFilter, SearchFilter, ChainFilter, StarFilter, PriceFilter, AmenityFilter, PropertyFilter, QuickFilter]);

    const resetFilters = () => {
        setSearchFilter(); setLocationFilter([]); setChainFilter([]); setQuickFilter([]); setStarFilter([]); setPriceFilter(maxValue); setAmenityFilter([]); setPropertyFilter([])
    }

   const Filters = () => {
        return (
            <div>
                <SearchHotels searchValue={setSearchFilter} list={hotels} onSelect={(hotel)=>{
                    if(hotel.pricePerNight>1){
                        HISTORY.push({
                            pathname: `/hotels/view`,
                            state: {id:hotel.id,key:hotel.providerKey,session,dates,cityId}
                        })
                    }
                }} className='mb-4' placeholder='Search by Property or Location'/>
            </div>
        )
   }
    return Filters()
}

export default Index;
