import React, { useRef, useEffect } from 'react';
import SelectInput from '../../../../../../Component/Form/Inputs/SelectInput'
import SelectSeat from '../../../../../../Component/FlightSeatMap/FlightSeatMap'
import { showToast } from '../../../../../../Component/Toast';
import Input from '../../../../../../Component/Form/Inputs/TextInput';
import moment from 'moment';

function TravllerCard({rules, person, setBaggage, setMeal, setSeat, type, seatMap, passport, getPassport}) {

    let onwardSegments = rules.review.ONWARDS.segments;
    let returnSegments = rules.review.RETURN.segments;

    let onwardBaggages = rules.review.ONWARDS.baggages;
    let returnBaggages = rules.review.RETURN.baggages;

    let BAGGAGE = useRef(), MEAL = useRef(), PASSPORT = useRef(), NATIONALITY = useRef(), ISSUEYEAR = useRef(), ISSUEMONTH = useRef(), EXPIREMONTH = useRef(), EXPIREYEAR = useRef() ;

        //Function to fetch passport details
        useEffect(() => {
            if(getPassport){
                getPassport(()=>{
                    let number = PASSPORT.current.value   
                    let nationality = NATIONALITY.current.value   
                    let issueYear = ISSUEYEAR.current.value   
                    let issueMonth = ISSUEMONTH.current.value   
                    let expireYear = EXPIREYEAR.current.value   
                    let expireMonth = EXPIREMONTH.current.value   
    
                    if(!number){
                        showToast({
                            type:'error',
                            message:'Passport number is mandatory'
                        })
                        return false
                    }
                    if(issueMonth==='NONE'||issueYear==='NONE'){
                        showToast({
                            type:'error',
                            message:'Passport Issue date is mandatory'
                        })
                        return false
                    }
                    if(expireMonth==='NONE'||expireYear==='NONE'){
                        showToast({
                            type:'error',
                            message:'Passport Expire date is mandatory'
                        })
                        return false
                    }
                    if(moment(`01-${expireMonth}-${expireYear}`,'DD-MM-YYYY').valueOf()-moment().valueOf()<15552000000){
                        showToast({
                            type:'error',
                            message: 'Passport should be valid for atleast next 6 months'
                        })
                        return false
                    }
                    if(moment(`01-${issueMonth}-${issueYear}`,'DD-MM-YYYY').valueOf()>moment().valueOf()){
                        showToast({
                            type:'error',
                            message: 'Passport issue date cannot be more than today'
                        })
                        return false
                    }
    
                    return {number, nationality, issue:moment(moment(`01-${issueMonth}-${issueYear}`).format('DD-MM-YYYY')).valueOf(), expiry:moment(moment(`01-${expireMonth}-${expireYear}`).format('DD-MM-YYYY')).valueOf()}
                })
            }
        }, [getPassport]);

    const addMeal = (val, id) => {
        if(val==='NONE'){
            val = undefined
        }
        setMeal({[id]:val},type,person.id)
    }

    const addSeat = (val, id) => {
      setSeat({[id]:val.code},type,person.id)
  }

    const addBaggage = (val, leg) => {
        if(val==='NONE'){
            val = undefined
        }
        setBaggage(val, type, person.id, leg)
    }
    
    return (
        <>
        <div className='p-4 mt-3 rounded-3 bg-white shadow-sm' style={{padding:'2rem'}}>
            <div className='w-100 d-flex justify-content-between align-items-center'>
                <h5>{person.name}</h5>
                <div className='text-small'>{person.type}</div>
            </div>

            <h6 className='mt-4 fw-bold'>For {onwardSegments[0].departure.city} to {onwardSegments[onwardSegments.length-1].arrival.city}</h6>
            {
                passport&&person.type!=='INFANT'?
                <div className='row'>
                    <div className='col-6 col-md-4'>
                        <Input label='Passport Number*' placeholder='Enter Passport Number' ref={PASSPORT}/>
                    </div>
                    <div className='col-6 col-md-2'>
                        <SelectInput ref={NATIONALITY} label='Nationality' options={[{label:'India', value: 'IN'},{label:'USA', value: 'US'},{label:'Canada', value: 'CA'},{label:'UK', value: 'UK'},{label:'Europe', value: 'EU'},{label:'Australia', value: 'AU'},{label:'Russia', value: 'RU'},{label:'Africa', value: 'AF'}]}/>
                    </div>
                    <div className='col-6 col-md-3'>
                        <label className='text-input-label'>Issue Date*</label>
                        <div className='d-flex'>
                            <SelectInput ref={ISSUEMONTH} className='w-50' placeholder='Month' options={[{label:'1', value:'01'},{label:'2', value:'02'},{label:'3', value:'03'},{label:'4', value:'04'},{label:'5', value:'05'},{label:'6', value:'06'},{label:'7', value:'07'},{label:'8', value:'08'},{label:'9', value:'09'},{label:'10', value:'10'},{label:'11', value:'11'},{label:'12', value:'12'}]}/>
                            <SelectInput ref={ISSUEYEAR} className='w-50' placeholder='Year' options={Array(10).fill(0).map((x,i)=>({label:moment().subtract(i,'years').format('YYYY'), value: moment().subtract(i,'years').format('YYYY')}))}/>
                        </div>
                    </div>
                    <div className='col-6 col-md-3'>
                        <label className='text-input-label'>Passport Expiry*</label>
                        <div className='d-flex'>
                            <SelectInput ref={EXPIREMONTH} className='w-50' placeholder='Month' options={[{label:'1', value:'01'},{label:'2', value:'02'},{label:'3', value:'03'},{label:'4', value:'04'},{label:'5', value:'05'},{label:'6', value:'06'},{label:'7', value:'07'},{label:'8', value:'08'},{label:'9', value:'09'},{label:'10', value:'10'},{label:'11', value:'11'},{label:'12', value:'12'}]}/>
                            <SelectInput ref={EXPIREYEAR} className='w-50' placeholder='Year' options={Array(10).fill(0).map((x,i)=>({label:moment().add(i,'years').format('YYYY'), value: moment().add(i,'years').format('YYYY')}))}/>
                        </div>
                    </div>
                </div>
                :undefined
            }
            {
                onwardBaggages.length&&person.type!=='INFANT'?
                <SelectInput onChange={(val)=>addBaggage(val,'onwards')} placeholder='None' ref={BAGGAGE} label='Customize Baggage' options={onwardBaggages.map(i=>({value: i.code?i.code:0, label:`${i.name} (Rs. ${i.amount?i.amount:0})`}))}/>
                :undefined
            }
            {
                onwardSegments.length?
                <div className='row'>
                {
                    onwardSegments.map((flight,index)=>{
                      
                      let map = undefined;
                      if(seatMap){
                        seatMap.map.forEach(item => {
                            if(item.id===flight.id){
                               map = item
                            }else{
                                map=false
                            }
                        });
                      }else{
                        if(seatMap===false){
                            map = false
                          }
                      }

                      let freeMeal = undefined
                      if(flight.meals.length){
                        flight.meals.forEach(meal => {
                            if(meal.amount===0){
                                freeMeal=true
                                return
                            }
                        });
                      }

                        return (
                            <>
                            <div key={index} className='mt-3 col-md-6'>
                                <div className='text-small fw-bold flex-fill mb-2'>
                                    {index+1}) {flight.departure.city} to {flight.arrival.city}
                                </div>
                                {
                                    person.type!=='INFANT'?
                                    <div className='row flex-fill'>
                                        {
                                        flight.meals.length?
                                        <div className='w-100'>
                                            <SelectInput onChange={(val)=>addMeal(val, flight.id)} placeholder='None' ref={MEAL} label='Select Meal' options={flight.meals.map(i=>({value: i.code?i.code:0, label:`${i.name} (Rs. ${i.amount?i.amount:0})`}))}/>
                                        </div>
                                        :<div className='w-100 text-small'>
                                            No Meal Choices
                                        </div>
                                        }
                                        {
                                        map
                                        ?<div className='mt-2 me-2 w-100'><SelectSeat onSelect={(obj)=>addSeat(obj, flight.id)} map={map.seats} rows={map.rows} cols={map.cols}/></div>
                                        :map===false
                                        ?<></>
                                        :<div className='text-small d-flex pt-2'><div className="spinner-dark me-2"><div className="bounce1" /><div className="bounce2" /><div className="bounce3" /></div> Checking Seat Choices</div>
                                        }
                                        {
                                            freeMeal?
                                            <div className='d-flex align-items-center mt-2 mb-3'>
                                                <div className='p-2 text-small' style={{background:'var(--material-green)', borderRadius:'0.5rem'}}>
                                                🌮 This Flight has free meal option
                                                </div>
                                            </div>:<></>
                                        }
                                    </div>
                                    :<></>
                                }
                            </div>
                            </>
                        )
                    })
                }
                </div>
                :undefined
            }

            <div className='border-top mt-4 mb-4'/>
            <h6 className='fw-bold'>For {returnSegments[0].departure.city} to {returnSegments[returnSegments.length-1].arrival.city}</h6>
            {
                returnBaggages.length&&person.type!=='INFANT'?
                <SelectInput onChange={(val)=>addBaggage(val,'return')} placeholder='None' ref={BAGGAGE} label='Customize Baggage' options={returnBaggages.map(i=>({value: i.code?i.code:0, label:`${i.name} (Rs. ${i.amount?i.amount:0})`}))}/>
                :undefined
            }
            {
                returnSegments.length?
                <div className='row'>
                {
                    returnSegments.map((flight,index)=>{
                      
                      let map = undefined;
                      if(seatMap){
                        seatMap.map.forEach(item => {
                            if(item.id===flight.id){
                               map = item
                            }else{
                                map=false
                            }
                        });
                      }else{
                        if(seatMap===false){
                            map = false
                          }
                      }

                      let freeMeal = undefined
                      if(flight.meals.length){
                        flight.meals.forEach(meal => {
                            if(meal.amount===0){
                                freeMeal=true
                                return
                            }
                        });
                      }

                        return (
                            <>
                            <div key={index} className='mt-3 col-md-6'>
                                <div className='text-small fw-bold flex-fill mb-2'>
                                    {index+1}) {flight.departure.city} to {flight.arrival.city}
                                </div>
                                {
                                    person.type!=='INFANT'?
                                    <div className='row flex-fill'>
                                        {
                                        flight.meals.length?
                                        <div className='w-100'>
                                            <SelectInput onChange={(val)=>addMeal(val, flight.id)} placeholder='None' ref={MEAL} label='Select Meal' options={flight.meals.map(i=>({value: i.code?i.code:0, label:`${i.name} (Rs. ${i.amount?i.amount:0})`}))}/>
                                        </div>
                                        :<div className='w-100 text-small'>
                                            No Meal Choices
                                        </div>
                                        }
                                        {
                                            freeMeal?
                                            <div className='d-flex align-items-center mt-2 mb-3'>
                                                <div className='p-2 text-small' style={{background:'var(--material-green)', borderRadius:'0.5rem'}}>
                                                🌮 This Flight has free meal option
                                                </div>
                                            </div>:<></>
                                        }
                                        {
                                        map
                                        ?<div className='mt-2 me-2 w-100'><SelectSeat onSelect={(obj)=>addSeat(obj, flight.id)} map={map.seats} rows={map.rows} cols={map.cols}/></div>
                                        :map===false
                                        ?<></>
                                        :<div className='text-small d-flex pt-2'><div className="spinner-dark me-2"><div className="bounce1" /><div className="bounce2" /><div className="bounce3" /></div> Checking Seat Choices</div>
                                        }
                                    </div>
                                    :<></>
                                }
                            </div>
                            </>
                        )
                    })
                }
                </div>
                :undefined
            }
        </div>
        </>
    );
}

export default TravllerCard;