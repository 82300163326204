import React, {useEffect} from 'react';
import Logo from './cleartrip_sq.png'

function Index({orderId, amount, apiKey, name, email, contact, sessionId, processing, onSuccess, onFailure}) {

    const loadScript = (src) => {
        return new Promise((resolve) => {
          const script = document.createElement("script");
          script.src = src;
          script.onload = () => {
            resolve(true);
          };
          script.onerror = () => {
            resolve(false);
          };
         document.body.appendChild(script);
       });
    };

    var options = {
        "key": apiKey,
        "amount": amount,
        "currency": "INR",
        "name": "Cleartrip",
        "description": "Flight Booking",
        "image": Logo,
        "order_id": orderId,
        "modal": {
            "ondismiss": ()=>processing()
        },
        "handler": onSuccess,
        "prefill": {
            "name": name?name:'',
            "email": email?email:'',
            "contact": contact?contact:''
        },
        "notes": {
            "address": "Cleartrip Pvt Ltd., 9th floor, Tower AEmbassy Tech Village,Devarabeesanahalli, ORR,Bangalore - 560103"
        },
        "theme": {
            "color": "#fee4d4"
        }
    };

    useEffect(() => {
        loadScript("https://checkout.razorpay.com/v1/checkout.js");
    });


    const startPayment = () => {
        processing('PAYMENT')
        const RazPay = new window.Razorpay(options);
        RazPay.on('payment.failed', onFailure);
        RazPay.open();
    }


    return (
        <button onClick={startPayment} id="rzp-button1" className='mt-4 btn btn-lg btn-dark rounded-pill ps-4' style={{border:'2px solid #000'}}>
            Pay now<i className="fas fa-arrow-right ms-3"/>
        </button>
    );
}

export default Index;