import React from 'react';
import Scribble from './scribble.svg'

function Index() {
    return (
        <div style={{width:'100%', height:'100vh', backgroundColor: 'var(--material-beage)'}} className='d-flex justify-content-center align-items-center'>
            <div className='text-center p-5'>
                <img src={Scribble} alt='Not Found' className='mb-4 w-100' style={{maxWidth:'500px'}}/>
                <h1 className='display-2 fw-bold'>
                Hey Traveler ..&#125;
                </h1>
                <h1 className='display-6'>
                    The Page you are looking does not exist
                </h1>
            </div>
        </div>
    );
}

export default Index;