
function useEventsHook() {

	const getLocation = () => {
		if (!navigator.geolocation){
			console.log("Geolocation is not supported by browser");
			return 'BLOCKED';
	   }
		navigator.geolocation.getCurrentPosition((position)=>{
			let lat = position.coords.latitude;
			let long = position.coords.longitude
			
			if(lat&&long){
				return {lat, long}
			}else{
				return 'BLOCKED';
			}
		}, ()=>{
			console.log('Location Blocked');
			return 'BLOCKED';
		});
	}

	const getUserAgent = () => {
		let value = window.navigator.userAgent
		if(value){
			return value 
		}else{
			return 'UNKNOWN'
		}
	}

	const getDomain = () => {
		let value = window.location.hostname
		if(value){
			return value 
		}else{
			return 'UNKNOWN'
		}
	}

	const pushDataLayer = (e, data) => {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
		'event': e,
		...data
		});
	}

	const Events = {
		home: (data)=>{
			pushDataLayer('Home_Page', {
				domain: getDomain(), country: getLocation(), device: getUserAgent(), ...data
			})
		},
		flights: (data)=>{
			pushDataLayer('Flights_Home_Page', {
				domain: getDomain(), country: getLocation(), device: getUserAgent(), ...data
			})
		},
		srp: (data)=>{
			pushDataLayer('flights_srp',{
				domain: getDomain(), country: getLocation(), device: getUserAgent(), ...data
			})
		},
		review: (data)=>{
			pushDataLayer('flights_Itinerary',{
				domain: getDomain(), country: getLocation(), device: getUserAgent(), ...data
			})
		},
		pay: (data)=>{
			pushDataLayer('flights_Pay',{
				domain: getDomain(), country: getLocation(), device: getUserAgent(), ...data
			})
		},
		booking: (data)=>{
			pushDataLayer('flights_purchase',{
				domain: getDomain(), country: getLocation(), device: getUserAgent(), ...data
			})
		}
	}

	return Events
   
}

export {
	useEventsHook
}