import React, {useState} from 'react'
import Moment from 'moment'
import {useHistory} from "react-router-dom";
import ViewAmenities from './Amenities'
import BoardandDrop from './BoardandDrop';

export default function BusItem({item,sessionId}) {
	const HISTORY = useHistory()

	const [Amenities, setAmenities] = useState(false);
	const [BoardPoints, setBoardPoints] = useState(false);

	return (
		<>
		<div className="bus-card bg-white rounded-4 shadow-sm mt-3 p-4" >
			<div className="row position-relative">
				<div className="col-12 col-md-8">
					<h6 className='fw-bold'>{item.provider}</h6>
					<div className="text-small text-muted">{item.label}</div>
					
					<div className="d-flex pt-3">
						<div className="flex-fill">
							<div className="text-input-label">Departure</div>
							<h5>{Moment(item.departure).format("HH:mm")}</h5>
							<h6 className='text-small'>{Moment(item.departure).format("DD MMM YY")}</h6>
						</div>
						<div className="flex-fill text-center align-content-center justify-content-center d-flex flex-column ">
							<div className="text-input-label">Duration</div>
							<div className="text-small">{new Date(item.arrival - item.departure).toISOString().substr(11,5)}</div>
						</div>
						<div className="flex-fill text-end">
							<div className="text-input-label">Arrival</div>
							<h5>{Moment(item.arrival).format("HH:mm")}</h5>
							<h6 className='text-small'>{Moment(item.arrival).format("DD MMM YY")}</h6>
						</div>
					</div>
				</div>
				<div className="col-12 col-md-4 text-md-end">
					<div className="text-input-label">Starts From</div>
					<h4>INR {parseInt(item.price,10)}</h4>
					<button onClick={()=>{HISTORY.push({
							pathname: `/buses/booking`,
							state: {sessionId,busId:item.busId,bus:item}
						}
					)}} className="btn btn-primary  mt-2 sm-100">
						Seats<i className={`fas fa-chevron-right ms-2`}/>
					</button>
				</div>
			</div>
			<div className='d-flex align-items-center mt-2'>
				<div onClick={()=>setBoardPoints(true)} className='info me-2'>
					<i className="fas fa-map-pin me-1"></i> Board and Drop Points
				</div>
				<div onClick={()=>setAmenities(true)} className='info me-2'>
					<i className="me-2 fas fa-info-circle"></i> Amenities
				</div>
			</div>
		</div>
		{
			Amenities&&
			<ViewAmenities onClose={()=>setAmenities(false)} amenities={item.amenities&&item.amenities}/>
		}
		{
			BoardPoints&&
			<BoardandDrop onClose={()=>setBoardPoints(false)} board={item.boarding} drop={item.dropping}/>
		}
		</>
	)
}
