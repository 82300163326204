import React, {useState, useEffect} from 'react';
import { useHistory, useLocation } from 'react-router-dom';

function Index() {
    let LOCATION = useLocation()
    let HISTORY = useHistory()
    const path = LOCATION.pathname;

    const Params = new URLSearchParams(LOCATION.search);
	let From = Params.get('from')
	let fCity = Params.get('fcity')
	let To = Params.get('to')
	let tCity = Params.get('tcity')
	let Date = parseInt(Params.get('date'))
	let departDate = parseInt(Params.get('departDate'))
	let Adult = parseInt(Params.get('adult'))
	let Child = parseInt(Params.get('child'))
	let Infant = parseInt(Params.get('infant'))


    const [SwitchMode, setSwitchMode] = useState(false);
    const ToggleSwitch = () => {
        if(!SwitchMode){
            HISTORY.push(`/flights/main/round/search?from=${From}&fcity=${fCity}&to=${To}&tcity=${tCity}&departDate=${Date}&adult=${Adult}&child=${Child}&infant=${Infant}`)
        }else{
            HISTORY.push(`/flights/main/search?from=${From}&fcity=${fCity}&to=${To}&tcity=${tCity}&date=${departDate}&adult=${Adult}&child=${Child}&infant=${Infant}`)
        }
    }

    useEffect(() => {
        if(path.includes('flights/main/round/search')){
            setSwitchMode(true)
        }else{
            setSwitchMode(false)
        }
    }, [path]);

    return (
        <>
        <div onClick={ToggleSwitch} className="d-inline-flex rounded-pill bg-dark cursor-pointer" style={{fontSize: '0.7rem'}}>
            <div className={`d-inline-flex rounded-pill p-2 border border-dark ${SwitchMode?'text-white':'bg-white'}`}><span>One Way</span></div>
            <div className={`d-inline-flex rounded-pill p-2 border border-dark ${!SwitchMode?'text-white':'bg-white'}`}><span>Round Trip</span></div>
        </div>
        </>
    );
}

export default Index;