import React, {useState, useEffect, useRef} from 'react'
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './index.css'
import {showToast} from '../../Toast'
import Modal from '../../Containers/Modal'
import Emoji_Bed from './bed.png'
import SelectInput from '../Inputs/SelectInput'

function Index({background, color, border, onSelect, selected, max=3, style}) {

    const [Picker, setPicker] = useState(false);
    const [Rooms, setRooms] = useState([{adult:1, childAges:[]}]);
    let PICKER = useRef(), AGES = useRef({});

    useEffect(() => {
        if(selected){
            setRooms(selected)
        }
    }, [selected]);

    const updateRooms = (op, index) => {
        if(op>0){
            setRooms(prev=>[...prev,{adult:1, childAges:[]}])
        }else if(op<1 && Rooms.length>1){
            let rooms = Rooms.filter((room,i)=>i!==index);
            setRooms(rooms)
        }
    }

    const updateAdult = (op, index) => {
        let rooms = [...Rooms]
        let newCount = rooms[index].adult + op

        if(newCount<1){
            updateRooms(-1,index)
            return
        }
        if(newCount>3){
            showToast({
                type: 'error',
                message: 'Max 3 Adults allowed in one room'
            })
            return
        }

        rooms[index].adult = newCount
        setRooms(rooms)
    }

    const updateChild = (op, index) => {
        let rooms = [...Rooms]
        let newCount = rooms[index].childAges.length + op

        if(newCount<0){
            return
        }

        if(newCount>2){
            showToast({
                type: 'error',
                message: 'Max 2 Child allowed in one room'
            })
            return
        }

        if(op>0){
            rooms[index].childAges.push('NONE')
        }else{
            rooms[index].childAges.pop()
        }
        setRooms(rooms)
    }

    const updateAges = (i,j) => {
        let rooms = [...Rooms]
        Rooms[i].childAges[[j]] = AGES.current[`R${i}C${j}`].value
        setRooms(rooms)
    }

    const applyChanges = () => {

        let flag = true
        Rooms.forEach((room) => {
            if(room.childAges.includes('NONE')){
                showToast({
                    type: 'error',
                    message: 'Select ages for all Children'
                })
                flag = false;
                return;
            }
        });

        if(flag){
            onSelect(Rooms);
            setPicker(undefined)
        }
    }

    const openPicker = () => {
        setPicker(true)
        document.addEventListener("click", function closeMenu(event) {
            if (!PICKER.current.contains(event.target)) {
                setPicker(false)
                document.removeEventListener('click',closeMenu)
            }
        });
    }

    //Counting total travellers
    let Travellers = 0;
    Rooms.forEach(room => {
        Travellers += room.adult + room.childAges.length
    });
    

    return (
        <>
        <div className="row d-flex justify-content-between align-items-center p-3" style={style}>
            <div ref={PICKER} onClick={openPicker} className="col-md-12 position-relative p-2 rounded-3" style={{border: `1px solid ${border?border:'var(--material-silver)'}`,background: background?background:'#fff'}}>
                
                <p className="text-muted position-absolute ps-2 pe-2" style={{top: '-12px', background: background?background:'#fff'}}>Rooms</p>
                <div onClick={()=>setPicker(true)} className="p-2 d-flex align-items-center cursor-pointer">
                        <h5 className="m-0 text-truncate"><i className="fas fa-bed me-2"></i></h5>
                        <div><span style={{fontSize:'inherit'}} className="m-0 text-truncate">{Rooms.length} Room</span></div>
                        <div className='FadeLeft-Effect ps-3 ms-3' style={{borderLeft: `1px solid ${border?border:'var(--material-grey)'}`}}><span style={{fontSize:'inherit', fontWeight:500}} className="m-0 text-truncate">{Travellers} Person</span></div>
                </div>

                {
                    Picker?
                    <Modal onClose={()=>setPicker(undefined)} onSuccess={applyChanges} title='Add Rooms' button={'Done'}>
                        <div className="container text-center">
                            <button onClick={()=>updateRooms(1)} className='btn btn-dark btn-sm text-small rounded-pill ps-3 pe-3 mt-2'>+ Add Room</button>
                            <div className='text-center mt-3'>
                                {
                                    Rooms.map((room,index)=>{
                                        return (
                                            <div key={index} className='pt-3 pe-4 w-100'>
                                                <div className='p-3 rounded-4 position-relative' style={{background: '#f9ede4'}}>
                                                    <div className='d-flex aling-items-center'>
                                                        <div className='display-1 flex-fill d-flex justify-content-start align-items-center p-0 pe-2' style={{maxWidth:120}}>
                                                            <img alt='Man' className='m-auto w-100' src={Emoji_Bed} style={{maxWidth: 70}}/>
                                                        </div>
                                                        <div className='flex-fill m-auto' style={{maxWidth:150}}>
                                                            <label className='fw-bold text-small'>Room {index+1}</label>
                                                            <div className='mt-2 d-flex justify-content-between align-items-center'>
                                                                <div onClick={()=>updateAdult(-1,index)} className='d-flex align-items-center justify-content-center rounded-pill inc-dec-btn'>-</div>
                                                                <div className='ps-2 pe-2 text-truncate text-small fw-bold'>{room.adult} Adult</div>
                                                                <div onClick={()=>updateAdult(1,index)} className='d-flex align-items-center justify-content-center rounded-pill inc-dec-btn'>+</div>
                                                            </div>
                                                            <div className='mt-2 d-flex justify-content-between align-items-center'>
                                                                <div onClick={()=>updateChild(-1,index)} className='d-flex align-items-center justify-content-center rounded-pill inc-dec-btn'>-</div>
                                                                <div className='ps-2 pe-2 text-truncate text-small fw-bold'>{room.childAges.length} Child</div>
                                                                <div onClick={()=>updateChild(1,index)} className='d-flex align-items-center justify-content-center rounded-pill inc-dec-btn'>+</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        room.childAges.length>0?
                                                        <div className='d-flex mt-3'>
                                                            {
                                                            room.childAges.map((age, i)=>{
                                                                return (
                                                                    <SelectInput onChange={()=>updateAges(index,i)} key={i} value={age} placeholder='Select' ref={r=>AGES.current[`R${index}C${i}`]=r} className='m-1 flex-fill' style={{fontSize:'0.8rem'}} label='Child 1 Age' options={[{label:'1', value:1},{label:'2', value:2},{label:'3', value:3},{label:'4', value:4},{label:'5', value:5},{label:'6', value:6},{label:'7', value:7},{label:'8', value:8},{label:'9', value:9},{label:'10', value:10},{label:'11', value:11},{label:'12', value:12}]}/>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                        :<></>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            
                        </div>
                    </Modal>
                    :undefined
                }

            </div>
        </div>
        </>
    );
}

export default Index;
