import React from 'react';
import Back from './standing.png'
import {useLoginHook} from "../../Hooks/useLoginHook";

function Index({style={},title="Login to view OMG Fares"}) {
    let CONFIG = useLoginHook();
    return (
        <div className="container p-4 mt-4">
            <div className="row bg-white shadow-sm" style={{borderRadius: '1rem',...style}}>
                <div className='col-md-5 p-3 p-md-5'>
                    <div className='ps-4 pe-4'>
                        <h3 className="display-5 fw-bold">
                            <i className="fas fa-eye me-2"/><br/>
                            {title}
                        </h3>
                        <button onClick={()=>CONFIG.Login()} className='btn btn-dark btn-lg mt-4 rounded-pill ps-4 pe-4'>Login now</button>
                    </div>
                </div>
                <div className='col-md-7 d-flex align-items-end'>
                    <img src={Back} alt='Humaans' className='img-fluid' style={{maxHeight:'400px', width:'100%', objectFit:'cover', objectPosition: 'left top'}}/>
                </div>
            </div>
        </div>

    );
}

export default Index;
