import React, {useState, useEffect} from 'react';
import './index.css'
import SearchDeparture from '../../../../../Component/Form/SearchDeparture'
import SelectPassengers from '../../../../../Component/Form/SelectPassengers'
import DateInput from '../../../../../Component/Form/DateInput'
import SearchDestination from '../../../../../Component/Form/SearchDestination'
import Modal from '../../../../../Component/Containers/Modal'
import RoundTripToggle from './Components/RoundTripToggle'
import moment from 'moment'
import {useCheckScreenHook} from '../../../../../Hooks/useCheckScreenHook'

function Index({from, to, dates, passengers, setFrom, setTo, setDates, setPassengers}) {

    const [FlightFrom, setFlightFrom] = useState(undefined);
    const [FlightTo, setFlightTo] = useState(undefined);
    const [Travellers, setTravellers] = useState({adult: 1, child: 0, infant: 0});
    const [TravelDates, setTravelDates] = useState({startDate: moment().valueOf(), endDate: moment().add(3,'days').valueOf(), key: 'selection'});

    useEffect(() => {
        setFlightFrom(from)
        setFlightTo(to)
        setTravellers(passengers)
        setTravelDates(dates)
    }, [from,to, dates, passengers]);

    const swapPoints = (params) => {
        let temp = FlightFrom;
        setFlightFrom(FlightTo)
        setFlightTo(temp)
    }

    const [getModal, setModal] = useState(false);
    const [Collapse, setCollapse] = useState(true);

    const ScreenSize = useCheckScreenHook();

    useEffect(() => {
        if(ScreenSize==='sm'){
            setCollapse(true)
            setModal(true)
        }else{
            setCollapse(undefined)
            setModal(undefined)
        }
    }, [ScreenSize]);

    const updateSearch = () => {
        setFrom(FlightFrom); setTo(FlightTo); setDates(TravelDates); setPassengers(Travellers)
    }

    const updateDate = (departDate, returnDate) => {
        if(departDate&&returnDate){
            setTravelDates({
                startDate: departDate,
                endDate: returnDate,
                key: 'selection'
            })
        }else{
            setTravelDates({
                startDate: departDate,
                endDate: undefined,
                key: 'selection'
            })
        }
    }

    return (
        <>
            <div className="mt-2 pb-2 mt-md-4 pb-md-4">
                <div className='d-flex align-items-center justify-content-between'>
                    <h1 className="heading-1" style={{fontWeight: 'bold'}}>
                        {FlightFrom&&FlightFrom.code}&nbsp;<i className="fas fa-plane ms-3 me-3"></i> {FlightTo&&FlightTo.code}
                    </h1>
                    <div className='mb-3'>
                        <div onClick={()=>setCollapse(!Collapse)} className='d-flex align-items-center rounded-pill p-1 ps-3 pe-2 text-white bg-dark text-small cursor-pointer' style={{border:'1px solid #000'}}>
                            Edit <h6 className='mb-1 ms-2'><svg style={{transform: !Collapse?'rotate(-180deg)':''}} xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-chevron-down"><path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" /></svg></h6>
                        </div>
                    </div>
                </div>
                {
                    Collapse?<></>:getModal?
                    <Modal onClose={()=>{setCollapse(true)}} onSuccess={()=>{updateSearch();setCollapse(true)}} title='Search' button={'Search'}>
                        <div className="mt-4">
                            <RoundTripToggle/>
                            <div className="row mt-3">
                                <div className="col-md-4 col-xl-5">
                                    <div className="row d-flex justify-content-between align-items-center p-3">
                                    <SearchDeparture node='results' api='/search/airport' onSelect={setFlightFrom} selected={FlightFrom} label='From' placeholder='From' value=''/>

                                        <div onClick={swapPoints} className="col-2 text-center"
                                            style={{transform: 'rotate(90deg)', fontSize: '1.6rem'}}>
                                            <div className='swap-btn'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                                                    fill="currentColor" viewBox="0 0 16 16" className="bi bi-arrow-down-up">
                                                    <path fillRule="evenodd"
                                                        d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5zm-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5z"/>
                                                </svg>
                                            </div>
                                        </div>

                                        <SearchDestination node='results' origin={FlightFrom?FlightFrom.code:undefined} api='/search/airport' onSelect={setFlightTo} selected={FlightTo} label='To' placeholder='To' value=''/>
                                    </div>
                                </div>
                                <div className="col-md-4 col-xl-3">
                                    <DateInput min={moment()._d} selectedDate={TravelDates} label='Travel Dates' placeholder={['Depart','Return']} onSelect={updateDate} border="#222" type='range'/>
                                </div>
                                <div className="col-md-4 col-xl-2">
                                    <SelectPassengers noFooter={true} border="#222" passengers={Travellers} onSelect={setTravellers}/>
                                </div>
                            </div>
                        </div>
                    </Modal>:
                    <div className="mt-4">
                        <RoundTripToggle/>
                        <div className="row mt-3">
                            <div className="col-md-4 col-xl-5">
                                <div className="row d-flex justify-content-between align-items-center p-3">
                                <SearchDeparture node='results' background={'var(--material-blue)'} api='/search/airport' onSelect={setFlightFrom} selected={FlightFrom} label='From' placeholder='From' value=''/>

                                    <div onClick={swapPoints} className="col-2 text-center"
                                        style={{transform: 'rotate(90deg)', fontSize: '1.6rem'}}>
                                        <div className='swap-btn'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                                                fill="currentColor" viewBox="0 0 16 16" className="bi bi-arrow-down-up">
                                                <path fillRule="evenodd"
                                                    d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5zm-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5z"/>
                                            </svg>
                                        </div>
                                    </div>

                                    <SearchDestination node='results' background={'var(--material-blue)'} origin={FlightFrom?FlightFrom.code:undefined} api='/search/airport' onSelect={setFlightTo} selected={FlightTo} label='To' placeholder='To' value=''/>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 col-xl-3">
                                <DateInput min={moment()._d} selectedDate={TravelDates} label='Travel Dates' placeholder={['Depart','Return']} onSelect={updateDate} background='var(--material-blue)' border="#222" type='range'/>
                            </div>
                            <div className="col-6 col-md-4 col-xl-2">
                                <SelectPassengers noFooter={true} border="#222" background='var(--material-blue)' passengers={Travellers} onSelect={setTravellers}/>
                            </div>
                            <div className="col-md-4 col-xl-2 align-self-center">
                                <button onClick={updateSearch} className="btn btn-dark btn-lg ps-4 w-100" type="button">Search
                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor"
                                        viewBox="0 0 16 16" className="bi bi-arrow-right ms-2 mb-1">
                                        <path fillRule="evenodd"
                                            d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                }
            </div>

        </>
    );
}

export default Index;
