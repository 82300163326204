import moment from 'moment';
import React from 'react';

function Itenary({segments}) {

    return (
        <>
            <div className='mt-2'>
                    {
                        segments&&segments.map((segment, index)=>{
                            
                            const duration = moment.duration(moment(segment.arrivalTime).diff(moment(segment.departureTime)));
                            const hours = parseInt(duration.asHours());
                            const minutes = parseInt(duration.asMinutes()) % 60;

                            return (
                                <>
                                <div key={index} className="row flight-card mt-3 p-1">
                                    <div className="col-12 col-md-2 text-center mt-2 text-small">
                                        <img className="mb-1" src={segment.logo} alt='logo' style={{width: 30, height: 30, objectFit: 'cover', borderRadius: 30}} />
                                        <div>{segment.airline}</div><span className="text-muted">{segment.flightCode}-{segment.flightNumber}</span>
                                    </div>
                                    <div className='col-12 col-md-3 text-start mt-2'>
                                    <h4 className="mb-0 fw-bold">{moment(segment.departureTime).format('HH:mm')}</h4>
                                    <h6 className="mb-0">{moment(segment.departureTime).format('DD MMM YY')}</h6>
                                    <span className="text-muted" style={{fontSize: '0.8rem'}}>{segment.departure.airport}</span>
                                    </div>
                                    <div className='col-12 col-md-4 mt-2'>
                                        <div className="text-center position-relative" style={{fontSize: '0.8rem', borderTop: '2px dashed #999', marginTop: 30}}>
                                            <div className="position-absolute text-nowrap" style={{bottom: '-30px', left:'50%', transform: 'translateX(-50%)'}}>
                                                <span><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-clock me-2">
                                                    <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                                                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                                                </svg>{hours}h {minutes}m</span>
                                            </div>

                                            {
                                                index===0&&<i className="fas fa-plane-departure me-2 position-absolute bg-white p-1 rounded-3" style={{left: '0px', top: '-12px'}} />
                                            }
                                            {
                                                index===segments.length-1&&<i className="fas fa-plane-arrival me-2 position-absolute bg-white p-1 rounded-3" style={{right: '-7px', top: '-12px'}} />
                                            }
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3 text-end mt-2">
                                        <h3 className="mb-0 fw-bold">{moment(segment.arrivalTime).format('HH:mm')}</h3>
                                        <h6 className="mb-0">{moment(segment.arrivalTime).format('DD MMM YY')}</h6>
                                        <span className="text-muted" style={{fontSize: '0.8rem'}}>{segment.arrival.airport}</span>
                                    </div>
                                </div>
                                </>
                            )
                        })
                    }
                </div>
        </>
    );
}

export default Itenary;