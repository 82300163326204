import React from 'react'
import {Route, Switch} from "react-router-dom";
import RoundSRP from './Round-SRP'
import RoundBooking from './Round-SRP/Booking'
import SRP from "./SRP";
import Booking from './SRP/Booking'
import Home from './Home'


export default function Render() {
	return (
		<div>
			<Switch>
				<Route path="/flights/main/round/booking" component={RoundBooking}/>
				<Route path="/flights/main/round/search" component={RoundSRP}/>
				<Route path="/flights/main/booking" component={Booking}/>
				<Route path="/flights/main/search" component={SRP}/>
				<Route path="/flights/main" component={Home}/>
			</Switch>
		</div>
	)
}
