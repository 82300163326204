import React from 'react'
import {Route, Switch} from "react-router-dom";
import Main from './Main'


export default function Render() {
	return (
		<div>
			<Switch>
				<Route path="/flights/main" component={Main}/>
			</Switch>
		</div>
	)
}
