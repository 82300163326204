import React from 'react';

function Index({setSort,sort = {
    type:'price',
    asc:true
}}) {
    let UP = <svg className='cursor-pointer' xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" ><path fillRule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z" /></svg>;
    let DOWN = <svg className='cursor-pointer' xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16"><path fillRule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"></path></svg>;
    const toggleSort = (type) => {
        setSort({
            type,
            asc:sort.type===type?!sort.asc:true
        })
    }
    return (
        <div className="w-100 sticky-top bg-light p-2" style={{top:0, zIndex:10}}>
            <div className='w-100  d-flex justify-content-between align-items-center'>
                <div className="d-flex flex-column text-small" style={{width:60}}>
                    <span onClick={()=>{
                        toggleSort('airline')
                    }} className="text-nowrap text-truncate cursor-pointer">
                        {
                            sort.type==='airline'?sort.asc
                                    ?<b>{DOWN} Airline</b>
                                    :<b>{UP} Airline</b>
                                :'Airline'
                        }
                    </span>
                </div>

                <div className="d-flex justify-content-around align-items-center flex-fill">
                    <div className="d-flex flex-column text-small text-center" style={{width:'30%'}} >
                        <span onClick={()=> {
                            toggleSort('depart')
                        }} className='text-nowrap text-truncate cursor-pointer'>
                        {
                            sort.type==='depart'?sort.asc
                                    ?<b>{DOWN} Departure</b>
                                    :<b>{UP} Departure</b>
                                :'Depart'
                        }
                        </span>
                    </div>
                    <div className="d-flex flex-column text-small text-center d-none d-md-block" style={{width:'30%'}} >
                        <span onClick={()=>{
                            toggleSort('duration')
                        }} className='text-nowrap text-truncate cursor-pointer'>
                        {
                            sort.type==='duration'?sort.asc
                                    ?<b>{DOWN} Duration</b>
                                    :<b>{UP} Duration</b>
                                :'Duration'
                        }
                        </span>
                    </div>
                    <div className="d-flex flex-column text-small text-center" style={{width:'30%'}} >
                        <span onClick={()=>{
                            toggleSort('arrival')
                        }} className='text-nowrap text-truncate cursor-pointer'>
                        {
                            sort.type==='arrival'?sort.asc
                                    ?<b>{DOWN} Arrival</b>
                                    :<b>{UP} Arrival</b>
                                :'Arrival'
                        }
                        </span>
                    </div>
                </div>

                <div className="d-flex flex-column text-small text-end"  style={{width:110}}>
                    <span onClick={()=>{
                        toggleSort('price')
                    }} className='text-nowrap text-truncate cursor-pointer'>
                    {
                        sort.type==='price'?sort.asc
                                ?<b>{DOWN} Price</b>
                                :<b>{UP} Price</b>
                            :'Price'
                    }
                    </span>
                </div>
            </div>
        </div>
    );
}

export default Index;
