import React from 'react';
import moment from 'moment';

function Hotel({amount, bookedBy, service, status, ticket, documents}) {
    const nights = moment(ticket.date.in, "DD-MM-YYYY").diff(moment(ticket.date.out, "DD-MM-YYYY"), 'days')*-1
    
    if (ticket)
    return (
        <>
        <div className='row text-small'>
            <div className='col-6'>
                <label className='text-muted mt-4'>PNR</label>
                <div>{ticket.pnr}</div>
            </div>
            <div className='col-6'>
                <label className='text-muted mt-4'>Stay Duration ({nights} Night)</label>
                <div>
                    {moment(ticket.date.in, "DD-MM-YYYY").format('DD MMM YYYY')} to {moment(ticket.date.out, "DD-MM-YYYY").format('DD MMM YYYY')}
                </div>
            </div>
        </div>
        <div className='row text-small mt-4'>
            <div className='col-6'>
            <div className=' fw-bold'><i className='fa fa-user mr1'/>Travellers</div>
                {
                    ticket.travellers.map((traveller,index)=>{
                        return <div>{index+1}) {traveller.name}</div>
                    })
                }
            </div>
        </div>
        <div className='rounded-4 bg-fff border p-3 mt-4'>
            <div className='row'>
                <div className='col-3 pe-2'>
                    <img src={ticket.image} loading='lazy' className='rounded-md w-100 h-100' alt='Room' style={{objectFit:'cover'}}/>
                </div>
                <div className='col-6'>
                    <h4 className="fw-bold">{ticket.name}</h4>
                    <div>{ticket.roomId?.split('|')[1]}</div>
                    <div className='text-small'>Address: {ticket.address}, {ticket.city}</div>
                    <h6 className='mt1'>Rs. {Math.ceil(ticket.amount)} for {nights} Night</h6>
                </div>
            </div>
        </div>
        </>
    );

    return <></>
}

export default Hotel;