import React from 'react';
import IMAGE from '../../../../../Assets/Images/photo.png'
import { priceBreakup } from '../../../HotelUtils';
import './index.css'

function Index({onClick, id, name, rooms, type, starRating, lat, lng, city, address, image, images, pricePerNight, available, amenities, providerKey}) {

    pricePerNight = pricePerNight/rooms.length

    return  (
        <div onClick={()=>{
            onClick(id,providerKey)
        }} className="hotel-card">
            <img src={image} loading='lazy' alt='Room' />
            <p style={{marginTop:12}} className="text-truncate mb-0 fw-500">{name}</p>
            <p  className='text-small text-muted'>
                {address}
            </p>
            <div style={{display:'flex',flexDirection:'row'}}>
                <div style={{flex:1,display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <h5 className="fw-bold m-0">Rs.  {priceBreakup(pricePerNight).base}</h5>
                    <div className='text-small'>&nbsp;&nbsp;/&nbsp;&nbsp;Night</div>
                    <div className='text-small text-muted'>&nbsp;&nbsp;+ Rs. {priceBreakup(pricePerNight).tax} tax</div>
                </div>
                <div>
                    {
                        Array(starRating).fill(0).map((i,index)=>{
                            return <span key={index} className='text-small'>★</span>
                        })
                    }
                </div>
            </div>
        </div>
    )

    return (
        <>
        <div onClick={()=>{
            if(pricePerNight>1){
                onClick(id,providerKey)
            }
        }} className="flight-card shadow-sm mt-3 p-3" style={pricePerNight<0?{background:'transparent', border: '1px solid #ddd'}:{}}>
            <div className='row'>
                <div className="col-12 col-md-9 d-flex flex-column">
                    <div className='row'>
                        <div className='col-12 col-md-6'>
                            <div className='rounded-4 overflow-hidden h-100' style={{backgroundColor:'#f2f2f2', backgroundImage:`url(${IMAGE})`, backgroundPosition:'center', backgroundSize:'100px', backgroundRepeat:'no-repeat'}}>
                                <img src={image} loading='lazy' className='me-3 w-100 h-100' alt='Room' style={{height: 220, objectFit:'cover'}}/>
                            </div>
                        </div>
                        <div className='col-12 col-md-6 p-3 d-flex flex-column justify-content-between'>
                            <div>
                                <h6 className="fw-bold">{name}</h6>
                                <div className='text-small'>
                                    <svg className='me-1 mb-1' style={{width:14}} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z"/></svg>
                                    {address}
                                </div>
                                <div className='d-flex align-items-center mt-2'>
                                    {
                                        !amenities.parking&&<div className='text-pill border bg-light me-1'><i className='fas fa-car me-1'/> Parking</div>
                                    }
                                    {
                                        !amenities.wifi&&<div className='text-pill border bg-light me-1'><i className='fas fa-wifi me-1'/> WiFi</div>
                                    }
                                    {
                                        amenities.ac&&<div className='text-pill border bg-light me-1'>AC</div>
                                    }
                                    {
                                        amenities.tv&&<div className='text-pill border bg-light me-1'><i className='fas fa-tv me-1'/> TV</div>
                                    }
                                    {
                                        amenities.pool&&<div className='text-pill border bg-light me-1'><i className='fas fa-water me-1'/> Pool</div>
                                    }
                                </div>
                                <div className='mt-2'>
                                    {
                                        Array(starRating).fill(0).map((i,index)=>{
                                            return <span key={index} className='text-small'>★</span>
                                        })
                                    }
                                </div>
                            </div>
                            {/* <div className='text-small'>
                                <div className='d-flex align-items-center mt-2 text-small' onClick={(e)=>{e.stopPropagation();window.open(`https://www.google.com/maps/dir//${lat},${lng}`, '_blank')}}>
                                    <u>View on Map</u>
                                </div>
                                <div className='d-flex align-items-center text-nowrap text-truncate'>
                                    {
                                        amenities.map((amenity, index)=>{
                                            return <span className='me-2' key={index}>{amenity}</span>
                                        })
                                    }
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-3 text-md-end p-3 d-flex flex-md-column justify-content-between'>
                    {
                            pricePerNight>0?
                            <>
                            <div>
                                <h4 className="fw-bold text-end mb-0">Rs.  {priceBreakup(pricePerNight).base}</h4>
                                <div className='text-small'>Per Night</div>
                                <div className='text-small text-muted'>+ Rs. {priceBreakup(pricePerNight).tax} tax</div>
                            </div>
                            <div className='d-none d-md-block'>
                                <button className='btn btn-primary mt-4'>View</button>
                            </div>
                            </>
                            :pricePerNight<0?
                            <h6 className="fw-bold text-end">Unavailable</h6>
                            :<div className='h-100' style={{alignItems:'end'}}>
                                <div className="spinner"><div className="bounce1" style={{background:'#111'}}/><div className="bounce2" style={{background:'#111'}} /><div className="bounce3" style={{background:'#111'}} /></div>
                            </div>
                        }
                </div>
            </div>
        </div>
        </>
    );
}

export default Index;
