import React from 'react';
import './RoomCard.css'
import { priceBreakup } from '../../../HotelUtils';

function RoomCard({roomDetails, travellers, onSelect, selected, stay}) {

    const {room, options} = roomDetails
    
    const selectRoom = (room, option, total, bool) => {
        let updateSelected = undefined
        if(!bool){
            updateSelected = {...room, ...option, totalFare: total}
        }
        onSelect(updateSelected)
    }

    const mapOptions = (option, index) => {
        
        //Checking Selected Option
        let isSelected = false;
        if(selected&&selected.id===option.id){
            isSelected = true
        }
        
        //Number of Adults and Child
        let adult = 0, child = 0
        travellers&&travellers.forEach(item => {
            adult += item.adult
            child += item.childAges.length
        });


        return (
            <div onClick={()=>selectRoom(room, option, option.price, isSelected)} className={`room-option d-flex p-4 border-top ${isSelected?'selected':''}`}>
                <div className='flex-fill'>
                    <div className='text-small'>
                        <i className="fas fa-user-friends me-1"/> For {adult} Adult {child?<> + {child} Child</>:<></>}
                    </div>
                    <div>
                        <div className='mt-3 bg-light border rounded-pill me-1' style={{display:'inline-block', padding:'0.2rem 0.7rem', fontSize:'0.7rem'}}>
                        {
                            option.breakfast&&
                            <div>🌮 Breakfast Included</div>
                        }
                        {
                            !option.breakfast&&!option.halfBoard&&!option.fullBoard&&
                            <div>🛏 Room Only</div>
                        }
                        {
                            option.halfBoard&&
                            <div>🌮 Half Board (Breakfast  + Lunch/Diner)</div>
                        }
                        {
                            option.fullBoard&&
                            <div>🌮 Full Board (Breakfast + Lunch + Dinner)</div>
                        }
                        {
                            !option.refundable&&
                            <div>❌ Non-Refundable</div>
                        }
                        </div>
                    </div>
                </div>
                <div className='flex-fill text-end'>
                    <h3 className="mb-0 fw-bold">₹ {priceBreakup(option.price).base}</h3>
                    <div className='text-small'>+ {priceBreakup(option.price).tax} taxes</div>
                    <div className='text-small text-muted'>For {stay} Night</div>
                    <button className={`btn btn-sm text-small mt-2 ${isSelected?'btn-black':'btn-primary'}`}>{isSelected?'Remove':'Select Room'}</button>
                </div>
            </div>
        )
    }

    return (
       <div className='pe-3'>
         <div className={`row room-card border rounded-4 mt-2 overflow-hidden bg-white`}>
            <div className="col-12 col-md-5 p-4 d-flex flex-column justify-content-between border-end" style={{background:'#f6f6f6'}}>
                <div>
                    <h4 className="fw-bold mb-0">{room.name.includes('-')?room.name.split('-')[0]:room.name}</h4>
                    <div className='text-small mt-2 mb-2'>{room.description.replace( /(<([^>]+)>)/ig, '\n')}</div>
                </div>
                <div className='mt-2 border-top pt-3'>
                    <h6>Cancellation Policy</h6>
                    <div className='text-small'>
                        {room.cancellationPolicy}
                    </div>
                </div>
            </div>
            <div className='col-12 col-md-7 p-0'>
                <div>
                    {
                        options.map(mapOptions)
                    }
                </div>
            </div>
        </div>
       </div>
    );
}

export default RoomCard;