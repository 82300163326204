import React, {useId, useEffect} from 'react'
import Props from 'prop-types'
import './Input.css'

const SelectInput = React.forwardRef(({label, onChange, disabled, defaultValue, value, placeholder, className, style, options}, ref) => {
	const ID = useId();

	useEffect(() => {
		if(defaultValue && ref.current){
			ref.current.value = defaultValue
		}
	}, [defaultValue, ref]);

	const changeEvent = (e) => {
		let val = e.target.value;
		if(onChange){
			onChange(val)
		}
	}


	return (
		<div className={`${className}`}>
			<div className='form-text text-start'>
				{label&&<label htmlFor={ID} className={`text-input-label mb-1`}>{label}</label>}
			</div>
			<div className='select-wrapper'>
				<select {...value&&{value:value}} style={style} ref={ref} className='form-control' onChange={changeEvent} disabled={disabled}>
					{
						placeholder&&<option value='NONE'>{placeholder}</option>
					}
					{
						options.map((option, index)=>{
							return (
								<option value={option.value} key={index}>
									{option.label}
								</option>
							)
						})
					}
				</select>
				<i className="fas fa-chevron-down"></i>
			</div>
		</div>
	)
})

SelectInput.defaultProps = {
	defaultValue: '',
	placeholder: '',
	options: []
}

SelectInput.propTypes = {
	/**
	 * Use external css classes
	 */
	className:Props.string,
	/**
	 * Function to handle onChange event
	 */
	onChange:Props.func,
	/**
	 * Default value of input
	 */
	defaultValue: Props.string,
	/**
	 * Placeholder Value
	 */
	 placeholder: Props.string,
	/**
	 * Make input disabled
	 */
	disabled: Props.bool,
	/**
	 * Label for input
	 */
	label: Props.string,
}

export default SelectInput;
