import React from 'react';
import './index.css'
import Itenary from './Itenary'
import {useHistory} from 'react-router-dom'
import {showToast} from '../../../../../Component/Toast'
import TimeIcon from './time.png'

function Index({departureFlight, returnFlight, passengers, from, to, onCancel}) {

    let timeValidCombination = true;

    if(departureFlight&&returnFlight){
        timeValidCombination = (returnFlight.flight.details.departure-departureFlight.flight.details.arrival)>(2*60*60*1000);
        if(departureFlight.fare.type==='SPECIAL_RETURN' || returnFlight.fare.type==='SPECIAL_RETURN'){
            if(departureFlight.fare.type!==returnFlight.fare.type){
                showToast({message: 'Both fares must be SPECIAL RETURN'})
                onCancel()
            }
            if(departureFlight.flight.operator.name!==returnFlight.flight.operator.name){
                showToast({message: 'For SPECIAL RETURN both airlines must be same'})
                onCancel()
            }
        }
    }

    let HISTORY = useHistory();
    let adultFare = (departureFlight&&departureFlight.fare.adult?departureFlight.fare.adult.publishedFare:0)+(returnFlight&&returnFlight.fare.adult?returnFlight.fare.adult.publishedFare:0);
    let childFare = (departureFlight&&departureFlight.fare.child?departureFlight.fare.child.publishedFare:0)+(returnFlight&&returnFlight.fare.adult?returnFlight.fare.adult.publishedFare:0);
    let infantFare = (departureFlight&&departureFlight.fare.infant?departureFlight.fare.infant.publishedFare:0)+(returnFlight&&returnFlight.fare.adult?returnFlight.fare.adult.publishedFare:0);

    let adultTotal = passengers.adult * adultFare
    let childTotal = passengers.child * childFare
    let infantTotal = passengers.infant * infantFare

    let Total  = adultTotal + childTotal + infantTotal

    const startBooking = (params) => {
        if(!departureFlight){
            showToast({
                message: 'Select Departure Flight'
            })
            return
        }
        if(!returnFlight){
            showToast({
                message: 'Select Return Flight'
            })
            return
        }
        HISTORY.push({
            pathname: `/flights/main/round/booking`,
            state: {DEPART: departureFlight, RETURN: returnFlight, totalFare: Total}
        })
    }

    if(!timeValidCombination){
        return (
            <div className='FadeUp-Effect info-pane shadow-lg d-flex justify-content-center align-items-center'>
                <div className='container d-flex align-items-center justify-content-center p-2 pt-4 pb-4'>
                    <img src={TimeIcon} style={{width:50}} alt='Time' className='me-4'/>
                    <div>
                        <h4 className='mb-0'>Overlapping Flights</h4>
                        <span className='text-small'>Ensure 2 Hours difference for your return flight</span>
                    </div>
                </div>
            </div>
        )
    }

    if(departureFlight||returnFlight){
        return (
            <div className='FadeUp-Effect info-pane shadow-lg d-flex justify-content-center align-items-center'>
                <div className='container'>
                    <div className='row'>
                        {
                            departureFlight&&<div className={`mt-4 mb-md-4 col-12 ${departureFlight&&returnFlight?'col-md-4':'col-md-8'}`}><Itenary {...departureFlight} from={from} to={to} heading='Depart'/></div>
                        }
                        {
                            returnFlight&&<div className={`mt-4 mb-md-4 col-12 ${departureFlight&&returnFlight?'col-md-4':'col-md-8'}`}><Itenary {...returnFlight} from={to} to={from} heading='Return'/></div>
                        }
                        <div className='col-12 col-md-4 mb-4 mt-4 ps-md-5 align-self-center'>
                            <div className='flex-fill d-flex d-md-block align-items-center'>
                                <span className='text-small text-muted ms-2 d-none d-md-block'>Total Fare</span>
                                {/* <div className='d-flex align-items-center'>
                                    <h6 className='m-0 ms-2'>Rs. {Math.ceil(adultFare)}</h6>
                                    <h6 className='m-0 ms-2 me-2'>x</h6>
                                    <h6 className='m-0 d-flex align-items-center text-nowrap flex-nowrap'>
                                        {passengers.adult} <i className="fas fa-user ms-1 me-1" style={{fontSize:'0.8rem'}}/> Adult
                                    </h6>
                                </div> */}
                                <div className='d-flex align-items-center mb-md-1 ms-2'>
                                    <h6 className='m-0 d-flex align-items-center text-nowrap flex-nowrap'>
                                        {
                                            passengers.adult?
                                            <>
                                            {passengers.adult} <i className="fas fa-user ms-1 me-1" style={{fontSize:'0.8rem'}}/> x Adult,
                                            &nbsp;
                                            </>:<></>
                                        }
                                        {
                                            passengers.child?
                                            <>
                                            {passengers.child} <i className="fas fa-user ms-1 me-1" style={{fontSize:'0.8rem'}}/> x Child,
                                            &nbsp;
                                            </>:<></>
                                        }
                                        {
                                            passengers.infant?
                                            <>
                                            {passengers.infant} <i className="fas fa-user ms-1 me-1" style={{fontSize:'0.8rem'}}/> x Infant
                                            </>:<></>
                                        }
                                    </h6>
                                </div>

                                <h3 className='m-0 ms-2'>= Rs. {Math.ceil(Total)}</h3>
                            </div>
                            <div className='align-items-center'>
                                <button onClick={startBooking} className='btn btn-dark btn-lg rounded-pill ps-4 pe-4 me-3 mt-3 mt-md-3 text-truncate'>Book<i className="fas fa-arrow-right ms-2"></i></button>
                                <button onClick={()=>onCancel()} className='btn btn-outline-dark btn-lg rounded-pill ps-4 pe-4 mt-3 text-truncate'>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }else{
        return <></>
    }
}

export default Index;