import React from 'react';

function Index({steps=[{label:'', active: false}]}) {
    return (
        <div className='d-flex'>
            {
                steps.map((step,index)=>{
                    return (
                        <div key={index} className='d-flex align-items-center'>
                        <h6 className={`${step.active?'fw-bold':'text-muted'}`}>{step.label}</h6>
                        {
                            index<(steps.length-1)&&<h6><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-chevron-right ms-2 me-2"><path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" /></svg></h6>
                        }
                        </div>
                    )
                })
            }
        </div>
    );
}

export default Index;