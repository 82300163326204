import React, {useEffect, useState} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import FareCalc from '../../../FareCalc'
import Loader from '../../../../../../Component/Loader'
import {post} from '../../../../../../Model/Config/Axios'
import { showToast } from '../../../../../../Component/Toast';
import moment from 'moment';
import Itenary from './Itenary';
import FareRules from './Rules'
import Modal from '../../../../../../Component/Containers/Modal'
import OOPS from '../../../../../../Assets/Images/oops.webp'
import {useEventsHook} from '../../../../../../Hooks/useEventsHook';

function Index() {
	let EVENTS = useEventsHook();
    let HISTORY = useHistory()
    let {state} = useLocation();
    let departureFlight = state.DEPART;
    let returnFlight = state.RETURN;
    let totalFare = state.totalFare

    if(!(departureFlight&&returnFlight)){
        showToast({
            type: 'error',
            message:'Invalid Session'
        })
        HISTORY.goBack()
    }

    let departureDetails = departureFlight.flight.details
    let departureOperator = departureFlight.flight.operator
    let returnDetails = returnFlight.flight.details
    let returnOperator = returnFlight.flight.operator
    const [Failed, setFailed] = useState(false);
    const [PriceAlert, setPriceAlert] = useState();

    const [ReviewData, setReviewData] = useState(undefined);

    //ONWARDS
    const [OnwardRules, setOnwardRules] = useState(undefined);
    const [OnwardReviewData, setOnwardReviewData] = useState(undefined);
    let OnwardSegments = OnwardReviewData&&OnwardReviewData.segments;

    //RETURN
    const [ReturnRules, setReturnRules] = useState(undefined);
    const [ReturnReviewData, setReturnReviewData] = useState(undefined);
    let ReturnSegments = ReturnReviewData&&ReturnReviewData.segments;

    const {adult,child,infant} = ReviewData?ReviewData.search.count:{}
    const PassengerCount = parseInt(adult)+parseInt(child)+parseInt(infant);
    
    let max = 10;

    if(PassengerCount>max || adult<1){
        showToast({
            type: 'error',
            message:`Maximum ${max} travellers are allowed / No Adult added`
        })
        HISTORY.goBack()
    }
    
    useEffect(() => {
        fetchReview()
        // eslint-disable-next-line
    }, []);

    const fetchReview = () => {
        post(`/flight/domestic/round/review`, {onwardsFareId: departureFlight.fare.key, returnFareId: returnFlight.fare.key, inventoryId: departureFlight.flight.key}, (e,r)=>{
            if(r.errors){
                setFailed(r.errors[0].message)
            }else if(r){
            setReviewData(r)

            //Check Price Change
            setPriceAlert(r.review.fare.publishedFare-totalFare)

            setOnwardReviewData(r.review.ONWARDS)
            setReturnReviewData(r.review.RETURN)
            fetchOnwardRules()
            fetchReturnRules()
            EVENTS.review({
				origin: r.search.origin.code||'BLANK',
				destination: r.search.destination.code||'BLANK',
				passengers: r.search.count.adult+r.search.count.child+r.search.count.infant||0,
                totalFare: r.review.fare.publishedFare||0
			})
            }else if(e){
                showToast({
                    type: 'error',
                    message: e.data?e.data.message:'Error in reviewing flight'
                })
            }
        })
    }

    const fetchOnwardRules = () => {
        post(`/flight/domestic/round/rules`, {fareId: departureFlight.fare.key, inventoryId: departureFlight.flight.key}, (e,r)=>{
            if(r.errors){
                // showToast({
                //     type: 'error',
                //     message:'Try Again, Session Expired'
                // })
                // HISTORY.goBack()
            }else if(r){
                setOnwardRules(r)
            }else if(e){
                // showToast({
                //     type: 'error',
                //     message: e.data?e.data.message:'Error in fetching rules'
                // })
            }
        })
    }

    const fetchReturnRules = () => {
        post(`/flight/domestic/round/rules`, {fareId: returnFlight.fare.key, inventoryId: departureFlight.flight.key}, (e,r)=>{
            if(r.errors){
                // showToast({
                //     type: 'error',
                //     message:'Try Again, Session Expired'
                // })
                // HISTORY.goBack()
            }else if(r){
                setReturnRules(r)
            }else if(e){
                // showToast({
                //     type: 'error',
                //     message: e.data?e.data.message:'Error in fetching rules'
                // })
            }
        })
    }

    const MoveNext = (params) => {
        HISTORY.push({
            pathname: `/flights/main/round/booking/travellers`,
            state: {reviewData: ReviewData, ONWARD_FARE_ID: departureFlight.fare.key, ONWARD_FLIGHT_ID: departureFlight.flight.key, RETURN_FARE_ID: returnFlight.fare.key, RETURND_FLIGHT_ID: returnFlight.flight.key}
        })
    }

    const departureStartTime = moment(departureDetails.departure);
    const departureEndTime = moment(departureDetails.arrival);
    const returnStartTime = moment(returnDetails.departure);
    const returnEndTime = moment(returnDetails.arrival);

    return (
        <>
        <div className="container mb-5">
            <div className="row">
                <div className="col-md-8 col-xl-9 p-3 pt-4 pt-md-5 pb-3">
                    <div className="d-flex mb-3">
                        <div style={{background: '#000', color: '#fff', height: 25, width: 25, borderRadius: 30, marginRight: 15, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 12, fontWeight: 'bold'}}><span>1</span></div>
                        <h5 style={{fontWeight: 'bold'}}>Review you Itinerary</h5>
                    </div>
                    {
                        departureFlight&&returnFlight&&ReviewData?
                        <div>
                            <div className="d-flex mt-2 align-items-center flex-wrap">
                                <h6 className="mb-0 me-4 fw-light mt-2" style={{fontSize: 14}}><span role='img' aria-label=''>👩‍🦰</span>&nbsp; {PassengerCount} Traveller</h6>
                                <h6 className="mb-0 me-4 fw-light mt-2" style={{fontSize: 14}}><span role='img' aria-label=''>🧳</span>&nbsp; Cabin - {departureFlight.fare.adult.baggage.cabin} &nbsp;|&nbsp; Checkin - {departureFlight.fare.adult.baggage.checkIn}</h6>
                            </div>
                            <div className='row'>
                                <div className='col-12 col-md-6 ps-3 mt-5'>
                                    <h6 className='fw-bold'>
                                    {departureStartTime.format('DD MMM YYYY')} - {OnwardSegments[0].departure.city} to {OnwardSegments[OnwardSegments.length-1].arrival.city}
                                    </h6>
                                    <div className="d-flex mt-2 align-items-center flex-wrap mb-2" style={{minHeight:25}}>
                                        {departureFlight.fare.adult.ancillery.meal&&<h6 className='fw-light me-4 mt-2 mb-0 text-small'>🌮 Meal</h6>}{departureFlight.fare.adult.ancillery.dateChange&&<h6 className='me-4 mt-2 mb-0'>📆 Flexible Date</h6>}
                                    </div>
                                    <Itenary startTime={departureStartTime} endTime={departureEndTime} segments={OnwardSegments} operator={departureOperator}/>
                                </div>
                                <div className='col-12 col-md-6 ps-3 mt-5'>
                                    <h6 className='fw-bold'>
                                    {returnStartTime.format('DD MMM YYYY')} -  Return {ReturnSegments[0].departure.city} to {ReturnSegments[ReturnSegments.length-1].arrival.city}
                                    </h6>
                                    <div className="d-flex mt-2 align-items-center flex-wrap mb-2" style={{minHeight:25}}>
                                        {returnFlight.fare.adult.ancillery.meal&&<h6 className='fw-light me-4 mt-2 mb-0 text-small'>🌮 Meal</h6>}{returnFlight.fare.adult.ancillery.dateChange&&<h6 className='me-4 mt-2 mb-0'>📆 Flexible Date</h6>}
                                    </div>
                                    <Itenary startTime={returnStartTime} endTime={returnEndTime} segments={ReturnSegments} operator={returnOperator}/>
                                </div>
                            </div>

                            <div className="border-box mt-4 shadow-sm rounded-3">
                                <FareRules flight={departureFlight} Rules={OnwardRules} heading={`${OnwardSegments[0].departure.city} to ${OnwardSegments[OnwardSegments.length-1].arrival.city}`}/>
                                <FareRules flight={returnFlight} Rules={ReturnRules} heading={`${ReturnSegments[0].departure.city} to ${ReturnSegments[ReturnSegments.length-1].arrival.city}`}/>
                            </div>
                            
                        </div>
                        :<div className="border-box mt-4 shadow-sm rounded-3 overflow-hidden text-center"><Loader/></div>
                    }
                </div>
                <div className="col-md-4 col-xl-3 p-0 pe-3 ps-3">
                    <FareCalc button={<button onClick={MoveNext} className='btn btn-lg btn-dark mt-4 rounded-pill'>Continue<i className="fas fa-arrow-right ms-3"/></button>} loading={!ReviewData} total={ReviewData?ReviewData.review.fare.publishedFare:0} tax={ReviewData?ReviewData.review.fare.taxes.total:0} base={ReviewData?ReviewData.review.fare.baseFare:0} coins={0} passengers={{adult,child,infant}}/>
                </div>
            </div>
        </div>
        {
        Failed&&
        <Modal title='Unable to Fetch Details' noButtons={true}>
            <div className='p-3 d-flex flex-column align-items-center justify-content-center'>
                <img src={OOPS} style={{width:100}} alt='Oops'/>
                <div className='text-center mt-2'>
                    We were unable to fetch this flight. You can go back and try booking again.
                    (Reason: {Failed})
                </div>
                <div className='mt-3'>
                    <button onClick={()=>HISTORY.goBack()} className='btn btn-dark'><i className="fas fa-arrow-left me-2"></i>Go Back</button>
                </div>
            </div>
        </Modal>
        }
        {
        PriceAlert&&
        <Modal maxWidth={350} title='Price Change Alert' noButtons={true}>
            <div className=' d-flex flex-column align-items-center justify-content-center'>
                <h3 className={`fw-bold rounded-4 w-100 mt-3 text-center p-3 ${PriceAlert>0?'text-danger':'text-success'}`} style={{background:'#f2f2f2'}}>
                    Rs. {totalFare} {PriceAlert>0?<i className="fas fa-long-arrow-alt-up ms-2"/>:<i className="fas fa-long-arrow-alt-down ms-2"/>}
                </h3>
                <div className='text-center p-2'>
                    <small>The Price for Selected flights has <b>{PriceAlert>0?'increased':'decreased'} by Rs. {Math.abs(PriceAlert)}</b>, Do you want to continue with this flight or select another.</small>
                </div>
                <div className='mt-3 mb-2 w-100 d-flex'>
                    <button onClick={()=>HISTORY.goBack()} className='flex-fill btn btn-dark me-2'>Back</button>
                    <button onClick={()=>{setPriceAlert()}} className='flex-fill btn btn-primary'>Continue</button>
                </div>
            </div>
        </Modal>
        }
        </>
    );
}

export default Index;