import React from 'react';
import Flight from './Flight'
import RoundFlight from './RoundFlight'
import Hotel from './Hotel'
import Bus from './Bus'

function BookingDetails({flight, hotel, bus, type}) {
    console.log(bus);
    if(type==='Oneway Flight')
    return <Flight TRAVELLERS={flight.TRAVELLERS} DETAILS={flight.DETAILS}/>

    if(type==='Round Flight')
    return <RoundFlight TRAVELLERS={flight.TRAVELLERS} DETAILS={flight.DETAILS}/>
    
    if(type==="Bus")
    return <Bus {...bus}/>
    
    if(type==='Hotel')
    return <Hotel ROOMS={hotel.ROOMS} TRAVELLERS={hotel.TRAVELLERS} GUESTS={hotel.GUESTS} HOTEL={hotel.HOTEL}/>
}

export default BookingDetails;