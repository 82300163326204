import React from 'react';
import Loader from '../../../../Component/Loader'

function Index({passengers={adult:1,child:0}, coins, meal=0, baggage=0, tax=0, base=0, total=0, addon=0, addonTypes, loading, button}) {

    if((loading)){
        return (
            <div className='position-sticky pt-5' style={{top:0, zIndex:15}}>
                <div><h6 className="pt-1 pb-4">Fare Details</h6></div>
                <div className='rounded-3 shadow-sm bg-white' style={{padding: '1rem'}}>
                    <div className='w-100 d-flex align-items-center justify-content-center'><Loader/></div>
                </div>
            </div>
        )
    }
    let discount = parseInt(coins)/10;
    
    return (
        <>
        <div className='position-sticky pt-5' style={{top:0, zIndex:15}}>
            <div><h6 className="pt-1 pb-4">Fare Details</h6></div>
            <div className='rounded-3 shadow-sm bg-white' style={{padding: '1rem'}}>
                <div>
                <div className="table-responsive" style={{fontSize: '0.8rem', borderBottom: '2px solid #000'}}>
                    <table className="table table-borderless">
                    <tbody>
                        <tr>
                            <td>
                                Base Fare<br/>
                                <span className='text-muted'>
                                    {
                                        passengers.adult?
                                        <>{passengers.adult} Adult</>
                                        :<></>
                                    }
                                    {
                                        passengers.child?
                                        <>, {passengers.child} Child, </>
                                        :<></>
                                    }
                                    {
                                        passengers.infant?
                                        <>, {passengers.infant} Infant</>
                                        :<></>
                                    }
                                </span>
                            </td>
                            <td className="text-end text-nowrap">Rs. {Math.ceil(base)}</td>
                        </tr>
                        {
                            meal?
                            <tr>
                                <td>Meal</td>
                                <td className="text-end text-nowrap">Rs. {Math.ceil(Math.ceil(meal))}</td>
                            </tr>
                            :undefined
                        }
                        {
                            baggage?
                            <tr>
                                <td>Baggage</td>
                                <td className="text-end text-nowrap">Rs. {Math.ceil(parseInt(baggage))}</td>
                            </tr>
                            :undefined
                        }
                        {
                            addon?
                            <tr>
                                <td>Addon<br/>
                                {
                                    addonTypes.length?
                                    <span className='text-muted'>
                                        ({
                                            addonTypes.map((item)=>{
                                                return `${item}, `
                                            })
                                        })
                                    </span>:<></>
                                }
                                </td>
                                <td className="text-end text-nowrap">Rs. {Math.ceil(addon)}</td>
                            </tr>
                            :undefined
                        }
                        {
                            tax?
                            <tr>
                                <td>Fee &amp; Surcharges</td>
                                <td className="text-end text-nowrap">Rs. {Math.ceil(tax)}</td>
                            </tr>
                            :undefined
                        }
                        {
                            coins&&parseInt(coins)>0?
                            <tr>
                                <td className="fw-bold text-success">Coins Redeemed</td>
                                <td className="text-end fw-bold text-success">Rs. -{discount}</td>
                            </tr>
                            :<></>
                        }
                    </tbody>
                    </table>
                </div>
                <div className="table-responsive mt-3">
                    <table className="table table-borderless">
                    <tbody>
                        <tr>
                        <td>Total Fare</td>
                        <td className="text-end fw-bold">Rs. {Math.ceil(total)}</td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                <div className='p-2 text-small' style={{background:'var(--material-green)', borderRadius:'0.5rem'}}>
                    You will earn <b>{Math.floor(total/10)}</b> coins on this booking 🎉
                </div>
                </div>
            </div>
            {button}
            {/* <div>
                <h6 className="pt-1 pb-2 mt-4">Apply Promo Code</h6>
            </div>
            <div>
                <div className="d-flex"><input type="text" className="form-control rounded-0" placeholder="Enter Code" /><button className="btn btn-dark btn-sm ps-3 pe-3" type="button" style={{borderRadius: 0}}>Apply</button></div>
            </div> */}
        </div>
        </>
    );
}

export default Index;