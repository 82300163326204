import React from 'react'
import Humaans from './humaans.svg'
import LoginWithPersonal from '../LoginWithPersonal'
import Spinner from '../../Component/Loader'

function Index(props) {

	return (
		<>
        <div style={{background: `url(${Humaans}) bottom / contain no-repeat, #DCECF0`, height: '100vh', backgroundPosition: 'center'}}>
            <div className="container h-100">
                <div className="row justify-content-center align-items-center h-100">
                    <div className="col-12 col-md-8 col-lg-6 h-100 p-0">
                        <div className="text-center shadow-lg position-relative w-100 h-100 p-2 p-md-4 bg-white m-auto" style={{maxWidth: 450}}>
                            <div className='position-relative h-100' style={{zIndex:10}}>
                                <LoginWithPersonal/>
                            </div>
                            <div className='position-absolute' style={{top:'50%', left:'50%', transform: 'translate(-50%, -50%)', zIndex:5}}>
                                <Spinner/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

		</>
	)
}

export default Index
