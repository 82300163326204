import axios from 'axios';
import qs from 'qs'
import * as Crypto from '../../Utils/Crypto';
import Moment from 'moment'

axios.defaults.baseURL = "https://provider.dice.tech/personal/";
const CancelToken = axios.CancelToken;
let isRefreshing = false;
axios.defaults.timeout = 125000;

axios.interceptors.response.use(response => {
	return response;
}, function (error) {
	const err = error.response;
	if(error.response.config.url==='auth/token/refresh'){
		localStorage.clear()
		window.location.reload();
	}
	
	if ( err && err.status === 401 && err.config) {
		const originalRequest = err.config;
		if (!isRefreshing) {
			isRefreshing = true;
			getRefreshToken()
				.then(function (success) {
					onRefreshed(success.data.access_token)
				}).catch(err => {
					localStorage.clear()
					window.location.reload();
				});
		}
		return new Promise((resolve, reject) => {
			subscribeTokenRefresh(token => {
				originalRequest.headers['Authorization'] = 'Bearer ' + token;
				resolve(axios(originalRequest));
			});
		});
	} else {
		return Promise.reject(err);
	}
});

axios.interceptors.request.use(async function (config) {
	const token = Crypto.get('token');
	if (token !== null && token !== "") {
		config.headers.Authorization = `Bearer ${token}`;

	}
	return config;
}, function (err) {
	return Promise.reject(err);
});

function getRefreshToken() {
	let refreshtoken = Crypto.get("refresh_token");
	if (!refreshtoken) {
		localStorage.clear()
		window.location.reload();
	}
	return axios.post(`auth/token/refresh`, {
		token: refreshtoken
	});
}


const Cancellable = (promise, mCancel = false) => {
	let hasCompleted = false;
	const wrappedPromise = new Promise((resolve, reject) => {
		promise.then(
			val => {
				if (hasCompleted) {
					reject({ isCanceled: true })
				} else {
					hasCompleted = true;
					resolve(val)
				}
			},
			error => {
				if (hasCompleted) {
					reject({ isCanceled: true })
				} else {
					reject(error);
				}
			}
		);
	});
	return {
		promise: wrappedPromise,
		cancel() {
			hasCompleted = true;
			if (mCancel) {
				mCancel()
			}
		},
		isCancellable() {
			return !hasCompleted;
		}
	};
};

let refreshSubscribers = [];

const subscribeTokenRefresh = (cb) => {
	refreshSubscribers.push(cb);
}

const onRefreshed = (token) => {
	setTokens(token);
	isRefreshing = false;
	refreshSubscribers = refreshSubscribers.filter(cb => {
		cb(token);
		return false;
	});
	refreshSubscribers = [];
}

const setTokens = (token) => {
	Crypto.set('token', token);
}


const get = (url, cb, data = {}) => {
	let currentTs = Moment().valueOf();
	let cancel = false;
	let cancelToken = new CancelToken(function executor(c) {
		cancel = c;
	});
	if(url.startsWith("/")){
		url = url.substr(1,url.length)
	}
	let cancellable = Cancellable(
		axios.get(url, {
			params: data,
			paramsSerializer: params => {
				return qs.stringify(params, { arrayFormat: 'repeat' })
			},
			cancelToken
		}), cancel
	);
	cancellable.promise.then(res => {
		cb(null, res.data)
		{
			let responseTs = Moment().valueOf();
			let time = responseTs - currentTs;
			data.code = `200`;
			data.time = `${time}`;
		}
	}).catch(err => {
		if (err && !err.isCanceled) {
			{
				let responseTs = Moment().valueOf();
				let time = responseTs - currentTs;
				data.code = `${err.code}`;
				data.time = `${time}`;
				data.error = err.data;
			}
			cb(err)
		}
	});
	return cancellable;
}

const post = (url, data, cb) => {
	if(url.startsWith("/")){
		url = url.substr(1,url.length)
	}
	let currentTs = Moment().valueOf();
	let cancellable = Cancellable(
		axios.post(url, data)

	);
	cancellable.promise.then(res => {
		cb(null, res.data)
		{
			let responseTs = Moment().valueOf();
			let time = responseTs - currentTs;
			data.code = `200`;
			data.time = `${time}`;
		}
	}).catch(err => {
		if (err && !err.isCanceled) {
			cb(err)
			{
				let responseTs = Moment().valueOf();
				let time = responseTs - currentTs;
				data.code = `${err.code}`;
				data.time = `${time}`;
				if (err.data)
					data.error = err.data.error;
			}
		}
	});

	return cancellable;
}

export default axios;
export {
	post, get, axios
}
