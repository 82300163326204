import React, {useEffect, useState, useRef} from 'react';
import {useLoginHook} from '../../../Hooks/useLoginHook'
import {post} from '../../../Model/Config/Axios'
import {showToast} from '../../../Component/Toast'
import moment from 'moment';
import TextInput from '../../../Component/Form/Inputs/TextInput'
import SelectInput from '../../../Component/Form/Inputs/SelectInput'
import DateInput from '../../../Component/Form/Inputs/DateInput'

function Index() {
    
    let CONFIG = useLoginHook();
    let {config} = CONFIG

    const [Name, setName] = useState(undefined);
    const [Gender, setGender] = useState(undefined);
    const [DOB, setDOB] = useState(undefined);

    const [Changes, setChanges] = useState(undefined);

    let NAME= useRef(), GENDER = useRef(), BIRTH = useRef()

    useEffect(() => {
        if(config){
            setName(config.name)
            setGender(config.gender)
            setDOB(config.dob)
        }
    }, [config]);

    const onUpdate = (changes,val) => {
        if(changes!==val){
            setChanges(true)
        }else{
            setChanges(undefined)
        }
    }

    const saveDetails = (params) => {
        let data = {
            name: NAME.current.value,
            gender: GENDER.current.value,
            dob: moment(moment(BIRTH.current.value,'YYYY-MM-DD')).valueOf()
        }
        
        post(`config`, data, (e,r)=>{
            if(r){
                showToast({
                    type: 'success',
                    message: 'Saved'
                })
                CONFIG.reload()
                setChanges(undefined)

            }else if(e){
                showToast({
                    type: 'error',
                    message: e.data?e.data.message:'Error in saving'
                })
            }
        })
    }

    return (
        <>
        <div className="mt-4 shadow-sm p-4 bg-white" style={{ borderRadius: "0.5rem" }}>
            <h3>Basic Info</h3>
            <p className="text-small">Basic info that is used for your bookings</p>

            <div className="mt-3 d-flex align-items-center">
                <div className='row mt-3'>
                    <div className='col-md-4'>
                        <TextInput defaultValue={Name} onChange={(val)=>onUpdate(val,Name)} ref={NAME} type='text' placeholder='Your Name' label='Name'/>
                    </div>
                    <div className='col-md-4'>
                        <SelectInput defaultValue={Gender} onChange={(val)=>onUpdate(val,Gender)} ref={GENDER} placeholder='Select Gender' label='Gender' options={[
                            {value: 'MALE', label: 'Male'},
                            {value: 'FEMALE', label: 'Female'}
                        ]}/>
                    </div>
                    <div className='col-md-4'>
                        <DateInput defaultValue={DOB} onChange={(val)=>onUpdate(val,DOB)} ref={BIRTH} label='Date of Birth'/>
                    </div>
                </div>
            </div>
                {
                    Changes&&<button onClick={saveDetails} className="btn btn-dark rounded-pill p-2 ps-4 pe-4 mt-3 text-nowrap" type="button">Save Changes</button>
                }
        </div>
        </>
    );
}

export default Index;