import React, {useState, useEffect} from 'react'
import { DateRange, Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import Modal from '../../../Component/Containers/Modal'
import './index.css'
import moment from 'moment';

function Index({color, type, min, max, background,border, onSelect, selectedDate, disabledDates, label='Travel Dates', placeholder=['Departure', 'Return']}) {
    const [date, setDate] = useState(moment()._d);
    const [Picker, setPicker] = useState(undefined);
    const [range, setRange] = useState({
        startDate: moment().add(1,'days')._d,
        endDate: moment().add(3,'days')._d,
        key: 'selection'
      });
      const [DisableDates, setDisableDates] = useState([]);
      useEffect(() => {
        if(type==='range'){
            if(selectedDate){
                setRange({
                    startDate: moment(selectedDate.startDate)._d,
                    endDate: moment(selectedDate.endDate)._d,
                    key: 'selection'
                })
            }
        }else{
            if(selectedDate){
                setDate(moment(selectedDate)._d)
            }
        }
        if(disabledDates){
            setDisableDates(disabledDates)
        }
      }, [selectedDate,disabledDates, type]);

    return (
        <>
        <div className="row d-flex justify-content-between align-items-center p-3">
            <div onClick={()=>{setPicker(true)}} className="col-md-12 position-relative p-2 rounded-3 cursor-pointer" style={{border: `1px solid ${border?border:'var(--material-silver)'}`,background: background?background:'#fff'}}>
                <p className="text-muted position-absolute ps-2 pe-2" style={{top: '-12px', background: background?background:'#fff'}}>
                    {type==='range'?label:'Date'}
                </p>
                <div className="p-2 d-flex align-items-center">
                        <div style={{marginTop:'-5px'}}><h5 className="m-0 text-truncate"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-calendar-date-fill me-2"><path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5zm5.402 9.746c.625 0 1.184-.484 1.184-1.18 0-.832-.527-1.23-1.16-1.23-.586 0-1.168.387-1.168 1.21 0 .817.543 1.2 1.144 1.2z" /><path d="M16 14V5H0v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2zm-6.664-1.21c-1.11 0-1.656-.767-1.703-1.407h.683c.043.37.387.82 1.051.82.844 0 1.301-.848 1.305-2.164h-.027c-.153.414-.637.79-1.383.79-.852 0-1.676-.61-1.676-1.77 0-1.137.871-1.809 1.797-1.809 1.172 0 1.953.734 1.953 2.668 0 1.805-.742 2.871-2 2.871zm-2.89-5.435v5.332H5.77V8.079h-.012c-.29.156-.883.52-1.258.777V8.16a12.6 12.6 0 0 1 1.313-.805h.632z" /></svg></h5></div>
                    {
                        type==='range'?
                        <>
                            <div><span style={{fontSize:'inherit', fontWeight:500}} className="m-0 text-truncate">{range.startDate?moment(range.startDate).format('DD MMM'):<span className='text-muted'>{placeholder[0]}</span>}</span></div>
                            <div className='FadeLeft-Effect ps-3 ms-3' style={{borderLeft: `1px solid ${border?border:'var(--material-grey)'}`}}><span style={{fontSize:'inherit', fontWeight:500}} className="m-0 text-truncate">{range.endDate?moment(range.endDate).format('DD MMM'):<span className='text-muted'>{placeholder[1]}</span>}</span></div>
                        </>
                        :<div><span style={{fontSize:'inherit', fontWeight:500}} className="m-0 text-truncate">{date?moment(date).format('DD MMM YYYY'):<span className='text-muted'>Select Date</span>}</span></div>
                    }
                </div>
            </div>
        </div>
        {
            Picker?
            <Modal header={{background:'var(--material-beage)'}} maxWidth='500px' noButtons={true} description="Select travel date to continue" title='Select Date' onClose={()=>setPicker(undefined)} onSuccess={()=>{}}>
                {
                    type==='range'?
                    <DateRange
                    editableDateInputs={true}
                    onChange={item => {
                        onSelect(moment(item.selection.startDate).valueOf(), moment(item.selection.endDate).valueOf())
                        if(item.selection.startDate!==item.selection.endDate){setPicker(undefined)}
                    }}
                    moveRangeOnFirstSelection={false}
                    ranges={[range]}
                    minDate={min?min:moment()._d}
                    disabledDates = {DisableDates}
                    rangeColors={['#000']}
                    />:
                    <Calendar
                    onChange={item => {setDate(item);onSelect(moment(item).valueOf());setPicker(undefined)}}
                    date={date}
                    minDate={min?min:moment()._d}
                    disabledDates = {DisableDates}
                    color='#000'
                    />
                }
            </Modal>
            :<></>
        }
        </>
    );
}

export default Index;
