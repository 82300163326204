import React, {useEffect} from 'react'
import {useLocation,Redirect} from 'react-router-dom'
import { showToast } from '../../Component/Toast';
import Spinner from "../../Component/Loader";
import Humaans from '../Login/humaans.svg'
import { post } from '../../Model/Config/Axios';
import {useLoginHook} from "../../Hooks/useLoginHook";
import usePersonaHook from '../../Hooks/usePersonaHook';
import useDiceClientHook from '../../Hooks/useDiceClientHook';

export default function Index() {

	const {search} = useLocation()
	const Login = useLoginHook()
	const PERSONAL = usePersonaHook()
	const DICE = useDiceClientHook()

	useEffect(()=>{
		const params = new URLSearchParams(search);
		let type = params.get('type')

		let data = {
			code : params.get("code"),
			grant_type : params.get("grant_type"),
			client_id: type==='dice'?DICE:PERSONAL
		}
		post(`/auth/authorize`,data, (e,r)=>{
			if(r){
				Login.onLogin(r)
			}else if(e){
				showToast({
					type:'error',
					message: 'Problem in Logging in'
				})
			}
		})
	},[search, Login, DICE, PERSONAL])
	
	if (Login.authenticated) {
		return  (
			<Redirect to="/"/>
		)
	}

	return (
		<div style={{background: `url(${Humaans}) bottom / contain no-repeat, #DCECF0`, height: '100vh', backgroundPosition: 'center calc(100% + 80px)'}}>
			<div className="container h-100">
				<div className="row justify-content-center align-items-center h-100">
					<div className="col-md-8 col-lg-6">
						<div className="FadeUp-Effect text-center shadow-lg overflow-hidden bg-white rounded-4 m-auto" style={{maxWidth: 120}}>
							<Spinner radius={36} visible={true} color="var(--primary-color)"/>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}