import {useSelector, useDispatch} from "react-redux";
import * as Crypto from "../Utils/Crypto";
import Moment from "moment";
import {AuthActions} from '../Redux/Reducers/auth';
import {get} from '../Model/Config/Axios'
import {useEffect} from 'react'
import { useHistory } from "react-router-dom";

function useLoginHook() {
	let {authenticated,name, email, dob, avatar,config} = useSelector( ({auth}) => {
		return {name:auth.name, avatar: auth.config.avatar, email: auth.config.email, dob: auth.config.dob,authenticated: auth.authenticated,config:auth.config}
	});
	const HISTORY = useHistory()
	const dispatch = useDispatch()

	useEffect(()=>{
		if(authenticated && config.loading){
			if(!window.IS_LOADING){
				window.IS_LOADING = true;
				get(`config`,(e,r)=>{
					if(r){
						window.IS_LOADING = false;
						dispatch({
							type: AuthActions.Config,
							data: r
						})
					}
				})
			}
		}
	},[
		authenticated,dispatch,config
	])

	return {
		name,
		avatar,
		email,
		dob,
		authenticated,
		config,
		reload : () => {
			if(!window.IS_LOADING){
				window.IS_LOADING = true;
				get(`config`,(e,r)=>{
					if(r){
						window.IS_LOADING = false;
						dispatch({
							type: AuthActions.Config,
							data: r
						})
					}
				})
			}
		},
		onLogin:(data)=>{
			Crypto.set('token', data.access_token);
			Crypto.set('refresh_token', data.refresh_token);
			Crypto.set("dice-personal-last", Moment().valueOf())
			Crypto.set("dice-personal-login-in", "yes");
			Crypto.set("dice-personal-name", data.name)
			dispatch({
				type: AuthActions.Login,
				data: {
					name: data.name,
					login: Moment()
				}
			})

			let active_path = Crypto.get('active_path');
			if(active_path){
				Crypto.set('active_path', undefined);
				HISTORY.push(active_path)
			}
		},
		Login:(data)=>{
			if(!authenticated){
				Crypto.set('active_path', window.location.pathname);
				HISTORY.push(`/login`)
			}
		}
	}
}


export {
	useLoginHook
}
