import React, { useRef } from 'react';
import Modal from '../../../../Component/Containers/Modal'
import TravellerForm from '../../../../Component/TravellerForm'

function Index({prefill, onClose, onUpdate}) {

    let Form = useRef()
    return (
        <>
        <Modal onClose={()=>onClose()} onSuccess={()=>onUpdate(Form.current(), prefill.id)} title='Edit Traveller' button={'Update'}>
            <div className='w-100'>
                <TravellerForm prefill={prefill} getData={func=>Form.current=func}/>
            </div>
        </Modal>
        </>
    );
}

export default Index;