import React from 'react';
import './BusSeatMap.css'
import SteeringIcon from './steering.png'

function FlightSeatMap({map, onSelect, selected, rows, cols, label}) {
    
    const plotMap = (seat, index) => {
        let isSelected = false
        selected.forEach(item => {
            if(item.code===seat.code){
                isSelected=true
            }
        });
        // eslint-disable-next-line
        const {row, col, height, width, status, type, code, price} = seat;
        if(status==='EMPTY')
        return <></>
        return (
            <>
            <div onClick={()=>{selectSeat(seat, isSelected)}} className={`${status==='VACANT'?isSelected?'seat selected':'seat':'seat disabled'}`} style={{
                gridArea: `${row+1} / ${col+1} / span ${height} / span ${width}`,
            }}>
                {code}
            </div>
            </>
        )
        
    }

    const selectSeat = (seat, isSelected) => {
        let seats = []
        if(isSelected){
            seats = selected.filter(item=>item.code!==seat.code)
        }else{
            seats = [...selected, seat]
        }
        onSelect(seats)
    }

    return (
        <>
          <div className='bus-seatmap mt-2 flex-fill'>
            {label&&<div className='label'>{label}</div>}
            <div className='legend'>
                <div className='d-flex align-items-center me-3'>
                    <div className='indicator me-2 bg-white'/>
                    <div>Available</div>
                </div>
                {/* <div className='d-flex align-items-center'>
                    <div className='indicator me-2' style={{background:'#F6C6EA'}}/>
                    <div>Female Only</div>
                </div> */}
            </div>
            <div className='wrapper-busseats mt-4' style={{maxWidth: `${cols*3}rem`}}>
                <div className='d-flex justify-content-between mb-3 border p-2 rounded-3'>
                    <div className='text-small'>Front</div>
                    <img src={SteeringIcon} style={{width:20}} alt='Steering'/>
                </div>
                <div className='select-busseats mt-2' style={{
                    gridTemplateColumns: `repeat(${cols}, 1fr)`,
                    gridTemplateRows: `repeat(${rows}, 1fr)`,
                }}>
                    {map.map(plotMap)}
                </div>
            </div>
        </div>
        </>
    );
}

export default FlightSeatMap;