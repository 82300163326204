import React from 'react'

export default function BusAnimation({loading, finished}) {
	if(loading){
        return (
            <div>
                <div id="plane-animation" className={`srp-progress ${!finished&&'hide-bar-anim'}`}>
                    <div style={{background: 'linear-gradient(to right, #c32a67 , #f6966f)'}} className={`srp-fill-bar ${!finished?'full-bar-anim':'fill-bar-anim'}`}/>
                    <i className={`fas fa-bus-alt position-absolute ${!finished?'end-plane-anim':'move-plane-anim'}`} style={{left:5,top:7}} />
                </div>
            </div>
        )
    }else{
        return <></>
    }
}
