import React from 'react';
import moment from 'moment';

function Index({flight, fare}) {
    let {heading, details, operator,  segments} = flight;
    const startTime = moment(details.departure);
    const endTime = moment(details.arrival);
    const duration = moment.duration(endTime.diff(startTime));
    const hours = parseInt(duration.asHours());
    const minutes = parseInt(duration.asMinutes()) % 60;
    return (
        <div>
            <div className='FadeUp-Effect d-flex align-items-center justify-content-between mb-3'>
                <div>
                    <h4 className='m-0'>{heading}</h4>{startTime.format('DD MMM YY')}
                </div>
                <div>
                    <img alt='Airline_logo' className="me-2 ms-3" src={operator.logo} style={{width: 25, height: 25, objectFit: 'cover', borderRadius: 30}} />
                    <span className='text-small'>{operator.name}</span> &nbsp;&nbsp;
                    <span className="text-muted text-small">{operator.code}-{operator.number}</span>
                </div>
            </div>

            <div className="d-flex flight-card bg-white border justify-content-between align-items-center mt-2 p-3" >
                <div className="d-flex justify-content-between align-items-center flex-fill me-3">
                    <div>
                        <div className="mb-2">
                            <img alt='Airline_logo' className="me-1" src={operator.logo} style={{width: 18, height: 18, objectFit: 'cover', borderRadius: 30}} />
                            <span className='text-small'>{operator.name}</span> &nbsp;&nbsp;
                            <span className="text-muted text-small">{operator.code}-{operator.number}</span>
                        </div>
                        <h6>{startTime.format('HH:mm')}</h6>
                        <span className='text-small'>{segments[0].departure.code}</span>
                    </div>

                    <div className="text-center ps-3 pe-3 text-small">
                        <div><span>{hours}h {minutes}m</span></div>
                        <div className="mt-1 mb-1" style={{borderBottom: '1px solid #000'}} />
                        <div><span className="text-muted">
                            {
                                segments.length>1?`${segments.length-1} Stop`:'Non-Stop'
                            }
                            </span>
                        </div>
                        {
                            segments.length>1?
                            <div className='text-tiny mt-2 text-center text-muted'>
                                Layover at
                                {
                                    segments.map((leg,index)=>{
                                        if(index===0){
                                            return <></>
                                        }
                                        return (
                                            <span> - {leg.departure.city}</span>
                                        )
                                    })
                                }
                            </div>
                            :<></>
                        }
                    </div>

                    <div className='text-end'>
                        <h6>{endTime.format('HH:mm')}</h6>
                        <span className='text-small'>{segments[segments.length-1].arrival.code}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Index;
