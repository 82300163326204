import React, { useRef } from 'react';
import Modal from '../../../../Component/Containers/Modal'
import TravellerForm from '../../../../Component/TravellerForm'

function Index({onClose, onSubmit}) {

    let Form = useRef()
    
    return (
        <>
        <Modal onClose={()=>onClose()} onSuccess={()=>onSubmit(Form.current())} title='Add Traveller' button={'Add'}>
            <div className='w-100'>
                <TravellerForm getData={func=>Form.current=func}/>
            </div>
        </Modal>
        </>
    );
}

export default Index;