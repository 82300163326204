import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import './index.css'
import SearchCity from '../../../../Component/Form/SearchCity'
import SelectRooms from '../../../../Component/Form/SelectRooms'
import DateInput from '../../../../Component/Form/DateInput'
import {useLoginHook} from "../../../../Hooks/useLoginHook";
import { showToast } from '../../../../Component/Toast';
import moment from 'moment';

function Index() {

    let HISTORY = useHistory()
    let CONFIG = useLoginHook();

    useEffect(()=>{
        fetchTrending()
    },[])


    const [City, setCity] = useState(undefined);
    const [Rooms, setRooms] = useState([{adult: 1, childAges: []}]);
    const [Dates, setDates] = useState({startDate: moment().add(1,'days').valueOf(), endDate: moment().add(3,'days').valueOf(), key: 'selection'})

    const [TrendingHotels,] = useState([{city:'Delhi', country: 'India', id: 1082, state: 'Delhi'},{city:'Mumbai', country: 'India', id: 2743, state: 'Maharashtra'}]);

    const fetchTrending = (params) => {

    }

    const SearchHotels = (params) => {
        if(!City){
            showToast({
                message:'Select City to search Hotels'
            })
            return
        }
        if(!Dates.startDate||!Dates.endDate){
            showToast({
                message:'Select your stay dates'
            })
            return
        }
        HISTORY.push(`/hotels/search?city=${City.city}&country=${City.country}&cityId=${City.id}&checkin=${Dates.startDate}&checkout=${Dates.endDate}&rooms=${JSON.stringify(Rooms)}`)
    }

    const updateDate = (checkinDate, checkoutDate) => {
        setDates({
            startDate: checkinDate,
            endDate: checkoutDate,
            key: 'selection'
        })
    }

    return (
        <>
            <div className="p-1 search mt-4">
                <h5>Hello {CONFIG.name}</h5>
                <h1 className="display-5" style={{fontWeight: 'bold'}}>Book Hotel instantly</h1>
                <div className="bg-white shadow-sm mt-4 rounded-3 p-4">
                    <div className="row mt-3">
                        <div className="col-md-4 col-xl-4">
                            <div className="row d-flex justify-content-between align-items-center p-3">
                                <SearchCity api='/search/city' onSelect={setCity} selected={City} label='Where to?' placeholder='Search City' value=''/>
                            </div>
                        </div>
                        <div className="col-md-4 col-xl-3">
                            <DateInput min={moment().add(1,'days')._d} selectedDate={Dates} label='Stay' placeholder={['Checkin','Checkout']} onSelect={updateDate} border="var(--material-grey)" type='range'/>
                        </div>
                        <div className="col-md-4 col-xl-3">
                            <SelectRooms border="var(--material-grey)" selected={Rooms} onSelect={setRooms} label='Rooms'/>
                        </div>
                        <div className="col-md-4 col-xl-2 align-self-center">
                            <button onClick={() => SearchHotels()} className="btn btn-dark btn-lg ps-4 w-100"
                                    type="button">Search
                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor"
                                     viewBox="0 0 16 16" className="bi bi-arrow-right ms-2 mb-1">
                                    <path fillRule="evenodd"
                                          d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="mt-5" style={{marginBottom:'-220px'}}>
                    <h5>Popular Cities</h5>
                    <div>
                        {
                            TrendingHotels.map((item, index)=>{
                                return (<button onClick={()=>{setCity(item)}} key={index} className="btn btn-sm btn-outline-dark rounded-pill p-2 ps-3 pe-3 me-2 mt-2" type="button">{item.city}</button>)
                            })
                        }
                    </div>
                </div>
            </div>

        </>
    );
}

export default Index;
