import React, {useRef} from 'react';
import './index.css'
import usePersonaHook from "../../Hooks/usePersonaHook";
import useDiceClientHook from "../../Hooks/useDiceClientHook";

export default function Index(props) {
    let RedirectURI = window.location.origin;
    const clientId = usePersonaHook();
    const diceId = useDiceClientHook();
    let AuthFrame = useRef(null);
    const startAuth = () => {
        let href = null;
        try {
            href = AuthFrame.current.contentWindow.location.href
            if(href.split('?')[0].includes(RedirectURI)){
                window.location.replace(`${RedirectURI}/authorize?${href.split('?')[1]}`)

            }
        } catch (err) {
            console.log('IFRAME => ',href)
        }
    }

    return (
        <>
            <iframe title='Dice_Auth' onLoad={startAuth} style={{width:'100%', height: '100%', overflow:'scroll'}} ref={AuthFrame} src={`https://oauth.dice.tech/personal?client_id=${clientId}&dice_id=${diceId}&origin=${window.location.hostname}`}/>
        </>
    );
}
