import React, {useState} from 'react';
import FlightCard from './Components/MultiplePriceCard'
import SortBar from './Components/SortBar'
import {flightFilterUtils, flightSortItem, FlightWrapper} from "../../../FlightUtils";

function Index({loaded, filters, flights, selected, onSelect}) {
	const [sort, setSort] = useState({
		type: 'price',
		asc: true
	})
	const filteredFlights = flights.filter(item=>flightFilterUtils(filters,item))

	return  (
		<FlightWrapper length={filteredFlights.length} loading={!loaded}>
			<div className="row mt-2">
				{
					flights.length === filteredFlights.length ?
						<p className="text-muted">Showing all {flights.length} results</p>
						:
						<p className="text-muted">Showing {filteredFlights.length} flights from {flights.length} results</p>
				}
				<div className="FadeUp-Effect col-12 position-relative">
					<SortBar sort={sort} setSort={setSort}/>
					<div className='mt-2 pe-2'>
						{
							filteredFlights.sort((a,b)=>flightSortItem(sort,a,b)).map((flight, index) => {
								return <FlightCard selected={selected && selected.fare} onClick={onSelect} key={index} flight={flight}/>
							})
						}
					</div>
				</div>
			</div>
		</FlightWrapper>
	)
}

export default Index;
