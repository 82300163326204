import React, {useEffect, useState} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Loader from '../../../../Component/Loader'
import {post} from '../../../../Model/Config/Axios'
import Details from '../Common/Details'
import PickandDrop from './PickandDrop'
import BusSeatMap from '../../../../Component/BusSeatMap/BusSeatMap'

function Index() {

    let HISTORY = useHistory()
    const {state} = useLocation();
    const SESSIONID = state.sessionId;
    const BUSID = state.busId;

	const [BusDetails, setBusDetails] = useState(undefined);
    const [SeatMap, setSeatMap] = useState(undefined);
    const [SelectedSeats, setSelectedSeats] = useState([]);
    const [Points, setPoints] = useState({pick: undefined, drop:undefined});

    useEffect(() => {
        fetchDetails(SESSIONID,BUSID)
        fetchMap(SESSIONID,BUSID)
    }, [SESSIONID,BUSID]);

    const MoveNext = () => {
        HISTORY.push({
            pathname: `/buses/booking/travellers`,
            state: {details: BusDetails, points: Points, seats: SelectedSeats, session: SESSIONID, busId: BUSID}
        })
    }

    const fetchDetails = (sessionId,busId) => {
        post(`/bus/details`,{sessionId,busId},(e,r)=>{
			if(r){
				setBusDetails(r.bus)
			}
		})
    }

    const fetchMap = (sessionId,busId) => {
        post(`/bus/layout`,{sessionId,busId},(e,r)=>{
			if(r){
				setSeatMap(r.layout)
			}
		})
    }

    return (
        <>
        <div className="container mb-5">
            <div className="row">
                <div className="col-md-7 p-3 pt-4 pt-md-5 pb-3">
                    <div className="d-flex mb-3">
                        <div style={{background: '#000', color: '#fff', height: 25, width: 25, borderRadius: 30, marginRight: 15, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 12, fontWeight: 'bold'}}><span>1</span></div>
                        <h5 style={{fontWeight: 'bold'}}>Select Seats</h5>
                    </div>
                    {
                        BusDetails&&SeatMap?
                        SeatMap.decker?
                        <div className='d-flex flex-wrap'>
                            <BusSeatMap label='Upper Birth' map={SeatMap.upper.seats} cols={SeatMap.upper.cols} rows={SeatMap.upper.rows} selected={SelectedSeats} onSelect={setSelectedSeats}/>
                            <BusSeatMap label='Lower Birth' map={SeatMap.lower.seats} cols={SeatMap.lower.cols} rows={SeatMap.lower.rows} selected={SelectedSeats} onSelect={setSelectedSeats}/>
                        </div>
                        :<BusSeatMap label='Lower Birth' map={SeatMap.lower.seats} cols={SeatMap.lower.cols} rows={SeatMap.lower.rows} selected={SelectedSeats} onSelect={setSelectedSeats}/>
                        :<div className="border-box mt-4 shadow-sm rounded-3 overflow-hidden text-center"><Loader/></div>
                    }
                    
                </div>
                <div className="col-md-5 p-0 pe-3 ps-3">
                    <PickandDrop points={Points} details={BusDetails} setPoints={setPoints}/>
                    <Details points={Points} seats={SelectedSeats} details={BusDetails}/>
                    {
                        Points.pick&&Points.drop&&SelectedSeats.length?
                        <button onClick={MoveNext} className='btn btn-lg btn-dark mt-4 rounded-pill'>Continue<i className="fas fa-arrow-right ms-3"/></button>
                        :<></>
                    }
                </div>
            </div>
        </div>
        </>
    );
}

export default Index;