import React, {useState, useEffect} from 'react';
import Modal from '../../Containers/Modal'
import IncrementDecrement from '../IncrementDecrement'
import Emoji_Adult from './adult.png'
import Emoji_Child from './child.png'
import Emoji_Infant from './infant.png'

function Index({onSelect, passengers, background,border,showInfant = true, noFooter}) {

    const [getModal, setModal] = useState(undefined);
    const [Adult, setAdult] = useState(0);
    const [Child, setChild] = useState(0);
    const [Infant, setInfant] = useState(0);

    const updatePassengers = () => {
        let data = {
            adult: Adult,
            child: Child,
            infant: Infant
        }
        onSelect(data)
        setModal(undefined)
    }

    useEffect(() => {
        if(passengers){
            setAdult(parseInt(passengers.adult))
            setChild(parseInt(passengers.child))
            setInfant(parseInt(passengers.infant))
        }
    }, [passengers]);

    return (
        <>
        <div className="row d-flex justify-content-between align-items-center p-3">
            <div onClick={()=>setModal(true)} className="col-md-12 position-relative p-2 rounded-3 cursor-pointer" style={{border: `1px solid  ${border?border:'var(--material-grey)'}`,background: background?background:'#fff'}}>
                <p className="text-muted position-absolute ps-2 pe-2" style={{top: '-10px',background: background?background:'#fff'}}>Travelers</p>
                <div style={{fontWeight:500, fontSize:'inherit'}} className="m-0 p-2 text-truncate d-flex align-items-center">
                    <div><h5 className='m-0'><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-people-fill me-2"><path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" /><path fillRule="evenodd" d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z" /><path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" /></svg></h5></div>
                    <div>
                        {parseInt(passengers.adult)+parseInt(passengers.child)+parseInt(passengers.infant)} People
                    </div>
                </div>

            </div>
        </div>
        {
            getModal?
            <Modal onClose={()=>setModal(undefined)} onSuccess={()=>updatePassengers()} title='Add Passengers' button={'Done'}>
            <div className="container">
                <div>
                <div className="row justify-content-around">
                    <div className="col-md-4 text-center position-relative p-2">
                        <div className='row h-100 w-100 d-flex align-items-center p-3 m-auto rounded-4' style={{background: 'var(--material-beage)'}}>
                            <div className='col-4 col-md-12 display-1 d-flex justify-content-start align-items-center p-0'>
                                <img alt='Man' className='m-auto' src={Emoji_Adult} style={{maxWidth: 55, width:'100%'}}/>
                            </div>
                            <div className='col-8 col-md-12 p-0'>
                                <div className='m-auto pt-2' style={{maxWidth:150}}>
                                    <IncrementDecrement description={'>12 yrs'} label='Adult' onChange={setAdult} passengers={{Adult,Child,Infant}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 text-center position-relative p-2">
                        <div className='row h-100 w-100 d-flex align-items-center p-3 m-auto rounded-4' style={{background: 'var(--material-beage)'}}>
                            <div className='col-4 col-md-12 display-1 d-flex justify-content-start align-items-center p-0'>
                                <img alt='Man' className='m-auto' src={Emoji_Child} style={{maxWidth: 50, width:'100%'}}/>
                            </div>
                            <div className='col-8 col-md-12 p-0'>
                                <div className='m-auto pt-2' style={{maxWidth:150}}>
                                    <IncrementDecrement description={'2-12 yrs'} label='Child' onChange={setChild} passengers={{Adult,Child,Infant}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        showInfant&&
                            <div className="col-md-4 text-center position-relative p-2">
                                <div className='row h-100 w-100 d-flex align-items-center p-3 m-auto rounded-4' style={{background: 'var(--material-beage)'}}>
                                    <div className='col-4 col-md-12 display-1 d-flex justify-content-start align-items-center p-0'>
                                        <img alt='Man' className='m-auto' src={Emoji_Infant} style={{maxWidth: 50, width:'100%'}}/>
                                    </div>
                                    <div className='col-8 col-md-12 p-0'>
                                        <div className='m-auto pt-2' style={{maxWidth:150}}>
                                            <IncrementDecrement description={'<2 yrs'} label='Infant' onChange={setInfant} passengers={{Adult,Child,Infant}}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }
                </div>
            </div>
            {
                !noFooter&&Infant>0&&<p className='text-center text-muted text-small mt-3 mb-0'>( Rs. 1750 per infant will be charged extra )</p>
            }
            </div>
            </Modal>
        :<></>
        }
        </>
    );
}

export default Index;
