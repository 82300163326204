import React, {useState, useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import Back from './humaans.svg'
import {useLoginHook} from "../../Hooks/useLoginHook";
import useOriginHook from "../../Hooks/useOrigin";
import ClearTrip from '../Logos/ClearTrip'
import Dice from '../Logos/Dice'

function Index() {

    let {CLEARTRIP} = useOriginHook();
    let CONFIG = useLoginHook();
    let HISTORY  = useHistory()

    const [LoggedIn, setLoggedIn] = useState(false);

    useEffect(() => {
        if(CONFIG.authenticated){
            setLoggedIn(true)
        }
    }, [CONFIG]);

    return (
        <div className="container FadeUp-Effect p-4 mt-4">
            <div className="row bg-white shadow-sm" style={{borderRadius: '1rem'}}>
                <div className='col-md-5 p-4 p-md-5'>
                    <div className='mb-4'>
                        {CLEARTRIP?<ClearTrip/>:<Dice/>}
                    </div>
                    <h1 className="display-5 fw-bold mt-3">Best Deals</h1>
                    <h2 className="fw-bold text-muted">No Convenience Fee.</h2>
                    <h2 className="fw-bold">No Hidden Charges.</h2>
                    {
                        LoggedIn?
                        <button onClick={()=>HISTORY.push('/flights/main')} className='btn btn-dark btn-lg mt-4 rounded-pill ps-4 pe-4 mb-3'>View Fares</button>
                        :<button onClick={()=>CONFIG.Login()} className='btn btn-dark btn-lg mt-4 rounded-pill ps-4 pe-4 mb-3'>Log in</button>
                    }

                </div>
                <div className='col-md-7 p-0 d-flex align-items-end'>
                    <img src={Back} alt='Humaans' className='img-fluid' style={{objectFit:'cover', height:'100%'}}/>
                </div>
            </div>
        </div>

    );
}

export default Index;
