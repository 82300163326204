import React from 'react';

function Hotel({ROOMS, TRAVELLERS, GUESTS, HOTEL}) {

    let guests = Object.keys(GUESTS).map(guest=>GUESTS[guest])

    return ( 
        <>
        <h5 style={{fontWeight: 'bold'}}>Booking Details</h5>
        <div className='mt-3'>
            <div className={`room-card bg-white border rounded-4 p-3 mt-3`}>
                <div className='row'>
                    <div className='col-12 col-md-3 mt-2'>
                        <img src={HOTEL.details.images[0]} loading='lazy' className='me-3 rounded-4 w-100 h-100' alt='Room' style={{height: 100, objectFit:'cover'}}/>
                    </div>
                    <div className='col-12 col-md-4 mt-3 border-end'>
                        <h6 className="fw-bold">{HOTEL.details.name}</h6>
                        <div>
                            {
                                Array(HOTEL.details.rating).fill(0).map((i,index)=>{
                                    return <span key={index} style={{color:'#fd7e14'}}>★</span>
                                })
                            }
                        </div>
                        <div className='text-small mt-2'><i className="fas fa-map-pin me-2"/>{HOTEL.details.address.address}, {HOTEL.details.address.city}</div>
                    </div>
                    <div className="col-12 col-md-5 mt-3">
                        <h6 className="fw-bold">Room</h6>
                        <h6>{ROOMS.name}</h6>
                        <div className='text-small mb-2'>
                            <i className="fas fa-bed me-1"/> {TRAVELLERS.adult} Adult
                            {TRAVELLERS.child?<> + {TRAVELLERS.child} Child</>:<></>}
                        </div>

                        <h6 className="fw-bold mt-3">Guests</h6>
                        <div className='text-small'>
                            {
                                guests.map((guest,i)=>{
                                    return <div className='me-2' key={i}>{i+1}) {guest.name} - {guest.email}</div>
                                })
                            }
                        </div>
                    </div>
                    <div className='mt-4 border-top pt-3'>
                        <h6>Cancellation Policy</h6>
                        <div className='text-small text-muted'>
                            {ROOMS.cancellationPolicy}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
     );
}

export default Hotel;
