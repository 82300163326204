import React, {useState, useEffect} from 'react';
import {showToast} from '../../../../../../Component/Toast'
import {get, post} from '../../../../../../Model/Config/Axios'
import AddTraveller from '../../../../../Account/Travellers/Add'

function SelectTravellers({selected, onSelect, passengers}) {

    const [AddModal, setAddModal] = useState(undefined);
    const [TravellersList, setTravellersList] = useState({adult: [], child: [], infant: []});

    useEffect(() => {
        fetchTravellersList()
    }, []);

    const renderTravellers = (traveller, type, index) => {
        let isSelected = false;
        selected[type].forEach(selected => {
            if(selected.id===traveller.id){
                isSelected = true
            }
        });
        return (
            <div onClick={()=>{selectTraveller(traveller, type, isSelected)}} key={index} className={`hoverExpand FadeUp-Effect mt-2 me-3 rounded-pill text-nowrap cursor-pointer ${isSelected&&'text-white'}`} style={{background: isSelected?'var(--bs-green)':'var(--bs-gray-400)', padding: '0.7rem'}}>
                <h6 className="m-0 text-small">{isSelected?<i className="fas fa-check me-2" />:<i className="fas fa-plus-circle me-2" />}{traveller.name}</h6>
            </div>
        )
    }

    const selectTraveller = (traveller, type, isSelected) => {
        if(isSelected){
            let update = selected[type].filter(person=>person.id!==traveller.id)
            onSelect(prev=>({
                ...prev, [type]: update
            }))
        }else{
            if(selected[type].length<passengers[type]){
                onSelect(prev=>({
                    ...prev, [type]: [...prev[type], traveller]
                }))
            }else{
                let update = [...selected[type]];
                update.pop()
                onSelect(prev=>({
                    ...prev, [type]: [...update, traveller]
                }))
            }
        }
    }

    const fetchTravellersList = (params) => {
        get(`travellers`, (e,r)=>{
            if(r){
                let travellers = {adult:[], child: [], infant: []};
                r.travellers.forEach(traveller => {
                    if(traveller.type==='ADULT'){
                        travellers.adult.push(traveller)

                    }else if(traveller.type==='CHILD'){
                        travellers.child.push(traveller)

                    } else if(traveller.type==='INFANT'){
                        travellers.infant.push(traveller)
                    }
                });
                setTravellersList(travellers)

            }else if(e){
                showToast({
                    type: 'error',
                    message: e.data?e.data.message:'Problem in fetching travellers, try again'
                })
            }
        })
    }

    const addTraveller = (data) => {
        
        if(data){
         post(`travellers/`, data, (e,r)=>{
             if(r){
                 showToast({
                     type: 'success',
                     message: 'Traveller Added'
                 })
                 setAddModal(undefined)
                 fetchTravellersList()
                 
             }else if(e){
                 showToast({
                     type: 'error',
                     message: e.data?e.data.message:'Problem in adding traveller, try again'
                 })
             }
         })
        }
    }
    
    return (
        <>
        <div className='d-flex flex-wrap align-items-center justify-content-between'>
            <div className='mt-2'>
                <h5 style={{fontWeight: 'bold'}}>Select Travellers</h5>
            </div>
            <button onClick={()=>{setAddModal(true)}} className='btn btn-oultine-dark btn-sm text-small rounded-pill p-2 ps-3 pe-3 mt-2' style={{border:'2px solid #000'}}>+ New Traveller</button>
        </div>
        
        <h6 className='mt-4'>Adult ({selected.adult.length}/{passengers.adult})</h6>
        <div className='d-flex flex-wrap align-items-center'>
            {   TravellersList.adult.length?
                TravellersList.adult.map((traveller, index)=>(renderTravellers(traveller, 'adult', index)))
                :<button onClick={()=>{setAddModal(true)}} className='btn btn-success rounded-pill btn-sm mt-2 p-2 ps-3 pe-3'>+ New</button>
            }
        </div>

        {
            passengers.child?
            <>
            <h6 className='mt-4'>Child ({selected.child.length}/{passengers.child})</h6>
            <div className='d-flex flex-wrap align-items-center'>
                {   TravellersList.child.length?
                    TravellersList.child.map((traveller, index)=>(renderTravellers(traveller, 'child', index)))
                    :<button onClick={()=>{setAddModal(true)}} className='btn btn-success rounded-pill btn-sm mt-2 p-2 ps-3 pe-3'>+ New</button>
                }
            </div>
            </>
            :<></>
        }
        {
            passengers.infant?
            <>
            <h6 className='mt-4'>Infant ({selected.infant.length}/{passengers.infant})</h6>
            <div className='d-flex flex-wrap align-items-center'>
                {   TravellersList.infant.length?
                    TravellersList.infant.map((traveller, index)=>(renderTravellers(traveller, 'infant', index)))
                    :<button onClick={()=>{setAddModal(true)}} className='btn btn-success rounded-pill btn-sm mt-2 p-2 ps-3 pe-3'>+ New</button>
                }
            </div>
            </>
            :<></>
        }
        {
            AddModal&&<AddTraveller onClose={()=>setAddModal(undefined)}  onSubmit={addTraveller}/>
        }
        </>
    );
}

export default SelectTravellers;