import React, {useState} from 'react';

function Index(props) {
    
    const [Expand, setExpand] = useState(true);

    return (
        <>
            <div className="border-box bg-white shadow-sm mb-4" style={{...props.style}}>
                <div onClick={()=>setExpand(!Expand)} className="d-flex justify-content-between cursor-pointer">
                    <h6>{props.heading}</h6><h6><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-chevron-down"><path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" /></svg></h6>
                </div>
               {Expand?<div className='FadeDown-Effect'>{props.children}</div>:<></>}
            </div>
        </>
    );
}

export default Index;