import {get} from "../../Utils/Crypto";

const AuthActions = {
	Login: "Auth_Login",
	Logout: "Auth_Logout",
	Config: "Auth_Config"
}

let emptyState = {
	authenticated: false,
	name:"Traveller",
	config: {
		loading : true
	}
}

const initialState = () => {
	const loggedIn = get("dice-personal-login-in");
	if (loggedIn && loggedIn === "yes") {
		return {
			...emptyState, authenticated: true
		}
	} else
		return emptyState;
}


export const auth = (state = initialState(), action) => {
	let data = action.data;
	switch (action.type) {
		case AuthActions.Config:
			return {
				...state,
				config: {
					...data,
					loading:false
				},
				name:data.name?data.name.split(" ")[0]:"Traveller"
			}
		case AuthActions.Logout:
			return initialState();
		case AuthActions.Login:
			return {
				...initialState(),
				authenticated: true,
				name: data.name,
			}
		default:
			return state;
	}
}

export {
	AuthActions
}
