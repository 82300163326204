import React from 'react';
import moment from 'moment';

function BookingDetails({TRAVELLERS, DETAILS}) {
    return (
        <>
            <h5 style={{fontWeight: 'bold'}}>Booking Details</h5>
            <div className='row'>
                <div className='col-md-6 mt-3'>
                    <div className="p-3 border bg-light rounded-3 h-100">
                        <h6 className="fw-bold">Departure</h6>
                        <div className="m-0 text-small">
                            <div className="text-center mt-2 text-small d-flex align-items-center mb-3">
                                <img className="me-3" src={DETAILS.segments[0].logo} alt='logo' style={{width: 30, height: 30, objectFit: 'cover', borderRadius: 30}} />
                                <div>{DETAILS.segments[0].airline} {DETAILS.segments[0].flightCode}-{DETAILS.segments[0].flightNumber}</div>
                            </div>
                            <b>{moment(DETAILS.segments[0].departureTime).format('HH:mm, DD MMMM YY')}</b>
                            <br/>
                            <b>{DETAILS.segments[0].departure.city}</b>, {DETAILS.segments[0].departure.terminal} {DETAILS.segments[0].departure.airport}
                            {
                                DETAILS.segments.length>1?
                                <div className='d-flex align-items-center mt-2'>
                                    <div className='me-2'>Layover at</div>
                                    {
                                        DETAILS.segments.map((leg,index)=>{
                                            if(index===0){
                                                return <></>
                                            }
                                            return (
                                                <div className='me-2 fw-bold border p-1 rounded-3'>{leg.departure.city}</div>
                                            )
                                        })
                                    }
                                </div>
                                :<></>
                            }
                        </div>
                    </div>
                </div>
                <div className='col-md-6 mt-3'>
                    <div className="p-3 border bg-light rounded-3 h-100">
                        <h6 className="fw-bold">Arrival</h6>
                        <div className="m-0 text-small">
                            <div className="text-center mt-2 text-small d-flex align-items-center mb-3">
                                <img className="me-3" src={DETAILS.segments[0].logo} alt='logo' style={{width: 30, height: 30, objectFit: 'cover', borderRadius: 30}} />
                                <div>{DETAILS.segments[DETAILS.segments.length-1].airline} {DETAILS.segments[DETAILS.segments.length-1].flightCode}-{DETAILS.segments[DETAILS.segments.length-1].flightNumber}</div>
                            </div>
                            <b>{moment(DETAILS.segments[DETAILS.segments.length-1].arrivalTime).format('HH:mm, DD MMMM YY')}</b>
                            <br/>
                            <b>{DETAILS.segments[DETAILS.segments.length-1].arrival.city}</b>, {DETAILS.segments[DETAILS.segments.length-1].arrival.terminal}  {DETAILS.segments[DETAILS.segments.length-1].arrival.airport} 
                        </div>
                    </div>
                </div>
                <div className='col-md-6 mt-3'>
                    <div className="p-3 border bg-light rounded-3 h-100">
                        <h6 className="fw-bold">Passengers ({(TRAVELLERS.adult.length + TRAVELLERS.child.length + TRAVELLERS.infant.length)})</h6>
                        <p className="m-0 text-small">
                        {
                            DETAILS.travellers.map((person,i)=>{
                                return <div key={i}><b>{person.name&&person.name}</b> ({person.email&&person.email}, {person.mobile&&person.mobile})<br/></div>
                            })
                        }
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BookingDetails;