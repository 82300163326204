import React, { useRef, useState } from 'react';
import Banner from './zombieing.svg';
import './index.css';
import axios from 'axios';
import {showToast} from '../Toast'

function Index(props) {	

	const [Submitted, setSubmitted] = useState(false);
	let name = useRef(null), email = useRef(null);

	const submitForm = ()=> {
		if(!name.current.value){
			showToast({
				type: 'error',
				message:'Please enter your name'
			})
			return;
		}
		if(!email.current.value){
			showToast({
				type: 'error',
				message:'Please enter your Email'
			})
			return;
		}
		axios({
			method: 'post',
			url: `https://heimdall.eka.io/triggers/contact`,
			data: {
				to: 'lakshay@antrepriz.com',
				name: `${name.current.value}`,
				email: email.current.value,
				company: 'None',
				designation: 'None',
				size: 'None',
				phone: 'None',
				industry: 'Personal Travel Subscription'
			}
		}).then(res => {
			setSubmitted(true)
		})
	}

	let toggleDemoForm = ()=>{
		props.onClose();
	}

	return (
		<div id="contact-modal" className="FadeIn-Effect position-fixed d-flex flex-column overlay p-3">
			<div className="FadeUp-Effect shadow-lg contact" data-aos="fade-down">
				<div className="row">
					<div className="col-md-12 p-4 p-md-5 position-relative" style={{backgroundImage: `url('${Banner}')`, backgroundColor: '#DD3062', maxWidth:500}}>
						{
							Submitted?
						<div>
							<div className="p-4 text-center m-auto text-white" data-aos="fade-up">
							<h1 className="display-3 fw-bold">Wohoo!</h1>
							<p style={{fontSize: '18px'}}>Thanks for subscribing, You will be notified through email when new updates rollout on our platform.</p>
							</div>
						</div>:
						<>
							<h1 className="display-1 fw-bold text-white">Get <br/>Notified.</h1>
							<p className="text-white fw-light" style={{fontSize:16}}>Subscribe to our notifications and stay updated with when new features rollout for users</p>
							<div className="row">
								<div className="col-12 pe-2"><input ref={name} className="form-control mt-2 rounded-pill ps-4" type="text" placeholder="Name" style={{border:'none', height: 50}} /></div>
								<div className="col-12"><input ref={email} className="form-control mt-2 rounded-pill ps-4" type="email" placeholder="Email"  style={{border:'none', height: 50}}/></div>
							</div>
							<button onClick={submitForm} className="btn btn-dark rounded-pill mt-4 p-3 ps-4 pe-4" x="button">Submit<svg className="ms-2 bi bi-arrow-right-circle-fill" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
								<path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"></path>
							</svg></button>
						</>
						}
						<div onClick={toggleDemoForm} className="position-absolute" style={{fontSize: "2rem", right: "20px", top: "10px", cursor: "pointer",color: '#fff'}}><svg className="bi bi-x-circle" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
								<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
								<path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path>
							</svg>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
 
export default Index;