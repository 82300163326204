import React from 'react'
import {Route, Switch} from "react-router-dom";
import Landing from './Landing'
import Flights from './Flights'
import Hotels from './Hotels'
import Buses from './Buses'
import Rewards from './Rewards'
import Notfound from "./404";
import Footer from "../Component/Footer";
import Account from './Account'
import Bookings from './Bookings'
import Support from './Support'
import Terms from './Terms'
import CheckInternet from '../Component/CheckInternet/CheckInternet';
import Packages from './Packages';

function Index() {
	return (
		<>
			<CheckInternet/>
			<Switch>
				<Route path="/holidays" component={Packages}/>
				<Route path="/terms" component={Terms}/>
				<Route path="/support" component={Support}/>
				<Route path="/bookings" component={Bookings}/>
				<Route path="/account" component={Account}/>
				<Route path="/buses" component={Buses}/>
				<Route path="/hotels" component={Hotels}/>
				<Route path="/rewards" component={Rewards}/>
				<Route path="/flights" component={Flights}/>
				<Route path="/" component={Landing} exact={true}/>
				<Route path='*' component={Notfound}/>
			</Switch>
			<Footer/>
		</>
	)
}

export default Index
