import React, {useState, useEffect} from 'react';
import {get, post} from '../../../../Model/Config/Axios'
import { showToast } from '../../../../Component/Toast';
import AddGuest from '../../../Account/Travellers/Add'
import Loader from '../../../../Component/Loader'

function Index({ROOMS, TRAVELLERS, selected, setSelected}) {

    // if(!ROOMS){
    //     showToast({
    //         type: 'error',
    //         message:'Invalid Session'
    //     })
    //     HISTORY.push('/hotels')
    // }


    const [GuestsList, setGuestsList] = useState({adult: [], child: [], infant: []});

    const [AddModal, setAddModal] = useState(undefined);

    useEffect(() => {
        fetchGuestsList()
    }, []);

    const fetchGuestsList = () => {
        get(`travellers`, (e,r)=>{
            if(r){
                let travellers = {adult:[], child: [], infant: []};
                r.travellers.forEach(traveller => {
                    if(traveller.type==='ADULT'){
                        travellers.adult.push(traveller)

                    }else if(traveller.type==='CHILD'){
                        travellers.child.push(traveller)

                    } else if(traveller.type==='INFANT'){
                        travellers.infant.push(traveller)
                    }
                });
                setGuestsList(travellers)

            }else if(e){
                showToast({
                    type: 'error',
                    message: e.data?e.data.message:'Error in fetching Guests'
                })
            }
        })
    }

    const addGuest = (data) => {      
        if(data){
         post(`travellers/`, data, (e,r)=>{
             if(r){
                 showToast({
                     type: 'success',
                     message: 'Guest Added'
                 })
                 setAddModal(undefined)
                 fetchGuestsList()
                 
             }else if(e){
                 showToast({
                     type: 'error',
                     message: e.data?e.data.message:'Error in adding Guest'
                 })
             }
         })
        }
     }

    const selectGuest = (guest, bool, index) => {
        let guests = {...selected}
        if(bool){
            delete guests[index]
        }else{
            guests[index] = guest
        }
        setSelected(guests)
    }

    const renderGuests = (traveller, id, index) => {
        let isSelected = false;
        if(selected[index]&&selected[index].id===traveller.id){
            isSelected = true
        }

        
        let includeThis = true
        Object.keys(selected).forEach(node => {
            console.log(node!==index);
            if(selected[node].id===traveller.id && !isSelected){
                includeThis = false
            }
        });
        
        if(includeThis)
        return (
            <div onClick={()=>{selectGuest(traveller, isSelected, index)}} key={index+traveller.id} className={`hoverExpand FadeUp-Effect mt-2 me-3 rounded-pill text-nowrap cursor-pointer ${isSelected&&'text-white'}`} style={{background: isSelected?'var(--bs-green)':'var(--bs-gray-400)', padding: '0.7rem'}}>
                <h6 className="m-0 text-small">{isSelected?<i className="fas fa-check me-2" />:<i className="fas fa-plus-circle me-2" />}{traveller.name}</h6>
            </div>
        )

        return <></>
    }
    return (
        <>
        <div className="d-flex mt-5">
            <div style={{background: '#000', color: '#fff', height: 25, width: 25, borderRadius: 30, marginRight: 15, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 12, fontWeight: 'bold'}}><span>2</span></div>
            <h5 style={{fontWeight: 'bold'}}>Add Guest Details</h5>
        </div>
        <div className="border-box mt-4 mb-4 border bg-light">
            <p className="mb-0 text-small">
                Enter your details as mentioned on Aadhar Card or any other Government issued ID
            </p>
        </div>

        <div>
            <div className='d-flex flex-wrap align-items-center justify-content-between mb-4'>
                <div className='mt-2'>
                    <h5 style={{fontWeight: 'bold'}}>Select Guests ({Object.keys(selected).length}/{TRAVELLERS.length})</h5>
                    <div className='text-small'>We need details of one guest for each room</div>
                </div>
                <button onClick={()=>{setAddModal(true)}} className='btn btn-oultine-dark text-small btn-sm rounded-pill p-2 ps-3 pe-3 mt-2' style={{border:'2px solid #000'}}>+ Add Guest</button>
            </div>
            {
                ROOMS&&TRAVELLERS?
                TRAVELLERS.map((x, index)=>{
                    return (
                        <div key={index} className='bg-white shadow-sm p-4 rounded-4 mt-3'>
                            <h6>Guest for Room {index+1} ({ROOMS.name})</h6>
                            <div className='guests-list d-flex flex-wrap align-items-center mt-3'>
                                {   GuestsList.adult.length?
                                    GuestsList.adult.map((person, i)=>renderGuests(person, ROOMS.id, index))
                                    :<button onClick={()=>{setAddModal(true)}} className='btn btn-success rounded-pill btn-sm mt-2 p-2 ps-3 pe-3 ms-2'>+ Add</button>
                                }
                            </div>
                        </div>
                    )
                })
                :<div className="border-box mt-4 shadow-sm rounded-3 overflow-hidden text-center"><Loader/></div>
            }
        </div>
    {
        AddModal&&<AddGuest onClose={()=>setAddModal(undefined)}  onSubmit={addGuest}/>
    }
    </>
    );
}

export default Index;