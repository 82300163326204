import React from 'react';
import ClearTrip from '../Logos/ClearTrip';
import Dice from '../Logos/Dice';
import './index.css';
import useOriginHook from "../../Hooks/useOrigin";
import { useHistory } from 'react-router-dom';

function Index(props) {

	const HISTORY = useHistory()
	let {CLEARTRIP} = useOriginHook();

	return (
		<div>
				<footer className="footer-clean text-white bg-black mt-4">
					<div className="container pt-3 pb-3">
						<div className="row">
							<div className="col-sm-4 col-md-3 align-self-center item">
								{CLEARTRIP?<ClearTrip white={true}/>:<Dice white={true}/>}
							</div>
							<div className="col-sm-4 col-md-6 align-self-center item text-muted text-small" >
								<span onClick={()=>HISTORY.push('/terms')} className='text-white cursor-pointer'><u>Terms of Service &amp; Privacy Policy</u></span><br/><br/>
								All rights are reserved by the Company. The content on this Website are protected. Users shall not reproduce, copy, or redistribute content in any form without permission from the Company.
							</div>
						</div>
					</div>
				</footer>
			</div>
	);
}

export default Index;