import moment from 'moment';
import React from 'react';

function Bus({details, type, copyValue}) {
    console.log(details);
    
    const boardPoints = details.ticket.bus.boarding
    const dropPoints = details.ticket.bus.dropping

    return (
        <>
         {
            details?.ticket?
            <div className='row text-small'>
                <div className='col-6'>
                    <label className='text-muted mt-4'>PNR</label>
                    <div>{details.ticket.pnr}</div>
                </div>
                <div className='col-6'>
                    <label className='text-muted mt-4'>Ticket</label>
                    <div>{details.ticket.ticket}</div>
                </div>
            </div>
            :<></>
        }
        {
            details.ticket.bus?
            <>
            <div className='row text-small'>
                <div className='col-12 border bg-light rounded-3 p-3 mt-4'>
                    <div>
                        {details.ticket.bus.provider}<br/>
                        <b>{details.ticket.bus.label}</b><br/>
                        Seats {details.ticket?.travellers.map((traveller, i)=>{
                            return `[${traveller?.seatNo}] `
                        })}
                    </div>
                </div>
            </div>
            <div className='row text-small mt-3'>
                <div className='col-6 p-0'>
                    <div className='border bg-light rounded-3 p-3 h-100'>
                        <label className='text-muted'>BOARD</label>
                        {
                            boardPoints.map((point, i)=>{
                                if(point.code===details.ticket.pickupId){
                                    return <div key={i}>{point.name} at <b>{moment(point.time).format('hh:MM A on DD MMM YY')}</b></div>
                                }
                                return <></>
                            })
                        }
                    </div>
                </div>
                <div className='col-6 pe-0'>
                    <div className='border bg-light rounded-3 p-3 h-100'>
                        <label className='text-muted'>DROP</label>
                        {
                            dropPoints.map((point, i)=>{
                                if(point.code===details.ticket.dropId){
                                    return <div key={i}>{point.name} at <b>{moment(point.time).format('hh:MM A on DD MMM YY')}</b></div>
                                }
                                return <></>
                            })
                        }
                    </div>
                </div>
            </div>
            </>
            :<></>
        }
        </>
    );
}

export default Bus;