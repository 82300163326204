import React, {useId, useState, useEffect} from 'react'
import Props from 'prop-types'
import './Input.css'

const Input = React.forwardRef(({label, placeholder, type, morph, onChange, disabled, validation, defaultValue, className}, ref) => {

	const ID = useId();
	const [Value, setValue] = useState('');
	const [Valid, setValid] = useState(undefined);

	useEffect(() => {
		if(defaultValue){
			setValue(defaultValue)
		}
	}, [defaultValue]);

	const changeEvent = (e) => {
		let val = e.target.value;
		if(onChange){
			onChange(val)
		}
		if(validation){
			if(val){
				if(validation(val)){
					setValid(true)
				}else{
					setValid(false)
				}
			}else{
				setValid(undefined)
			}
		}
		setValue(val)
	}

	return (
		<div className={`${className}`}>
			<div className='form-text text-start'>
				{(label&&!morph)&&<label htmlFor={ID} className={`text-input-label mb-1 ${Valid===true?'valid':''} ${Valid===false?'invalid':''}`}>{label}</label>}
			</div>
			<div className={`position-relative`}>
				<input className={`form-control ${Valid===true?'valid':''} ${Valid===false?'invalid':''}`} id={ID} onChange={changeEvent} ref={ref} type={type} placeholder={!morph?placeholder:''} disabled={disabled} value={Value}/>
				{(label&&morph)&&<label htmlFor={ID} className={`morph-label ${Value?'morph-active':''} ${Valid===true?'valid':''} ${Valid===false?'invalid':''}`}>{label}</label>}
			</div>
		</div>
	)
})

Input.defaultProps = {
	defaultValue: '',
	placeholder: '',
	type: 'text'
}

Input.propTypes = {
	/**
	 * Use external css classes
	 */
	className:Props.string,
	/**
	 * Function to handle onChange event
	 */
	onChange:Props.func,
	/**
	 * Default value of input
	 */
	defaultValue: Props.string,
	/**
	 * Make input disabled
	 */
	disabled: Props.bool,
	/**
	 * Function for validating input
	 */
	validation: Props.func,
	/**
	 * Label for input
	 */
	label: Props.string,
	/**
	 * Placeholder for input
	 */
	placeholder: Props.string,
	/**
	 * Label doubles as placeholder
	 */
	morph: Props.bool,
	/**
	 * Field Type
	 * text
	 * number
	 * password
	 * email
	 */
	type: Props.string
}

export default Input;
