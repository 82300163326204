import React from 'react';
import './RoomCard.css'

function AddGuest({room, travellers, roomId, hotelId, session, stay}) {

    return (
        <>
        <div className={`room-card bg-white border rounded-4 p-3 mt-3`}>
            <div className='row'>
                <div className="col-8 d-flex flex-column">
                    <h6 className="fw-bold">{room.name.includes('Non-refundable')?room.name.split('Non-refundable')[0]:room.name}</h6>
                    
                    <div className='fw-bold mb-2'>
                        <i className="fas fa-bed me-1"/> {travellers.adult} Adult
                        {travellers.child?<> + {travellers.child} Child</>:<></>}
                    </div>
                    <div className='d-flex flex-wrap align-items-center'>
                        {
                            room.meal==='BREAKFAST'&&
                            <div className='text-small me-2'>🌮 Breakfast Included</div>
                        }
                        {
                            room.meal==='ROOM ONLY'&&
                            <div className='text-small me-2'>🛏 Room Only</div>
                        }
                        {
                            room.meal==='HALF BOARD'&&
                            <div className='text-small me-2'>🌮 Breakfast  + Lunch/Diner</div>
                        }
                        {
                            room.meal==='FULL BOARD'&&
                            <div className='text-small me-2'>🌮 Breakfast + Lunch + Dinner</div>
                        }
                        {
                            !room.refundable&&
                            <div className='text-small me-2'>❌ Non-Refundable</div>
                        }
                    </div>
                </div>
                <div className='col-4 text-end'>
                    <div>
                        <h6 className="fw-bold">Rs. {Math.ceil(room.totalFare)}</h6>
                        <div className='text-small'>For {stay} Night</div>
                    </div>
                </div>
                <div className='mt-4 border-top pt-3'>
                    <h6>Cancellation Policy</h6>
                    <div className='text-small text-muted'>
                        {room.cancellationPolicy}
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

export default AddGuest;