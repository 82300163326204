import React from 'react'
import {useLoginHook} from "../../../Hooks/useLoginHook";
import SectionHeader from "../../../Component/Containers/Section-Header";
import NavBar from "../../../Component/NavBar";
import HiddenFares from "../../../Component/Banners/HiddenFares";
import HelpSection from "../../Common/HelpSection";
import Search from "./Search";

export default function Home() {
	let CONFIG = useLoginHook();

	if(!CONFIG.authenticated){
		return  (
			<>
				<SectionHeader color={'#FACFCF'}>
					<NavBar/>
					<div className="p-1 mt-4 pb-4 ps-2">
						<h5>Welcome</h5>
						<h1 className="display-5" style={{fontWeight: 'bold'}}>Best deals on your Bus booking</h1>
					</div>
					<HiddenFares title='Login to browse flights' style={{marginBottom:-150}}/>
				</SectionHeader>
				<div style={{marginTop:260}}/>
				<HelpSection/>
			</>
		)
	}
	return (
		<div>
			<SectionHeader color={'#FACFCF'}>
				<NavBar/>
				<Search/>
			</SectionHeader>
			<div style={{marginTop:150}}/>
			<HelpSection/>
		</div>
	)
}
