import React from 'react';
import CollapseBox from '../../Containers/CollapseBox'

function Index({heading,options=[{name:'', value:''}],onChange, selected}) {

    const updateFilter = (e, value) => {
        let bool = e.target.checked
        if(bool){
            onChange([...selected, value])
        }else{
            let updatedFilters = selected.filter(option=>option!==value)
            onChange(updatedFilters)
        }
    }

    return (
        <CollapseBox heading={heading}>
            {
            options.map((option,index)=>{
                return (
                    <div key={index} className="form-check mt-2">
                        <input checked={selected.includes(option.value)} onChange={(e)=>{updateFilter(e,option.value)}} className="form-check-input" type="checkbox" />
                        <label className="form-check-label" htmlFor="formCheck-1" style={{fontSize: 14}}>{option.name}</label>
                    </div>
                )
            })
            }
        </CollapseBox>
    );
}

export default Index;