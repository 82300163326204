import React from 'react';
import { useHistory } from 'react-router-dom';

function Index({title, icon, active, url,style}) {
    let HISTORY = useHistory()
    return (
        <>
        <li onClick={()=>HISTORY.push(url)} className="nav-item text-center cursor-pointer" style={{flex:1,...style}}>
            <div className={`nav-link ${active&&'active'} text-truncate`}>{icon&&icon}{title}</div>
        </li>
        </>
    );
}

export default Index;
