import React, {useState, useRef} from 'react';
import BorderBox from '../../../Component/Cards/BorderBox'
import Modal from '../../../Component/Containers/Modal'
import { showToast } from '../../../Component/Toast';
import { post } from '../../../Model/Config/Axios';

function Index() {

    let EMAIL = useRef(), BOOKID = useRef();
    const [MailTicket, setMailTicket] = useState(undefined);

    const onSubmit = (params) => {
        let email = EMAIL.current.value;
        let id = BOOKID.current.value;

        if(!email){
            showToast({
                type:'error',
                message: 'Enter your email'
            })
            return
        }
        if(!id){
            showToast({
                type:'error',
                message: 'Enter Booking Id'
            })
            return
        }

        post(``, {email, id}, (e,r)=>{
            if(r){
                setMailTicket(undefined)
                showToast({
                    type:'success',
                    message: 'We have emailed your itinerary. Check your inbox.'
                })
            }else if(e){
                showToast({
                    type:'error',
                    message: 'Problem in sending, Try later.'
                })
            }
        })   
    }

    return (
        <>
        <div className="pt-4 pb-4">
            <div className="container">
                <div className="row">
                <div className="col-md-12">
                    <h5>Quick Links</h5>
                    <h1 style={{fontWeight: 'bold'}}>Need Some help?</h1>
                </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <BorderBox onClick={()=>{window.open(`mailto:connect.b2b@cleartrip.com?subject=Support Team`,'_self')}} title={'Support'} description={
                    <>For any Trip ID related services, log on to www.cleartrip.com/support Call +91-98205 10666 (24X7) or email at perks@cleartrip.com</>
                    } icon={<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-chat-right-text-fill me-3"><path d="M16 2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h9.586a1 1 0 0 1 .707.293l2.853 2.853a.5.5 0 0 0 .854-.353V2zM3.5 3h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1 0-1zm0 2.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1 0-1zm0 2.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1z" /></svg>}/>
                    <BorderBox onClick={()=>setMailTicket(true)} title={'Email itinerary'} description={'Get your ticket and boarding pass directly on your email.'} icon={<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-file-text-fill me-3"><path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM5 4h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1 0-1zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zM5 8h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1 0-1zm0 2h3a.5.5 0 0 1 0 1H5a.5.5 0 0 1 0-1z" /></svg>}/>
                    <BorderBox title={'Flight Status'} description={'Check your flight status by entering flight number or PNR number'} icon={<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-geo-fill me-3"><path fillRule="evenodd" d="M4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999zm2.493 8.574a.5.5 0 0 1-.411.575c-.712.118-1.28.295-1.655.493a1.319 1.319 0 0 0-.37.265.301.301 0 0 0-.057.09V14l.002.008a.147.147 0 0 0 .016.033.617.617 0 0 0 .145.15c.165.13.435.27.813.395.751.25 1.82.414 3.024.414s2.273-.163 3.024-.414c.378-.126.648-.265.813-.395a.619.619 0 0 0 .146-.15.148.148 0 0 0 .015-.033L12 14v-.004a.301.301 0 0 0-.057-.09 1.318 1.318 0 0 0-.37-.264c-.376-.198-.943-.375-1.655-.493a.5.5 0 1 1 .164-.986c.77.127 1.452.328 1.957.594C12.5 13 13 13.4 13 14c0 .426-.26.752-.544.977-.29.228-.68.413-1.116.558-.878.293-2.059.465-3.34.465-1.281 0-2.462-.172-3.34-.465-.436-.145-.826-.33-1.116-.558C3.26 14.752 3 14.426 3 14c0-.599.5-1 .961-1.243.505-.266 1.187-.467 1.957-.594a.5.5 0 0 1 .575.411z" /></svg>}/>
                </div>
            </div>
        </div>
        {
            MailTicket?
            <Modal onClose={()=>setMailTicket(undefined)} onSuccess={()=>onSubmit()} title='Email Itinerary' description='You can also download your bookings from Bookings page' button={'Send'}>
                <div className='w-100'>
                    <div className='row'>
                        <div className='col-md-6'>
                        <label style={{fontSize:'0.7rem'}} className='form-text text-muted col-12 text-truncate' htmlFor="EmailInput">Your Email</label>
                            <input ref={EMAIL} type="text" className="form-control" placeholder="Email" id='EmailInput' />
                        </div>
                        <div className='col-md-6'>
                        <label style={{fontSize:'0.7rem'}} className='form-text text-muted col-12 text-truncate' htmlFor="IdInput">Booking Id</label>
                            <input ref={BOOKID} type="text" className="form-control" placeholder="Booking Id" id='IdInput' />
                        </div>
                    </div>
                </div>
            </Modal>
            :<></>
        }
        </>
    );
}

export default Index;
