import React from 'react';
import moment from 'moment';

function Index({amount, createdAt, date, id, refunded, status, type, onClick}) {

    return (
        <div  onClick={onClick} className='col-12 col-md-3 mt-4 cursor-pointer'>
            <div className='bg-white p-4 shadow-sm h-100' style={{borderRadius:'0.5rem'}}>
                <h3><i className="fas fa-plane"></i></h3>
                <span className={`p-1 ps-2 pe-2 rounded-pill text-truncate ${status==='FAILED'?'bg-danger text-white':'bg-light border'}`} style={{fontSize:'0.6rem'}}>
                    {status}
                </span>
                <h6 className='m-0 mt-4'>{moment(date).format('DD MMM YY')}</h6>
                <p className='m-0 text-small text-muted'>Booking Id: <span style={{textTransform:'uppercase', wordBreak:'break-all'}}>{id}</span></p>
                <h4 className='m-0 me-3 mt-2'>Rs. {amount}</h4>
                {
                    refunded&&<span className={`p-1 ps-2 pe-2 rounded-pill text-truncate text-white bg-danger`} style={{fontSize:'0.6rem'}}>Refunded</span>
                }
            </div>
        </div>
    );
}

export default Index;