import React, {useEffect, useState} from 'react';
import NavBar from '../../Component/NavBar'
import SectionHeader from '../../Component/Containers/Section-Header'
import {useLoginHook} from "../../Hooks/useLoginHook";
import Tabs from '../../Component/Tabs'
import FlightOrder from '../../Component/Cards/FlightOrder'
import HotelOrder from '../../Component/Cards/HotelOrder'
import BusOrder from '../../Component/Cards/BusOrder'
import SupportDetails from '../../Component/NavBar/SupportDetails'
import {get} from '../../Model/Config/Axios'
import { showToast } from '../../Component/Toast';
import Loader from '../../Component/Loader';
import ViewTicket from './View'
import { useLocation } from 'react-router-dom';
import TicketIcon from '../../Assets/Images/ticket.webp'
import Modal from '../../Component/Containers/Modal'

function Index() {

    let CONFIG = useLoginHook();
    let {search} = useLocation();
    const params = new URLSearchParams(search)
    let bookingId = params.get('id')
    let bookingType = params.get('type')
    const [Support, setSupport] = useState(undefined);

	if(!CONFIG.authenticated){
		CONFIG.Login()
	}

    const [Bookings, setBookings] = useState(undefined);
    const [FilteredBookings, setFilteredBookings] = useState([]);
    const [Type, setType] = useState('all');
    const [ViewBooking, setViewBooking] = useState(bookingId?bookingId:undefined);

    useEffect(() => {
        fetchBookings()
    }, []);

    const fetchBookings = (type) => {
        setBookings(undefined)
        get(`bookings/all`, (e,r)=>{
            if(r){
                setBookings(r.bookings)
                setFilteredBookings(r.bookings)
            }else if(e){
                showToast({
                    type:'error',
                    message: 'Problem in loading bookings'
                })
            }
        })
    }

    const filterBookings = (e) => {
        let val = e.target.value;
        if(val){
            let filtered = Bookings.filter(booking=>booking.id.includes(val.toLowerCase()));
            setFilteredBookings(filtered)
        }else{
            setFilteredBookings(Bookings)
        }
    }

    const openBooking = (booking) => {
        if(booking.status!=='FAILED'){
            setViewBooking(booking.id)   
        }else{
            showToast({type:'error', message:'This booking failed'})
        }
    }
    
    return (
        <>
        <SectionHeader color={'var(--material-green)'}>
            <NavBar/>
            <div className="p-1 mt-4 pb-4 ps-2">
                <h5>Orders</h5>
                <h1 className="display-5" style={{fontWeight: 'bold'}}>Your Bookings</h1>
            </div>
        </SectionHeader>
        <div className="container mb-5">
            <div className="row">
                <div className="col-md-12 pt-4">       
                    <div className='row bg-light pb-4 position-sticky' style={{top:0}}>
                        <div className='mt-3 col-md-5 text-md-end'>
                            <Tabs active={Type} tabs={[
                                {label: 'All', onClick: ()=>setType('all')},
                                {label: 'Refunded', onClick: ()=>setType('refunded')},
                            ]}/>
                        </div>
                        <div className='mt-3 col-md-4 d-flex offset-md-3 align-items-center'>
                            <input onChange={filterBookings} type="text" className="form-control bg-white" placeholder="Search by Booking Id" style={{borderRadius: '0.5rem', padding: '0.8rem 1rem'}}/>   
                            <h5 style={{marginLeft:'-60px', marginTop:8}}><i className="ms-3 fas fa-search"></i></h5>
                        </div>
                    </div>
                    <div className='text-small border rounded-4 p-3 d-flex align-items-center justify-content-between' style={{maxWidth:600}}>
                        <div>Facing any issues with your bookings ? Reach out to Support.</div>
                        <button onClick={()=>setSupport(true)} className='ms-3 btn btn-dark'>Support</button>
                    </div>
                    <div className='mt-3 row' style={{margin:'-0.25rem'}}>
                        {
                            FilteredBookings?
                            FilteredBookings.length?
                            FilteredBookings.map((booking, index)=>{
                                
                                if(Type==='refunded'&&booking.status!=='REFUNDED'){
                                    return <></>
                                }

                                if(booking.type==='DOMESTICROUNDFLIGHT'||booking.type==='DOMESTICFLIGHT')
                                return (
                                    <FlightOrder {...booking} onClick={()=>openBooking(booking)} key={index}/>
                                )

                                if(booking.type==='HOTEL')
                                return (
                                    <HotelOrder {...booking} onClick={()=>openBooking(booking)} key={index}/>
                                )
                                
                                if(booking.type==='BUS')
                                return (
                                    <BusOrder {...booking} onClick={()=>openBooking(booking)} key={index}/>
                                )

                                else return <></>
                            })
                            :<div className='p-4 border rounded-4 text-center w-100'>
                                <img src={TicketIcon} className='mb-2' style={{width:40}} alt='Ticket'/><br/>
                                No Bookings found
                            </div>                                
                            :<div className='w-100 d-flex flex-center'><Loader/></div>
                        }
                   </div>
                </div>
            </div>
        </div>
        {
            ViewBooking?
            <Modal maxWidth={700} noButtons={true} onClose={()=>setViewBooking()} title='Booking'>
                <ViewTicket onReload={fetchBookings} id={ViewBooking} type={bookingType}/>
            </Modal>
            :<></>
        }
        {
            Support&&<SupportDetails onClose={()=>setSupport(false)}/>
        }
        </>
    );
}

export default Index;
