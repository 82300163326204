import React from 'react';
import NavBar from '../../../Component/NavBar'
import SectionHeader from '../../../Component/Containers/Section-Header'
import HotelSearch from './Search'
import HelpSection from "../../Common/HelpSection";
import {useLoginHook} from "../../../Hooks/useLoginHook";

function Index() {

    let CONFIG = useLoginHook();
	
	if(!CONFIG.authenticated){
		return  (
			<>
				<SectionHeader color={'var(--material-pink)'}>
					<NavBar/>
					<div className="p-1 mt-4 pb-4 ps-2">
						<h5>Welcome</h5>
						<h1 className="display-5" style={{fontWeight: 'bold'}}>Book Hotels anywhere instantly</h1>
					</div>
				</SectionHeader>
				<div style={{marginTop:260}}/>
				<HelpSection/>
			</>
		)
	}
	return (
		<>
			<SectionHeader color={'var(--material-pink)'}>
				<NavBar/>
				<HotelSearch/>
			</SectionHeader>
			<div style={{marginTop:280}}/>
			<HelpSection/>
		</>
	);
}

export default Index;
