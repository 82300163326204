import React, {useEffect, useState} from 'react';
import {get} from '../../Model/Config/Axios'

function Rewards({updateCoins, coins}) {
    const [RedeemCoins, setRedeemCoins] = useState(coins);
    const [TotalRewards, setTotalRewards] = useState(0);

    const totalRewards = () => {
        get(`/rewards`,(e,r)=>{
            if(r){
                setTotalRewards(r.reward)
            }else if(e){
                // showToast({
                //     type:'error',
                //     message: 'Problem in loading Rewards'
                // })
            }
        }) 
    }


    useEffect(() => {
        setRedeemCoins(coins)
        totalRewards()
    }, [coins]);


    return (
        <>
        <div className='mt-4 p-4 rounded-3 bg-white shadow-sm' style={{padding:'2rem'}}>
            <h5 style={{fontWeight: 'bold'}}>Redeem Coins</h5>
            <p className="mt-3 text-small">
                You can use your reward coins to get off equivalent to your coins value on this booking.
            </p>

            <div className='row mt-3'>
                <div className='col-md-8'>
                    <div className='mt-3' style={{maxWidth:400}}>
                        <div className="d-flex justify-content-between" style={{fontSize: '0.8rem'}}>
                            <span className="fw-bold" style={{fontSize: '0.8rem'}}>Use {RedeemCoins} Coins</span>
                            <span className="fw-bold" style={{fontSize: '0.8rem'}}>Total {parseInt(TotalRewards)} Coins</span>
                        </div>
                        <input onChange={e=>setRedeemCoins(e.target.value)} className="form-range mt-3 w-100" type="range" step={100} value={RedeemCoins} min={0} max={TotalRewards}/>
                    </div>
                </div>
                <div className='col-md-4 text-end mt-3'>
                    {
                        RedeemCoins!==coins&&
                        <div onClick={()=>updateCoins(RedeemCoins)} className='cursor-pointer bg-success text-white p-3 text-center rounded-pill text-nowrap'>
                            <i className='fas fa-check me-3'/>Apply Rs. {parseInt(RedeemCoins)/10} OFF
                        </div>
                    }
                </div>
            </div>
        </div>
        </>
    );
}

export default Rewards;