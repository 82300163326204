import LoadingIcon from "./loading.webp";
import React from "react";

const hotelSortItem = (sort, a, b) => {
	if(sort.type){
		if (sort.type === "rating")
			if (sort.asc) {
				return a.starRating - b.starRating;
			} else {
				return b.starRating - a.starRating;
			}

		//Push Unavailable at the end on sorting
		if(a.pricePerNight<0){
			return 0-b.pricePerNight
		}
		if(b.pricePerNight<0){
			return 0-a.pricePerNight
		}
		if (sort.asc) {
			return a.pricePerNight - b.pricePerNight
		} else {
			return b.pricePerNight - a.pricePerNight
		}
	}
}


const hotelFilterUtils = (filters, hotel) => {
	if(filters.price){
		if(hotel.pricePerNight>filters.price)
		return false
	}
	if(hotel.starRating<4)
		return false
	if(filters.stars&&filters.stars.length){
		for(let i=0; i<filters.stars.length;i++){
			if(!filters.stars.includes(hotel.starRating))
			return false
		}
	}
	if(filters.quick&&filters.quick.length){
		if(hotel.pricePerNight<0)
		return false
	}
	if(filters.location&&filters.location.length){
		if(!filters.location.includes(hotel.locality))
		return false
	}
	if(filters.chain&&filters.chain.length){
		if(!filters.chain.includes(hotel.hotelChain))
		return false
	}
	if(filters.search){
		let val = filters.search.toLowerCase()
		if(!(hotel.name&&hotel.name.toLowerCase().includes(val)&&hotel.description&&hotel.description.toLowerCase().includes(val)))
		return false
	}

	return true
}

const HotelWrapper = ({loading, text = "hotels", length, children}) => {
	if (loading) {
		return (
			<div className="flex-fill pe-2 ps-2">
				<div className='FadeUp-Effect bg-white p-4 mt-4 border rounded-3 text-center'>
					<div className='d-flex align-items-center justify-content-center'>
						<img src={LoadingIcon} alt='Loading' className='me-3' style={{maxWidth: 50}}/>
						<h5>Searching {text}...</h5>
					</div>
				</div>
				{
					Array(10).fill(0)
						.map((_, index) => {
							return (
								<div style={{
									background: "#f6f7f8"
								}} key={index} className="rounded-4 mt-3 overflow-hidden">
									<div className="Stencil has-shimmer w-100" style={{
										minHeight: 180
									}}/>
								</div>
							)
						})
				}
			</div>
		)
	}
	if (length)
		return (
			<>
				{children}
			</>
		)
	return (
		<>

			<div className="flex-fill pe-2 ps-2">
				<div className='FadeUp-Effect bg-white p-4 mt-4 border rounded-3 text-center'>
					<div className='d-flex align-items-center justify-content-center'>
						<h5>No Hotels Found</h5>
					</div>
				</div>
			</div>
		</>
	)
}

const priceBreakup = (price) => {
	// Hotel Tax Breakup
	// <1000 = 0%
	// 1000 - 7500 = 12%
	// 7500+ = 18%

	let tax = 18
	if(price<7500){
		tax = 12
	}
	if(price<1000){
		tax = 0
	}
	let base = (price*100)/(100+tax)
	return {tax: Math.floor((price-base)), base: Math.ceil(base.toFixed(2)), taxPercent: tax}
}

export {
	hotelSortItem, hotelFilterUtils, HotelWrapper, priceBreakup
}
