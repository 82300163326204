import React, {useState, useEffect} from 'react';
import FlightCard from './Components/MultiplePriceCard'
import SortBar from './Components/SortBar'
import {flightFilterUtils, FlightWrapper, flightSortItem} from "../../../FlightUtils";
import {useCheckScreenHook} from '../../../../../Hooks/useCheckScreenHook'

function Index({loaded,filters,departureFlights,returnFlights,selectDeparture,selectedDeparture,selectReturn,selectedReturn, Tab, setTab,}) {
	
	const [AppId, setAppId] = useState(undefined);
	const [departureSort, setDepartureSort] = useState({type: 'price',asc: true})
	const [arrivalSort, setArrivalSort] = useState({type: 'price',asc: true})

	//Remove Special Flight Filter on Cancel
	useEffect(() => {
		if(!selectedDeparture&&!selectedReturn){
			setAppId(undefined)
		}
	}, [selectedDeparture, selectedReturn]);

    const ScreenSize = useCheckScreenHook();

	useEffect(() => {
        if(ScreenSize==='sm'){
            setTab("DEPARTURE")
        }else{
            setTab(undefined)
        }
		// eslint-disable-next-line
    }, [ScreenSize]);
	
	const DepartureFlights = departureFlights.filter(item=>flightFilterUtils(filters,item))
	const ReturnFlights = returnFlights.filter(item=>flightFilterUtils(filters,item))
	
	return (
		<div className="row d-flex justify-content-between">
			{
				(Tab === undefined ||  Tab === 'DEPARTURE') &&
				<FlightWrapper text="onward flight" loading={!loaded} length={departureFlights.length}>
					<div className={"col-12 col-md-6"} >
						<div className="position-relative">
							{
								DepartureFlights.length === departureFlights.length ?
									<p className="text-muted">Showing all {departureFlights.length} onwards results</p>
									:
									<p className="text-muted">Showing {DepartureFlights.length} flights from {departureFlights.length} onwards results</p>
							}
							<SortBar sort={departureSort} setSort={setDepartureSort}/>
							<div className='mt-2'>
								{
									DepartureFlights.sort((a,b)=>flightSortItem(departureSort,a,b)).map((flight, index) => {
										return (
											<FlightCard fullSelection={selectedDeparture && selectedReturn} appId={AppId}
														setAppId={setAppId}
														selected={selectedDeparture && selectedDeparture.fare}
														onClick={selectDeparture} key={index} flight={flight}/>
										)
									})
								}
							</div>
						</div>
					</div>
				</FlightWrapper>
			}
			{
				(Tab === undefined || Tab === 'RETURN') &&
				<FlightWrapper text="return flight" loading={!loaded} length={returnFlights.length}>
					<div className="col-12 col-md-6">
						<div className="position-relative">
							{
								ReturnFlights.length === returnFlights.length ?
									<p className="text-end text-muted">Showing all {ReturnFlights.length} return flights</p>
									:
									<p className="text-end text-muted">Showing {ReturnFlights.length} flights from {returnFlights.length} return flights</p>
							}
							<SortBar sort={arrivalSort} setSort={setArrivalSort}/>
							<div className='mt-2'>
								{
									ReturnFlights.sort((a,b)=>flightSortItem(arrivalSort,a,b)).map((flight, index) => {
										return (
											<FlightCard fullSelection={selectedDeparture && selectedReturn}
														appId={AppId} setAppId={setAppId}
														selected={selectedReturn && selectedReturn.fare}
														onClick={selectReturn} key={index} flight={flight}/>
										)
									})
								}
							</div>
						</div>
					</div>
				</FlightWrapper>
			}
		</div>
	);
}

export default Index;
