import React from 'react';
import Modal from '../../../../../Component/Containers/Modal'

function Amenities({onClose, amenities}) {
    return (
        <>
        <Modal onClose={onClose} title='Amenities' noButtons={true}>
            <div className='d-flex flex-wrap align-items-center text-small w-100 mb-3'>
            {
                amenities.map((amenity,index)=>{
                    return <div key={index} className='me-1 text-nowrap'>{amenity.replaceAll('_',' ')},</div>
                })
            }
            </div>
        </Modal>
        </>
    );
}

export default Amenities;