import React from 'react'
import {Route, Switch} from "react-router-dom";
import SRP from "./SRP";
import Booking from './Booking'
import Home from './Home'
import ViewHotel from './ViewHotel'

export default function Render() {
	return (
		<div>
			<Switch>
				<Route path="/hotels/booking" component={Booking}/>
				<Route path="/hotels/view" component={ViewHotel}/>
				<Route path="/hotels/search" component={SRP}/>
				<Route path="/hotels" component={Home}/>
			</Switch>
		</div>
	)
}
