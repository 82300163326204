import React, {useState, useEffect, useRef} from 'react';
import Modal from '../../../Component/Containers/Modal'
import {get, post} from '../../../Model/Config/Axios'
import Loader from '../../../Component/Loader'
import { showToast } from '../../../Component/Toast';
import moment from 'moment';
import {savePDF } from "@progress/kendo-react-pdf";
import useOriginHook from "../../../Hooks/useOrigin";
import ClearTrip from '../../../Component/Logos/ClearTrip'
import Dice from '../../../Component/Logos/Dice'
import SupportDetails from '../../../Component/NavBar/SupportDetails'
import TextInput from '../../../Component/Form/Inputs/TextInput'
import Flight from './Types/Flight';
import Hotel from './Types/Hotel';
import Bus from './Types/Bus';

function Index({onClose, id, type, onReload}) {

    const [Details, setDetails] = useState(undefined);
    const [DeleteModal, setDeleteModal] = useState();
    let REMARKS  = useRef(), TICKET = useRef(), POLLING = useRef();
    const [Support, setSupport] = useState();
    let {CLEARTRIP} = useOriginHook();

    useEffect(() => {
        getDetails()
        return ()=>{
            clearInterval(POLLING.current)
        }
        // eslint-disable-next-line
    }, []);

    const CancelBooking = () => {
        let remarks = REMARKS.current.value;
        if(!remarks){
            showToast({
                type:'error',
                message: `Enter reason for cancellation`
            })
            return
        }
        post(`bookings/${id}/refund`, {remarks}, (e,r)=>{
            if(r){
                getDetails(id)
                onReload&&onReload()
                setDeleteModal()
                showToast({
                    type:'success',
                    message: `Booking cancelled`
                })
            }else if(e){
                showToast({
                    type:'error',
                    message: 'Problen in cancelling booking'
                })
            }
        })
    }

    const getDetails = () => {
        get(`/bookings/${id}/details`, (e,r)=>{
            if(r){
                setDetails(r)
                if(r.status==='ONGOING'){
                    polling()
                }
            }else if(e){
                showToast({
                    type:'error',
                    message: 'Problen in openig booking'
                })
            }
        })
    }

    const polling = () => {
        POLLING.current = setInterval(() => {
            get(`/bookings/${id}/details`, (e,r)=>{
                if(r){
                    setDetails(r)
                    if(r.status==='COMPLETED'){
                        clearInterval(POLLING.current)
                    }
                }else if(e){
                    showToast({
                        type:'error',
                        message: 'Problen in openig booking'
                    })
                }
            })
        }, 5000);
    }

    const copyValue = (val) => {
        navigator.clipboard.writeText(val).then(() => {
            showToast({
                type:'success',
                message: 'PNR Copied'
            })
        });
    }

    const downloadPDF = () => {
        savePDF(TICKET.current, {
          paperSize: "auto",
          margin: 50,
          fileName: `Ticket-${id}`,
        });
    };

    if(Support){
        return <SupportDetails onClose={()=>setSupport(false)}/>
    }

    return (
        <>
        {
            Details?
            <div ref={TICKET} className='p-3 pt-0 w-100' style={{fontFamily:'Arial'}}>
                <div className='mb-2'>
                    {CLEARTRIP?<ClearTrip/>:<Dice/>}
                </div>
                {
                    Details.status==='ONGOING'?
                    <div className='text-small text-white text-center mt-3 p-3 rounded-3' style={{background:'#fea601'}}><i className='fas fa-clock me-2'/>We are confirming your booking, this may take upto few minutes ...</div>
                    :Details.status==='REFUNDED'?
                    <div className='text-small text-center mt-3 p-3 rounded-3 border bg-light'><i className='fas fa-check me-2'/>This booking has been cancelled and refunded</div>
                    :Details.status.startsWith('REFUND')?
                    <div className='text-small text-center mt-3 p-3 rounded-3 border bg-light'><i className='fas fa-hourglass-half me-2'/>We are processing your refund request ...</div>
                    :
                    <>
                     <div className='row pt-3'>
                        <div className='col-md-6 text-small'>
                            <label className='text-muted'>Booking Date</label>
                            <p>{moment(Details.createdAt).format('DD MMM YY')}</p>
                        </div>
                        <div className='col-md-6 text-small'>
                            <label className='text-muted'>Booking ID</label>
                            <p style={{textTransform:'uppercase'}}>{Details.id}<i onClick={()=>copyValue(Details.id)} className="ms-2 cursor-pointer far fa-copy"></i></p>
                        </div>
                    </div>
                    <div className='row pt-2'>
                        <div className='col-md-6 text-small'>
                            <label className='text-muted'>Travel Date</label>
                            <p>{moment(Details.date).format('DD MMM YY')}</p>
                        </div>
                        <div className='col-md-6 text-small'>
                            <label className='text-muted'>Amount</label>
                            <div className='d-flex align-items-center'>
                                <p className='m-0 me-2'>Rs. {Details.amount}</p>
                                {
                                    Details.refunded&&<span className={`p-1 ps-2 pe-2 rounded-pill text-truncate text-white bg-danger`} style={{fontSize:'0.6rem'}}>Refunded</span>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12'>
                            <label className='text-muted text-small me-2 mt-1'>Status</label>
                            <div><span className={`p-1 ps-2 pe-2 rounded-pill text-truncate ${Details.status==='COMPLETED'?'bg-success text-white':'bg-light border'}`} style={{fontSize:'0.6rem'}}>{Details.status}</span></div>
                        </div>
                    </div>

                    {
                        (Details.type==='DOMESTICROUNDFLIGHT' || Details.type==='DOMESTICFLIGHT')
                        ?<Flight details={Details} type={type} copyValue={copyValue}/>
                        :Details.type==='HOTEL'
                        ?<Hotel {...Details}/>
                        :Details.type==='BUS'
                        ?<Bus details={Details}/>:
                        <></>
                    }

                    <div className='d-flex flex-center text-small mt-4'>
                        <div>Facing issues with this booking? </div>
                        <div onClick={()=>setSupport(true)} className='fw-bold ms-2 cursor-pointer'>Contact Support <i className='fas fa-arrow-circle-right'/></div>
                    </div>
                    <div className='p-3 pb-5 d-flex flex-center'>
                        <button className='btn btn-dark rounded-md me-2' onClick={()=>Details.file?window.open(Details.file,'_blank'):downloadPDF()}>Download Ticket</button>
                        {
                            (Details.date>moment().valueOf() && Details.status!=='REFUND_REQUESTED' && !Details.refunded) &&
                            <button className='btn btn-danger rounded-md' onClick={()=>setDeleteModal(true)}>Cancel Booking</button>
                        }
                    </div>
                    </>
                    
                }
            </div>
            :<Loader/>
        }
        {
            DeleteModal&&
            <Modal onClose={()=>setDeleteModal()} onSuccess={()=>CancelBooking(DeleteModal)} title={`Cancel this booking`} button={'Yes, Cancel Booking'}>
                <div className='text-start w-100'>
                    Enter reason for  cancelling this booking
                    <TextInput className='mt-3' ref={REMARKS} type='text' placeholder='Reason for cancellation'/>
                </div>
            </Modal>
        }
        </>
    );
}

export default Index;