import React, {useState} from 'react';
import './FlightSeatMap.css'
import Modal from  '../Containers/Modal'
import { showToast } from '../Toast';

function FlightSeatMap({map, onSelect, rows, cols, label}) {
    const [ViewMap, setViewMap] = useState(false);
    const [Selected, setSelected] = useState(undefined);

    const plotMap = (seat, index) => {        
        const {row, column, code, amount, booked, empty} = seat;
        let isSelected = false;
        if(Selected&&Selected.code===code){
            isSelected=true
        }

        if(empty){
            return <></>
        }

        return (
            <div title={amount} onClick={()=>{if(!booked){isSelected?setSelected(undefined):setSelected(seat)}}} className={`seat ${booked?'disabled':''} ${amount===0?'free':''} ${isSelected?'selected':''} `} style={{
                gridArea: `${row} / ${column} / span 1 / span 1`,}}>
                {code}
            </div>
        )
        
    }

    const selectSeat = () => {
        if(!Selected){
            showToast({
                type:'error',
                message:'You have not selected any seat'
            })
            setViewMap(undefined)
        }
        onSelect(Selected)
        setViewMap(undefined)
    }

    return (
        <>
            <button onClick={()=>setViewMap(true)} className={`btn w-100 pt-2 pb-2 ${Selected?'btn-success':'btn-outline-dark'} rounded-3 text-small`}>{Selected?`💺 Change Seat (${Selected.code})`:'💺 Choose Seat'}</button>
            {
                ViewMap&&
                <Modal noButtons={true} button='Select' title='Select Seat' onClose={()=>{setViewMap(false);setSelected(undefined)}}>
                    <div className='seat-map'>
                        <div className='selected-seats fw-bold pb-3'>
                            {Selected?
                            <div className='d-flex align-items-center justify-content-between'>
                                <div className='d-flex align-items-center justify-content-center'>
                                    <div className='ms-2 me-2 bg-success text-white rounded-3 p-1 ps-2 pe-2'>
                                        {Selected.code}
                                    </div>
                                    Seat Charges Rs. {Selected.amount}
                                    <div onClick={()=>setSelected(undefined)} className='p-1 cursor-pointer'>
                                        <i className='fas fa-times-circle ms-3' style={{fontSize:'1.2rem'}}/>
                                    </div>
                                </div>
                                <button onClick={selectSeat} className='btn btn-dark'>Select</button>
                            </div>
                            :
                            <div className='d-flex align-items-center mb-2 pb-2'>
                                <div style={{background:'#a3d4a7', width:15, height:15}} className='rounded-pill me-2'/>
                                Free Seat
                            </div>
                            }
                        </div>
                        <div className='select-seats'>
                            <div className='border rounded-3 p-3 w-100 text-center'>Front</div>
                                <div className='flightseats mt-3' style={{
                                    gridTemplateColumns: `repeat(${cols}, 1fr)`,
                                    gridTemplateRows: `repeat(${rows}, 1fr)`,
                                }}>
                                {map.map(plotMap)}
                            </div>
                            <div className='border rounded-3 mt-3 p-3 w-100 text-center'>Back</div>
                        </div>
                    </div>
                </Modal>
            }
        </>
    );
}

export default FlightSeatMap;