import React from 'react';

function ScrollToTop() {
    
    return (
        <>
        <div onClick={()=>window.scrollTo(0, 0)} className='position-fixed cursor-pointer rounded-3 p-2 text-center' style={{background:'var(--material-blue)',bottom:'2rem', right:'2rem'}}>
				<i className="fas fa-long-arrow-alt-up"></i>
				<div>Top</div>
			</div>
        </>
    );
}

export default ScrollToTop;