import React from 'react';

function Index(props) {

    return (
        
            <div onClick={()=>{window.open(props.url,'_blank')}} className="mt-4 me-4 align-items-center shadow-sm bg-white p-4 cursor-pointer" style={{borderRadius:'1rem', maxWidth: 260, width:260, minWidth:260}}>
                <div><img alt="Box" className="img-fluid" src={props.photo} style={{height:140, width:'100%', borderRadius:'0.5rem', objectFit:'cover', objectPosition: 'left top'}} /></div>
                <div className="pt-4">
                    <h6 className='fw-bold text-truncate text-truncate--2'>{props.title}</h6>
                    <p className="text-muted text-small text-truncate text-truncate--2" style={{maxHeight: '60px'}}>{props.description}</p>
                    <h6 className='text-dark text-decoration-none'>
                        Read More
                        <svg className="bi bi-arrow-right ms-2" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                        </svg>
                    </h6>
                </div>
            </div>
    );
}

export default Index;