import React, {useRef, useState, useEffect} from 'react';
import User_Ico from "./user.png";
import axios from 'axios'
import {post} from '../../../Model/Config/Axios'
import {showToast} from '../../../Component/Toast'
import {useLoginHook} from '../../../Hooks/useLoginHook'

function Index() {
    
    let {config} = useLoginHook();
    
    let FILE_REF = useRef(), User_Photo = useRef();
    const [ProfileDP, setProfileDP] = useState(undefined);
    const [Name, setName] = useState('Loading');

    useEffect(() => {
        if(config){
            setProfileDP(config.avatar)
            setName(config.name)
        }
    }, [config]);

    const SelectPhoto = () => {
        let file = FILE_REF.current.files[0];
		if (file) {
			User_Photo.current.innerHTML = "<div class='mini-loader'></div>";
			const formData = new FormData();
			formData.append('file', file);

			axios({
				method: 'post',
				url: 'https://file.eka.io/file',
				data: formData,
				headers: {
					clientId: '422dc3e2-a960-4941-9a7a-af64732157b5',
					clientSecret: "9cd4ce3e-6ea6-45d7-bf92-06c9c7a69873"
				}
			}).then(res => {
				setProfileDP(res.data.url);
                User_Photo.current.innerHTML = "";
                updatePhoto(res.data.url);

			}).catch(()=>{
                showToast({
                    type:'error',
                    message: 'Problem in uploading Photo'
                })
			})

		} else {
			showToast({
				type: 'error',
				message: 'Please select a file'
			})
		}
    }

    const updatePhoto = (url) => {
        post(`config`, {avatar: url}, (e,r)=>{
            if(r){
                showToast({
                    type:'success',
                    message: 'Photo Updated'
                })
            }else{
                showToast({
                    message: 'Problem in updating photo'
                })
            }
        })
    }

    return (
        <div className="mt-4 p-4 bg-white shadow-sm" style={{ borderRadius: "0.5rem" }}>
            <div className="d-flex align-items-center">

                <input ref={FILE_REF} onChange={SelectPhoto} id='SelectDP' type='file' style={{display: 'none'}}/>

                <label ref={User_Photo} htmlFor='SelectDP' className='position-relative d-flex justify-content-center align-items-center' style={{borderRadius: '100%', cursor: 'pointer', width: '90px',minWidth: '90px' , height: '90px', background: `url(${ProfileDP?ProfileDP:User_Ico}) center center / cover no-repeat`}}>
                    <svg className='text-dark' style={{fontSize: '1.4rem', position: 'absolute', top: 0, right: 0}} xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"></path>
                    </svg>
                </label>

                <div className="ms-4">
                    <p className="m-0 text-muted">Name</p>
                    <h5 className="m-0">{Name}</h5>
                    <button onClick={()=>{localStorage.clear();window.location.reload()}} className="btn btn-outline-dark btn-sm ps-3 pe-3 mt-2 text-small" type="button">Logout</button>
                </div>
            </div>
        </div>
    );
}

export default Index;