import React from 'react';
import './index.css'

function Index({loading,done}) {
   
    if(loading)
    return (
        <div id="plane-animation" className={`srp-progress ${done&&'hide-bar-anim'}`}>
            <div className={`srp-fill-bar ${done?'full-bar-anim':'fill-bar-anim'}`}/>
            <i className={`fas fa-plane position-absolute ${done?'end-plane-anim':'move-plane-anim'}`} style={{left:5,top:7}} />
        </div>

    );
    else return <></>;
}

export default Index;