import React, {useEffect, useState} from 'react'
import {useLoginHook} from "../../../Hooks/useLoginHook";
import {useHistory, useLocation} from "react-router-dom";
import {post} from "../../../Model/Config/Axios";
import {showToast} from "../../../Component/Toast";
import Moment from "moment";
import BusSearch from './BusSearch'
import SectionHeader from "../../../Component/Containers/Section-Header";
import HelpSection from "../../Common/HelpSection";
import NavBar from "../../../Component/NavBar";
import BusAnimation from "../../../Component/BusAnimation";
import Results from "./Results";
import DaysCarousel from "../../../Component/DaysCarousel";
import moment from "moment/moment";
import BusFilters from "../../../Component/Filters/Bus";

export default function SRP() {
	let CONFIG = useLoginHook();
	let HISTORY = useHistory();
	if(!CONFIG.authenticated){
		HISTORY.push('/login')
	}
	const [Loading, setLoading] = useState(true);
	const [sessionId,setSessionId] = useState("")
	const [Filters,setFilters] = useState({})
	
	let {search} = useLocation();
	const Params = new URLSearchParams(search);
	let From = Params.get('from')
	let To = Params.get('to')
	let mDate = Params.get('date')
	const [Date, setDate] = useState(mDate?parseInt(mDate):moment().valueOf());
	const [results,setResults] = useState([])

	const searchBuses = (from, to, date) => {
		setLoading(true)
		setResults([])

		post(`/bus/search`,{
			from,
			to,
			date,
			country:'India'
		}, (e,r)=>{
			if(r&&r.success){
				setResults(r.results)
				setLoading(false)
				setSessionId(r.sessionId)
			}else if(e){
				showToast({
					message: 'Problem in searching buses'
				})
			}
		})
	}
	useEffect(() => {
		if(From&&To&&Date){
			searchBuses(From,To,Moment(parseInt(Date,10)).format("DD-MM-YYYY"))
		}
	}, [From, To, Date]);

	return (
		<div>
			<SectionHeader color={'#FACFCF'}>
				<NavBar/>
				<BusSearch setDate={setDate} from={From} to={To} date={Date}/>
			</SectionHeader>
			<BusAnimation loading={true}  finished={Loading}/>
			<div className="container mt-4 mt-md-5 mb-5">
				<div className="row">
					<div className="col-md-12 col-lg-9 ps-3">
						<DaysCarousel onClick={(date)=>setDate(date)} selected={Date}/>
						<Results sessionId={sessionId} filters={Filters} loading={Loading} buses={results}/>
					</div>
					<div className='col-md-12 col-lg-3'>
						<div className='scrollbar-hidden' style={{position:'sticky', top:'2rem', height: 'calc(100vh - 20px)', overflowY:'scroll'}}>
							<BusFilters loading={Loading} results={results} filters={Filters} onApply={setFilters}/>
						</div>
					</div>
				</div>
			</div>
			<div style={{marginTop:150}}/>
			<HelpSection/>
		</div>
	)
}
