import React from 'react';
import Card from '../../../Component/Cards/Background'
import moment from 'moment';
import { useHistory } from 'react-router-dom';

function Index() {

    let HISTORY = useHistory()

    let Destinations = [
        {
            photo: require('./bangalore.jpeg'),
            title: 'Bangaluru',
            description: 'Search flights to the startup hub of India Bangaluru.',
            button: {
                name: 'View Flights', onClick: ()=>{searchFlights('BLR')}
            }
        },
        {
            photo: require('./delhi.jpg'),
            title: 'Delhi',
            description: 'Plannig a visit to Delhi, get lowest fare on your flight.',
            button: {
                name: 'View Flights', onClick: ()=>{searchFlights('DEL')}
            }
        },
        {
            photo: require('./mumbai.jpeg'),
            title: 'Mumbai',
            description: 'Book your flights to mumbai and earn rewards.',
            button: {
                name: 'View Flights', onClick: ()=>{searchFlights('BOM')}
            }
        },
        {
            photo: require('./pune.jpg'),
            title: 'Pune',
            description: 'Pune got the best weather and we got the best fares to pune.',
            button: {
                name: 'View Flights', onClick: ()=>{searchFlights('PNQ')}
            }
        },
        {
            photo: require('./hyderabad.jpeg'),
            title: 'Hyderabad',
            description: 'View flights to hyderabad and get best fare for your trip.',
            button: {
                name: 'View Flights', onClick: ()=>{searchFlights('HYD')}
            }
        },
        {
            photo: require('./jaipur.jpg'),
            title: 'Jaipur',
            description: 'Get OMG deal on your flight to Jaipur with OMG Fares.',
            button: {
                name: 'View Flights', onClick: ()=>{searchFlights('JAI')}
            }
        }
    ]

    const searchFlights = (from,to='DEL') => {
        HISTORY.push(`/flights/deals/search?from=${from}&to=${to}&date=${moment().add(10,'day').valueOf()}&adult=1&child=0&infant=0`)
    }

    return (
        <>
       <div className="mt-5 pt-4 pb-4 FadeUp-Effect">
            <div className="container">
                <div className="row">
                <div className="col-md-12">
                    <h5>Offers</h5>
                    <h1 style={{fontWeight: 'bold'}}>Top Destinations</h1>

                    <div className="d-flex flex-nowrap overflow-scroll overflow-y-hidden pt-4 pb-3 scrollbar-autohide">
                        {
                            Destinations.map((destination,index)=>{
                                return <Card key={index} photo={destination.photo} title={destination.title} description={destination.description} button={destination.button}/>
                            })
                        }
                    </div>
                </div>
                </div>
            </div>
        </div>

        </>
    );
}

export default Index;
