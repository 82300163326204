import React, {useEffect, useRef, useState} from 'react'
import Props from 'prop-types'
import './ChatBox.css'
import Attachment from '../../Component/Attachment/Attachment'
import Thumbnail from '../../Component/Thumbnail/Thumbnail'
import FileInput from '../../Component/Form/Inputs/FileInput'
import {useLoginHook} from '../../Hooks/useLoginHook'
import moment from 'moment'

/**
 *@author [Aakash Bhadana](https://github.com/aakashbhadana)
 *
 * Chatbox Component
 */

 function ChatBox({className, id, onClose, comments, title, category, onSend}) {

    let CONFIG = useLoginHook();
    let CHATBOX = useRef(), FILE = useRef(), MESSAGE = useRef();

    const [Uploading, setUploading] = useState(false);
    const [File, setFile] = useState(undefined);

    //Send File when new file is uploaded
    useEffect(() => {
        if(File){
            onSend('file', File, id)
            setFile(undefined)
        }
    }, [id, File, onSend]);

    //Send Text Message
    const sendMessage = () => {
        let msg = MESSAGE.current.value;
        if(!msg){
            return
        }
        onSend('text', MESSAGE.current.value, id)
        MESSAGE.current.value = ''
    }

    useEffect(() => {
        if(CHATBOX){
            CHATBOX.current.scroll({ top: CHATBOX.current.scrollHeight, behavior: "smooth"})
        }
    }, [comments]);

    const renderChats = (chat, index) => {
		if(chat.type==="assigned"){
			return  (
				<div key={index} className="text-center text-light text-small">
					{chat.content}
				</div>
			)
		}
        if(CONFIG.name===chat.ownerName){
            return (
                <div key={index} className='sent'>
                    <div className='bubble'>
                        <div className='message'>
                            <div className='text-truncate'>
                                <span className='fw-bold me-2'>{chat.ownerName}</span>
                                <span className='text-muted'>{moment(chat.time).format('hh:MM a')}</span>
                            </div>
                            <div>
                                {
                                    chat.type==='text'
                                    ?chat.content
                                    :<Attachment className='mt-2' url={chat.content}/>
                                }
                            </div>
                        </div>
                        <Thumbnail className='ms-2' size='L' letter={chat.ownerName[0]}/>
                    </div>
                </div>
            )
        }else{
            return (
                <div key={index} className='received'>
                    <div className='bubble'>
                        <Thumbnail className='me-2' size='L' letter={chat.ownerName[0]}/>
                        <div className='message'>
                            <div className='text-truncate'>
                                <span className='fw-bold me-2'>{chat.ownerName}</span>
                                <span className='text-muted'>{moment(chat.time).format('hh:MM a')}</span>
                            </div>
                            <div>
                                {
                                    chat.type==='text'
                                    ?chat.content
                                    :<Attachment url={chat.content}/>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

	return (
        <div className={`FadeUp-Effect chat-wrapper ${className}`} style={{minHeight:600}}>
            <div className='d-flex align-items-center justify-content-between p-3 border-bottom bg-white'>
                <div className='d-flex align-items-center'>
                    <Thumbnail className='me-2' size='L' letter={title[0]}/>
                    <div>
                        <div className='fw-bold text-truncate'>{title}</div>
                        <div className='text-small text-truncate'>{category}</div>
                    </div>
                </div>
                <i onClick={onClose} className="fas fa-times cursor-pointer me-1" style={{fontSize:'1.5rem'}}/>
            </div>
            <div className='chats' ref={CHATBOX}>
                {
                    comments.length
                    ?comments.map(renderChats)
                    :<div className='w-100 text-center text-small'><i className="fas fa-headphones-alt mr1"></i> You ticket is not assigned yet</div>
                }
            </div>
            <div className='bottom-bar'>
                <div className='message-box'>
                    <input className='flex-fill' onKeyDown={e=>e.key==='Enter'?sendMessage():null} ref={MESSAGE} type='text' placeholder='Type Message...'/>
                    <div className='icons'>
                        {
                            Uploading
                            ?<div><div className='mini-loader'></div></div>
                            :<i onClick={()=>FILE.current.click()} className="fas fa-paperclip"/>
                        }
                        <FileInput status={setUploading} ref={FILE} mono={true} updateFiles={setFile} className='d-none'/>
                        <i onClick={sendMessage} className="fas fa-paper-plane"></i>
                    </div>
                </div>
            </div>
        </div>
	)
}

ChatBox.propTypes = {
	/**
	 * All Classes
	 */
	className:Props.string,
    /**
	 * ID of Support Ticket
	 */
	id:Props.string,
    /**
	 * Array of Comments Objects
	 */
	comments:Props.array,
    /**
	 * Title for Chatbox
	 */
	title:Props.string,
    /**
	 * Sub heading for Chatbox
	 */
	category:Props.string,
	/**
	 * Pass function to send messages
	 */
	onSend:Props.func,
    /**
	 * Pass function to handle close
	 */
	onClose:Props.func,
}

export default ChatBox;
