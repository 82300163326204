import React, {useEffect, useState} from 'react';
import NavBar from '../../../Component/NavBar'
import SectionHeader from '../../../Component/Containers/Section-Header'
import {post} from '../../../Model/Config/Axios'
import {showToast} from '../../../Component/Toast'
import { useHistory, useLocation } from 'react-router-dom';
import Loader from '../../../Component/Loader'
import TextEllipsis from '../../../Component/TextEllipsis';
import MapsIcon from './maps.png'
import ImageViewer from './Components/ImageViewer';
import Summary from './Components/Summary'
import RoomsRendrer from './Components/RoomsRendrer';
import moment from 'moment';
import Experience from './Components/Experience'

function ViewHotel() {

    const {state} = useLocation();
    let ID = state.id
    let APP_ID = state.appId
    let SESSION = state.session
    let DATES = state.dates
    let ROOMS = state.rooms

    let HISTORY = useHistory();

    const [Details, setDetails] = useState(undefined);
    const [Selected, setSelected] = useState();

    useEffect(() => {
        staticDetails(ID, SESSION)
         // eslint-disable-next-line
    }, [SESSION, ID]);

    const staticDetails = (hotelId, sessionId) => {
		post(`/hotel/details`,{hotelId, sessionId}, (e,r)=>{
            if(r.errors.length){
                showToast({
                    message:'Please try again'
                })
                return
            }
            if(r){
                setDetails(r)
                fetchRooms(ID, SESSION, APP_ID)

            }else{
                showToast({
                    message:'Problem in searching Flights'
                })
            }
        })
	}
    // eslint-disable-next-line
    const fetchRooms = (hotelId, sessionId, appId) => {
		post(`/hotel/rooms`,{hotelId, sessionId, appId}, (e,r)=>{
            if(r.errors.length){
                showToast({
                    message:'Please try again'
                })
                return
            }
            if(r){
                setDetails(prev=>({...prev, rooms: r.rooms}))

            }else{
                showToast({
                    message:'Problem in fetching Rooms'
                })
            }
        })
	}

    const startBooking = (params) => {
        HISTORY.push({
            pathname: `/hotels/booking`,
            state: {TRAVELLERS: ROOMS, ROOMS:Selected, SESSION:Details.sessionId, DATES, ID, APP_ID, HOTEL:Details}
        })
    }

    return (
        <>
            {
                Selected&&<Summary dates={DATES} travellers={ROOMS} onBook={startBooking} selected={Selected} onCancel={()=>setSelected()}/>
            }
            <SectionHeader color={'var(--material-pink)'}>
                <NavBar/>
                <div className="search mt-4 pt-4" style={{paddingBottom: 80}}>
                    <h5 className='cursor-pointer' onClick={()=>HISTORY.goBack()}><i className="me-2 fas fa-arrow-left"></i> Back to all Results</h5>
                </div>
            </SectionHeader>
            {
                !Details?
                <div className='d-flex align-items-center justify-content-center w-100' style={{height:400}}><Loader/></div>:
                <div className='container'>
                    <div className='row' style={{marginTop:-50}}>
                        <div className='col-12 col-md-6'>
                            <ImageViewer images={Details.details.images} title={Details.details.name}/>
                        </div>
                        <div className='col-12 col-md-6 ps-md-5 pt-md-5 mt-5'>
                            <h2 className="fw-bold">{Details.details.name}</h2>

                            <div className="d-flex align-items-center flex-wrap">
                                <button className="btn btn-sm rounded-pill p-2 ps-3 pe-3 me-3 mt-3" s="button" style={{background: '#ceebf2', fontWeight: 'bold', fontSize: 16}}>{moment(DATES.endDate).diff(moment(DATES.startDate), 'days')} Night</button>
                                <h6 className='fw-bold mb-0 me-4 mt-3'>{moment(DATES.startDate).format('DD MMM YY')} to {moment(DATES.endDate).format('DD MMM YY')}</h6>
                            </div>

                            <div className='d-flex align-center mt-3'>
                                {
                                    Details.details.map.parking&&<div className='text-pill border me-1'><i className='fas fa-car me-1'/> Parking</div>
                                }
                                {
                                    Details.details.map.wifi&&<div className='text-pill border me-1'><i className='fas fa-wifi me-1'/> WiFi</div>
                                }
                                {
                                    Details.details.map.ac&&<div className='text-pill border me-1'>AC</div>
                                }
                                {
                                    Details.details.map.tv&&<div className='text-pill border me-1'><i className='fas fa-tv me-1'/> TV</div>
                                }
                                {
                                    Details.details.map.pool&&<div className='text-pill border me-1'><i className='fas fa-water me-1'/> Pool</div>
                                }
                            </div>

                            <div className='d-flex align-items-center mt-2'>
                                <div className='fw-bold text-small me-2'>{Details.details.type} -</div>
                                <div>
                                    {
                                        Array(Details.details.rating).fill(0).map((i,index)=>{
                                            return <span key={index} className='text-small'>★</span>
                                        })
                                    }
                                </div>
                            </div>

                            <div className='text-small mt-2'>
                                <svg className='me-1 mb-1' style={{width:14}} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z"/></svg>
                                {Details.details.address.address}
                            </div>
                            <div className='text-small'>{Details.details.address.city}</div>
                            <div className='cursor-pointer d-flex align-items-center mt-2 text-small' onClick={(e)=>{e.stopPropagation();window.open(`https://www.google.com/maps/dir//${Details.details.address.lat},${Details.details.address.lng}`, '_blank')}}>
                                <img src={MapsIcon} alt='Maps' className='me-2' style={{width:16}} />Directions
                            </div>

                            <h5 className='mt-4'>About</h5>
                            <div className='text-small mt-3'>
                                <TextEllipsis>{Details.details.description.replace(/<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g, '')}</TextEllipsis>
                            </div>
                        </div>
                    </div>

                    <div className='mt-5'>
                        <Experience hotelId={Details.details.id} userRating={Details.details.userRating}/>
                    </div>

                    {
                        Details.details.amenities.length?
                        <>
                            <h4 className='mt-5'>Amenities</h4>
                            <div className='mt-3 mb-3 border-bottom w-100'/>
                            <div className='row text-small'>
                                {
                                    Details.details.amenities.map((amenity, index)=>{
                                        if(amenity.startsWith('YES')){
                                            amenity = amenity.split('YES')[1]
                                        }
                                        if(amenity.startsWith('NO')){
                                            return <></>
                                        }
                                        return <div className='col-6 col-md-2 mt-2' style={{textTransform:'uppercase'}} key={index} >{amenity}</div>
                                    })
                                }
                            </div>
                        </>
                        :<></>

                    }

                    <div className='row mt-5'>
                        <h1 className="display-6 fw-bold">Rooms Choices</h1>
                        <div className='mt-4'>
                            <RoomsRendrer dates={DATES} onSelect={setSelected} selected={Selected} rooms={Details.rooms} travellers={ROOMS}/>
                        </div>
                    </div>
                </div>

            }
            {/* <div className='container mt-5 mb-5'>
                <iframe className='w-100 rounded-4 mt-3' title='maps'  style={{height:300}} src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d15129.425879391305!2d73.78308580000001!3d18.55796105!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1663087813398!5m2!1sen!2sin" width="600" height="450" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"/>
            </div> */}
        </>
    );
}

export default ViewHotel;
