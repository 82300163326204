import React from 'react';
import NavBar from '../../Component/NavBar'
import SectionHeader from '../../Component/Containers/Section-Header'

function Support() {

    return (
        <>
        <SectionHeader color={'#FAEEE0'}>
            <NavBar/>
            <div className="p-1 mt-4 pb-4 ps-2">
                <h1 className="display-5 mb-0" style={{fontWeight: 'bold'}}>Terms of Service</h1>
            </div>
        </SectionHeader>

        <div className='container p-4'>
        <h3 className='fw-bold mt-4 mb-3'>Cleartrip Terms of Service</h3>
        This document is an electronic record in terms of Information Technology Act, 2000 and rules thereunder, as applicable, and the amended provisions pertaining to electronic records in various statutes, as amended by the Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures.
        <br/><br/>
        ACCESSING, BROWSING OR OTHERWISE USING THE SITE INDICATES YOUR AGREEMENT TO ALL THE TERMS AND CONDITIONS UNDER THIS TERMS OF USE, SO PLEASE READ THE TERMS OF USE CAREFULLY BEFORE PROCEEDING. By impliedly or expressly accepting this Terms of Use, You also accept and agree to be bound by Cleartrip Private Limited policies (including but not limited to Privacy Policy) as amended from time to time.
        <br/><br/>
        This web site www.cleartrip.com (the “Site”) including the mobile site, Smartphone App Platforms such as iOS, Android, Windows is published and maintained by Cleartrip Private Limited (“Cleartrip”), a company incorporated and existing in accordance with the laws of the Republic of India (together with its subsidiaries and other affiliates, “us”, “We”, “our” or “Cleartrip”). By accessing or using the Site or by using the Service on the Site you agree to be bound by these terms and conditions set forth below including any additional guidelines and future modifications. When you access any sub-site, webpage, hyperlink (whether belonging to an ‘associate’ of Cleartrip or otherwise) through this site, then such sub-site may have its own terms and conditions of use which is specific to such sub-site, webpage, hyperlink. The sub-sites, webpage, hyperlink may contain such additional terms and conditions of use as may be set out in such sub-sites, webpage, hyperlink.
        <br/><br/>
        Cleartrip may add to, change or remove any part of these Terms and Conditions of Use at any time, without notice. Any changes to these Terms and Conditions of Use or any terms posted on this Site apply as soon as they are posted. You shall re-visit the “Terms & Conditions” link from time to time to stay abreast of any changes that the “Site” may introduce.
        <h3 className='fw-bold mt-5 mb-3'>Your Agreement to these Terms and Conditions for availing Service</h3>
        The Terms and Conditions is a legal contract between you being, an individual customer, user, or beneficiary of this service, and Cleartrip Private Limited (Cleartrip) having its registered office at 06, Godrej Business District, Pirojshanagar Vikhroli, Mumbai – 400079, IndiaCleartrip acts as an “Intermediary” solely to assist customers in gathering travel information, determining the availability of travel-related products and services, making legitimate reservations or otherwise transacting business with travel suppliers, and for facilitating travel requirements. You acknowledge that Cleartrip is merely acting in a limited role as an agent of the service providers offering their services on the Cleartrip platform, and that Cleartrip merely provides intermediary services in order to facilitate services to you. Cleartrip is not the last mile service provider to you and therefore Cleartrip shall not be deemed to be responsible for, and shall have no liability for, any lack, or deficiency, interruption, change, rescheduling, delay, or cancellation of services provided by any person or entity (including airline, hotel, activity provider or similar agency) that, you may engage or, hire from the content available on the Site (though Cleartrip may pass on such information to customers when requested to do so by any person providing such services). Cleartrip is not responsible for any refunds in connection with any services offered by any person on the Cleartrip platform (except for passing on such refunds when received from such persons to customers), other than refunds of convenience fees paid made to Cleartrip for the services it provides to customers (subject to such refund requests being in compliance with these Terms and Conditions and with the terms of the applicable ticket / booking)
        <h3 className='fw-bold mt-5 mb-3'>Use of the Website</h3>
        As a condition of your use of this Website, you warrant that:

        <ul>
            <li>you possess the legal authority to create a binding legal obligation and enter into these Terms of Use.</li>
            <li>you will use this Website in accordance with the Terms of Use,</li>
            <li>you will use this Website to make only legitimate reservations for you or for another person for whom you are legally authorized to act,</li>
            <li>you will inform such other persons about the Terms of Use that apply to the reservations you have made on their behalf, including all rules and restrictions applicable thereto,</li>
            <li>you will provide your proper and accurate Name (as per Passport or any other office ID), Address, Email ID, Cell Number . You will be solely responsible for the Information provided and in case of any error or mistake in provision of information, Cleartrip will not be liable for the same</li>
            <li>all information supplied by you on this Website is true, accurate, current and complete,</li>
            <li>if you have an online account with this Website, you will safeguard your login details – login ID and password and will supervise the same.</li>
        </ul>
        You will be completely responsible for any use or misuse of your account by you and anyone other person other than you.
        <br/><br/>

        Cleartrip retains the right in its sole discretion to deny access to anyone to this Website and the services we offer, at any time without notice and for any reason, including, but not limited to, for violation of these Terms of Use.
        <h3 className='fw-bold mt-5 mb-3'>Communication Policy of the site</h3>
        Upon transacting on the Site, you will receive an e-mail from Cleartrip informing the status of your transaction. The e-mail will be sent to e-mail address provided by you and Cleartrip is not responsible towards the receipt of the said e-mail in your inbox. You will have to take due care and caution. You are responsible for entering the proper and accurate contact details including your name (as per Passport), email ID to ensure that we can effectively communicate with you.
        <br/>The customer acknowledges that the SMS (Short Messaging Service) provided by Cleartrip is an additional facility provided for the customer’s convenience. It is not mandatory under the law in force to provide SMS service alerts to the customers. In case you do not receive any SMS for whatsoever reason, Cleartrip is not liable for the same.
        <br/>Cleartrip is not responsible to provide information on any change in flight schedules, cancellation, refund, status of airline/hotel etc, and bears no liability in respect of any such changes.
        <br/>Any grievance regarding the service should be communicated as per the grievance policy laid out herein.
        <h3 className='fw-bold mt-5 mb-3'>Site and its Content</h3>
        This Site is only for your personal use. You shall not copy, license, adapt, distribute, exchange, modify, sell or transmit any content or material from this Site, including but not limited to any text, images, audio, video or links for any business, commercial or public purpose.
        <br/><br/>
        Cleartrip grants you a non-exclusive, non-transferable, limited right to enter, view, use and transact on this Site. You agree not to interrupt or attempt to interrupt the operation of this Site in any way. Access to certain areas of the Site may only be available to registered members. To become a registered member, you may be required to answer certain questions and provide certain details. Answers to such questions and details are mandatory. You represent and warrant that all information you supply to us, about yourself, and others, is true and accurate.
        <br/><br/>
        <br/>You understand that except for information, products or services clearly indicated as being supplied by Cleartrip, we do not operate, control, or endorse any information, products or services on the Internet in anyway. You also understand that Cleartrip cannot and does not guarantee or warrant that files available for downloading through the Site will be free of viruses, worms or other code that may be damaging. You are responsible for implementing procedures to satisfy your particular requirements and for accuracy of data input and output.
        <br/><br/>
        Cleartrip may add, change, discontinue, remove or suspend any other Content or services posted on this Site, including features and specifications of products described or depicted on the Site, temporarily or permanently, at any time, without notice and without liability.
        <br/><br/>
        Ownership
        All materials on this Site, including but not limited to audio, images, software, text, icons and such like (the “Content”), are protected by copyright under international conventions and intellectual property laws. You cannot use or license the Content, except as specified herein. You agree to follow all instructions on this Site limiting the way you may use the Content.
        <br/><br/>
        There are a number of proprietary logos, service marks and trademarks found on this Site whether owned/used by Cleartrip or otherwise. By displaying them on this Site, Cleartrip is not granting you any license to utilize those proprietary logos, service marks, or trademarks. Any unauthorized use of the Content may violate copyright laws, trademark laws, the laws of privacy and publicity, civil and criminal statutes.
        <br/><br/>
        The content and information on this Website (including, but not limited to, price and availability of travel services), as well as the infrastructure used to provide such content and information, are proprietary to us or our suppliers and providers.
        <br/><br/>
        While you may make limited copies of your travel itinerary (and related documents) for travel or service reservations booked through this Website, you agree not to otherwise modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell or re-sell any information, software, products, or services obtained from or through this Website.
        <h3 className='fw-bold mt-5 mb-3'>Availability, Rules of Service</h3>
        The products and services displayed on the Site may not be available for purchase in your particular country or locality. The reference to such products and services on the Site does not imply or warrant that these products or services will be available at any time in your particular geographical location. The products and services also are subject to availability, Cleartrip shall not be responsible if at the time of completing the transaction on the Site, the product is not available or is sold out.
        <br/><br/>
        All the products and services available on the Website are governed by the rules of service and regulations including the terms of contract of carriage, of the respective service provider. You are required to refer to, acquaint and abide by those rules of service and regulations of the respective service provider, prior to booking with us. We are not liable for any breach of such rules of service and regulations of such service providers.
        <h3 className='fw-bold mt-5 mb-3'>Visa Guidelines</h3>
        Please carry a valid visa for the country you will be visiting or transiting through. Please check with the concerned airline and embassy for concerned Visa requirements
        <h3 className='fw-bold mt-5 mb-3'>Cleartrip Rights</h3>
        If you send any communications or materials to Cleartrip by electronic mail or otherwise, including any comments, data, questions, suggestions or the like, all such communications are, and will be treated by Cleartrip, as non-confidential.
        <br/><br/>
        You hereby give up any and all claim that any use of such material violates any of your rights including moral rights, privacy rights, proprietary or other property rights, publicity rights, rights to credit for material or ideas, or any other right, including the right to approve the way Cleartrip uses such material.
        <br/><br/>
        Any material submitted to this Site may be adapted, broadcasted, changed, copied, disclosed, licensed, performed, posted, published, sold, transmitted or used by Cleartrip anywhere in the world, in any medium, forever.
        <h3 className='fw-bold mt-5 mb-3'>Others’ rights</h3>
        If this Site contains bulletin boards, chat rooms, blogs, access to mailing lists or other message or communication facilities, you agree to use the same only to send and receive messages and materials that are proper and related thereto. By way of example and not as a limitation, you agree that when using the Site or any facility available herefrom, you shall not do any of the following:
        <br/><br/>

        <ul>
            <li>Defame, abuse, harass, stalk, threaten or otherwise violate the legal rights (such as rights of privacy and publicity) of others</li>
            <li>Publish, post, distribute or disseminate any defamatory, infringing, obscene, indecent or unlawful material or information</li>
            <li>Upload or attach files that contain software or other material protected by intellectual property laws (or by rights of privacy and publicity) unless the User owns or controls the rights thereto or has received all consents thereof as may be required by law</li>
            <li>Upload or attach files that contain viruses, corrupted files or any other similar software or programs that may damage the operation of another’s computer</li>
            <li>Delete any author attributions, legal notices or proprietary designations or labels in any file that is uploaded</li>
            <li>Falsify the origin or source of software or other material contained in a file that is uploaded</li>
            <li>Advertise or offer to sell any goods or services, or conduct or forward surveys, contests or chain letters, or download any file posted by another user of a Forum that the User knows, or reasonably should know, cannot be legally distributed in such manner.</li>
            <li>use this Website or its contents for any commercial purpose;</li>
            <li>make any speculative, false, or fraudulent reservation or any reservation in anticipation of demand;</li>
            <li>access, monitor or copy any content or information of this Website using any robot, spider, scraper or other automated means or any manual process for any purpose without our express written permission;</li>
            <li>violate the restrictions in any robot exclusion headers on this Website or bypass or circumvent other measures employed to prevent or limit access to this Website;</li>
            <li>take any action that imposes, or may impose, in our discretion, an unreasonable or disproportionately large load on our infrastructure;</li>
            <li>deep-link to any portion of this Website (including, without limitation, the purchase path for any travel services) for any purpose without our express written permission; or</li>
            <li>‘frame’, ‘mirror’ or otherwise incorporate any part of this Website into any other website without our prior written authorization.</li>
            <ol>
                <li>host, display, upload, modify, publish, transmit, update or share the following:</li>
                <li>Information belonging to another person for which the user does not have any right.</li>
                <li>Information that is hateful, grossly harmful including harmful to minors, pedophilic, invasive of another privacy, racially, ethnically objectionable, disparaging, encouraging money laundering or gambling.</li>
                <li>Information that infringes any Patent, Trademark, Copyright or other Proprietary rights.</li>
                <li>Information that deceives or misleads the addressee about the origin of such message or communicates any information which is grossly offensive or menacing in nature.</li>
                <li>Information that threatens the unity, integrity, defense, Security or Sovereignty of India, friendly relation with foreign states or Public order or cause incitement to the commission of any cognizable offence or prevents investigation of any offence or insulting any other nation.</li>
                <li>Information that impersonates another person.</li>
            </ol>
        </ul>

        If your booking or account shows signs of fraud, abuse, or suspicious activity, Cleartrip may cancel any travel or service reservations associated with your name, email address, or account, and close any associated Cleartrip accounts. In addition, Cleartrip may verify (i.e. preauthorize) your credit card. If you have conducted any fraudulent activity, Cleartrip reserves the right to take any necessary legal action and you may be liable for monetary losses to Cleartrip, including litigation costs and damages. To contest the cancellation of a booking or freezing or closure of an account, please contact Cleartrip Customer Service.
        <br/><br/>
        Cleartrip reserves the right to undertake all necessary steps to ensure that the security, safety and integrity of Cleartrip’s systems as well as its client’s interests are and remain, well-protected. Towards this end, Cleartrip may take various steps to verify and confirm the authenticity, enforceability and validity of orders placed by you.
        <br/><br/>
        If Cleartrip, in its sole and exclusive discretion, concludes that the said transactions are not or do not reasonably appear to be, authentic, enforceable or valid, then Cleartrip may cancel the said orders at any time up before the scheduled time of departure of the relevant flight or before the expected date of visit to any property booked through Cleartrip.
        <h3 className='fw-bold mt-5 mb-3'>Transmitted Material</h3>
        Internet transmissions are never completely private or secure. You understand that any message or information you send to this Site may be read or intercepted by others unless there is a special notice that a particular message (for example, credit card information) is encrypted (sent in code). Sending a message to Cleartrip does not cause Cleartrip to have any special responsibility to you.
        <br/>The copyright in the contents of this website belong to Cleartrip. Accordingly Cleartrip reserves all rights. Copying of part or all the contents of this website without permission of Cleartrip is prohibited except to the extent that such copying/printing is necessary for the purposes of availing of the paid services provided.
        <h3 className='fw-bold mt-5 mb-3'>Offers, Contests and interactions</h3>
        This Site may contain contests that require you to send in material or information about yourself or offer prizes. Each offers, contest and interactions has its own rules, which you must read and agree to before you participate.
        <h3 className='fw-bold mt-5 mb-3'>Disclaimer and No Warranties</h3>
        You acknowledge that Cleartrip is an intermediary and is not liable for any 3rd party (suppliers or service providers) obligations due to rates, quality, and all other instances, whether to any such subscribers or otherwise. You expressly agree that use of the services and the site is at your sole risk. It is your responsibility to evaluate the accuracy, completeness and usefulness of all opinions, advice, services, merchandise and other information provided through the site or on the internet generally.We do not warrant that any service will be uninterrupted or error-free or that defects in the site will be corrected. Any claims in relation to any services provided on the Cleartrip platform (including any services provided by air carriers, hotels, etc) should be made against the relevant third-party service provider, and Cleartrip shall have no liability in respect of such claims.
        <br/>The materials on this site are provided and “as available,” “with all faults” basis and without warranties or representations of any kind either expressed or implied. Information, software, products, and services contained on this website, and the inclusion or offering of any products or services on this website does not constitute any endorsement or recommendation of such products or services by Cleartrip, Cleartrip Affiliates and their respective suppliers. The material in this Site could include technical inaccuracies or typographical errors. Cleartrip may make changes or improvements at any time. To the fullest extent permissible pursuant to applicable law, Cleartrip disclaims all warranties of merchantability, relating to the information and description of the hotel , air or any other travel products displayed on this website (including, without limitation, the pricing, photographs, list of amenities, general product descriptions, etc.), which information is provided by the respective suppliers and for which Cleartrip is not responsible. Hotel or any other ratings displayed on this website are intended as only general guidelines, and Cleartrip, Cleartrip Affiliates and their respective suppliers do not guarantee the accuracy of the ratings. Cleartrip, Cleartrip Affiliates and their respective suppliers make no guarantees about the availability of specific products and services. Cleartrip may make improvements and/or changes on this website at any time. Cleartrip and its third party suppliers, licensors, and partners do not warrant or make any representations regarding the use or the results of the use of the services, the site or any reference sites in terms of correctness, accuracy, reliability, or otherwise.
        <br/>Cleartrip does not warrant that the functions contained in this site will be uninterrupted or error free, that defects will be corrected, or that this site or the servers that make it available are free of viruses or other harmful components, but shall endeavor to ensure your fullest satisfaction
        <br/>Cleartrip does not warrant or make any representations regarding the use of or the result of the use of the material on the site in terms of their correctness, accuracy, reliability, or otherwise, insofar as such material is derived from other service providers such as airlines, hotel owners and tour operators.
        <br/>You acknowledge that this Website is provided only on the basis set out in these terms and conditions. Your uninterrupted access or use of this Website on this basis may be prevented by certain factors outside our reasonable control including, without limitation, the unavailability, inoperability or interruption of the Internet or other telecommunications services or as a result of any maintenance or other service work carried out on this Website. Cleartrip does not accept any responsibility and will not be liable for any loss or damage whatsoever arising out of or in connection with any ability/inability to access or to use the Site.
        <h3 className='fw-bold mt-5 mb-3'>General Provisions Indemnification and Limitation of Liability</h3>
        You agree to indemnify, save, and hold Cleartrip, its affiliates, contractors, employees, officers, directors and agents harmless from any and all claims, losses, damages, and liabilities, costs and expenses, including without limitation legal fees and expenses, arising out of or related to your use or misuse of the Services or of the Site, any violation by you of this Agreement, or any breach of the representations, warranties, and covenants made by you herein.
        <br/>In no event will Cleartrip be liable to you for any special, indirect, incidental, consequential, punitive, reliance, or exemplary damages (including without limitation lost business opportunities, lost revenues, or loss of anticipated profits or any other pecuniary or non-pecuniary loss or damage of any nature whatsoever) arising out of or relating to (i) this agreement, (ii) the services, the site or any reference site, or (iii) your use or inability to use the services, the site (including any and all materials) or any reference sites. In no event will Cleartrip or any of its contractors, directors, employees, agents, third party partners, licensors or suppliers’ total liability to you for all damages, liabilities, losses, and causes of action arising out of or relating to (i) this Agreement, (ii) any services provided by Cleartrip, (iii) your use or inability to use the Services or the Site (including any and all Materials) or any reference sites, or (iv) any other interactions with Cleartrip, however caused and whether arising in contract, tort including negligence, warranty or otherwise, shall not exceed the amount paid by you, if any, to Cleartrip as Convenience Fees. giving rise to the cause of action,
        <h3 className='fw-bold mt-5 mb-3'>Privacy Policy</h3>
        Cleartrip believes in protecting your privacy. Please click here to review our current <a href='https://www.cleartrip.com/privacy-policy'>Privacy Policy</a>, which also governs your use of the Website, to understand our practices: Privacy policy – <a href='https://www.cleartrip.com/privacy-policy'>https://www.cleartrip.com/privacy-policy</a>
        <h3 className='fw-bold mt-5 mb-3'>Notice</h3>
        Cleartrip may provide you with notices and communications by email, regular mail or postings on the Site or by any other reasonable means. Except as otherwise set forth herein, notice to Cleartrip must be sent by courier or registered mail to and Cleartrip Private Limited (Cleartrip) having its registered office at 06, Godrej Business District, Pirojshanagar Vikhroli, Mumbai – 400079, India
        <h3 className='fw-bold mt-5 mb-3'>Waiver</h3>
        The failure of Cleartrip to exercise or enforce any right or provision of this Agreement will not constitute a waiver of such right or provision. Any waiver of any provision of this Agreement will be effective only if in writing and signed by Cleartrip.
        <h3 className='fw-bold mt-5 mb-3'>Arbitration</h3>
        As the acceptance terms of Use constitute a valid and binding agreement, Cleartrip may elect to resolve any dispute, controversy or claim arising out of or relating to this Agreement or Service provided in connection with this Agreement by binding arbitration in accordance with the provisions of the Arbitration & Conciliation Act, 1996. Any such dispute, controversy or claim referred to and decided by a Sole Arbitrator appointed and nominated by Cleartrip and you by agreeing to the terms and conditions herein consent to the Arbitration. The arbitration shall be conducted in Mumbai, India and judgment on the arbitration award may be entered in any court having jurisdiction thereof. The language of Arbitration shall be English.
        <br/>Either you or We may seek any interim or preliminary relief from a court of competent jurisdiction in Mumbai, India, necessary to protect the rights or the property of Cleartrip (or its agents, suppliers, and subcontractors), pending the completion of arbitration.
        <h3 className='fw-bold mt-5 mb-3'>Governing Law and Forum for Disputes</h3>
        Except as otherwise agreed by the parties or as described in the Arbitration section above, you agree that any claim or dispute you may have against Cleartrip must be resolved by a court located in Mumbai, India. You agree to submit to the personal jurisdiction of the courts located within Mumbai, India for the purpose of litigating all such claims or disputes. This Agreement shall be governed by the laws of India, without giving effect to any principles of conflicts of law.
        </div>
        </>
    );
}

export default Support;