import React from 'react';
import ReactDOM from 'react-dom/client';
import Authentication from './Authentication';
import './Assets/styles.css';
import './Assets/animations.css';
import './Assets/skeleton.css';
// import * as ServiceWorker from './serviceWorker';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render( <Authentication />);

// if ("serviceWorker" in navigator) {
// 	navigator.serviceWorker
// 		.register("/firebase-messaging-sw.js")
// 		.then(function (registration) {
// 			console.log("Registration successful, scope is:", registration.scope);
// 		})
// 		.catch(function (err) {
// 			console.log("Service worker registration failed, error:", err);
// 		});

// 	ServiceWorker.register({
// 		onUpdate: reg => {
// 			const waitingServiceWorker = reg.waiting
// 			waitingServiceWorker.addEventListener("statechange", event => {
// 				if (event.target.state === "activated") {
// 					console.log("Updated")
// 				}
// 			});
// 			waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
// 		}
// 	})
// }

