import React, {useState, useEffect} from 'react';
import NavBar from '../../Component/NavBar'
import SectionHeader from '../../Component/Containers/Section-Header'
import {useLoginHook} from "../../Hooks/useLoginHook";
import HiddenFares from "../../Component/Banners/HiddenFares";
import HelpSection from "../Common/HelpSection";
import {get} from '../../Model/Config/Axios'
import {showToast} from '../../Component/Toast'
import  moment from 'moment'

function Index() {

    let CONFIG = useLoginHook();
    const [Rewards, setRewards] = useState({reward:0,value:0});
    const [History, setHistory] = useState([]);
    
    useEffect(() => {
       if(CONFIG.authenticated){
           totalRewards()
           transactions()
       }
    }, [CONFIG.authenticated]);

	if(!CONFIG.authenticated){
		return (
            <>
                <SectionHeader color={'#F1DDEC'}>
                    <NavBar/>
                    <div className="p-1 mt-4 pb-4 ps-2">
                        <h5>Welcome to</h5>
                        <h1 className="display-5" style={{fontWeight: 'bold'}}>Trip Rewards</h1>
                    </div>
                    <HiddenFares title="Login to view rewards" style={{
                        marginBottom:-150
                    }}/>
                </SectionHeader>
                <div style={{marginTop:260}}/>
                <HelpSection/>
            </>
        )
	}

    const totalRewards = (params) => {
        get(`/rewards`,(e,r)=>{
            if(r){
                setRewards(r)
            }else if(e){
                showToast({
                    type:'error',
                    message: 'Problem in loading Rewards'
                })
            }
        })
    }

    const transactions = (params) => {
        get(`/rewards/txns`,(e,r)=>{
            if(r){
                setHistory(r.ledger)
            }else if(e){
                showToast({
                    type:'error',
                    message: 'Problem in loading Transactions'
                })
            }
        })
    }

    return (
        <>
        <SectionHeader color={'#F1DDEC'}>
            <NavBar/>
            <div className="p-1 mt-4 pb-4 ps-2">
                <h5>Welcome to</h5>
                <h1 className="display-5" style={{fontWeight: 'bold'}}>Trip Rewards</h1>
            </div>
        </SectionHeader>
        <div className="container mb-5">
            <div className="row">
                <div className="col-md-8 col-xl-9 col-xxl-8 ps-3 pe-5 pt-5">
                <div>
                    <h4 className="fw-bold">Your Earnings <span aria-label="celebration" role="img">🎉</span></h4>
                    <p>Use your reward coins to book flights, hotel, buses and save on your next booking</p>
                </div>
                <div className="mt-5 d-flex align-items-center">
                    <div className="p-3 ps-4 pe-4" style={{background: '#FEE4D4', borderRadius: '0.5rem'}}>
                    <h1 className="fw-bold">{parseInt(Rewards.reward)} Coins</h1>
                    </div>
                    <div className="p-3 ps-4 pe-4 d-flex align-items-center">
                    <h1 className="display-5 fw-bold">=</h1>
                    </div>
                    <div className="d-flex align-items-center" style={{borderRadius: '0.5rem'}}>
                    <h1 className="fw-bold" style={{color: 'var(--bs-success)'}}>INR {Rewards.value} <span aria-label="money" role="img">🤑</span></h1>
                    </div>
                </div>
                <div className="mt-5 p-4 bg-white shadow-sm rounded-3">
                    <h4 className="fw-bold">Refer &amp; Earn</h4>
                    <p>Refer to your friends and earn rewards when they do their first booking.</p>
                    <div className="d-flex mt-3" style={{maxWidth: 300}}><input type="text" className="form-control" placeholder="Email Address" /><button className="btn btn-dark btn-sm ps-3 pe-3" type="button" style={{borderRadius: 0}}>Invite</button></div>
                </div>
                </div>

            <div className="col-md-4 col-xl-3 col-xxl-4 p-0 pe-3 ps-3 pt-5">
                <div className="d-flex align-items-center">
                    <h5>Coins History</h5>
                </div>
                <div className="mt-3 p-3 text-small bg-white shadow-sm rounded-3">
                    <div className="p-2 d-flex justify-content-between">
                    <h6>Transaction</h6>
                    <h6>Coins</h6>
                    </div>
                    <div className="table-responsive overflow-scroll scrollbar-autohide overflow-x-hidden" style={{maxHeight: 400}}>
                    <table className="table">
                        <tbody>
                        {
                            History.length?
                            History.map((txn, index)=>{
                                return (
                                    <tr key={index}>
                                        <td>
                                            <div>{moment(txn.createdAt).format('DD MMM YY')}</div>
                                            <div>{txn.remarks}</div>
                                        </td>
                                        <td style={{width:80}} className={`text-end ${txn.type==='EARN'?'text-success fw-bold':''}`}>
                                            <h6>{parseInt(txn.amount)}</h6>
                                        </td>
                                    </tr>
                                )
                            })
                            :<tr>
                                <td className='bg-light border text-center' colSpan={2}>No Transactions Found</td>
                            </tr>
                        }
                        </tbody>
                    </table>
                    </div>
                </div>
            </div>
        </div>
        </div>
        </>
    );
}

export default Index;
