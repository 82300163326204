import moment from "moment/moment";
import LoadingIcon from "../../Assets/Images/loading.webp";
import React from "react";

const busSortItem = (sort, a, b) => {
	if (sort.type === "depart")
		if (sort.asc) {
			return a.departure - b.departure;
		} else {
			return b.departure - a.departure;
		}
	if (sort.type === "arrival") {
		if (sort.asc) {
			return a.arrival - b.arrival;
		} else {
			return b.arrival - a.arrival;
		}
	}

	if (sort.asc) {
		return a.price - b.price
	} else {
		return b.price - a.price
	}
}

const busFilterUtils = (filters, bus) => {
	if (filters.type && filters.type.length) {
		let flag = false
		if (filters.type.includes(bus.seating))
		flag = true
		return flag
	}

	if (filters.time && filters.time.length) {
		let time = '0';
		let dep = parseInt(moment(bus.departure).format('HH'));
		if (dep < 24) {
			time = 24
		}
		if (dep < 19) {
			time = 19
		}
		if (dep < 15) {
			time = 15
		}
		if (dep < 11) {
			time = 11
		}
		if (dep < 6) {
			time = 6
		}
		if (!filters.time.includes(time))
			return false
	}
	return true
}

const BusWrapper = ({loading, text = "bus", length, children}) => {
	if (loading) {
		return (
			<div className="flex-fill pe-2 ps-2">
				<div className='FadeUp-Effect bg-white p-4 mt-4 border rounded-3 text-center'>
					<div className='d-flex align-items-center justify-content-center'>
						<img src={LoadingIcon} alt='Loading' className='me-3' style={{maxWidth: 50}}/>
						<h5>Searching {text}...</h5>
					</div>
				</div>
				{
					Array(10).fill(0)
						.map((_, index) => {
							return (
								<div style={{
									background: "#f6f7f8"
								}} key={index} className="rounded-4 mt-3 overflow-hidden">
									<div className="Stencil has-shimmer w-100" style={{
										minHeight: 180
									}}/>
								</div>
							)
						})
				}
			</div>
		)
	}
	if (length)
		return (
			<>
				{children}
			</>
		)
	return (
		<>

			<div className="flex-fill pe-2 ps-2">
				<div className='FadeUp-Effect bg-white p-4 mt-4 border rounded-3 text-center'>
					<div className='d-flex align-items-center justify-content-center'>
						<h5>No Buses Found</h5>
					</div>
				</div>
			</div>
		</>
	)
}

export {
	busSortItem, busFilterUtils, BusWrapper
}
