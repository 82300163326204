import React from 'react';
import { priceBreakup } from '../HotelUtils';

function Index({rooms, price, fee, coins, adult=0, child=0, button}) {

    let discount = parseInt(coins)/10;
    let total = price - discount
    
    return (

        <>
        <div className='position-sticky pt-5' style={{top:0, zIndex:15}}>
            <div><h6 className="pt-1 pb-2">Fare Details</h6></div>
            <div className='rounded-3 shadow-sm bg-white' style={{padding: '1rem'}}>
                <div>
                <div className="table-responsive" style={{fontSize: '0.8rem', borderBottom: '2px solid #000'}}>
                    <table className="table table-borderless">
                    <tbody>
                        <tr>
                            <td>Base Fare</td>
                            <td className="text-end">Rs. {price&&priceBreakup(price/rooms).base}</td>
                        </tr>
                        <tr>
                            <td>Taxes</td>
                            <td className="text-end">Rs. {price&&priceBreakup(price/rooms).tax}</td>
                        </tr>
                        <tr>
                            <td>
                                Rooms<br/>
                                <span className="text-muted">
                                    {adult} Adult, {child} Child
                                </span>
                            </td>
                            <td className="text-end">x {rooms}</td>
                        </tr>
                        <tr>
                            <td>Platform charges</td>
                            <td className="text-end">Rs. {parseInt(fee)}</td>
                        </tr>
                        {
                            coins&&parseInt(coins)>0?
                            <tr>
                                <td className="fw-bold text-success">Coins Redeemed</td>
                                <td className="text-end fw-bold text-success">Rs. -{discount}</td>
                            </tr>
                            :<></>
                        }
                    </tbody>
                    </table>
                </div>
                <div className="table-responsive mt-3">
                    <table className="table table-borderless">
                    <tbody>
                        <tr>
                        <td>Total Fare</td>
                        <td className="text-end fw-bold">Rs. {Math.ceil(total)}</td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                <div className='p-2 text-small' style={{background:'var(--material-green)', borderRadius:'0.5rem'}}>
                    You will earn <b>{Math.floor(total/10)}</b> coins on this booking 🎉
                </div>
                </div>
            </div>
            {button}
            {/* <div>
                <h6 className="pt-1 pb-2 mt-4">Apply Promo Code</h6>
            </div>
            <div>
                <div className="d-flex"><input type="text" className="form-control rounded-0" placeholder="Enter Code" /><button className="btn btn-dark btn-sm ps-3 pe-3" type="button" style={{borderRadius: 0}}>Apply</button></div>
            </div> */}
        </div>
        </>
    );
}

export default Index;