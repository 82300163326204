import React from 'react';
import CollapseBox from '../../Containers/CollapseBox'

function Index({label, value, min=0, max, onChange, step=1000}) {
    return (
        <CollapseBox heading={label?label:'Price'}>
            <div>
                <span className="fw-bold" style={{fontSize: '0.8rem'}}>Rs. {value}</span>
            </div>
            <input onChange={e=>onChange(e.target.value)}  className="form-range" type="range" step={step} value={value} min={min} max={max} />
            <div className="d-flex justify-content-between text-muted" style={{fontSize: '0.8rem'}}>
                <span>Rs. {min}</span><span>Rs. {max}</span>
            </div>
        </CollapseBox>
    );
}

export default Index;