import React, {useState, useEffect} from 'react';
import NavBar from '../../../Component/NavBar'
import SectionHeader from '../../../Component/Containers/Section-Header'
import HotelSearch from './HotelSearch'
import HotelResults from './Results'
import HotelFilters from '../../../Component/Filters/Hotels'
import HotelAnimation from '../../../Component/HotelAnimation'
import HelpSection from "../../Common/HelpSection";
import {useLoginHook} from "../../../Hooks/useLoginHook";
import { useHistory, useLocation } from 'react-router-dom';
import {post} from '../../../Model/Config/Axios'
import { showToast } from '../../../Component/Toast';
import moment from 'moment';

function Index() {

	let CONFIG = useLoginHook();
	let HISTORY = useHistory();
	if(!CONFIG.authenticated){
		HISTORY.push('/login')
	}

	let {search} = useLocation()
	let PARAMS = new URLSearchParams(search)
	let CityId = PARAMS.get('cityId')
	let CityName = PARAMS.get('city')
	let Country = PARAMS.get('country')
	let CheckIn = Number(PARAMS.get('checkin'))
	let CheckOut = Number(PARAMS.get('checkout'))
	let roomsString = PARAMS.get('rooms')

	//Cleansing reference and creating a copy of rooms (!!! Do not remove, will trigger side effect loop !!!)
	let Rooms = JSON.parse(roomsString);
	let max = 5;
	//Max Limit
	if(Rooms?.length>max){
		Rooms = [{adult:1, childAges:[1]}]
	}

	const [SessionId, setSessionId] = useState(undefined);
	const [Result, setResult] = useState([]);
	const [Filters, setFilters] = useState({stars:undefined, price: undefined, amenity:undefined, property: undefined});

	const [SearchFinished, setSearchFinished] = useState(false);
	const [ShowFilters, setShowFilters] = useState(undefined);

	useEffect(() => {
		if(CityName&&Country&&CityId&&CheckIn&&CheckOut&&Rooms){
			searchHotels(CityName, Country, CityId, CheckIn, CheckOut, Rooms)
		}
		// eslint-disable-next-line
	}, [CityName, Country, CityId, CheckIn, CheckOut, roomsString]);

	const searchHotels = (city, country, cityId, checkin, checkout, rooms) => {
		setSearchFinished(false)
		fetchPrices(city, country, rooms,checkin,checkout)
	}

	const fetchPrices = ( city, country, rooms,checkin,checkout) => {
		post(`/hotel/search`,{
			city: city,
			country: country,
			stay: {in: moment(checkin).format('DD-MM-YYYY'), out: moment(checkout).format('DD-MM-YYYY')},
			rooms: rooms.map((room,i)=>{
				return {
					adult: room.adult,
					child: room.childAges?room.childAges.length:0,
					childAges: room.childAges?room.childAges:[]
				}
			})

		}, (e,r)=>{
			if(r){
				setSessionId(r.sessionId)
				setResult(r.results)
				setSearchFinished(true)
            }else{
				setSearchFinished(true)
            }
        })
	}

	return (
		<div style={{background:'white'}}>
			<SectionHeader color={'var(--material-pink)'}>
				<NavBar/>
				<HotelSearch/>
			</SectionHeader>
			<HotelAnimation loading={true} done={SearchFinished}/>
			<div style={{minHeight:"80vh"}} className="p-4">
				<HotelFilters cityId={CityId} dates={{startDate: CheckIn, endDate: CheckOut}} session={SessionId} hotels={Result} closeFilters={setShowFilters} filters={Filters} onApply={setFilters} showFilters={ShowFilters}/>
				<HotelResults cityId={CityId} dates={{startDate: CheckIn, endDate: CheckOut}} session={SessionId} loaded={SearchFinished} filters={Filters} rooms={Rooms} hotels={Result}/>
			</div>
		</div>
	);
}

export default Index;
