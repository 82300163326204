import React, {useEffect, useState} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import FareCalc from '../../../FareCalc'
import Loader from '../../../../../../Component/Loader'
import {post} from '../../../../../../Model/Config/Axios'
import { showToast } from '../../../../../../Component/Toast';
import moment from 'moment';
import Itenary from './Itenary';
import FareRules from './Rules'
import Modal from '../../../../../../Component/Containers/Modal'
import OOPS from '../../../../../../Assets/Images/oops.webp'
import {useEventsHook} from '../../../../../../Hooks/useEventsHook';

function Index() {

	let EVENTS = useEventsHook();
    let HISTORY = useHistory()
    let {state} = useLocation();
    let {departureFlight, totalFare} = state;

    if(!departureFlight){
        showToast({
            type: 'error',
            message:'Invalid Session'
        })
        HISTORY.goBack()
    }

    let {details, operator} = departureFlight.flight;
    const [Failed, setFailed] = useState(false);
    const [PriceAlert, setPriceAlert] = useState();
    const [PriceUpdated, setPriceUpdated] = useState();
    
    const [Rules, SetRules] = useState(undefined);
    const [ReviewData, setReviewData] = useState(undefined);
    let segments = ReviewData&&ReviewData.review.segments;
    const {adult,child,infant} = ReviewData?ReviewData.search.count:{}
    const PassengerCount = parseInt(adult)+parseInt(child)+parseInt(infant);
    
    let max = 10;

    if(PassengerCount>max || adult<1){
        showToast({
            type: 'error',
            message:`Maximum ${max} travellers are allowed / No Adult added`
        })
        HISTORY.goBack()
    }
    
    useEffect(() => {
        fetchReview()
        // eslint-disable-next-line
    }, []);

    const MoveNext = (params) => {
        HISTORY.push({
            pathname: `/flights/main/booking/travellers`,
            state: {reviewData: ReviewData, FARE_ID: departureFlight.fare.key, FLIGHT_ID: departureFlight.flight.key}
        })
    }

    const fetchReview = () => {
        post(`/flight/domestic/review`, {fareId: departureFlight.fare.key, inventoryId: departureFlight.flight.key}, (e,r)=>{
            if(r.errors){
                setFailed(r.errors[0].message)
            }else if(r){
            setReviewData(r)
            
            //Check Price Change
            setPriceAlert(r.review.fare.publishedFare-totalFare)
            
            fetchRules()
            EVENTS.review({
				origin: r.search.origin.code||'BLANK',
				destination: r.search.destination.code||'BLANK',
				passengers: r.search.count.adult+r.search.count.child+r.search.count.infant||'BLANK',
                totalFare: r.review.fare.publishedFare||0
			})
            }else if(e){
                showToast({
                    type: 'error',
                    message: e.data?e.data.message:'Problem in fetching flight details, try again'
                })
            }
        })
    }

    const fetchRules = () => {
        post(`/flight/domestic/rules`, {fareId: departureFlight.fare.key, inventoryId: departureFlight.flight.key}, (e,r)=>{
            if(r.errors){
                // showToast({
                //     type: 'error',
                //     message:'Problem in fetching flight fare rules, try again'
                // })
                // HISTORY.goBack()
            }else if(r){
            SetRules(r)
            }else if(e){
                // showToast({
                //     type: 'error',
                //     message: e.data?e.data.message:'Problem in fetching flight fare rules, try again'
                // })
            }
        })
    }

    const startTime = moment(details.departure);
    const endTime = moment(details.arrival);

    return (
        <>
        <div className="container mb-5">
            <div className="row">
                <div className="col-md-8 col-xl-9 p-3 pt-4 pt-md-5 pb-3">
                    <div className="d-flex mb-3">
                        <div style={{background: '#000', color: '#fff', height: 25, width: 25, borderRadius: 30, marginRight: 15, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 12, fontWeight: 'bold'}}><span>1</span></div>
                        <h5 style={{fontWeight: 'bold'}}>Review you Itinerary</h5>
                    </div>

                    {
                        departureFlight&&ReviewData?
                        <div>
                            <div className='d-flex flex-wrap align-items-center'>
                                <button className="mt-3 btn btn-sm rounded-pill p-2 ps-3 pe-3 me-4" s="button" style={{background: '#ceebf2', fontWeight: 'bold', fontSize: 16}}>{startTime.format('DD MMM YYYY')}</button>
                                <h6 className="mt-3 fw-bold me-4 mb-0">{departureFlight.fare.type} FARE ({ReviewData.search.cabinClass})</h6>
                            </div>
                            {
                                PriceUpdated?
                                <div className='text-small mt-3 mb-3 border p-2 rounded-3' style={{display:'inline-flex'}}>
                                    <i className='fas fa-bell me-2 mt-1'/>The Price for this flight has changed from Rs. {totalFare} to Rs. {ReviewData.review.fare.publishedFare}
                                </div>:undefined
                            }
                            <div className="d-flex mt-2 align-items-center flex-wrap">
                                {departureFlight.fare.adult.ancillery.meal&&<h6 className='fw-light me-4 mt-2 mb-0'>🌮 Meal</h6>}
                                {departureFlight.fare.adult.ancillery.seat&&<h6 className='me-4 mt-2 mb-0'>💺 Seat Choice</h6>}
                                {departureFlight.fare.adult.ancillery.dateChange&&<h6 className='me-4 mt-2 mb-0'>📆 Flexible Date</h6>}
                                <h6 className="mb-0 me-4 fw-light mt-2" style={{fontSize: 14}}><span role='img' aria-label=''>👩‍🦰</span>&nbsp; {PassengerCount} Traveller</h6>
                                <h6 className="mb-0 me-4 fw-light mt-2" style={{fontSize: 14}}><span role='img' aria-label=''>🧳</span>&nbsp; Cabin - {departureFlight.fare.adult.baggage.cabin} &nbsp;|&nbsp; Checkin - {departureFlight.fare.adult.baggage.checkIn}</h6>
                            </div>
                            <Itenary startTime={startTime} endTime={endTime} segments={segments} operator={operator}/>
                            <FareRules departureFlight={departureFlight} Rules={Rules}/>
                        </div>
                        :<div className="border-box mt-4 shadow-sm rounded-3 overflow-hidden text-center"><Loader/></div>
                    }
                    
                </div>
                <div className="col-md-4 col-xl-3 p-0 pe-3 ps-3">
                    <FareCalc button={<button onClick={MoveNext} className='btn btn-lg btn-dark mt-4 rounded-pill'>Continue<i className="fas fa-arrow-right ms-3"/></button>} loading={!ReviewData} total={ReviewData?ReviewData.review.fare.publishedFare:0} tax={ReviewData?ReviewData.review.fare.taxes.total:0} base={ReviewData?ReviewData.review.fare.baseFare:0} coins={0} passengers={{adult,child,infant}}/>
                </div>
            </div>
        </div>
        {
        Failed&&
        <Modal title='Unable to Fetch Details' noButtons={true}>
            <div className='p-3 d-flex flex-column align-items-center justify-content-center'>
                <img src={OOPS} style={{width:100}} alt='Oops'/>
                <div className='text-center mt-2'>
                    We were unable to fetch this flight. You can go back and try booking again.
                    (Reason: {Failed})
                </div>
                <div className='mt-3'>
                    <button onClick={()=>HISTORY.goBack()} className='btn btn-dark'><i className="fas fa-arrow-left me-2"></i>Go Back</button>
                </div>
            </div>
        </Modal>
        }
        {
        PriceAlert&&
        <Modal maxWidth={350} title='Price Change Alert' noButtons={true}>
            <div className=' d-flex flex-column align-items-center justify-content-center'>
                <h3 className={`fw-bold rounded-4 w-100 mt-3 text-center p-3 ${PriceAlert>0?'text-danger':'text-success'}`} style={{background:'#f2f2f2'}}>
                    Rs. {ReviewData.review.fare.publishedFare} {PriceAlert>0?<i className="fas fa-long-arrow-alt-up ms-2"/>:<i className="fas fa-long-arrow-alt-down ms-2"/>}
                </h3>
                <div className='text-center p-2'>
                    <small>The Price for Selected flight has <b>{PriceAlert>0?'increased':'decreased'} by Rs. {Math.abs(PriceAlert)}</b>, Do you want to continue with this flight or select another.</small>
                </div>
                <div className='mt-3 mb-2 w-100 d-flex'>
                    <button onClick={()=>HISTORY.goBack()} className='flex-fill btn btn-dark me-2'>Back</button>
                    <button onClick={()=>{setPriceAlert();setPriceUpdated(true)}} className='flex-fill btn btn-primary'>Continue</button>
                </div>
            </div>
        </Modal>
        }
        </>
    );
}

export default Index;