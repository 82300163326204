import React, {useState} from 'react';

function Fares({selected, fares, onClick, flight, retailFare, fullSelection, appId, setAppId}) {
    
    const [Expand, setExpand] = useState(false);

    return (
            fares.map((fare, index)=>{
                if((appId&&appId!==fare.appId)||(appId&&fare.type!=='SPECIAL_RETURN')){
                    return <></>
                }
                let percentOff = Math.floor((retailFare-fare.adult.publishedFare)*100/retailFare)
                if(index===0){
                    return (
                        <section onMouseOver={()=>setExpand(true)} onMouseOut={()=>setExpand(false)} onClick={(e)=>{
                            if(selected&&selected.key===fare.key){
                                onClick(undefined)
                                if(fare.type==='SPECIAL_RETURN'){
                                    if(!fullSelection){
                                        setAppId(undefined)
                                    }
                                }
                            }else{
                                onClick({flight:flight, fare:fare})
                                if(fare.type==='SPECIAL_RETURN'){
                                    setAppId(fare.appId)
                                }
                            }
                        }} className={`${selected&&selected.key===fare.key?'fares-selected':'fares'} p-3 ps-4 pe-4 border-top position-relative`}>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div>
                                    <h6 className="fw-bold me-3 mb-0">{fare.type.replace('_',' ')}</h6>
                                    <div className='d-flex flex-wrap align-center mt-2'>
                                    {fare.adult.ancillery.meal&&<div className='rounded-pill text-small me-3'>🌮 Meal</div>}
                                    {fare.adult.ancillery.seat&&<div className='rounded-pill text-small me-3'>💺 Seat Choice</div>}
                                    {fare.adult.ancillery.dateChange&&<div className='rounded-pill text-small me-3'>📆 Flexible Date</div>}
                                    {fare.adult.cancellationCharges.type==='REFUNDABLE'&&<div className='rounded-pill text-small me-3'>🤑 Refundable</div>}
                                    </div>
                                </div>
                                <div className='text-end'>
                                    <h6 className={`fw-bold text-nowrap text-end ${index===0?'text-success':''}`}>Rs. {Math.ceil(fare.adult.publishedFare)} / <i className="fas fa-user"/></h6>
                                    {
                                        retailFare&&percentOff?
                                        <div style={{display:'inline'}} className='p-1 ps-2 pe-2 text-nowrap rounded-pill bg-success text-white text-tiny'>
                                            {percentOff}% Off
                                        </div>
                                        :<></>
                                    }

                                </div>
                            </div>
                            {
                                Expand?
                                <div className='poition-relative'>
                                    {fare.message?<div className='text-muted text-truncate text-tiny mt-1'>{fare.message}</div>:<></>}
                                </div>
                                :fares.length-1>0
                                ?<div className='d-flex align-items-center justify-content-center mb-1'>
                                    <div onClick={(e)=>{e.stopPropagation();setExpand(!Expand)}} className='border rounded-pill ps-2 pe-2 text-small bg-light position-absolute' style={{bottom:'0.5rem', left:'50%', transform:'translateX(-50%)'}}>
                                        + {fares.length-1} more
                                        <i className='fa fa-chevron-down ms-1'/>
                                    </div>
                                </div>
                                :<></>
                            }
                        </section>
                    )    
                }

                return (
                    <>
                    <section onMouseOver={()=>setExpand(true)} onMouseOut={()=>setExpand(false)}  onClick={(e)=>{
                            if(selected&&selected.key===fare.key){
                                onClick(undefined)
                                if(fare.type==='SPECIAL_RETURN'){
                                    if(!fullSelection){
                                        setAppId(undefined)
                                    }
                                }
                            }else{
                                onClick({flight:flight, fare:fare})
                                if(fare.type==='SPECIAL_RETURN'){
                                    setAppId(fare.appId)
                                }
                            }
                        }} className={`${selected&&selected.key===fare.key?'fares-selected':Expand||appId?'fares':'fares-hidden'} p-3 ps-4 pe-4 border-top`} key={index}>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div>
                                <h6 className="fw-bold me-3 mb-0">{fare.type.replace('_',' ')}</h6>
                                <div className='d-flex flex-wrap align-center mt-2'>
                                {fare.adult.ancillery.meal&&<div className='rounded-pill text-small me-3'>🌮 Meal</div>}
                                {fare.adult.ancillery.seat&&<div className='rounded-pill text-small me-3'>💺 Seat Choice</div>}
                                {fare.adult.ancillery.dateChange&&<div className='rounded-pill text-small me-3'>📆 Flexible Date</div>}
                                {fare.adult.cancellationCharges.type==='REFUNDABLE'&&<div className='rounded-pill text-small me-3'>🤑 Refundable</div>}
                                </div>
                            </div>
                            <h6 className={`fw-bold text-end ${index===0?'text-success':''}`}>Rs. {Math.ceil(fare.adult.publishedFare)} / <i className="fas fa-user"/></h6>
                        </div>
                        <div>{fare.message?<div className='text-muted text-truncate text-tiny mt-1'>{fare.message}</div>:<></>}</div>
                    </section>
                    </>
                )
            })
    );
}

export default Fares;