import React, {useState, useEffect} from 'react';
import './index.css'
import SearchCity from '../../../../Component/Form/SearchCity'
import SelectRooms from '../../../../Component/Form/SelectRooms'
import DateInput from '../../../../Component/Form/DateInput'
import moment from 'moment';
import Modal from '../../../../Component/Containers/Modal'
import {useCheckScreenHook} from '../../../../Hooks/useCheckScreenHook'
import { useHistory, useLocation } from 'react-router-dom';

function Index() {

    const HISTORY = useHistory()
    let {search} = useLocation()
	let PARAMS = new URLSearchParams(search)
	let CityId = PARAMS.get('cityId')
	let CityName = PARAMS.get('city')
	let Country = PARAMS.get('country')
	let CheckIn = Number(PARAMS.get('checkin'))
	let CheckOut = Number(PARAMS.get('checkout'))
	let Rooms = JSON.parse(PARAMS.get('rooms'))

    const [hotelCity, setHotelCity] = useState({city: CityName, country: Country, id: CityId});
    const [hotelRooms, setHotelRooms] = useState(Rooms);
    const [stayDates, setStayDates] = useState({
        startDate: moment(CheckIn).valueOf(),
        endDate: moment(CheckOut).valueOf(),
        key: 'selection'
    })

    const [getModal, setModal] = useState(false);
    const [Collapse, setCollapse] = useState(true);

    const ScreenSize = useCheckScreenHook();

    useEffect(() => {
        if(ScreenSize==='sm'){
            setCollapse(true)
            setModal(true)
        }else{
            setCollapse(undefined)
            setModal(undefined)
        }
    }, [ScreenSize]);

    const updateSearch = () => {
        HISTORY.push(`/hotels/search?city=${hotelCity.city}&country=${hotelCity.country}&cityId=${hotelCity.id}&checkin=${stayDates.startDate}&checkout=${stayDates.endDate}&rooms=${JSON.stringify(hotelRooms)}`)
    }

	const updateDate = (checkinDate, checkoutDate) => {
        setStayDates({
            startDate: checkinDate,
            endDate: checkoutDate,
            key: 'selection'
        })
    }

    return (
        <>
            <div className="mt-2 pb-2 mt-md-4 pb-md-4">
                {
                    Collapse?<></>:getModal?
                    <Modal onClose={()=>{setCollapse(true)}} onSuccess={()=>{updateSearch();setCollapse(true)}} title='Search' button={'Search'}>
                        <div className="mt-4">
                            <div className="row mt-3">
                                <div className="col-md-4 col-xl-4">
                                    <div className="row d-flex justify-content-between align-items-center p-3">
                                        <SearchCity api='/search/city' onSelect={setHotelCity} selected={hotelCity} label='From' placeholder='From' value=''/>
                                    </div>
                                </div>
                                <div className="col-md-4 col-xl-3">
                                <DateInput border='var(--bs-dark)' min={moment()._d} selectedDate={stayDates} label='Stay' placeholder={['Checkin','Checkout']} onSelect={updateDate} type='range'/>
                                </div>
                                <div className="col-md-4 col-xl-3">
                                    <SelectRooms border="#222" selected={hotelRooms} onSelect={setHotelRooms} label='Rooms'/>
                                </div>
                            </div>
                        </div>
                    </Modal>:
                    <div className="mt-4">
                        <div className="row mt-3">
                            <div className="col-md-4 col-xl-4">
                                <div className="row d-flex justify-content-between align-items-center p-3">
                                    <SearchCity background={'var(--material-pink)'} api='/search/city' onSelect={setHotelCity} selected={hotelCity} label='From' placeholder='From' value=''/>
                                </div>
                            </div>
                            <div className="col-md-4 col-xl-3">
                            <DateInput background={'var(--material-pink)'} border='var(--bs-dark)' min={moment()._d} selectedDate={stayDates} label='Stay' placeholder={['Checkin','Checkout']} onSelect={updateDate} type='range'/>
                            </div>
                            <div className="col-md-4 col-xl-3">
                                <SelectRooms background={'var(--material-pink)'} border="#222" selected={hotelRooms} onSelect={setHotelRooms} label='Rooms'/>
                            </div>
                            <div className="col-md-4 col-xl-2 align-self-center">
                                <button onClick={updateSearch} className="btn btn-dark btn-lg ps-4 w-100 d-none d-md-block" type="button">Search
                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor"
                                        viewBox="0 0 16 16" className="bi bi-arrow-right ms-2 mb-1">
                                        <path fillRule="evenodd"
                                            d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                }
            </div>

        </>
    );
}

export default Index;
