import React from 'react';
import './index.css'

function Index({tabs, active, background}) {
    return (
       <>
            <div className='tabs-menu' style={{background: background}}>
                {
                    tabs.map((tab,index)=>{
                        return(
                            <div onClick={tab.onClick} key={index} className={`${tab.label.toLowerCase()===active.toLowerCase()?'tab-active shadow-sm':'tab text-truncate'} cursor-pointer`}>
                                {tab.label}
                            </div>
                        )
                    })
                }
            </div>
       </>
    );
}

export default Index;