import React, {useState} from 'react';
import './TextEllipsis.css'

function TextEllipsis({children}) {
    const [Show, setShow] = useState(false);
    return (
        <>
        <div className={Show?'truncate-show':'truncate-overflow'}>
            {children}
            <br/>
        </div>
        <div onClick={()=>setShow(!Show)} className='fw-bold cursor-pointer mt-1'>
            Read More {Show?<i className="fas fa-chevron-up"/>:<i className="fas fa-chevron-down"/>}
        </div>
        </>
    );
}

export default TextEllipsis;