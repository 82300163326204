import React, {useRef} from 'react';
import { useHistory } from 'react-router-dom';
import { useLoginHook } from '../../Hooks/useLoginHook';
import Icon_Avatar from '../NavBar/Avatars/avatar.gif'
import './index.css'

function Sidebar({visible, setVisible, options}) {

    let CONFIG = useLoginHook();
    let HISTORY = useHistory();
    let SIDEBAR = useRef(), SIDEBAR_WRAPPER = useRef();

    const animateClose = () => {
        SIDEBAR_WRAPPER.current.classList.add('FadeOut-Effect');
        SIDEBAR.current.classList.add('FadeOutLeft-Effect');
        setTimeout(() => {setVisible(false)}, 200);
    }

    return (
        <>
            <div className='position-absolute mt-2 d-flex align-items-center justify-content-center p-1' style={{ borderRadius: '0.25rem', top: '0.75rem', zIndex: 20}}>
                <h1 onClick={()=>{setVisible(true)}} className='cursor-pointer mb-0' style={{marginTop:'-0.35rem'}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none"><path d="M2 6C2 5.44772 2.44772 5 3 5H21C21.5523 5 22 5.44772 22 6C22 6.55228 21.5523 7 21 7H3C2.44772 7 2 6.55228 2 6Z" fill="currentColor"></path><path d="M2 12.0322C2 11.4799 2.44772 11.0322 3 11.0322H21C21.5523 11.0322 22 11.4799 22 12.0322C22 12.5845 21.5523 13.0322 21 13.0322H3C2.44772 13.0322 2 12.5845 2 12.0322Z" fill="currentColor"></path><path d="M3 17.0645C2.44772 17.0645 2 17.5122 2 18.0645C2 18.6167 2.44772 19.0645 3 19.0645H21C21.5523 19.0645 22 18.6167 22 18.0645C22 17.5122 21.5523 17.0645 21 17.0645H3Z" fill="currentColor"></path></svg>
                </h1>
            </div>
            {
                visible&&
                <div ref={SIDEBAR_WRAPPER} onClick={()=>animateClose()} className='FadeIn-Effect sidebar-wrapper'>
                    <div ref={SIDEBAR} onClick={e=>e.stopPropagation()} className='FadeLeft-Effect sidebar shadow-md p-3'>
                        <div className='d-flex align-items-center'>
                            <h1 onClick={()=>{animateClose()}} className='cursor-pointer mb-0 me-2 display-3' style={{marginTop:'-0.35rem'}}>
                                <svg className="bi bi-arrow-left-short" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16"><path fillRule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"></path></svg>
                            </h1>
                            <div className='d-flex align-items-center cursor-pointer' onClick={()=>{HISTORY.push('/account');animateClose()}}>
                                <img className='me-3' alt='Avatar' src={CONFIG.avatar?CONFIG.avatar:Icon_Avatar} style={{width: 30 ,height:30, objectFit: 'cover', borderRadius: 30}} />
                                {
                                    CONFIG.authenticated?
                                    <h6 className='m-0 text-truncate'>{CONFIG.name}</h6>
                                    :'Login'
                                }
                            </div>
                        </div>

                        <div className='p-2 mt-3'>
                            {
                                options.map((option, index)=>{
                                    if(option.type==='divider'){
                                        return (
                                            <div className='text-small mt-4 mb-3 ms-4'>
                                                {option.name}
                                            </div>
                                        )
                                    }
                                    return (
                                        <div key={index} onClick={option.onClick} className={`ps-4 mt-2 pt-3 pb-3 ${option.active?'option-active':'option'}`}>
                                            <div style={{width:'40px'}}>
                                                <h5 className='mb-0'>{option.icon}</h5>
                                            </div>
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <span className='m-0'>{option.name}</span>
                                                {
                                                    option.badge&&
                                                    <span style={{fontSize:'0.6rem', marginLeft: '7px', color:'#fff',background: '#198754', borderRadius: '50px', padding: '0.10rem 0.5rem'}}>
                                                        {option.badge}
                                                    </span>
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default Sidebar;