import React from 'react';
import Loader from '../../../../../../Component/Loader'

function FareRules({flight, Rules, heading}) {
    return (
        <>
        <h6 style={{fontWeight: 'bold'}}>{heading} - Fare Rules</h6>
        <div className="mb-0" style={{fontSize: '0.8rem'}}>
            <ul>
                {
                    flight.fare.adult.cancellationCharges.type==='REFUNDABLE'&&
                    <li>🤑 This flight allows cancellation refund with conditions</li>
                }
                {flight.fare.adult.ancillery.seat&&<li>💺 Seat Selection is available for this flight</li>}
                {flight.fare.adult.ancillery.dateChange&&<li>📆 Date change is available for you on this flight</li>}
                {flight.fare.adult.ancillery.meal&&<li>🌮 Inflight Meal is avaialble on this flight</li>}
                <li>🧳 Total check-in baggage is 15Kg (1 unit)</li>
            </ul>
        </div>

        {
            Rules&&Rules.fareRules?
            <div className="border-box mt-4 mb-4" style={{background: '#FEF3CC', border: 'none'}}>
                <h6 style={{fontWeight: 'bold'}}>Important Information</h6>
                <div className="mb-0" style={{fontSize: '0.8rem'}}>
                    <ul>
                        {
                                Rules.fareRules.cancellation&&Rules.fareRules.cancellation.policy?
                                <>
                                    <span className='fw-bold'>Cancellation</span><br/>
                                    {Rules.fareRules.cancellation.policy.split('__nls__').map((item,i)=>{if(item){return <li key={i}>{item}</li>}else{return <></>}})}
                                    {
                                        Rules.fareRules.cancellation.total?
                                        <li>Total Cancellation Charges Rs. {Rules.fareRules.cancellation.total}</li>:<></>
                                    }
                                </>:<></>
                            }
                            {
                                Rules.fareRules.dateChange&&Rules.fareRules.dateChange.policy?
                                <>
                                    <br/><span className='fw-bold'>Date Change</span><br/>
                                    {Rules.fareRules.dateChange&&Rules.fareRules.dateChange.policy.split('__nls__').map((item,i)=>{if(item){return <li key={i}>{item}</li>}else{return <></>}})}
                                    {
                                        Rules.fareRules.dateChange&&Rules.fareRules.dateChange.total?
                                        <li>Total Cancellation Charges Rs. {Rules.fareRules.dateChange.total}</li>:<></>
                                    }
                                </>:<></>

                            }
                            {
                                Rules.fareRules.noShow&&Rules.fareRules.noShow.policy?
                                <>
                                    <br/><span className='fw-bold'>Refund</span><br/>
                                    {Rules.fareRules.noShow.policy.split('__nls__').map((item,i)=>{if(item){return <li key={i}>{item}</li>}else{return <></>}})}
                                    {
                                        Rules.fareRules.noShow.total?
                                        <li>Total Cancellation Charges Rs. {Rules.fareRules.noShow.total}</li>:<></>
                                    }
                                </>:<></>
                            }
                    </ul>
                </div>
            </div>
            :<Loader/>
        }
        </>
    );
}

export default FareRules;