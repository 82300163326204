import React from 'react';
import './index.css'
import Itenary from './Itenary'
import {useHistory} from 'react-router-dom'

function Index({selected, passengers, onCancel}) {
    let HISTORY = useHistory();
    let adultFare = (selected&&selected.fare.adult?selected.fare.adult.publishedFare:0);
    let childFare = (selected&&selected.fare.child?selected.fare.child.publishedFare:0)
    let infantFare = (selected&&selected.fare.infant?selected.fare.infant.publishedFare:0)

    let adultTotal = passengers.adult * adultFare
    let childTotal = passengers.child * childFare
    let infantTotal = passengers.infant * infantFare

    let Total  = adultTotal + childTotal + infantTotal

    const startBooking = () => {
        HISTORY.push({
            pathname: `/flights/main/booking`,
            state: {departureFlight:selected, totalFare: Total}
        })
    }
    if(selected){
        return (
            <>
            <div className='FadeUp-Effect info-pane shadow-lg d-flex justify-content-center align-items-center'>
                <div className='container'>
                    <div className='row'>
                        {
                            selected&&<div className={`mt-4 mb-md-4 col-12 col-md-8`}><Itenary {...selected} heading='Departure'/></div>
                        }
                        <div className='col-12 col-md-4 mb-4 mt-4 ps-md-5 align-self-center'>
                            <div className='flex-fill d-flex d-md-block align-items-center'>
                                <span className='text-small text-muted ms-2 d-none d-md-block'>All Passengers</span>
                                <div className='d-flex align-items-center mb-md-1 ms-2'>
                                    <h6 className='m-0 d-flex align-items-center text-nowrap flex-nowrap'>
                                        {
                                            passengers.adult?
                                                <>
                                                    {passengers.adult} <i className="fas fa-user ms-1 me-1" style={{fontSize:'0.8rem'}}/> x Adult,
                                                    &nbsp;
                                                </>:<></>
                                        }
                                        {
                                            passengers.child?
                                                <>
                                                    {passengers.child} <i className="fas fa-user ms-1 me-1" style={{fontSize:'0.8rem'}}/> x Child,
                                                    &nbsp;
                                                </>:<></>
                                        }
                                        {
                                            passengers.infant?
                                                <>
                                                    {passengers.infant} <i className="fas fa-user ms-1 me-1" style={{fontSize:'0.8rem'}}/> x Infant
                                                </>:<></>
                                        }
                                    </h6>
                                </div>
                                <span className='text-small text-muted ms-2 d-none d-md-block mt-2'>Estimated Total</span>
                                <h3 className='m-0 ms-2'>Rs. {Math.ceil(Total)}</h3>
                            </div>
                            <div className='align-items-center'>
                                <button onClick={startBooking} className='btn btn-dark btn-lg rounded-pill ps-4 pe-4 me-3 mt-3 mt-md-3 text-truncate'>Book<i className="fas fa-arrow-right ms-2"></i></button>
                                <button onClick={()=>onCancel()} className='btn btn-outline-dark btn-lg rounded-pill ps-4 pe-4 mt-3 text-truncate'>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
        );
    }else{
        return <></>
    }
}

export default Index;
