import React from 'react';

function Index({title, items, onSelect, style}) {
    return (
        <>
        <div className="mt-5" style={{...style}}>
            <h5>
                {title}
                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-lightning-charge-fill ms-2"><path d="M11.251.068a.5.5 0 0 1 .227.58L9.677 6.5H13a.5.5 0 0 1 .364.843l-8 8.5a.5.5 0 0 1-.842-.49L6.323 9.5H3a.5.5 0 0 1-.364-.843l8-8.5a.5.5 0 0 1 .615-.09z" /></svg>
            </h5>
            <div>
                {
                    items.map((item, index)=>{
                        return (
                            <button onClick={()=>{onSelect(item)}} key={index} className="btn btn-sm btn-outline-dark rounded-pill p-2 ps-3 pe-3 me-2 mt-2" type="button">{item.origin.city} to {item.destination.city}</button>
                        )
                    })
                }
            </div>
        </div>
        </>
    );
}

export default Index;
