
export default function usePersonaHook() {
	let CLIENTID = undefined;
	let origin = window.origin;
	if(origin.includes("http://localhost")){
		CLIENTID =  "asa1d915cff47e0007bfb69d62a1d915cff47e0007bfb69d";
	}
	else if(origin.includes("https://personal.dice.tech")){
		CLIENTID =  '62a1d915cff47e0007bfb69d62a1d915cff47e0007bfb69d';
	}
	else if(origin.includes("cleartrip.com")){
		CLIENTID =  '231a1d915cff47e0007bfb69d62a1d915cff47e0007bfb69d';
	}
	return CLIENTID || '62a1d915cff47e0007bfb69d62a1d915cff47e0007bfb69d';
}
