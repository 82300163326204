import React, {useState, useRef, useEffect, useCallback} from 'react';
import './index.css'
import {get} from '../../../Model/Config/Axios'

function Index({label, placeholder, value, onSelect, selected, background, api,node}) {

    const [Value, setValue] = useState(value?value:'');
    const [FilteredValues, setFilteredValues] = useState([]);

    let LIST = useRef(null), INPUT = useRef(null);

    useEffect(() => {
        if(selected){
            setValue(`${selected.city?selected.city:''}${selected.code?` (${selected.code})`:''}`)
        }
    }, [selected]);

    const debounce = (func) => {
        let timer;
        return function (...args) {
          const context = this;
          if (timer) clearTimeout(timer);
          timer = setTimeout(() => {
            timer = null;
            func.apply(context, args);
          }, 400);
        };
    };

    // eslint-disable-next-line
    const onChange = useCallback(debounce(() => {
        
        let val = INPUT.current.value;

        if(val.length>1){
            get(`${api}`, (e,r)=>{
                if(r){
                    setFilteredValues(node?r[node]:r.airports)
                    if(node?r[node].length:r.airports.length){
                        document.addEventListener("click", function closeMenu(event) {
                            if (LIST.current && !LIST.current.contains(event.target)) {
                              setFilteredValues([])
                              document.removeEventListener('click',closeMenu)
                            }
                        });
                    }
                }
            },{q:val})
        }else{
            setFilteredValues([])
        }
    }), [])

    const selectItem = (item) => {
        setValue(`${item.name} (${item.code})`);
        onSelect(item)
        setFilteredValues([])
    }

    return (
        <>
        <div className="col-5 position-relative rounded-3 position-relative p-0">
            <p className="text-muted position-absolute ps-2 pe-2" style={{top: '-10px', left: '8px',background: background?background:'#fff'}}>{label}</p>
            <input ref={INPUT} onFocus={()=>setValue('')} onChange={(e)=>{setValue(e.target.value);onChange()}} type='text' placeholder={placeholder} className='search-input' value={Value} style={{background: background?background:'#fff'}}/>
            {
                FilteredValues.length?
                <div ref={LIST} className='search-result shadow-sm FadeDown-Effect'>
                    <div className='header text-muted border-bottom'>Results</div>
                    {
                        FilteredValues.map((item,index)=>{
                            return (
                                <div onClick={()=>{selectItem(item)}} key={index} className='item'>
                                    {item.name}, {item.city} <span className='text-small text-muted'>({item.code})</span>
                                </div>
                            )
                        })
                    }
                </div>
                :<></>
            }
        </div>
        </>
    );
}

export default Index;
