import React from 'react';
import moment from 'moment';
import Fares from './Fares';

function Index({flight, onClick, selected, appId, setAppId, fullSelection}) {

    let {details, operator, fares, remaining, segments, } = flight;

    //Finding the Retail fare
    let retailFare=0;
    fares.forEach(fare => {
        if(fare.type==='RETAIL'){
            retailFare = fare.adult.publishedFare
        }
    });

    const startTime = moment(details.departure);
    const endTime = moment(details.arrival);
    const duration = moment.duration(endTime.diff(startTime));
    const hours = parseInt(duration.asHours());
    const minutes = parseInt(duration.asMinutes()) % 60;

    return (
        <>
        <div className="check-child FadeUp-Effect  bg-white rounded-4 shadow-sm mt-3 overflow-hidden">
            <div className='mb-3 d-flex justify-content-between align-items-center p-3 ps-4 pe-4 pb-0'>
                <div className="d-flex flex-column text-small" style={{width:60}}>
                    <img alt='Airline_logo' className="mb-1" src={operator.logo} style={{width: 30, height: 30, objectFit: 'cover', borderRadius: 30}} />
                    <span>{operator.name}</span>
                    <span className="text-muted">{operator.code}-{operator.number}</span>
                </div>
                <div className="d-flex flex-fill justify-content-around align-items-center">
                    <div className='text-center flex-fill' style={{width:'30%'}}>
                        <h6 className='text-nowrap'>{moment(details.departure).format('HH:mm')}</h6>
                        <h6 className='text-nowrap'>{moment(details.departure).format('DD MMM')}</h6>
                    </div>

                    <div className="text-center ps-3 pe-3 text-small d-none d-sm-block" style={{width:'30%'}}>
                        <div><span>{hours}h {minutes.toFixed(0)}m</span></div>
                        <div className="mt-1 mb-1" style={{borderBottom: '1px dashed #111'}} />
                        <div>
                            <span className="text-muted">
                                {
                                    segments.length>1?`${segments.length-1} Stop`:'Non-Stop'
                                }
                            </span>
                        </div>
                    </div>

                    <div className='text-center flex-fill' style={{width:'30%'}}>
                        <h6 className='text-nowrap'>{moment(details.arrival).format('HH:mm')}</h6>
                        <h6 className='text-nowrap'>{moment(details.arrival).format('DD MMM')}</h6>
                    </div>
                </div>
                {
                    remaining>0?
                    <div className='text-end'>
                        <span className="text-white p-1 ps-2 pe-2 rounded-pill text-truncate" style={{fontSize:'0.6rem', background: '#D1512D'}}>{remaining} seats left</span>
                    </div>:<></>
               }
            </div>
            <Fares appId={appId} fullSelection={fullSelection} setAppId={setAppId} selected={selected} onClick={onClick} fares={fares} flight={flight} retailFare={retailFare}/>
        </div>
        </>
    );
}

export default Index;
