import React from 'react';

function Index(props) {
    return (
        <>
        <div className='section_header' style={{background: props.color?props.color:'var(--material-blue)'}}>
            <div className="container">
                {props.children}
            </div>
        </div>

        </>
    );
}

export default Index;