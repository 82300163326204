import moment from 'moment';
import React, {useState} from 'react';
import Tabs from '../../../../Component/Tabs'
import Loader from '../../../../Component/Loader'

function PickandDrop({details, points, setPoints}) {
    const [Tab, setTab] = useState('Boarding');
    if(!details){
        return (
            <div className='w-100 bg-white rounded-4 shadow-sm mt-5 p-4 d-flex flex-center'><Loader/></div>
        )
    }
    const {boarding, dropping} = details;

    return (
        <>
        <div className='w-100 bg-white rounded-4 shadow-sm mt-5 p-4'>
            <div>
                <Tabs background='var(--material-silver)' active={Tab} tabs={[
                    {label: 'Boarding', onClick: ()=>{setTab('Boarding')}},
                    {label: 'Drops', onClick: ()=>{setTab('Drops')}},
                ]}/>
            </div>
            <div className='mt-3 pe-2 scrollbar-autohide' style={{maxHeight:300, overflowY:'scroll'}}>
            {
                Tab==='Boarding'?
                boarding.map((point,index)=>{
                    let selected = false
                    if(points.pick&&points.pick.code===point.code){
                        selected = true
                    }
                    return <div onClick={()=>{setPoints({drop:points.drop, pick: point})}} key={index} className={`boarding-point text-truncate mt-2 ${selected?'active':''}`}>{selected&&<i className="fas fa-check-circle me-2"></i>}<b>{moment(point.time).format('HH:mm A')}</b> at {point.name.split('-')[0]}</div>
                })
                :Tab==='Drops'?
                dropping.map((point,index)=>{
                    let selected = false
                    if(points.drop&&points.drop.code===point.code){
                        selected = true
                    }
                    return <div onClick={()=>setPoints({pick:points.pick, drop: point})} key={index} className={`boarding-point text-truncate mt-2 ${selected?'active':''}`}>{selected&&<i className="fas fa-check-circle me-2"></i>}<b>{moment(point.time).format('HH:mm A')}</b> at {point.name.split('-')[0]}</div>
                })
                :<></>
            }
            </div>
        </div>
        </>
    );
}

export default PickandDrop;