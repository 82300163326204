import React from 'react';
import NavBar from '../../Component/NavBar'
import Menu from './Menu'
import SectionHeader from '../../Component/Containers/Section-Header'
import TopDestinations from '../Common/TopDestinations'
import Guides from './Guides'
import NoHiddenCharges from '../../Component/Banners/NoHiddenCharges'
import HelpSection from '../Common/HelpSection'
import {useEventsHook} from '../../Hooks/useEventsHook'
// import PastBookings from './PastBookings'

function Index() {
	let EVENTS = useEventsHook();
	EVENTS.home()

	return (
		<>
			<SectionHeader color={'transparent'}>
				<NavBar/>
				<Menu/>
			</SectionHeader>
			<NoHiddenCharges/>
			{/* <PastBookings/> */}
			<TopDestinations/>
			<Guides/>
			<HelpSection/>
		</>
	);
}

export default Index;
