import React, {useRef, useEffect} from 'react';
import {showToast} from '../../Component/Toast'
import moment from 'moment';
import TextInput from '../Form/Inputs/TextInput'
import SelectInput from '../Form/Inputs/SelectInput'
import DateInput from '../Form/Inputs/DateInput'

function Index({index, getData, prefill}) {

    useEffect(() => {
        
        getData(Value)

        if(prefill){
            console.log(prefill);
            FNAME.current.value = prefill.fName;
            LNAME.current.value = prefill.lName;
            DOB.current.value = prefill.dob?moment(prefill.dob).format('DD/MM/YYYY'):'';
            GENDER.current.value = prefill.gender;
            COUNTRY.current.value = prefill.country;
            EMAIL.current.value = prefill.email;
            PHONE.current.value = prefill.mobile;
            TYPE.current.value = prefill.type;
        }
    }, [getData, prefill]);

    let FNAME = useRef(), LNAME = useRef(), GENDER = useRef(), DOB = useRef(), COUNTRY = useRef(), EMAIL = useRef(), PHONE = useRef(), TYPE = useRef();

    const Value = () => {
        let data = {
            fName: FNAME.current.value.trim(),
            lName: LNAME.current.value.trim(),
            email: EMAIL.current.value.trim(),
            mobile: PHONE.current.value.trim(),
            type: TYPE.current.value,
            dob:moment(moment(DOB.current.value,'YYYY-MM-DD')).valueOf(),
            gender: GENDER.current.value,
            country: COUNTRY.current.value,
        }
        if(data.email){
            if(!(data.email.includes('.')&&data.email.includes('@'))){
                showToast({
                    type:'error',
                    message:'Enter a valid Email'
                })
                return
            }
        }

        if(!data.fName&&!data.lName){
            showToast({
                type:'error',
                message:'Enter First Name'
            })
            return false
        }
        if(!data.email){
            showToast({
                type:'error',
                message:'Enter Email Address'
            })
            return false
        }
        if(!data.mobile){
            showToast({
                type:'error',
                message:'Enter Mobile Number'
            })
            return false
        }
        if(data.gender==='NONE'){
            showToast({
                type:'error',
                message:'Select Gender'
            })
            return false
        }
        
        let olderThan2 = (moment().valueOf()-moment(moment(DOB.current.value,'YYYY-MM-DD')).valueOf())>(2*365*24*60*60*1000)
        if(data.type==='INFANT'&&olderThan2){
            showToast({
                type:'error',
                message:'Infant age must be less than 2 years'
            })
            return false
        }
        return data
    }

    return (
        <>
        <div className='d-flex row mt-3'>
            <div className='col-md-4'>
                <TextInput ref={FNAME} type='text' placeholder='First Name' label='First Name*'/>
            </div>
            <div className='col-md-4'>
                <TextInput ref={LNAME} type='text' placeholder='Last Name' label='Last Name*'/>
            </div>
            <div className='col-md-4'>
                <SelectInput ref={GENDER} placeholder='Select Gender' label='Gender' options={[
                    {value: 'MALE', label: 'Male'},
                    {value: 'FEMALE', label: 'Female'}
                ]}/>
            </div>
        </div>
        <div className='d-flex row mt-3'>
            <div className='col-md-4'>
                <DateInput ref={DOB} label='Date of Birth*'/>
            </div>
            <div className='col-md-4'>
                <TextInput ref={EMAIL} type='email' validation={(val)=>val.includes('.')&&val.includes('@')} placeholder='Email' label='Email*'/>
            </div>
            <div className='col-md-4'>
                <TextInput ref={PHONE} type='number' placeholder='Contact' label='Phone*'/>
            </div>
        </div>
        <div className='d-flex row mt-3'>
            <div className='col-md-4'>
                <SelectInput defaultValue='ADULT' ref={TYPE} label='Age' options={[
                    {value: 'ADULT', label: 'Adult'},
                    {value: 'CHILD', label: 'Child'},
                    {value: 'INFANT', label: 'Infant'}
                ]}/>
            </div>
            <div className='col-md-4'>
                <SelectInput defaultValue='IN' ref={COUNTRY} label='Country' options={[
                    {value: 'IN', label: 'India'}
                ]}/>
            </div>
        </div>
    </>
    );
}

export default Index;