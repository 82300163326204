import React, {useState} from 'react';
import { useHistory } from 'react-router-dom';
import HotelCard from './Components/HotelCard'
import {hotelFilterUtils, hotelSortItem} from '../../HotelUtils'
import LoadingIcon from "../../../Flights/loading.webp";

function Index({cityId, loaded, rooms, hotels, filters, session, dates}) {
    let HISTORY = useHistory();
    const [sort, setSort] = useState({
		type: '',
		asc: true
	})
	const filteredHotels = hotels.filter(item=>hotelFilterUtils(filters,item))

    const openHotel = (id, appId) => {
        HISTORY.push({
            pathname: `/hotels/view`,
            state: {id, session, dates, cityId, appId, rooms}
        })
    }

	if(!loaded){
		return  (
			<div className='FadeUp-Effect bg-white p-4 mt-4 border rounded-3 text-center'>
				<div className='d-flex align-items-center justify-content-center'>
					<img src={LoadingIcon} alt='Loading' className='me-3' style={{maxWidth: 50}}/>
					<h5>Searching Hotels ...</h5>
				</div>
			</div>
		)
	}

	return (
		<div style={{
			display:'flex',
			flexWrap:'wrap',
			flexDirection:'row',
			margin:'10px -10px'
		}}>
			{
				filteredHotels.sort((a,b)=>hotelSortItem(sort,a,b)).map((hotel, index) => {
					return <HotelCard rooms={rooms} onClick={(id,providerKey)=>openHotel(id,providerKey)} key={index} {...hotel} />
				})
			}
		</div>
	)
}

export default Index;
