import React, {useState} from 'react'
import BusCard from "./Components/BusCard";
import {busFilterUtils, BusWrapper, busSortItem} from '../../BusUtils'
import SortBar from './Components/SortBar'

export default function Results({loading, sessionId, buses, filters}) {
	const [sort, setSort] = useState({
		type: 'price',
		asc: true
	})

	const filteredBuses = buses.filter(item=>busFilterUtils(filters,item))

	return (
		<>
		<SortBar sort={sort} setSort={setSort}/>
		<BusWrapper length={filteredBuses.length} loading={loading}>
			{
				filteredBuses.sort((a,b)=>busSortItem(sort,a,b)).map(item=>(<BusCard sessionId={sessionId} item={item} key={item.busId}/>))
			}
		</BusWrapper>
		</>
	)
}
