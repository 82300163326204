import React, {useState, useEffect} from 'react';
import {get, post} from '../../../Model/Config/Axios'
import {showToast} from '../../../Component/Toast'
import AllTravellers from './All'
import EditTraveller from './Edit'
import AddTraveller from './Add'
import Modal from '../../../Component/Containers/Modal'

function Index() {

    const [Travellers, setTravellers] = useState(undefined);

    const [EditModal, setEditModal] = useState(undefined);
    const [DeleteModal, setDeleteModal] = useState(undefined);
    const [AddModal, setAddModal] = useState(undefined);

    useEffect(() => {
        fetchTravellers()
    }, []);

    const fetchTravellers = (params) => {
        get(`travellers`, (e,r)=>{
            if(r){
                setTravellers(r.travellers)
            }else if(e){
                showToast({
                    type: 'error',
                    message: e.data?e.data.message:'Error in fetching Travellers'
                })
            }
        })
    }

    const deleteTraveller = (params) => {
        post(`travellers/${DeleteModal.id}/delete`, {}, (e,r)=>{
            if(r){
                showToast({
                    type: 'success',
                    message: 'Traveller Deleted'
                })
                setDeleteModal(undefined)
                fetchTravellers()
            }else if(e){
                showToast({
                    type: 'error',
                    message: e.data?e.data.message:'Error in deleting Traveller'
                })
            }
        })
    }

    const addTraveller = (data) => {
        
       if(data){
        post(`travellers/`, data, (e,r)=>{
            if(r){
                showToast({
                    type: 'success',
                    message: 'Traveller Added'
                })
                setAddModal(undefined)
                fetchTravellers()
                
            }else if(e){
                showToast({
                    type: 'error',
                    message: e.data?e.data.message:'Error in adding Traveller'
                })
            }
        })
       }
    }

    const updateTraveller = (data) => {
        if(data){
            post(`travellers${EditModal.id}`, data, (e,r)=>{
                if(r){
                    showToast({
                        type: 'success',
                        message: 'Traveller Updated'
                    })
                    setEditModal(undefined)
                    fetchTravellers()

                }else if(e){
                    showToast({
                        type: 'error',
                        message: e.data?e.data.message:'Error in updating Travellers'
                    })
                }
            })
        }
    }
    
    return (
        <>
        <div className="mt-4 shadow-sm p-4 bg-white" style={{ borderRadius: "0.5rem" }}>
            <div className="d-flex align-items-start justify-content-between">
                <div className='pe-3'>
                    <h3>Add Travellers</h3>
                    <p className="text-small">Add travellers which you can autofill when booking next time</p>
                </div>
                <button onClick={()=>setAddModal(true)} className="btn btn-outline-dark rounded-pill p-2 ps-4 pe-4 text-nowrap" type="button"><i className="fas fa-user-plus me-2" />Add</button>
            </div>

            <AllTravellers travellers={Travellers} onEdit={setEditModal} onDelete={(traveller)=>setDeleteModal(traveller)}/>
        </div>
        {
            EditModal&&<EditTraveller onClose={()=>setEditModal(undefined)} prefill={EditModal} onUpdate={updateTraveller}/>
        }
        {
            AddModal&&<AddTraveller onClose={()=>setAddModal(undefined)}  onSubmit={addTraveller}/>
        }
        {
            DeleteModal&&
            <Modal onClose={()=>setDeleteModal(undefined)} onSuccess={()=>deleteTraveller()} title={`Delete ${DeleteModal.name}`} button={'Delete'}>
                <div className='text-start w-100'>
                Are you sure you want to delete {DeleteModal.name} from your list.
                </div>
            </Modal>
        }
        </>
    );
}

export default Index;