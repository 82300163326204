import React from 'react';
import moment from 'moment';

function Itenary({operator, segments, startTime, endTime}) {

    const duration = moment.duration(endTime.diff(startTime));
    const hours = parseInt(duration.asHours());
    const minutes = parseInt(duration.asMinutes()) % 60;

    const connectingFlights = (layover, index) => {
        return (
            <div className="p-3 pt-0" key={index}>
                <div className="row flight-card p-2 p-md-2 " style={{background:'transparent', border:'1px dashed #ccc'}}>
                    <div className="col-12 col-md-3 text-center mt-2 text-small">
                        <img className="mb-1" src={operator.logo} alt='logo' style={{width: 30, height: 30, objectFit: 'cover', borderRadius: 30}} />
                        <div>{layover.airline}</div><span className="text-muted">{layover.flightCode}-{layover.flightNumber}</span>
                    </div>
                    
                    <div className='col-6 col-md-4 text-start mt-2'>
                        <h6 className="mb-0">{moment(layover.departureTime).format('HH:mm')}</h6>
                        <h6 className="mb-0">{layover.departure.city}</h6>
                        <span className="text-muted" style={{fontSize: '0.8rem'}}>{layover.departure.terminal} {layover.departure.airport}</span>
                    </div>

                    {/* <div className='col-12 col-md-4 mt-2 d-none d-md-block'>
                        <div className="text-center position-relative" style={{fontSize: '0.8rem', borderTop: '1px dashed #999', marginTop: 30}}>
                            <i className="fas fa-plane-departure me-2 position-absolute bg-light p-1 rounded-3" style={{left: '0px', top: '-12px'}} />
                            <i className="fas fa-plane-arrival me-2 position-absolute bg-light p-1 rounded-3" style={{right: '-7px', top: '-12px'}} />
                        </div>
                    </div> */}

                    <div className="col-6 col-md-5 text-end mt-2">
                        <h6 className="mb-0">{moment(layover.arrivalTime).format('HH:mm')}</h6>
                        <h6 className="mb-0">{layover.arrival.city}</h6>
                        <span className="text-muted" style={{fontSize: '0.8rem'}}>{layover.arrival.terminal} {layover.arrival.airport}</span>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className="p-1">
                <div className="text-center mt-2 text-small d-flex align-items-center mb-3">
                    <img className="me-3" src={operator.logo} alt='logo' style={{width: 30, height: 30, objectFit: 'cover', borderRadius: 30}} />
                    <div>{operator.name}</div><span className="text-muted">{operator.code}-{operator.number}</span>
                </div>
                <div className="row flight-card shadow-sm p-2">
                    <div className='col-12 col-md-4 text-start mt-2'>
                    <h4 className="mb-0 fw-bold">{startTime.format('HH:mm')}</h4>
                        <h6 className="mb-0">{segments[0].departure.city}</h6>
                        <span className="text-muted" style={{fontSize: '0.8rem'}}>{segments[0].departure.terminal} {segments[0].departure.airport}</span>
                    </div>

                    <div className='col-12 col-md-4 mt-2'>
                        <div className="text-center position-relative" style={{fontSize: '0.8rem', borderTop: '2px dashed #999', marginTop: 30}}>
                            <div className="position-absolute text-nowrap" style={{top: '-30px', left:'50%', transform: 'translateX(-50%)'}}>
                                <span><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-clock me-2"><path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" /><path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" /></svg>{hours}h {minutes}m</span>
                            </div>
                            <div style={{marginTop: 10}}><span className="text-muted">{segments.length>1?`${segments.length} Stop`:'Non-Stop'}</span></div>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 text-end mt-2">
                        <h3 className="mb-0 fw-bold">{endTime.format('HH:mm')}</h3>
                        <h6 className="mb-0">{segments[segments.length-1].arrival.city}</h6>
                        <span className="text-muted" style={{fontSize: '0.8rem'}}>{segments[segments.length-1].arrival.terminal} {segments[segments.length-1].arrival.airport}</span>
                    </div>
                </div>
            </div>

            {
                segments.length>1?
                <>
                <h6 className='w-100 d-flex flex-column align-items-center text-center text-small'>
                    <div style={{borderLeft: '1px dashed #ccc', height: '1rem', width:'1px'}}/>
                    <div className='mt-2 mb-2'>
                        <i className="fas fa-link"></i> {segments.length-1} Connecting Flight
                    </div>
                    <div style={{borderLeft: '1px dashed #ccc', height: '1rem', width:'1px'}}/>
                </h6>
                {
                    segments.map(connectingFlights)
                }
                </>
                :undefined
            }
        </>
    );
}

export default Itenary;