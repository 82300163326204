import React from 'react';
import NavBar from '../../../../Component/NavBar'
import FlightSearch from './Search'
import SectionHeader from '../../../../Component/Containers/Section-Header'
import HelpSection from "../../../Common/HelpSection";
import HiddenFares from '../../../../Component/Banners/HiddenFares'
import {useLoginHook} from "../../../../Hooks/useLoginHook";
import {useEventsHook} from "../../../../Hooks/useEventsHook";

function Index() {

	let EVENTS = useEventsHook();
	EVENTS.flights()
	
    let CONFIG = useLoginHook();
	
	if(!CONFIG.authenticated){
		return  (
			<>
				<SectionHeader color={'var(--material-beage)'}>
					<NavBar/>
					<div className="p-1 mt-4 pb-4 ps-2">
						<h5>Welcome</h5>
						<h1 className="display-5" style={{fontWeight: 'bold'}}>Best deals on your Flight booking</h1>
					</div>
					<HiddenFares title='Login to browse flights' style={{marginBottom:-150}}/>
				</SectionHeader>
				<div style={{marginTop:260}}/>
				<HelpSection/>
			</>
		)
	}
	return (
		<>
			<SectionHeader color={'var(--material-beage)'}>
				<NavBar/>
				<FlightSearch/>
			</SectionHeader>
			<div style={{marginTop:280}}/>
			<HelpSection/>
		</>
	);
}

export default Index;
