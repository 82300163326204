import React from 'react'
import {BrowserRouter, Route, Switch} from "react-router-dom";
import {Provider} from 'react-redux';
import Store from "../Redux";
import {ToastContainer} from "../Component/Toast/ToastContainer";
import {Toaster} from 'react-hot-toast'
import Pages from '../Pages'
import Login from '../Authentication/Login'
import ScrollToTop from './scrollToTop';
import Authorize from '../Authentication/Authorize'
import Success from './Success'
import AppUpdate from '../Component/AppUpdate/AppUpdate'

const ReduxStore = Store();

function Index() {
	return (
		<Provider store={ReduxStore}>
			<ToastContainer/>
			<Toaster/>
			<BrowserRouter>
			<ScrollToTop/>
			<AppUpdate/>
			<Switch>
				<Route path="/success" component={Success} exact={true}/>
				<Route path="/authorize" component={Authorize} exact={true}/>
				<Route path="/login" component={Login} exact={true}/>
				<Route path="/" component={Pages} />
			</Switch>
			</BrowserRouter>
		</Provider>
	)
}

export default Index
