import React from 'react';
import './index.css'

function Index() {
    return (
        <>
        <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
        </>
    );
}

export default Index;