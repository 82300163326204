import React, {useState, useEffect} from 'react';
import CheckboxFilter from './Components/CheckboxFilter'
import RangeFilter from './Components/RangeFilter'
import Modal from '../Containers/Modal'
import {useCheckScreenHook} from '../../Hooks/useCheckScreenHook'

function Index({count, showFilters,maxValue=350000, closeFilters, filters, onApply}) {

    const ScreenSize = useCheckScreenHook();

    let STOPS = [{name:'Non-Stop', value:1},{name:'Single-Stop', value:2}]
    let TIMES = [{name:'Early Morning', value:6},{name:'Morning', value:11},{name:'Afternoon', value:15},{name:'Evening', value:19},{name:'Night', value:24}]
    let AIRLIENS = [{name:'Air India', value:'Air India'},{name:'Vistara', value:'Vistara'},{name:'IndiGo', value:'IndiGo'},{name:'Go First', value:'Go First'},{name:'AirAsia', value:'AirAsia India'},{name:'SpiceJet', value:'SpiceJet'}]

    const [stopFilter, setstopFilter] = useState([]);
    const [PriceFilter, setPriceFilter] = useState(300000);
    const [TimeFilter, setTimeFilter] = useState([]);
    const [AirlineFilter, setAirlineFilter] = useState([]);

    const [getModal, setModal] = useState(showFilters);

    useEffect(() => {
        if(ScreenSize==='sm'){
            setModal(true)
        }else{
            setModal(undefined)
        }
    }, [ScreenSize]);

    useEffect(() => {
        if(onApply){
            onApply({...filters, stops: stopFilter, time: TimeFilter, price: PriceFilter, airline: AirlineFilter})
        }
        // eslint-disable-next-line
    }, [stopFilter, TimeFilter, PriceFilter, AirlineFilter]);


    if(getModal)
    return (
            showFilters?
            <Modal onClose={()=>closeFilters(undefined)} onSuccess={()=>{closeFilters(undefined)}} title='Filter' button={'Apply'}>
                <div className='w-100'>
                    <CheckboxFilter heading='Quick Filters' options={STOPS} onChange={setstopFilter} selected={stopFilter}/>
                    <RangeFilter value={PriceFilter} onChange={setPriceFilter} min={5000} max={maxValue}/>
                    <CheckboxFilter heading='Departure Time' options={TIMES} onChange={setTimeFilter} selected={TimeFilter}/>
                    <CheckboxFilter heading='Airlines' options={AIRLIENS} onChange={setAirlineFilter} selected={AirlineFilter}/>
                </div>
            </Modal>
            :<></>
    )
    else
    return (
        <div>
            <CheckboxFilter heading='Quick Filters' options={STOPS} onChange={setstopFilter} selected={stopFilter}/>
            <RangeFilter value={PriceFilter} onChange={setPriceFilter} min={5000} max={maxValue}/>
            <CheckboxFilter heading='Departure Time' options={TIMES} onChange={setTimeFilter} selected={TimeFilter}/>
            <CheckboxFilter heading='Airlines' options={AIRLIENS} onChange={setAirlineFilter} selected={AirlineFilter}/>
        </div>
    )
}

export default Index;
