import React, { useEffect, useState } from 'react';
import { post } from '../../../../Model/Config/Axios';
import GaugeLine from '../../../../Component/GaugeLine/GaugeLine'

function Experience({hotelId, userRating=0}) {

    const [Ratings, setRatings] = useState();

    useEffect(() => {
        fetchRatings(hotelId)
    }, [hotelId]);

    const fetchRatings = (id) => {
        post(`/hotel/ratings`,{hotelId: 'ChIJtZ0tUgnBwjsRYt1OE9ryjsw'},(e,r)=>{
            if(r){
                setRatings(r.sentiment)
            }
        })
    }

    let Rate = 'Average'
    if(userRating>3.2){
        Rate = 'Good'
    }
    if(userRating>3.9){
        Rate = 'Very good'
    }
    if(userRating>4.3){
        Rate = 'Excellent'
    }

    if(Ratings){
        return (
            <>
            <h2 className='fw-bold'>User Experience</h2>
            <div className='row mt-4 border-top pt-3 pb-3 border-bottom'>
                <div className='col-12 col-md-3 d-flex align-items-center'>
                    <div className='text-white p-2 rounded-3 bg-primary'><h1>{userRating.toFixed(1)}</h1></div>
                    <div className='ms-3'>
                        <h4>{Rate}</h4>
                        <div style={{fontSize:'0.8rem'}}>We rated this hotel as {Rate} based on user reviews</div>
                    </div>
                </div>
                <div className='col-12 col-md-9 row'>
                    <div className='col-12 col-md-2 ps-3'>
                        <GaugeLine labelStart='Value' labelEnd={<span className='fw-bold'>{Ratings.valueForMoney/10}</span>} total={10} value={Ratings.valueForMoney/10}/>
                    </div>
                    <div className='col-12 col-md-2 ps-3'>
                        <GaugeLine labelStart='Staff' labelEnd={<span className='fw-bold'>{Ratings.staff/10}</span>} total={10} value={Ratings.staff/10}/>
                    </div>
                    <div className='col-12 col-md-2 ps-3'>
                        <GaugeLine labelStart='Service' labelEnd={<span className='fw-bold'>{Ratings.service/10}</span>} total={10} value={Ratings.service/10}/>
                    </div>
                    <div className='col-12 col-md-2 ps-3'>
                        <GaugeLine labelStart='Location' labelEnd={<span className='fw-bold'>{Ratings.location/10}</span>} total={10} value={Ratings.location/10}/>
                    </div>
                    <div className='col-12 col-md-2 ps-3'>
                        <GaugeLine labelStart='Facilities' labelEnd={<span className='fw-bold'>{Ratings.facilities/10}</span>} total={10} value={Ratings.facilities/10}/>
                    </div>
                    <div className='col-12 col-md-2 ps-3'>
                        <GaugeLine labelStart='Room' labelEnd={<span className='fw-bold'>{Ratings.roomComforts/10}</span>} total={10} value={Ratings.roomComforts/10}/>
                    </div>
                </div>
            </div>
            </>
        );
    }
}

export default Experience;