import React from 'react';

function Index({photo, title, description, button= {name: '', onClick: ()=>{}}}) {
    return (
        <>
        <div className="mt-3 me-3 p-4 d-flex flex-column justify-content-end destination-card" style={{background: `linear-gradient(to top, rgba(0,0,0,0.7), rgba(0,0,0,0)), url(${photo}) center / cover no-repeat`}}>
            <h3 className="text-white">{title}</h3>
            <p className="text-white">{description}</p>
            <div><button onClick={button.onClick} className="btn btn-light btn-sm rounded-pill" type="button">{button.name}</button></div>
        </div>
        </>
    );
}

export default Index;