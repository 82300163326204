import React, {useState, useEffect} from 'react';
import './index.css'
import { useHistory } from 'react-router-dom';
import SubscribeModal from '../../../Component/Subscribe'
import {useLoginHook} from "../../../Hooks/useLoginHook";
import FlightBack from '../../../Assets/Images/flights.jpg'
import HotelsBack from '../../../Assets/Images/hotels.jpg'
import TrainsBack from '../../../Assets/Images/trains.jpg'
import BusesBack from '../../../Assets/Images/buses.jpg'
import HolidayBack from '../../../Assets/Images/holiday.jpg'

function Index() {

    let HISTORY = useHistory()
    let CONFIG = useLoginHook();

    const [Subscribe, setSubscribe] = useState(undefined);
    const [Name, setName] = useState('Traveller');

    useEffect(() => {
        if(CONFIG.authenticated){
            setName(CONFIG.name)
        }
    }, [CONFIG]);

    return (
        <>
       <div className="mt-5 search">
            <h5>Hello, {Name}</h5>
            <h1 className="display-5" style={{fontWeight: 'bold'}}>Get best price on your bookings.</h1>
            <div className="mt-4 FadeUp-Effect">
                <div className="row" style={{marginTop:'-1rem'}}>
                    <div className="col-6 col-md-4 mt-3">
                        <div onClick={()=>HISTORY.push('/flights/main')} className='menu-option p-5' style={{background: `linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0)), url(${FlightBack})`, backgroundSize: 'cover'}}>
                            <h5 className="text-center m-0 text-white"><i className="fas fa-plane mb-2 h4" /><br />Flights</h5>
                        </div>
                    </div>
                    <div onClick={()=>{CONFIG.authenticated?HISTORY.push('/hotels'):HISTORY.push('/login')}} className="col-6 col-md-4 mt-3">
                        <div className='menu-option p-5' style={{background: `linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0)), url(${HotelsBack})`, backgroundSize: 'cover'}}>
                            <h5 className="text-center m-0 text-white"><i className="fas fa-bed mb-2 h4" /><br />Hotels</h5>
                        </div>
                    </div>
                    <div className="col-6 col-md-4 mt-3">
                        <div onClick={()=>HISTORY.push('/buses')} className='menu-option p-5' style={{background: `linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0)), url(${BusesBack})`, backgroundSize: 'cover'}}>
                            <h5 className="text-center m-0 text-white"><i className="fas fa-bus mb-2 h4" /><br />Buses</h5>
                        </div>
                    </div>
                </div>

            </div>
            </div>
            {
                Subscribe?
                <SubscribeModal onClose={()=>setSubscribe(undefined)}/>
                :<></>
            }
        </>
    );
}

export default Index;
