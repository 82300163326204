import React from 'react';
import Article from '../../../Component/Cards/Articles'
import Goa from './goa.jpeg'
import Thar from './thar.png'
import Dam from './dam.jpeg'
import Jim from './jim.jpeg'
import Valley from './valley.jpeg'

function Index() {

    let Articles = [
        {
            title: 'The 9 Different Sunsets of Goa',
            description: 'The FOMO of ‘the sunset moments’ when you’re travelling is not worth the hype. It just comes with so much pressure to suddenly stop what you’re doing (read chilling) and go to the nearest beach',
            photo: Goa,
            url: 'https://medium.com/@avanika.ava/the-9-different-sunsets-of-goa-6e091747da58'
        },
        {
            title: 'From Thar Desert, India: The Hidden Treasure In You',
            description: 'Just imagine standing in the middle of a sandy desert with the sun shining brightly on top of your head. With the little quantity of water left in your bottle, you must be feeling thirsty and wondering where & how on earth do you quickly reach to the nearest source of water so that you can quench your thirst at the earliest.',
            photo: Thar,
            url: 'https://medium.com/@DiyaTravelBlogger/from-thar-desert-india-the-hidden-treasure-in-you-a8097c43b92a'
        },
        {
            title: 'A 3-day Trip to Wayanad, Kerala: How to make the most of it',
            description: 'I booked the Wayanad trip with an all-women travel organiser company in Bangalore called TheeBeauties. Since we wanted to skip nighttime travel, we started from Bangalore at 7 AM.',
            photo: Dam,
            url: 'https://chandanapitta.medium.com/a-3-day-trip-to-wayanad-kerala-how-to-make-the-most-of-it-a941086b7776'
        },
        {
            title: 'Jim Corbett: A quick, exciting getaway',
            description: 'This is our first post-pandemic trip, and what better than the Corbett Tiger Reserves in a hilly state of India (Uttarakhand)',
            photo: Jim,
            url: 'https://indiatravelogues.medium.com/corbett-sweet-escapades-in-an-indian-hill-forest-101d54b04a8'
        },
        {
            title: 'Top of the Hill, Yumthang Valley',
            description: 'Lets go to Yumthang Valley to watch the flora, thats how the conversation started with my partner when we were thinking of a plan for our vacation in December',
            photo: Valley,
            url: 'https://susmithasubramanya.medium.com/top-of-hill-2819add6508a'
        },
    ]

    return (
        <>
       <div className="mt-4 pt-4 pb-5">
            <div className="container">
                <div className="row">
                <div className="col-md-12">
                    <h5>Guides</h5>
                    <h1 style={{fontWeight: 'bold'}}>Travel Guides</h1>

                    <div className="d-flex flex-nowrap overflow-scroll overflow-y-hidden scrollbar-autohide pb-3">
                        {
                            Articles.map((article,index)=>{
                                return <Article key={index} photo={article.photo} title={article.title} description={article.description} url={article.url}/>
                            })
                        }
                    </div>
                </div>
                </div>
            </div>
        </div>

        </>
    );
}

export default Index;
