import React, {useState} from 'react';
import './index.css'
import Option from './Components/Options'
import User from './Components/User'
import { useHistory, useLocation} from 'react-router-dom';
import {useLoginHook} from "../../Hooks/useLoginHook";
import useOriginHook from "../../Hooks/useOrigin";
import Login_Icon from './login.png'
import ClearTrip from '../Logos/ClearTrip'
import Dice from '../Logos/Dice'
import Sidebar from '../../Component/Sidebar'
import SupportDetails from './SupportDetails'
// import Dropdown from './Components/Dropdown'

function Index({hideLogin=false}) {

    let route = useLocation().pathname
    let CONFIG = useLoginHook();
    let {CLEARTRIP} = useOriginHook();

    const [Drawer, setDrawer] = useState(undefined);
    const [Support, setSupport] = useState(undefined);

    let HISTORY = useHistory()

    const displayUser = () => {
        if(!CONFIG.authenticated){
            if(hideLogin){
                return  <></>
            }
            return  (
                <li onClick={()=>CONFIG.Login()} className="nav-item">
                    <span className={`nav-link text-truncate`} >
                        Login
                        <img className='ms-2' src={Login_Icon} style={{maxWidth:25, borderRadius:50}} alt='Avatar'/>
                    </span>
                </li>
            )
        }else{
            return (
                <User name={CONFIG.name} options={[
                {
                    name: 'Profile',
                    onClick: () => {HISTORY.push('/account')}
                },
                {
                    name: 'Rewards',
                    onClick: () => {HISTORY.push('/rewards')}
                },
                {
                    name: 'Support',
                    onClick: () => {setSupport(true)}
                },
                {
                    name: 'Logout',
                    onClick: () => {localStorage.clear();window.location.reload()}
                }
            ]} avatar={CONFIG.avatar}/>
            )
        }
    }

    let menu_options = [
        {
            name: 'Home',
            icon: <svg className="me-2" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 20" fill="none"><path d="M10.7071 2.29289C10.3166 1.90237 9.68342 1.90237 9.29289 2.29289L2.29289 9.29289C1.90237 9.68342 1.90237 10.3166 2.29289 10.7071C2.68342 11.0976 3.31658 11.0976 3.70711 10.7071L4 10.4142V17C4 17.5523 4.44772 18 5 18H7C7.55228 18 8 17.5523 8 17V15C8 14.4477 8.44772 14 9 14H11C11.5523 14 12 14.4477 12 15V17C12 17.5523 12.4477 18 13 18H15C15.5523 18 16 17.5523 16 17V10.4142L16.2929 10.7071C16.6834 11.0976 17.3166 11.0976 17.7071 10.7071C18.0976 10.3166 18.0976 9.68342 17.7071 9.29289L10.7071 2.29289Z" fill="currentColor"></path></svg>,
            onClick: ()=>{HISTORY.push('/');setDrawer(false)},
            active: route === '/'
        },
        {
            name: 'Flights',
            icon: <i className="fas fa-plane me-3"/>,
            onClick: ()=>{HISTORY.push('/flights/main');setDrawer(false)},
            active: route === '/flights/main'
        },
        {
            name: 'Hotels',
            icon: <i className="fas fa-bed me-3"/>,
            onClick: ()=>{HISTORY.push('/hotels');setDrawer(false)},
            active: route === '/hotels',
        },
        // {
        //     name: 'Train',
        //     icon: <i className="fas fa-train me-3"/>,
        //     onClick: ()=>{HISTORY.push('/trains');setDrawer(false)},
        //     active: route === '/trains',
        // },
        {
            name: 'Bus',
            icon: <i className="fas fa-bus me-3"/>,
            onClick: ()=>{HISTORY.push('/buses');setDrawer(false)},
            active: route === '/buses',
        },
        ...(CONFIG.authenticated?[
        {
            type:'divider',
            name: 'My Account'
        },
        {
            name: 'Rewards',
            icon: <i className="fas fa-asterisk me-3"/>,
            onClick: ()=>{HISTORY.push('/rewards');setDrawer(false)},
            active: route === '/rewards'
        },
        {
            name: 'Bookings',
            icon: <i className="fas fa-ticket-alt me-3"/>,
            onClick: ()=>{HISTORY.push('/bookings');setDrawer(false)},
            active: route === '/bookings'
        },
        {
            name: 'Support',
            icon: <i className="fas fa-ticket-alt me-3"/>,
            onClick: ()=>{setSupport(true)},
            active: route === '/support'
        },
        {
            name: 'Logout',
            icon: <i className="fas fa-lock me-3"/>,
            onClick: ()=>{localStorage.clear(); window.location.reload();setDrawer(false)}
        }]:[])
    ]

    return (
        <>
            <div className='responsive-nav-m w-100'>
                <nav className="navigation navbar navbar-light navbar-expand-lg w-100">
                    <div className="container p-0 flex-nowrap">
                        <div className='d-flex justify-content-center align-items-center ps-5'>
                            {CLEARTRIP?<ClearTrip/>:<Dice/>}
                        </div>
                        <ul className="navbar-nav align-items-center">
                            {displayUser()}
                        </ul>
                    </div>
                </nav>

                <Sidebar visible={Drawer} setVisible={setDrawer} options={menu_options}/>
            </div>

            <nav className="navigation navbar navbar-light navbar-expand-lg responsive-nav-web">
                <div className="container p-0">
                    <div className='d-flex justify-content-center align-items-center'>
                    {CLEARTRIP?<ClearTrip/>:<Dice/>}
                    </div>
                    <div className="navbar-collapse">
                        <ul className="navbar-nav m-auto p-2" style={{borderRadius: '10px', background: 'rgba(0,0,0,0.08)'}}>
                            <Option style={{marginRight: '0.5rem'}} active={route === '/'} title='Home' icon={<svg className="me-2 mb-1" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 20" fill="none"><path d="M10.7071 2.29289C10.3166 1.90237 9.68342 1.90237 9.29289 2.29289L2.29289 9.29289C1.90237 9.68342 1.90237 10.3166 2.29289 10.7071C2.68342 11.0976 3.31658 11.0976 3.70711 10.7071L4 10.4142V17C4 17.5523 4.44772 18 5 18H7C7.55228 18 8 17.5523 8 17V15C8 14.4477 8.44772 14 9 14H11C11.5523 14 12 14.4477 12 15V17C12 17.5523 12.4477 18 13 18H15C15.5523 18 16 17.5523 16 17V10.4142L16.2929 10.7071C16.6834 11.0976 17.3166 11.0976 17.7071 10.7071C18.0976 10.3166 18.0976 9.68342 17.7071 9.29289L10.7071 2.29289Z" fill="currentColor"></path></svg>} url={'/'}/>
                            <Option style={{marginRight: '0.5rem'}} active={route.includes('/flights/main')} title='Flights' icon={<i className="me-2 fas fa-plane"></i>} url={'/flights/main'}/>
                            <Option style={{marginRight: '0.5rem'}} active={route.includes('/hotels')} title='Hotels' icon={<i className="me-2 fas fa-bed"></i>} url={'/hotels'}/>
                            {/* <Dropdown className='me-2' title='Flights' icon={<i className="fas fa-plane me-3"/>} options={[
                                {name: 'Flights', url: '/flights/main'},
                                {name: 'OMG Fares', url: '/flights/deals', badge: 'Deals'},
                            ]}/> */}
                            {/*<Option style={{marginRight: '0.5rem'}} active={route.includes('/trains')} title='Train' icon={<i className="me-2 fas fa-train"></i>} url={'/trains'}/>*/}
                            <Option style={{marginRight: '0.5rem'}} active={route.includes('/buses')} title='Bus' icon={<i className="me-2 fas fa-bus"></i>} url={'/buses'}/>
                        </ul>
                        <div className='d-flex align-items-center'>
                            <ul className="navbar-nav m-auto align-items-center">
                                <Option style={{marginRight: '0.5rem'}} active={route === '/bookings'} title='Bookings' icon={<i className="fas fa-asterisk me-2"/>} url={'/bookings'}/>
                                {
                                    displayUser()
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
            {
                Support&&<SupportDetails onClose={()=>setSupport(false)}/>
            }
        </>
    );
}

export default Index;
