import React from 'react';
import moment from 'moment';

function BookingDetails({TRAVELLERS, DETAILS}) {

    let OnwardDetails = DETAILS.ONWARDS
    let ReturnDetails = DETAILS.RETURN

    return (
        <>
            <h5 style={{fontWeight: 'bold'}}>Booking Details</h5>
            
            <h6 className='fw-bold mb-0 mt-4'>{OnwardDetails[0].departure.city} to {OnwardDetails[OnwardDetails.length-1].arrival.city} </h6>
            <div className='row'>
                <div className='col-md-6 mt-3'>
                    <div className="p-3 border bg-light rounded-3 h-100">
                        <h6 className="fw-bold">Departure</h6>
                        <p className="m-0 text-small">
                            <div className="text-center mt-2 text-small d-flex align-items-center mb-3">
                                <img className="me-3" src={OnwardDetails[0].logo} alt='logo' style={{width: 30, height: 30, objectFit: 'cover', borderRadius: 30}} />
                                <div>{OnwardDetails[0].airline} {OnwardDetails[0].flightCode}-{OnwardDetails[0].flightNumber}</div>
                            </div>
                            <b>{moment(OnwardDetails[0].departureTime).format('HH:mm, DD MMMM YY')}</b>
                            <br/>
                            <b>{OnwardDetails[0].departure.city}</b>, {OnwardDetails[0].departure.terminal} {OnwardDetails[0].departure.airport}
                            {
                                OnwardDetails.length>1?
                                <div className='d-flex align-items-center mt-2'>
                                    <div className='me-2'>Layover at</div>
                                    {
                                        OnwardDetails.map((leg,index)=>{
                                            if(index===0){
                                                return <></>
                                            }
                                            return (
                                                <div className='me-2 fw-bold border p-1 rounded-3'>{leg.departure.city}</div>
                                            )
                                        })
                                    }
                                </div>
                                :<></>
                            }
                        </p>
                    </div>
                </div>
                <div className='col-md-6 mt-3'>
                    <div className="p-3 border bg-light rounded-3 h-100">
                        <h6 className="fw-bold">Arrival</h6>
                        <p className="m-0 text-small">
                            <div className="text-center mt-2 text-small d-flex align-items-center mb-3">
                                <img className="me-3" src={OnwardDetails[OnwardDetails.length-1].logo} alt='logo' style={{width: 30, height: 30, objectFit: 'cover', borderRadius: 30}} />
                                <div>{OnwardDetails[OnwardDetails.length-1].airline} {OnwardDetails[OnwardDetails.length-1].flightCode}-{OnwardDetails[OnwardDetails.length-1].flightNumber}</div>
                            </div>
                            <b>{moment(OnwardDetails[OnwardDetails.length-1].arrivalTime).format('HH:mm, DD MMMM YY')}</b>
                            <br/>
                            <b>{OnwardDetails[OnwardDetails.length-1].arrival.city}</b>, {OnwardDetails[OnwardDetails.length-1].arrival.terminal}  {OnwardDetails[OnwardDetails.length-1].arrival.airport} 
                        </p>
                    </div>
                </div>
            </div>

            <h6 className='fw-bold mb-0 mt-4'>Return {ReturnDetails[0].departure.city} to {ReturnDetails[ReturnDetails.length-1].arrival.city} </h6>
            <div className='row'>
                <div className='col-md-6 mt-3'>
                    <div className="p-3 border bg-light rounded-3 h-100">
                        <h6 className="fw-bold">Departure</h6>
                        <p className="m-0 text-small">
                            <div className="text-center mt-2 text-small d-flex align-items-center mb-3">
                                <img className="me-3" src={ReturnDetails[0].logo} alt='logo' style={{width: 30, height: 30, objectFit: 'cover', borderRadius: 30}} />
                                <div>{ReturnDetails[0].airline} {ReturnDetails[0].flightCode}-{ReturnDetails[0].flightNumber}</div>
                            </div>
                            <b>{moment(ReturnDetails[0].departureTime).format('HH:mm, DD MMMM YY')}</b>
                            <br/>
                            <b>{ReturnDetails[0].departure.city}</b>, {ReturnDetails[0].departure.terminal} {ReturnDetails[0].departure.airport}
                            {
                                ReturnDetails.length>1?
                                <div className='d-flex align-items-center mt-2'>
                                    <div className='me-2'>Layover at</div>
                                    {
                                        ReturnDetails.map((leg,index)=>{
                                            if(index===0){
                                                return <></>
                                            }
                                            return (
                                                <div className='me-2 fw-bold border p-1 rounded-3'>{leg.arrival.city}</div>
                                            )
                                        })
                                    }
                                </div>
                                :<></>
                            }
                        </p>
                    </div>
                </div>
                <div className='col-md-6 mt-3'>
                    <div className="p-3 border bg-light rounded-3 h-100">
                        <h6 className="fw-bold">Arrival</h6>
                        <p className="m-0 text-small">
                            <div className="text-center mt-2 text-small d-flex align-items-center mb-3">
                                <img className="me-3" src={ReturnDetails[ReturnDetails.length-1].logo} alt='logo' style={{width: 30, height: 30, objectFit: 'cover', borderRadius: 30}} />
                                <div>{ReturnDetails[ReturnDetails.length-1].airline} {ReturnDetails[ReturnDetails.length-1].flightCode}-{ReturnDetails[ReturnDetails.length-1].flightNumber}</div>
                            </div>
                            <b>{moment(ReturnDetails[ReturnDetails.length-1].arrivalTime).format('HH:mm, DD MMMM YY')}</b>
                            <br/>
                            <b>{ReturnDetails[ReturnDetails.length-1].arrival.city}</b>, {ReturnDetails[ReturnDetails.length-1].arrival.terminal} {ReturnDetails[ReturnDetails.length-1].arrival.airport} 
                        </p>
                    </div>
                </div>
            </div>

            <h6 className="fw-bold mb-0 mt-3">Passengers ({(TRAVELLERS.adult.length + TRAVELLERS.child.length + TRAVELLERS.infant.length)})</h6>
            <div className='row'>
                <div className='col-md-6 mt-3'>
                    <div className="p-3 border bg-light rounded-3 h-100">
                        <p className="m-0 text-small">
                        {
                            DETAILS.travellers.map((person,index)=>{
                                return <><b>{person.name&&person.name}</b> ({person.email&&person.email}, {person.mobile&&person.mobile})<br/></>
                            })
                        }
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BookingDetails;