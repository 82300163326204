
import React, {useState} from 'react';
import Modal from '../../../../../Component/Containers/Modal'
import Tabs from '../../../../../Component/Tabs'

function BoardandDrop({drop, board, onClose}) {
    
    const [Tab, setTab] = useState('Boarding');

    return (
        <>
        <Modal onClose={onClose} title='Boarding &amp; Drop Points' noButtons={true}>
            <div className='w-100'>
                <div>
                    <Tabs background='var(--material-silver)' active={Tab} tabs={[
                        {label: 'Boarding', onClick: ()=>{setTab('Boarding')}},
                        {label: 'Drops', onClick: ()=>{setTab('Drops')}},
                    ]}/>
                </div>
                <div className='mt-3 pe-2' style={{maxHeight:300, overflowY:'scroll'}}>
                {
                    Tab==='Boarding'?
                    board.map((point,index)=>{
                        return <div key={index} className='text-nowrap bg-light border p-2 rounded-3 mt-1 text-small'>{point.name}</div>
                    })
                    :Tab==='Drops'?
                    drop.map((point,index)=>{
                        return <div key={index} className='text-nowrap bg-light border p-2 rounded-3 mt-1 text-small'>{point.name}</div>
                    })
                    :<></>
                }
                </div>
            </div>
        </Modal>
        </>
    );
}

export default BoardandDrop;