import React, { useState, useEffect } from 'react';
import {get} from '../../Model/Config/Axios'
import {showToast} from '../../Component/Toast'
import FareCalc from '../Flights/Main/FareCalc'
import BookingCalc from '../Hotels/FareCalc'
import { useHistory, useLocation } from 'react-router-dom';
import Checkout from '../../Component/Razorpay'
import Visa from './Icons/visa.png';
import Master from './Icons/master.png';
import Rupay from './Icons/rupay.png';
import UPI from './Icons/upi.png';
import Rewards from './Rewards'
import BookingDetails from './BookingDetails'
import { useLoginHook } from '../../Hooks/useLoginHook';
import {post} from '../../Model/Config/Axios'
import axios from 'axios'
import ProcessingPage from './Processing'
import Loader from '../../Component/Loader'
import BusDetails from '../Buses/Booking/Common/Details'

function Index() {

    const [RazPay, setRazPay] = useState(undefined);
    //Booking Type
    const [BookingType, setBookingType] = useState();
    const [Processing, setProcessing] = useState();
    const [TransID, setTransID] = useState();
    const DETAILS = RazPay?RazPay.data:undefined

    //Checking for Addons
    let ADDONS = []
    if(RazPay){
        RazPay.data?.travellers.forEach(traveller => {
            if(Object.keys(traveller.meals).length){
                ADDONS.push('Meals')
            }
            if(Object.keys(traveller.seats).length){
                ADDONS.push('Seats')
            }
            if(traveller.baggage){
                ADDONS.push('Baggage')
            }
        });
    }

    let CONFIG = useLoginHook()
    let HISTORY = useHistory();
    let {state} = useLocation();
    const ORDERID = state.OrderId
    const TRAVELLERS = state.travellers
    const TYPE = state.type
    //For Flights
    const REVIEW = state.review
    //For Hotel
    const ROOMS = state.rooms
    const GUESTS = state.guests
    const HOTEL = state.hotel
    //For Bus
    const POINTS = state.points
    const BUS_DETAILS = state.details
    const SEATS = state.seats
    
    if(!TRAVELLERS || !ORDERID){
        showToast({
            type: 'error',
            message:'Invalid Session'
        })
        HISTORY.goBack()
    }

    useEffect(() => {
        //Initializing
        setRazPay()
        setProcessing()
        setBookingType()
        setTransID()

        if(ORDERID){
            getPaymentInfo(ORDERID)
        }
         // eslint-disable-next-line
    }, [ORDERID]);

    const updateCoins = (coins) => {
        axios.patch(`https://provider.dice.tech/personal/bookings/order`,{coins: Number(coins), id: ORDERID}).then(res=>{
            let r = res.data
            if(r.status==='PENDING'){
                setRazPay(r)
                showToast({
                    type:'success',
                    message: 'Rewards Applied'
                })
            }else{
                setProcessing('HOLD_FAIL')
            }
        }).catch(e=>{
            showToast({
                type:'error',
                message: 'Problem in apply coins'
            })
        })
    }

    const getPaymentInfo = (id) => {
        get(`/bookings/order`,(e,r)=>{
            if(r.errors){
                showToast({
                    type:'error',
                    message: 'Problem in fetching payment details'
                })
            }else if(r){
                if(r.status==='PENDING'){
                    setRazPay(r)
                }else{
                    setProcessing('HOLD_FAIL')
                }
            }else if(e){
                showToast({
                    type:'error',
                    message: 'Problem in fetching payment details'
                })
            }
        },{id})
    }

    const BookingStatus = (transId) => {
        get(`/bookings/${transId}/details`, (e,r)=>{
            if(r && r.status){
                setBookingType(r.type)
                if(r.status==='ONGOING' || r.status==='CAPTURED'){
                    //Overriding to forcefully show success page
                    setProcessing('COMPLETED')
                }else{
                    setProcessing(r.status)
                }
            }else if(e){
                setTimeout(() => {
                    BookingStatus(transId)
                }, 1000);
                // setProcessing('BOOKING_FAILED')
                showToast({
                    type:'error',
                    message: 'Problem in fetching payment details'
                })
            }
        })
    }

    const PaymentSucess = (response) => {
        setProcessing('ONGOING')
        post(`/bookings/order/async`, {paymentId: response.razorpay_payment_id, id: ORDERID, sign: `SIGNED`}, (e,r)=>{
            if(r){
                BookingStatus(r.transId)
                setTransID(r.transId)
            }else if(e){
                setProcessing('BOOKING_FAILED')
            }
        })
    } 
    
    const PayButton = () => {
        if(RazPay){
            return <Checkout onSuccess={PaymentSucess} onFailure={()=>setProcessing('PAYMENT_FAIL')} processing={setProcessing} sessionId={ORDERID} orderId={RazPay.hexId} apiKey={RazPay.key} amount={RazPay.pgAmount} name={CONFIG.name} email={CONFIG.email}/>
        }else{
            return (
                <button className='btn btn-lg btn-dark rounded-pill mt-4 ps-4' style={{border:'2px solid #000'}}>
                    <div className="spinner m-auto"><div className="bounce1" /><div className="bounce2" /><div className="bounce3" /></div>
                </button>
            )
        }
    } 

    if(Processing){
        return (
            <div className="container mb-5 mt-5">
                <div className="row">
                    <div className="col-md-8 offset-md-2 text-center">
                        <div className='rounded-4 p-4 bg-white shadow-sm'>
                            <ProcessingPage type={BookingType} State={Processing} transId={TransID}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
        <div className="container mb-5">
            <div className="row">
                <div className="col-md-8 col-xl-9 p-3 pt-4 pt-md-5 pb-3">
                <div className="d-flex">
                    <div style={{background: '#000', color: '#fff', height: 25, width: 25, borderRadius: 30, marginRight: 15, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 12, fontWeight: 'bold'}}><span>3</span></div>
                    <h5 style={{fontWeight: 'bold'}}>Complete Payment</h5>
                </div>
                <Rewards updateCoins={updateCoins} coins={RazPay?RazPay.coins:0}/>
                <div className='mt-3 p-4 rounded-3 bg-white shadow-sm' style={{padding:'2rem'}}>
                    {
                        RazPay?
                        <BookingDetails type={TYPE} flight={{DETAILS, TRAVELLERS}} hotel={{ROOMS, TRAVELLERS, GUESTS, HOTEL}} bus={{BUS_DETAILS, POINTS, TRAVELLERS}}/>
                        :<div className='w-100 d-flex flex-center'><Loader/></div>
                    }
                    <div className='mt-5'>
                        <h2 className='fw-bold'>Total Rs. {Math.ceil(RazPay?RazPay.pg:0)}</h2>
                    </div>
                </div>
            </div>
            <div className="col-md-4 col-xl-3 p-0 pe-3 ps-3">
                {
                    TYPE==='Round Flight' || TYPE==='Oneway Flight'?
                    <FareCalc button={PayButton()} loading={!RazPay} total={RazPay?RazPay.pg:0} coins={RazPay?RazPay.coins:0} tax={REVIEW?REVIEW.review.fare.taxes.total:0} base={REVIEW?REVIEW.review.fare.baseFare:0} addonTypes={ADDONS} addon={RazPay?RazPay.data.addOns:0} passengers={{adult:TRAVELLERS.adult.length,child:TRAVELLERS.child.length,infant:TRAVELLERS.infant.length}}/>
                    :TYPE==='Hotel'?
                    <BookingCalc button={PayButton()} adult={TRAVELLERS.adult} child={TRAVELLERS.child} rooms={TRAVELLERS.rooms} price={TRAVELLERS.total} coins={0} fee={0} />
                    :TYPE==='Bus'?
                    <div className='mt-5'>
                    <BusDetails showTotal={false} points={POINTS} seats={SEATS} details={BUS_DETAILS}/>
                    {PayButton()}
                    </div>
                    :<></>
                }
            </div>
        </div>
        <div className='row col-md-9 ps-3 mt-3'>
            <div className='col-md-6'><p className='text-small text-muted m-0'><i className="fas fa-lock me-3"></i>This Payment is secured by Razorpay</p><div className='mt-3 mb-3 d-flex align-items-center'><img className='me-3' alt='Visa' src={Visa} style={{maxWidth:50}}/><img className='me-3' alt='Master' src={Master} style={{maxWidth:30}}/><img className='me-3' alt='Rupay' src={Rupay} style={{maxWidth:70}}/><img className='me-3' alt='UPI' src={UPI} style={{maxWidth:60}}/></div></div>
        </div>
    </div>
    </>
    );
}

export default Index;