import React from 'react';
import Modal from '../../Component/Containers/Modal';
import useOriginHook from "../../Hooks/useOrigin";
import ClearTrip from '../Logos/ClearTrip';
import Dice from '../Logos/Dice';

function Contact({onClose}) {
	let {CLEARTRIP} = useOriginHook();

    return (
        <Modal onClose={onClose} title='Reach Support' noButtons={true}>
            <div className='flex flex-column w-100'>
                {CLEARTRIP?<ClearTrip white={true}/>:<Dice/>}
                <div className='w-100 pb-3 mt-4'>
                For any Booking related issues<br/>
                Log on to www.cleartrip.com/support Call <b>+91-98205 10666</b> (24X7)<br/> 
                Or email at <b>perks@cleartrip.com</b>
                </div>
            </div>
        </Modal>
    );
}

export default Contact;