import React, {useState, useEffect} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {get, post} from '../../../../Model/Config/Axios'
import { showToast } from '../../../../Component/Toast';
import AddGuest from '../../../Account/Travellers/Add'
import Details from '../Common/Details'
import Loader from '../../../../Component/Loader'
import moment from 'moment';

function Index() {

    let HISTORY = useHistory();
    let {state} = useLocation();
    let DETAILS = state.details
    let POINTS = state.points
    let SEATS = state.seats
    let SESSION = state.session
    let BUSID = state.busId

    if(!(DETAILS||POINTS||SEATS)){
        showToast({
            type: 'error',
            message:'Invalid Session'
        })
        HISTORY.push('/buses')
    }


    const [TravellerList, setTravellerList] = useState({adult:[], child: [], infant: []});
    const [SelectedTravellers, setSelectedTravellers] = useState({});

    const [AddModal, setAddModal] = useState(undefined);
    // eslint-disable-next-line
    const [Processing, setProcessing] = useState(undefined);

    useEffect(() => {
        fetchTravellers()
    }, []);

    const fetchTravellers = (params) => {
        get(`travellers`, (e,r)=>{
            if(r){
                let travellers = {adult:[], child: [], infant: []};
                r.travellers.forEach(traveller => {
                    if(traveller.type==='ADULT'){
                        travellers.adult.push(traveller)

                    }else if(traveller.type==='CHILD'){
                        travellers.child.push(traveller)

                    } else if(traveller.type==='INFANT'){
                        travellers.infant.push(traveller)
                    }
                });
                setTravellerList(travellers)

            }else if(e){
                showToast({
                    type: 'error',
                    message: e.data?e.data.message:'Error in fetching Guests'
                })
            }
        })
    }

    const addTraveller = (data) => {      
        if(data){
         post(`travellers/`, data, (e,r)=>{
             if(r){
                 showToast({
                     type: 'success',
                     message: 'Guest Added'
                 })
                 setAddModal(undefined)
                 fetchTravellers()
                 
             }else if(e){
                 showToast({
                     type: 'error',
                     message: e.data?e.data.message:'Error in adding Guest'
                 })
             }
         })
        }
     }

    const selectGuest = (guest, bool, id) => {
        let guests = {...SelectedTravellers}
        if(bool){
            delete guests[id]
        }else{
            guests[id] = guest
        }
        setSelectedTravellers(guests)
    }

    const renderTravellers = (traveller, id, index) => {
        let isSelected = false;
        if(SelectedTravellers[id]&&SelectedTravellers[id].id===traveller.id){
            isSelected = true
        }

        let flag = true
        Object.keys(SelectedTravellers).forEach(node => {
            if(SelectedTravellers[node].id===traveller.id && node!==id){
                flag = false
            }
        });
        
        if(flag){
            return (
                <div onClick={()=>{selectGuest(traveller, isSelected, id)}} key={index} className={`hoverExpand FadeUp-Effect mt-2 me-3 rounded-pill text-nowrap cursor-pointer ${isSelected&&'text-white'}`} style={{background: isSelected?'var(--bs-green)':'var(--bs-gray-400)', padding: '0.7rem'}}>
                    <h6 className="m-0 text-small">{isSelected?<i className="fas fa-check me-2" />:<i className="fas fa-plus-circle me-2" />}{traveller.name}</h6>
                </div>
            )
        }else{
            return <></>
        }
    }

    const getOrderId = (sessionId, busId, dropId, boardId, travellers) => {
        setProcessing(true)
		post(`/bus/hold`, {sessionId,busId, dropId, boardId, details:{travellers}}, (e,r)=>{
            if(r){
                HISTORY.push({
                    pathname: `/buses/booking/payment`,
                    state: {OrderId: r.id, travellers: SelectedTravellers, type:'Bus', details: DETAILS, points: POINTS, seats: SEATS, session: SESSION, busId: BUSID}
                })
            }else if(e){
                setProcessing(false)
                showToast({
                    message: e.data?e.data.message:'Error in fetching Order Id',
                })
            }
        })
    }

    const MoveNext = () => {
        if(Object.keys(SelectedTravellers)<SEATS?.length){
            showToast({
                type: 'error',
                message:'Please select traveller for every seat'
            })
            return
        }
        const travellers = []
        for(const seat in SelectedTravellers){
            const {gender, name, email,mobile, dob} = SelectedTravellers[seat]
            travellers.push({age: moment().diff(dob, 'years'),gender,seatNo: seat,name,email,mobile})
        }
        getOrderId(SESSION,BUSID, POINTS.drop.code, POINTS.pick.code, travellers)
    }

    return (
        <>
        <div className="container mb-5">
            <div className="row">
                <div className="col-md-8 p-3 pt-4 pt-md-5 pb-3">
                    <div className="d-flex">
                        <div style={{background: '#000', color: '#fff', height: 25, width: 25, borderRadius: 30, marginRight: 15, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 12, fontWeight: 'bold'}}><span>2</span></div>
                        <h5 style={{fontWeight: 'bold'}}>Add Guest Details</h5>
                    </div>
                    <div className="border-box mt-4 mb-4 border bg-light">
                        <p className="mb-0 text-small">
                            Enter your details as mentioned on Aadhar Card or any other Government issued ID
                        </p>
                    </div>

                    <div>
                        <div className='d-flex flex-wrap align-items-center justify-content-between mb-5'>
                            <div className='mt-2'>
                                <h5 style={{fontWeight: 'bold'}}>Select Travellers</h5>
                                <div className='text-small'>We need details of one guest for each seat</div>
                            </div>
                            <button onClick={()=>{setAddModal(true)}} className='btn btn-oultine-dark text-small btn-sm rounded-pill p-2 ps-3 pe-3 mt-2' style={{border:'2px solid #000'}}>+ New Guest</button>
                        </div>
                        {
                            SEATS?
                            SEATS.map((seat, index)=>{
                                return (
                                    <div key={index} className='bg-white shadow-sm p-4 rounded-4 mt-3'>
                                        <h6>Traveller on seat ({seat.code})</h6>
                                        <div className='guests-list d-flex flex-wrap align-items-center mt-3'>
                                            {   TravellerList.adult?.length?
                                                TravellerList.adult?.map((person, index)=>renderTravellers(person, seat.code, index))
                                                :<button onClick={()=>{setAddModal(true)}} className='btn btn-success rounded-pill btn-sm mt-2 p-2 ps-3 pe-3'>+ New</button>
                                            }
                                            {   TravellerList.child?.length?
                                                TravellerList.child?.map((person, index)=>renderTravellers(person, seat.code, index))
                                                :<button onClick={()=>{setAddModal(true)}} className='btn btn-success rounded-pill btn-sm mt-2 p-2 ps-3 pe-3'>+ New</button>
                                            }
                                        </div>
                                    </div>
                                )
                            })
                            :<div className="border-box mt-4 shadow-sm rounded-3 overflow-hidden text-center"><Loader/></div>
                        }
                    </div>
            </div>
            <div className="col-md-4 p-0 pe-3 ps-3 mt-5">
                <Details points={POINTS} seats={SEATS} details={DETAILS}/>
                {
                    Processing
                    ?<button className='btn btn-lg btn-dark rounded-pill ps-4 mt-4'><div className="spinner m-auto"><div className="bounce1" /><div className="bounce2" /><div className="bounce3" /></div></button>
                    :<button onClick={MoveNext} className='btn btn-lg btn-dark mt-4 rounded-pill'>Continue<i className="fas fa-arrow-right ms-3"/></button>
                }
            </div>
        </div>
    </div>
    {
        AddModal&&<AddGuest onClose={()=>setAddModal(undefined)}  onSubmit={addTraveller}/>
    }
    </>
    );
}

export default Index;