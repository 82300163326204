import React, {useId, useRef, useState} from 'react';
import './index.css'

function Index({placeholder, searchValue, disabled, value, onSelect, list, className=''}) {
    const [Value, setValue] = useState(value?value:'');
    const [FilteredValues, setFilteredValues] = useState([]);

    const ID = useId();
    let LIST = useRef(null), INPUT = useRef(null);

    const onChange = () => {
        let val = INPUT.current.value;
        if(searchValue){
            searchValue(val)
        }

        if(val.length>1){
            val = val.toLowerCase()
            let filtered = list.filter((item)=>(item.name&&item.name.toLowerCase().includes(val))||(item.description&&item.description.toLowerCase().includes(val)))
            setFilteredValues(filtered)
        }else{
            setFilteredValues(list)
        }
    }
    const selectItem = (item) => {
        setValue(`${item.name}`);
        onSelect(item)
        setFilteredValues([])
    }

    return (
        <>
        <div className={`position-relative ${className}`}>
            <input onBlur={()=>setFilteredValues([])} autoComplete='off' id={ID} ref={INPUT} onFocus={onChange} onChange={(e)=>{setValue(e.target.value);onChange()}} type='text' placeholder={placeholder} disabled={disabled} className='form-control hotel-search-input' value={Value}/>
            {
                FilteredValues.length?
                <div onMouseDown={(e) => e.preventDefault()} ref={LIST} className='hotel-search FadeDown-Effect'>
                    <div className='header text-muted'>Results</div>
                    {
                        FilteredValues.map((item,index)=>{
                            return (
                                <div onClick={()=>{
                                    if(item.pricePerNight>1){
                                        selectItem(item)
                                    }
                                }} key={index} className='item d-flex'>
                                    <img src={item.image} loading='lazy' className='me-2 rounded-4' alt='Hotel' style={{objectFit:'cover', width:40, height:40}}/>
                                    <div className='overflow-hidden'>
                                        <div className='text-truncate'>{item.name}</div>
                                        {
                                            item.pricePerNight>1?
                                            <div className='text-small text-truncate'>{item.address}</div>:
                                            <div className='text-small fw-bold'>Unavailable</div>
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                :<></>
            }
        </div>
        </>
    );
}

export default Index;
