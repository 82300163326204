import React, {useState} from 'react';

function    Index({onChange}) {

    const [SwitchMode, setSwitchMode] = useState(false);

    const ToggleSwitch = () => {
        setSwitchMode(!SwitchMode)
        onChange(!SwitchMode)
    }

    return (
        <>
        <div onClick={ToggleSwitch} className="d-inline-flex rounded-pill bg-dark cursor-pointer" style={{fontSize: '0.7rem'}}>
            <div className={`d-inline-flex rounded-pill p-2 border border-dark ${SwitchMode?'text-white':'bg-white'}`}><span>One Way</span></div>
            <div className={`d-inline-flex rounded-pill p-2 border border-dark ${!SwitchMode?'text-white':'bg-white'}`}><span>Round Trip</span></div>
        </div>
        </>
    );
}

export default Index;