import React, {useState} from 'react';

function Index({tabs=[{label:'', onClick: ()=>{}}]}) {

    const [Active, setActive] = useState(tabs[0].label);

    return (
        <div className='shadow-sm tabs scrollbar-hidden'>
            {
                tabs.map((tab, index)=>{
                    return (
                        <div onClick={()=>{setActive(tab.label); tab.onClick()}} key={index} className={Active===tab.label?'tab-active':'tab'}><button className='btn w-100 p-3'>{tab.label}</button></div>
                    )
                })
            }
        </div>
    );
}

export default Index;