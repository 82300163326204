import React from 'react'
import {Route, Switch} from "react-router-dom";
import Booking from "./Booking";
import SRP from "./SRP";
import Home from "./Home";

export default function Render() {
	return (
		<>
			<Switch>
				<Route path="/buses/booking" component={Booking}/>
				<Route path="/buses/search" component={SRP}/>
				<Route path="/buses" component={Home}/>
			</Switch>
		</>
	)
}
