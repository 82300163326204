import React, {useState, useEffect} from 'react';
import NavBar from '../../../../Component/NavBar'
import SectionHeader from '../../../../Component/Containers/Section-Header'
import FlightSearch from './FlightSearch'
import FlightsResult from './Results'
import FlightSummary from './FlightSummary'
import PlaneAnimation from '../../../../Component/PlaneAnimation'
import HelpSection from "../../../Common/HelpSection";
import Tabs from './Results/Components/Tabs'
import {useLoginHook} from "../../../../Hooks/useLoginHook";
import { useHistory, useLocation } from 'react-router-dom';
import {post} from '../../../../Model/Config/Axios'
import { showToast } from '../../../../Component/Toast';
import FlightFilters from '../../../../Component/Filters/Flight'
import moment from 'moment'
import ScrollToTop from '../../../../Component/ScrollToTop/ScrollToTop'
import {useEventsHook} from '../../../../Hooks/useEventsHook';

function Index() {

	let EVENTS = useEventsHook();
    let CONFIG = useLoginHook();
	let HISTORY = useHistory();
	if(!CONFIG.authenticated){
		HISTORY.push('/login')
	}

	let {search} = useLocation();
	const Params = new URLSearchParams(search);
	let From = Params.get('from')
	let fCity = Params.get('fcity')
	let To = Params.get('to')
	let tCity = Params.get('tcity')
	let Adult = parseInt(Params.get('adult'))
	let Child = parseInt(Params.get('child'))
	let Infant = parseInt(Params.get('infant'))
	
	let DepartDate = parseInt(Params.get('departDate'))
	let ReturnDate = parseInt(Params.get('returnDate'))

	const [FilterCount, setFilterCount] = useState(0);
	let max = 10;

	//Max Limit
	if(Adult&&Adult>max){
		Adult = 10
	}
	if(Adult&&Child&&(Adult+Child)>max){
		Child = 0
	}
	if(Adult&&Child&&Infant&&(Adult+Child+Infant)>max){
		Infant = 0
	}

	const [Result, setResult] = useState({ONWARDS:[], RETURN: []});
	const [Filters, setFilters] = useState({stops:undefined, price: undefined, time:undefined, airline: undefined, category: undefined});

	const [Departure, setDeparture] = useState(undefined);
	const [Return, setReturn] = useState(undefined);

	const [FromAirport, setFromAirport] = useState({code:From, city:fCity});
	const [ToAirport, setToAirport] = useState({code:To, city: tCity});
	
	const [SelectedDates, setSelectedDates] = useState({
		startDate: DepartDate?parseInt(DepartDate):moment().valueOf(),
		endDate: ReturnDate&&parseInt(ReturnDate),
		key: 'selection'
	});
	
	const [Passengers, setPassengers] = useState({adult:Adult?Adult:1, child:Child?Child:0, infant:Infant?Infant:0});
	const [SearchFinished, setSearchFinished] = useState(false);
	const [Tab, setTab] = useState(undefined);
	const [ShowFilters, setShowFilters] = useState(undefined);

	useEffect(() => {
		if(FromAirport.code&&ToAirport.code&&SelectedDates&&Passengers){
			SearchFlights(FromAirport, ToAirport, SelectedDates, Passengers)
			EVENTS.srp({
				origin: FromAirport.code||'BLANK',
				destination: ToAirport.code||'BLANK',
				passengers: Passengers.adult+Passengers.child+Passengers.infant||0,
			})
		}
		// eslint-disable-next-line
	}, [FromAirport, ToAirport, SelectedDates, Passengers]);

	const SearchFlights = (origin, destination, dates, count) => {

		setDeparture(undefined)
		setSearchFinished(false)
		setFilterCount(0)
		HISTORY.push(`/flights/main/round/search?from=${origin.code}&fcity=${origin.city}&to=${destination.code}&tcity=${destination.city}&departDate=${dates.startDate}&returnDate=${dates.endDate}&adult=${Passengers.adult}&child=${Passengers.child}&infant=${Passengers.infant}`)
		if(dates.endDate){
			post(`flight/domestic/round/search`, {origin:origin.code, destination: destination.code, date: moment(dates.startDate).format('DD-MM-YYYY'), returnDate: moment(dates.endDate).format('DD-MM-YYYY'), count}, (e,r)=>{
				if(r.errors){
					showToast({
						message: 'Problem in searching, try again'
					})
				}
				if(r){
					setSearchFinished(true)
					setResult(r)
					setFilterCount(r.ONWARDS.length)
				}else if(e){
					setSearchFinished(true)
					showToast({
						message: 'Problem in searching, try again'
					})
				}
			})
		}
	}

	useEffect(() => {
		setDeparture(undefined)
	}, [Passengers]);

	return (
		<>
			<FlightSummary from={FromAirport} to={ToAirport} passengers={Passengers} departureFlight={Departure} returnFlight={Return} onCancel={()=>{setDeparture(undefined);setReturn(undefined)}}/>
			<SectionHeader color={'var(--material-blue)'}>
				<NavBar/>
				<FlightSearch from={FromAirport} to={ToAirport} dates={SelectedDates} passengers={Passengers} setFrom={setFromAirport} setTo={setToAirport} setDates={setSelectedDates} setPassengers={setPassengers}/>
			</SectionHeader>
			
			<div className='bg-dark d-flex justify-content-end align-items-center d-lg-none w-100'>
				<div className='flex-fill'>
					<Tabs tabs={[{label:'DEPARTURE', onClick:()=>{setTab('DEPARTURE')}},{label:'RETURN', onClick:()=>{setTab('RETURN')}}]}/>
				</div>
				<div onClick={()=>setShowFilters(true)} className='text-small d-flex align-items-center justify-content-center ps-4 pe-4'>
					<i className="fa fa-filter text-white mt-2"/>
				</div>
			</div>
			<PlaneAnimation loading={true} done={SearchFinished}/>

			<div className="container mt-4 mt-md-5 mb-5">
				<div className="row">
					<div className="col-md-12 col-lg-9">
						<FlightsResult setFilterCount={setFilterCount} Tab={Tab} setTab={setTab} loaded={SearchFinished} filters={Filters} setFilters={setFilters} passengers={Passengers} selectedDeparture={Departure} selectDeparture={setDeparture} departureFlights={Result.ONWARDS} setDepartureResult={(arr)=>setResult(prev=>({...prev, ONWARDS: arr}))} selectedReturn={Return} selectReturn={setReturn} returnFlights={Result.RETURN} setReturnResult={(arr)=>setResult(prev=>({...prev, RETURN: arr}))}/>
					</div>
					<div className='col-md-12 col-lg-3'>
						<div className='scrollbar-hidden mt-2' style={{position:'sticky', top:'2rem', overflowY:'scroll', maxHeight:'calc(100vh - 3rem)'}}>
							<FlightFilters count={FilterCount} closeFilters={setShowFilters} filters={Filters} onApply={setFilters} showFilters={ShowFilters}/>
						</div>
					</div>
				</div>
			</div>
			<HelpSection/>
			<ScrollToTop/>
		</>
	);
}

export default Index;
