import React, {useState, useEffect, useRef} from 'react';
import moment from 'moment';

function Index({min, dates, selected,onClick}) {

    const WEEKDAY = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

    const [Page, setPage] = useState(0);
    const [Dates, setDates] = useState([]);

    let Carousel = useRef(), ActiveDay = useRef();

    useEffect(() => {
        if(ActiveDay.current){
            ActiveDay.current.scrollIntoView()
        }
    }, [ActiveDay,min]);

    useEffect(() => {
        let next7days = [];
        for(let i=(Page*10); i<(Page*10+10); i++){
            next7days.push(moment(min).add(i,'day').valueOf())
        }
        setDates(prev=>[...prev, ...next7days])
        // eslint-disable-next-line
    }, [Page]);

    const scrollCarousel = (op) => {
        let width = Carousel.current.clientWidth;
        let position = Carousel.current.scrollLeft;

        if(op>0){
            Carousel.current.scroll({
                left: position+width,
                behavior: 'smooth'
              });
            if( Carousel.current.clientWidth >= (Carousel.current.scrollWidth - Carousel.current.clientWidth - Carousel.current.scrollLeft) ){
                setPage(Page+1)
            }
        }else if(op<0){
            Carousel.current.scroll({
                left: position-width,
                behavior: 'smooth'
              });
        }
    }

    return (
        <>
         <div>
            <h6 className="pt-1 pb-2">Showing for <strong>{moment(selected).format('DD-MMM-YY')}</strong></h6>
            <div  className="d-flex justify-content-between align-items-center mt-3">
                <div  onClick={()=>{scrollCarousel(-1)}} className='me-3 cursor-pointer text-white text-small bg-dark d-flex align-items-center justify-content-center' style={{width:32, minWidth:32, height:32, borderRadius:50}}>
                    <i className="fas fa-chevron-left"/>
                </div>
                <div ref={Carousel} className="d-flex flex-nowrap scroll-smooth overflow-scroll overflow-y-hidden scrollbar-hidden pb-2">
                    {
                        Dates.map((date,index)=>{
                            return (
                                <div ref={moment(selected).isSame(date, 'day')?ActiveDay:undefined} onClick={()=>onClick(date)} key={index} className={`FadeRight-Effect text-center border-box date-card shadow-sm ${moment(selected).isSame(date, 'day')&&'bg-dark text-white'}`}>
                                    <span className={`text-tuncate text-nowrap ${!moment(selected).isSame(date, 'day')&&'text-muted'}`}>{moment(date).format('DD-MMM-YY')}</span><br />
                                    <b>{WEEKDAY[moment(date).weekday()]}</b>
                                </div>
                            )
                        })
                    }
                </div>
                <div  onClick={()=>{scrollCarousel(+1)}} className='ms-3 cursor-pointer text-white text-small bg-dark d-flex align-items-center justify-content-center' style={{width:32, minWidth:32, height:32, borderRadius:50}}>
                    <i className="fas fa-chevron-right"/>
                </div>
            </div>
        </div>
        </>
    );
}

export default Index;
