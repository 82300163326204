import React from 'react';
import {useHistory} from 'react-router-dom';
import DateInput from '../../../../Component/Form/DateInput'
import {useLoginHook} from "../../../../Hooks/useLoginHook";
import moment from 'moment';
import SearchCity from "../../../../Component/Form/SearchCity";
import {showToast} from "../../../../Component/Toast";
import useHistoryState from "../../../../Hooks/useHistoryState";

function Index() {
    let HISTORY = useHistory()
    let CONFIG = useLoginHook();

    const [BusFrom, setBusFrom] = useHistoryState("from",undefined);
    const [BusTo, setBusTo] = useHistoryState("to",undefined);
    const [TravelDate, setTravelDate] = useHistoryState("travel_date",moment().valueOf());

    const swapPoints = () => {
        let temp = BusFrom;
        setBusFrom(BusTo)
        setBusTo(temp)
    }
    const SearchBus = () => {
        if(!BusFrom){
            showToast({
                type:'error',
                message:"Select Origin"
            })
            return;
        }
        if(!BusTo){
            showToast({
                type:'error',
                message:"Select Destination"
            })
            return;
        }
        if(!TravelDate){
            showToast({
                type:'error',
                message:"Select Travel date"
            })
            return;
        }

        HISTORY.push(`/buses/search?from=${BusFrom.city}&to=${BusTo.city}&date=${TravelDate}`)
    }

    return (
        <>
            <div className="p-1 search mt-4">
                <h5>Hello {CONFIG.name}</h5>
                <h1 className="display-5" style={{fontWeight: 'bold'}}>Get on a Bus.</h1>
                <div className="bg-white shadow-sm mt-4 rounded-3 p-4">
                    <div className="row mt-3">
                        <div className="col-md-8 col-xl-6">
                            <div className="d-flex justify-content-between align-items-center h-100 p-1">
                                <div style={{flex:1}}>
                                    <SearchCity api='/search/city' onSelect={setBusFrom} selected={BusFrom} label='From' placeholder='Search City' value=''/>
                                </div>
                                <div onClick={swapPoints} className="col-2 text-center"
                                     style={{transform: 'rotate(90deg)', fontSize: '1rem'}}>
                                    <div className='swap-btn'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                                             fill="currentColor" viewBox="0 0 16 16" className="bi bi-arrow-down-up">
                                            <path fillRule="evenodd"
                                                  d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5zm-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5z"/>
                                        </svg>
                                    </div>
                                </div>
                                <div style={{flex:1}}>
                                    <SearchCity api='/search/city' onSelect={setBusTo} selected={BusTo} label='To' placeholder='Search City' value=''/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-xl-3">
                            <DateInput selectedDate={TravelDate} onSelect={setTravelDate} border="var(--material-grey)" type={undefined}/>
                        </div>
                        <div className="col-md-4 col-xl-3 align-self-center">
                            <button onClick={() => SearchBus()} className="btn btn-dark btn-lg ps-4 w-100" type="button">
                                    Search
                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-arrow-right ms-2 mb-1"><path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/></svg>
                            </button>
                        </div>
                    </div>
                </div>
                <div style={{
                    marginBottom:-80
                }}/>
            </div>
        </>
    );
}

export default Index;
