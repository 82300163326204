import React, {useState, useEffect} from 'react';
import NavBar from '../../Component/NavBar'
import SectionHeader from '../../Component/Containers/Section-Header'
import moment from 'moment';
import Thumbnail from '../../Component/Thumbnail/Thumbnail'
import { useHistory, useLocation } from 'react-router-dom';
import Chat from './Chat'
import AddTicket from './Add'
import {get} from '../../Model/Config/Axios'
import Loader from '../../Component/Loader'
import Modal from '../../Component/Containers/Modal'

function Support() {

    // eslint-disable-next-line
    const [Tickets, setTickets] = useState(undefined);
    // eslint-disable-next-line
    const [FilteredTickets, setFilteredTickets] = useState(undefined);

    let HISTORY = useHistory();
    let LOCATION = useLocation()
    let pathname = LOCATION.pathname;
    let params = new URLSearchParams(LOCATION.search)
	let ticketId = params.get('id')
    const [AddModal, setAddModal] = useState(undefined);

    useEffect(() => {
        fetchTickets()
    }, []);

    const fetchTickets = (params) => {
        get(`/support/pending`,(e,r)=>{
            if(r){
                let {tickets} = JSON.parse(r)
                setTickets(tickets)
                setFilteredTickets(tickets)
            }else if(e){

            }
        },{})
    }

    const filterTickets = (val) => {
        val = val.toLowerCase();
        if(val){
            let filtered = Tickets.filter((ticket)=>(ticket.title.toLowerCase().includes(val)||ticket.category.toLowerCase().includes(val)))
            setFilteredTickets(filtered)
        }else{
            setFilteredTickets(Tickets)
        }
    }
// eslint-disable-next-line
    const renderTickets = (ticket, index) => {

        return (
            <div onClick={()=>{HISTORY.push(`/support?id=${ticket.id}`)}} key={index} className='ticket'>
                <div className='me-3'><Thumbnail letter={ticket.title[0]} size='L'/></div>
                <div className='details'>
                    <div className='d-flex justify-content-between'>
                        <div>
                            <div className='fw-bold text-truncate'>{ticket.title}</div>
                            <div className='text-truncate'>{ticket.category}</div>
                        </div>
                        <div className='d-flex flex-column text-muted' style={{alignItems:'flex-end'}}>
                            <div>{moment(ticket.createdAt).format('DD MMM YY')}</div>
                            
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
        <SectionHeader color={'var(--material-silver)'}>
            <NavBar/>
            <div className="p-1 mt-4 pb-4 ps-2">
                <h1 className="display-5 mb-0" style={{fontWeight: 'bold'}}>Support</h1>
                <div>Create support tickets for any issues in using the portal</div>
            </div>
        </SectionHeader>

        <div className='container'>
            <div className='row mt-5 mb-5'>
                <div className='col-12 col-md-4'>
                        <div className='d-flex'>
                            <input onChange={(e)=>filterTickets(e.target.value)} type="text" className="form-control bg-white me-2 rounded-3" placeholder="Search Tickets"/>
                            <button onClick={()=>setAddModal(true)} className='btn btn-sm btn-dark text-nowrap rounded-3'>+ New Issue</button>
                        </div>
                        {   FilteredTickets?
                            FilteredTickets.length?
                            <div className='bg-white shadow-sm rounded-4 mt-4 p-3 support'>
                                {FilteredTickets.map(renderTickets)}
                            </div>
                            :<div className='border p-3 rounded-4 mt-3 text-muted text-small text-center'>No Tickets found</div>
                            :<div className='d-flex flex-center w-100 mt-3'><Loader/></div>
                        }
                </div>

                <div className='col-12 col-md-8'>
                    <div className='bg-white shadow-sm rounded-4 h-100'>
                        {
                            ticketId
                            ?<Chat id={ticketId} onClose={()=>HISTORY.push(pathname)}/>
                            :<div className='d-none d-md-flex flex-center h-100' style={{minHeight:400}}><AddTicket onReload={()=>fetchTickets(ticketId)}/></div>
                        }
                    </div>
                </div>
            </div>
        </div>
        {
            AddModal&&
            <Modal onClose={()=>setAddModal(undefined)} noButtons={true}>
                <div className='p-4 w-100'>
                    <AddTicket onReload={()=>{fetchTickets(ticketId);setAddModal(undefined)}}/>
                </div>
            </Modal>
        }
        </>
    );
}

export default Support;