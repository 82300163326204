import moment from "moment/moment";
import LoadingIcon from "./loading.webp";
import React from "react";

const flightSortItem = (sort, a, b) => {
	if (sort.type === "depart")
		if (sort.asc) {
			return a.details.departure - b.details.departure;
		} else {
			return b.details.departure - a.details.departure;
		}
	if (sort.type === "arrival") {
		if (sort.asc) {
			return a.details.arrival - b.details.arrival;
		} else {
			return b.details.arrival - a.details.arrival;
		}
	}
	if (sort.type === "duration") {
		if (sort.asc) {
			return a.details.duration - b.details.duration
		} else {
			return b.details.duration - a.details.duration
		}
	}
	if (sort.type === "airline") {
		if (a.operator.name > b.operator.name) {
			if (sort.asc) {
				return -1
			} else {
				return 1;
			}
		} else if (a.operator.name < b.operator.name) {
			if (sort.asc)
				return 1
			else
				return -1;
		}
		return 0
	}
	if (sort.asc) {
		if (a.segments.length > b.segments.length) {
			return 1;
		} else if (a.segments.length < b.segments.length)
			return -1;
		else
			return a.fares[0].adult.publishedFare - b.fares[0].adult.publishedFare
	} else {
		if (a.segments.length > b.segments.length) {
			return 1;
		} else if (a.segments.length < b.segments.length)
			return -1;
		else
			return b.fares[0].adult.publishedFare - a.fares[0].adult.publishedFare
	}
}


const flightFilterUtils = (filters, flight) => {
	if (flight.fares[0]) {
		if (filters.stops && filters.stops.length) {
			if (!filters.stops.includes(flight.segments.length))
				return false
		}
		if (filters.category && filters.category.length) {
			let flag = false
			flight.fares.forEach(fare => {
				if (filters.category.includes(fare.type))
					flag = true
			});
			return flag
		}
		if (filters.price) {
			let sortedFares = flight.fares.sort((a,b)=>a.adult.publishedFare-b.adult.publishedFare)
			if (sortedFares[0].adult.publishedFare > filters.price)
				return false
		}
		if (filters.time && filters.time.length) {
			let time = '0';
			let dep = parseInt(moment(flight.details.departure).format('HH'));
			if (dep < 24) {
				time = 24
			}
			if (dep < 19) {
				time = 19
			}
			if (dep < 15) {
				time = 15
			}
			if (dep < 11) {
				time = 11
			}
			if (dep < 6) {
				time = 6
			}
			if (!filters.time.includes(time))
				return false
		}
		if (filters.airline && filters.airline.length) {
			if (!filters.airline.includes(flight.operator.name))
				return false
		}
		return true
	}
	return false
}

const FlightWrapper = ({loading, text = "flights", length, children}) => {
	if (loading) {
		return (
			<div className="flex-fill pe-2 ps-2">
				<div className='FadeUp-Effect bg-white p-4 mt-4 border rounded-3 text-center'>
					<div className='d-flex align-items-center justify-content-center'>
						<img src={LoadingIcon} alt='Loading' className='me-3' style={{maxWidth: 50}}/>
						<h5>Searching {text}...</h5>
					</div>
				</div>
				{
					Array(10).fill(0)
						.map((_, index) => {
							return (
								<div style={{
									background: "#f6f7f8"
								}} key={index} className="rounded-4 mt-3 overflow-hidden">
									<div className="Stencil has-shimmer w-100" style={{
										minHeight: 180
									}}/>
								</div>
							)
						})
				}
			</div>
		)
	}
	if (length)
		return (
			<>
				{children}
			</>
		)
	return (
		<>

			<div className="flex-fill pe-2 ps-2">
				<div className='FadeUp-Effect bg-white p-4 mt-4 border rounded-3 text-center'>
					<div className='d-flex align-items-center justify-content-center'>
						<h5>No Flights Found</h5>
					</div>
				</div>
			</div>
		</>
	)
}

export {
	flightSortItem, flightFilterUtils, FlightWrapper
}
