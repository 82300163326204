import React from 'react';
import Icon_Avatar from '../Avatars/avatar.gif'

function Index({name, avatar, options=[{name:'First', onClick:()=>{}}]}) {
    return (
            <li className="nav-item dropdown">
                <span className="nav-link rounded-pill cursor-pointer" aria-expanded="false" data-bs-toggle="dropdown">
                    {name}
                    <img className='ms-2' alt='Avatar' src={avatar?avatar:Icon_Avatar} style={{width: 25 ,height:25, objectFit: 'cover', borderRadius: 30}} />
                </span>
                <div className="dropdown-menu FadeDown-Effect shadow-sm cursor-pointer" style={{width:'inherit', left:'-50%', right:0}}>
                    {
                        options.map((item,index)=>{
                            return <span onClick={item.onClick} key={index} className="dropdown-item">{item.name}</span>
                        })
                    }
                </div>
            </li>
    );
}

export default Index;