import React, {useState, useEffect, useRef} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {post} from '../../../../../../Model/Config/Axios'
import { showToast } from '../../../../../../Component/Toast';
import FareCalc from '../../../FareCalc'
import Loader from '../../../../../../Component/Loader'
import SelectTravellers from './SelectTravellers';
import TravellerCard from './TravellerCard'

function Index() {

    let HISTORY = useHistory();
    let {state} = useLocation();

    const FARE_ID = state.FARE_ID;
    const FLIGHT_ID = state.FLIGHT_ID
    const REVIEW = state.reviewData
    const CONDITIONS = state.reviewData.review.condition

    const [MAP, setMAP] = useState(undefined);
    const {adult,child,infant} = REVIEW?REVIEW.search.count:{}
    const [SelectedTravellers, setSelectedTravellers] = useState({adult: [], child: [], infant: []});
    const getPassport = useRef({});

    const [Processing, setProcessing] = useState(false);

    if(!FLIGHT_ID || !FARE_ID){
        showToast({
            type: 'error',
            message:'Invalid Session'
        })
        HISTORY.goBack()
    }

    useEffect(() => {
        fetchSeatMap(REVIEW.flowId, FLIGHT_ID)
    }, [REVIEW, FLIGHT_ID]);
    
    const addBaggage = (baggage,type,id) => {
        let selectedTravellers = {...SelectedTravellers}
        let updateTravellers = [...SelectedTravellers[type]]
        SelectedTravellers[type].forEach((traveller,index) => {
            if(traveller.id===id){
                updateTravellers[index] = {...updateTravellers[index], baggage}
            }
        });
        selectedTravellers[type] = updateTravellers
        setSelectedTravellers(selectedTravellers)
    }

    const addMeal = (meal,type,id) => {
        let selectedTravellers = {...SelectedTravellers}
        let updateTravellers = [...SelectedTravellers[type]]
        SelectedTravellers[type].forEach((traveller,index) => {
            if(traveller.id===id){
                let meals = updateTravellers[index].meals?{...updateTravellers[index].meals, ...meal}:meal
                updateTravellers[index] = {...updateTravellers[index], meals: meals}
            }
        });
        selectedTravellers[type] = updateTravellers
        setSelectedTravellers(selectedTravellers)
    }

    const addSeat = (seat,type,id) => {

        let selectedTravellers = {...SelectedTravellers}
        let updateTravellers = [...SelectedTravellers[type]]
        SelectedTravellers[type].forEach((traveller,index) => {
            if(traveller.id===id){
                let seats = updateTravellers[index].seats?{...updateTravellers[index].seats, ...seat}:seat
                updateTravellers[index] = {...updateTravellers[index], seats: seats}
            }
        });
        selectedTravellers[type] = updateTravellers
        setSelectedTravellers(selectedTravellers)
    }

    const fetchSeatMap = (flowId, inventoryId) => {
        post(`/flight/domestic/map`, {flowId, inventoryId}, (e,r)=>{
            if(r.errors){
                setMAP(false)
            }else if(r){
                setMAP(r)
            }
            if(e){
            }
        })
      }

    const MoveNext = () => {
        if((SelectedTravellers.adult.length+SelectedTravellers.child.length+SelectedTravellers.infant.length)<(adult+child+infant)){
            showToast({
                type: 'error',
                message:'Please select all travellers'
            })
            return
        }

        let flag=true
        let data = {travellers:[]}
        Object.keys(SelectedTravellers).forEach(key => {
            if(key==='adult'&&CONDITIONS.passportMandatory){
                SelectedTravellers[key].forEach(traveller => {
                    let passportDetails = getPassport.current[traveller.id]()
                    if(!passportDetails){
                        flag=false
                    }
                    data.travellers.push({...traveller, passport: passportDetails})
                });
            }else{
                SelectedTravellers[key].forEach(traveller => {
                    data.travellers.push(traveller)
                });
            }
        });

       if(flag){
            getOrderId(REVIEW.flowId, FLIGHT_ID, data)
       }else{
           setProcessing(false)
       }
    }
    
    const getOrderId = (flowId, inventoryId, details, coins) => {
        setProcessing(true)
		post(`/flight/domestic/hold`, {flowId, inventoryId, details}, (e,r)=>{
            if(r.errors&&r.errors.length){
                setProcessing(false)
                showToast({
                    message: r.errors[0].message
                })
            }else if(r){
                HISTORY.push({
                    pathname: `/flights/main/booking/payment`,
                    state: {OrderId: r.id, travellers: SelectedTravellers, data: details, fareId:FARE_ID, flightId: inventoryId, flowId: flowId, review: REVIEW, type: 'Oneway Flight'}
                })
            }else if(e){
                setProcessing(false)
                showToast({
                    message:'Something went wrong, Try booking again'
                })
            }
            setProcessing(false)
        })
    }

    return (
        <>
        <div className="container mb-5">
            <div className="row">
                <div className="col-md-8 col-xl-9 p-3 pt-4 pt-md-5 pb-3">
                    <div className="d-flex">
                        <div style={{background: '#000', color: '#fff', height: 25, width: 25, borderRadius: 30, marginRight: 15, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 12, fontWeight: 'bold'}}><span>2</span></div>
                        <h5 style={{fontWeight: 'bold'}}>Add Traveller Details</h5>
                    </div>
                    <div className="border-box mt-4 mb-4 border bg-light">
                        <p className="mb-0 text-small">Enter your details as mentioned on Aadhar Card or any other Government issued ID</p>
                    </div>
                    {
                        REVIEW?
                        <div className='p-4 rounded-3 bg-white shadow-sm' style={{padding:'2rem'}}>
                            <SelectTravellers inventoryId={FLIGHT_ID} selected={SelectedTravellers} onSelect={setSelectedTravellers} passengers={{adult,child,infant}}/>
                        </div>
                        :<div className="border-box mt-4 shadow-sm rounded-3 overflow-hidden text-center"><Loader/></div>
                    }
                    {
                        SelectedTravellers.adult.map((person, index)=>{
                            return (
                                <TravellerCard getPassport={func=>getPassport.current[person.id]=func} passport={CONDITIONS.passportMandatory} seatMap={MAP} type='adult' setBaggage={addBaggage} setMeal={addMeal} setSeat={addSeat} rules={REVIEW} key={index} person={person} />
                            )
                        })
                    }
                    {
                        SelectedTravellers.child.map((person, index)=>{
                            return (
                                <TravellerCard seatMap={MAP} type='child' setBaggage={addBaggage} setMeal={addMeal} setSeat={addSeat} rules={REVIEW} key={index} person={person} />
                            )
                        })
                    }
                    {
                        SelectedTravellers.infant.map((person, index)=>{
                            return (
                                <TravellerCard seatMap={MAP} type='infant' setBaggage={addBaggage} setMeal={addMeal} setSeat={addSeat} rules={REVIEW} key={index} person={person} />
                            )
                        })
                    }
                </div>
                <div className="col-md-4 col-xl-3 p-0 pe-3 ps-3">
                <FareCalc button={
                        Processing
                        ?<button className='btn btn-lg btn-dark rounded-pill ps-4 mt-4'><div className="spinner m-auto"><div className="bounce1" /><div className="bounce2" /><div className="bounce3" /></div></button>
                        :<button onClick={MoveNext} className='btn btn-lg btn-dark mt-4 rounded-pill'>
                            Payment<i className="fas fa-arrow-right ms-3"/>
                        </button> 
                } loading={!REVIEW} total={REVIEW?REVIEW.review.fare.publishedFare:0} tax={REVIEW?REVIEW.review.fare.taxes.total:0} base={REVIEW?REVIEW.review.fare.baseFare:0} coins={0} passengers={{adult,child,infant}}/>
                </div>
            </div>
            
            
        </div>
        </>
    );
}

export default Index;