import React from 'react';
import Portal from '../../Portal'
import './index.css'

function Index(props) {
    return (
        <Portal>
            <div onClick={props.onClose} className="modal FadeIn-Effect" role="dialog" tabIndex={-1} style={{display: 'block', background: 'rgba(255,221,202,0.6)'}}>
                <div onClick={e => e.stopPropagation()}
                     className="modal-dialog modal-fullscreen-sm-down modal-dialog-centered" role="document"
                     style={{maxWidth: props.maxWidth ? props.maxWidth : '600px'}}>
                    <div className="modal-content bg-white shadow-lg FadeUp-Effect overflow-hidden">
                        <div style={{
                            ...props.header
                        }} className="modal-header  align-items-start">
                            <div>
                                <h4 className="modal-title">{props.title}</h4>
                                {
                                    props.description ?
                                        <p className='text-small mb-2'>{props.description}</p>
                                        : <></>
                                }
                            </div>
                            <button onClick={props.onClose} className="btn-close p-3" type="button" aria-label="Close"/>
                        </div>
                        <div className="modal-body pt-0">
                            {
                                props.children
                            }
                        </div>
                        {
                        props.noButtons ? <></>
                            : <div className="modal-footer m-auto w-100">
                                <div className='col-12 col-md-4 p-1 pt-2 m-auto'>
                                    <button style={{fontSize:'1rem'}} onClick={props.onSuccess} className="btn btn-dark w-100 btn-lg rounded-pill" type="button">{props.button ? props.button : 'Apply'}</button>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </Portal>
    );
}

export default Index;
